import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Route } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Chip from "@material-ui/core/Chip";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
//local imports
import Header from "../Header";
import { style, colorStyles } from '../../Styles/theme';
import CalenderSiderLoader from '../Shared/SkeletonLoaders/calenderSiderLoader';
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { fetchOrgGrpsList } from '../../Store/Discussions/actionCreator';
import { listEventsCalendar, createMeetingEvent, deleteMeetingEvent, modifyMeetingEvent } from "../../Store/Meetings/actionCreator";
import { getDomainInfo } from '../../Helpers/basic';
import { fetchProfile } from '../../Store/Auth/actionCreator';
//FOR TEXT EDITOR CHANGES
import FormHelperText from '@material-ui/core/FormHelperText';
import TextEditor from '../Shared/TextEditor';

const localizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const inpersonMettingOptions = [
  {
    "id": 1,
    "location": "Remote"
  }
]

function Meetings(props) {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:600px)');
  const showNormalTextFieldAgenda = true;

  const [userDetails, setUserDetails] = useState({})
  const domainDetails = getDomainInfo();
  const [eventsListLoader, setEventsListLoader] = useState(true)
  const [eventsSiderList, setEventsSiderList] = useState([]);
  const [eventsListData, setEventsListData] = useState([]);
  const [eventsListDataCal, setEventsListDataCal] = useState([]);
  const [meetingUniqId, setMeetingUniqId] = useState();
  const [title, setTitle] = useState("");
  const [meetingType, setMeetingType] = useState(2);
  const [meetingTypeName, setMeetingTypeName] = useState("");
  const [agenda, setAgenda] = useState("");
  const [createEvent, setCreateEvent] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD[T]HH:mm"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [renderMeetingInfo, setRenderMeetingInfo] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [duration, setDuration] = useState(30);
  const [recType, setRecType] = useState(1);
  const [recInterval, setRecInterval] = useState(1);
  const [approvalType, setApprovalType] = useState(0);
  const [approvalTypeName, setApprovalTypeName] = useState("");
  const [recordingType, setRecordingType] = useState("none");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [recTypeName, setRecTypeName] = useState("");
  const [recurringDays, setRecurringDays] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [meetingURL, setMeetingURL] = useState("");
  const [meetingId, setMeetingId] = useState();
  const [meetingPassword, setMeetingPassword] = useState();
  const [errRecInteval, setErrRecInterval] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [modifyEventInfo, setModifyEventInfo] = useState(false);
  const [renderStartBtn, setRenderStartBtn] = useState(false);
  const [displayZoom, setDisplayZoom] = useState(false);
  const [zoomProps, setZoomProps] = useState();
  const [dtTimeErrFlag, setDtTimeErrFlag] = useState(false);
  const [durErrFlag, setDurErrFlag] = useState(false);
  //ENTIRE ORG GRPS SETSTATE
  const [orgGrpsList, setOrgGrpsList] = useState([])
  //ENTIRE ORG GRP MEMBERS SETSTATE
  const [orgGrpMemsList, setOrgGrpMemsList] = useState([])
  //selected grp from grp selection dropdown set state
  const [selectedGrp, setSelectedGrp] = useState([])
  //selected grp members from grp selection dropdown set state
  const [selectedGrpMemsList, setSelectedGrpMemsList] = useState([])
  const [defaultSelGrpMemList, setDefaultSelGrpMemList] = useState([])
  const [participantsList, setParticipantsList] = useState([]);
  //selected individual grp members from members selection
  const [selectIndividualMems, setSelectedIndividualMems] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])
  const [membersEmptyCheck, setMemEmptyCheck] = useState(false)
  const [isLoggedInUserHost, setIsLoggedInUserHost] = useState(false)
  const [selectedEventLabels, setSelectedEventLabels] = useState([])
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState('')
  //FOR INPERSON MEETING SELECTION
  const [selectedInPersLoc, setSelectedInPersLoc] = useState([]);
  const [defaultInPernLoacations, setDefaultInPernLocations] = useState([]);
  const [locEmptyErr, setLocEmptyErr] = useState(false);
  const [filterSearchTerm, setFilterSearchTerm] = React.useState('')
  const [filterApplied, setFilterApplied] = React.useState(false);

  const [daysCheckBox, setDaysCheckBox] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });
  const [meetingSettings, setMeetingSettings] = useState({
    host_video: false,
    participant_video: false,
    join_before_host: false,
    watermark: false,
    waiting_room: false,
    registrants_email_notification: false,
    meeting_authentication: false,
  });


  const daysOfWeek = [
    { id: 1, name: "sun", label: "SUN" },
    { id: 2, name: "mon", label: "MON" },
    { id: 3, name: "tue", label: "TUE" },
    { id: 4, name: "wed", label: "WED" },
    { id: 5, name: "thu", label: "THU" },
    { id: 6, name: "fri", label: "FRI" },
    { id: 7, name: "sat", label: "SAT" },
  ];

  const recurrenceTypeList = [
    { id: 1, name: "Daily" },
    { id: 2, name: "Weekly" },
    { id: 3, name: "Monthly" },
  ];

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSettingsChange = (event) => {
    setMeetingSettings({
      ...meetingSettings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDaysCheckBoxChanged = (event) => {
    setDaysCheckBox({
      ...daysCheckBox,
      [event.target.name]: event.target.checked,
    });
  };

  const createNewEvent = () => {
    setMemEmptyCheck(false)
    setTitleError(false)
    setModifyEventInfo(false);
    setDtTimeErrFlag(false);
    setDurErrFlag(false);
    setCreateEvent(true);
    setTitle("");
    setAgenda("");
    setDuration(30);
    setMeetingType(2);
    setRecType(1);
    setRecInterval(1);
    setApprovalType(0);
    setRecordingType("none");
    setStartDate(moment(new Date()).format("YYYY-MM-DD[T]HH:mm"));
    setMeetingSettings({
      host_video: false,
      participant_video: false,
      join_before_host: false,
      watermark: false,
      waiting_room: false,
      registrants_email_notification: false,
      meeting_authentication: false,
    });
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
  };

  const handleMeetingTypeChange = (event) => {
    setMeetingType(event.target.value);
  };

  const handleRecordingTypeChange = (event) => {
    setRecordingType(event.target.value);
  };

  const handleApprovalTypeChange = (event) => {
    setApprovalType(event.target.value);
  };

  const handleRecTypeChange = (event) => {
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDaysCheckBox(daysObj)
    setRecType(event.target.value);
  };

  const handleIntervalChange = (event) => {
    setErrRecInterval(false);
    setRecInterval(event.target.value);
  };

  //for grps listing callbakcs
  const successOrgGrpList = res => {
    setOrgGrpsList(res && res.data)
    if (res && res.data) {
      let grpData = res.data
      let grpMemebersData = grpData.flatMap((item) => item.group_members);
      let uniqueResData = grpMemebersData.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      if (uniqueResData && uniqueResData.length) {
        setOrgGrpMemsList(uniqueResData)
      } else {
        setOrgGrpMemsList([])
      }
    }
  }
  const failureOrgGrpList = error => {
    setOrgGrpsList([])
    setOrgGrpMemsList([])
  }

  //FOR FETHCING THE ZOOM KEYS AND SECRET KEYS TO UNABLE CREATE EVENT BUTTON
  const fetchProfileSuccess = res => {
    if (res && res.user && Object.keys(res.user) && Object.keys(res.user).length) {
      setUserDetails(res.user)
    }
  }
  const fetchProfileFailure = err => {
    props.showSnackBar({
      type: 'error',
      message: 'Something went wrong! While fetching zoom keys!',
      status: false
    })
    setUserDetails({})
  }

  useEffect(() => {
    fetchEvents();
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
    if (domainDetails.name === 'rmbl') {
      fetchOrgGrpsList({}, successOrgGrpList, failureOrgGrpList)
      fetchProfile({}, fetchProfileSuccess, fetchProfileFailure)
    }
  }, []);

  const renderMeetingDetails = (meetingNum) => {
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDaysCheckBox(daysObj)
    setTitleError(false)
    setMemEmptyCheck(false)
    setSelectedGrpMemsList([])
    setSelectedMembers([])
    setDefaultSelGrpMemList([])
    setSelectedGrp([])
    setSelectedIndividualMems([])
    setParticipantsList([])
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);

    let selectedInfo = eventsListData.find((obj) => obj.id == meetingNum);
    setIsLoggedInUserHost(selectedInfo && selectedInfo.is_host)
    setTitle(selectedInfo && selectedInfo.topic && selectedInfo.topic);
    setMeetingTypeName(selectedInfo && selectedInfo.meeting_type_display_name && selectedInfo.meeting_type_display_name);
    setMeetingType(selectedInfo.meeting_type);
    setStartDate(moment(selectedInfo.start_time).format("YYYY-MM-DD[T]HH:mm"));
    setEndDate(selectedInfo.end_time);
    setDuration(selectedInfo && selectedInfo.duration && selectedInfo.duration);
    setAgenda(selectedInfo && selectedInfo.agenda ? selectedInfo.agenda : '');
    setMeetingUniqId(selectedInfo && selectedInfo.id);
    setUploadedAttachmentPreview(selectedInfo && selectedInfo.attachment && selectedInfo.attachment)
    setSelectedEventLabels(selectedInfo && selectedInfo.event_labels && selectedInfo.event_labels)
    if (selectedInfo.course) {
      setSelectedCourseName(selectedInfo.course.title);
      setSelectedCourse(selectedInfo.course.id);
    } else {
      setSelectedCourseName("");
      setSelectedCourse("");
    }

    if (selectedInfo && selectedInfo.assigned_to && selectedInfo.assigned_to.length) {
      let membersArr = []
      selectedInfo.assigned_to.forEach((group) => {
        group && group.group_members && group.group_members.length && group.group_members.forEach((members) => {
          membersArr.push(members)
        })
      })
      let uniqueResData = membersArr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      setParticipantsList(uniqueResData);
    }

    let defaultSelMembers = []
    if (selectedInfo && selectedInfo.assigned_to && Object.keys(selectedInfo.assigned_to) && Object.keys(selectedInfo.assigned_to).length) {
      defaultSelMembers = selectedInfo.assigned_to
      setDefaultSelGrpMemList(selectedInfo.assigned_to)
    }
    setSelectedGrpMemsList(defaultSelMembers)

    //for saving the inperson meeting location
    let defaultSelectedInPernLoc = []
    for (let i in selectedInfo.meeting_location) {
      defaultSelectedInPernLoc.push(selectedInfo.meeting_location[i])
    }
    setDefaultInPernLocations(defaultSelectedInPernLoc);
    setSelectedInPersLoc(defaultSelectedInPernLoc);

    let startTime = selectedInfo.start_time;
    let currTime = new Date();
    let showStartButton = moment(currTime).isAfter(
      moment(startTime).subtract(1, "hours")
    );
    setRenderStartBtn(showStartButton);

    if (selectedInfo.recurrence_type !== null) {
      let reccType = recurrenceTypeList.find(
        (obj) => obj.id == selectedInfo.recurrence_type
      );
      setRecTypeName(reccType.name);
      setRecType(selectedInfo.recurrence_type);
      setRecInterval(
        selectedInfo.recurrence_repeat_interval !== null
          ? selectedInfo.recurrence_repeat_interval
          : ""
      );
    } else {
      setRecType(1);
      setRecTypeName("");
      setRecInterval(1);
    }

    if (selectedInfo.meeting_type == 8 && selectedInfo.recurrence_type !== 1) {
      let recDays = [];
      let rec = [];

      if (selectedInfo.weekly_days !== null) {
        rec = selectedInfo.weekly_days.split(",");
      }

      let daysObj = {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      };

      rec = rec.map(Number);
      rec.map((item, index) => {
        daysOfWeek.map((day, ind) => {
          if (item == day.id) {
            daysObj[day.name] = true;
          }
        });
      });
      setDaysCheckBox(daysObj);

      if (rec.length > 0) {
        daysOfWeek &&
          daysOfWeek.map((day) => {
            if (rec.includes(day.id)) {
              recDays.push(day.label.substring(0, 3));
            }
          });
      }
      setRecurringDays(recDays);
    } else {
      setRecurringDays([]);
    }

    setMeetingId(selectedInfo.meeting_id);
    if (selectedInfo && selectedInfo.is_host) {
      setMeetingURL(selectedInfo.start_url);
    } else {
      setMeetingURL(selectedInfo.join_url);
    }
    setMeetingPassword(selectedInfo.password);
    setApprovalType(selectedInfo !== null ? selectedInfo.approval_type : "");

    let approvalName =
      selectedInfo !== null && selectedInfo.approval_type == 0
        ? "Automatically Approve"
        : selectedInfo.approval_type == 1
          ? "Manually Approve"
          : "No Registration Required";
    setApprovalTypeName(approvalName);
    setRecordingType(selectedInfo !== null ? selectedInfo.auto_recording : "");

    let settingsObj = {
      host_video: false,
      participant_video: false,
      join_before_host: false,
      watermark: false,
      waiting_room: false,
      registrants_email_notification: false,
      meeting_authentication: false,
    };

    Object.keys(meetingSettings).length > 0 &&
      Object.keys(meetingSettings).map((name, index) => {
        if (Object.keys(settingsObj).includes(name)) {
          settingsObj[name] = selectedInfo[name];
        }
      });
    setMeetingSettings(settingsObj);
    setRenderMeetingInfo(true);
  };

  const fetchEvents = () => {
    setEventsListLoader(true)
    listEventsCalendar(successCallBack, failureCallBack);
  };

  const successCallBack = (res) => {
    let resultData = res.data;
    let resObjCal = {};
    let resArrCal = [];
    let resObj = {};
    let resArr = [];

    let uniqueResData = resultData.filter((v, i, a) => a.findIndex((t) => t.meeting_id === v.meeting_id) === i);
    setEventsListData(resultData);

    resultData && resultData.length > 0 &&
      resultData.forEach((item) => {
        let recDays = [];
        let rec = [];

        if (item && item.weekly_days && item.weekly_days !== null) {
          rec = item && item.weekly_days && item.weekly_days.split(",");
        }

        rec = rec && rec.length && rec.map(Number);
        if (rec && rec.length > 0) {
          daysOfWeek && daysOfWeek.forEach((day) => {
            if (rec && rec.includes(day.id)) {
              recDays.push(day.label.substring(0, 3));
            }
          });
        }

        resObjCal = {};
        resObjCal = {
          id: item && item.id,
          start: new Date(item.start_time),
          end: new Date(item.end_time),
          duration: item && item.duration && item.duration,
          title: item && item.topic && item.topic,
          agenda: item && item.agenda && item.agenda,
          courseId: item && item.course && item.course,
          isRecurringEvent: item.meeting_type == 8 ? true : false,
          recurringDays: recDays,
          is_host: item.is_host,
          assigned_to: item && item.assigned_to && item.assigned_to.length ? item.assigned_to : [],
          host_details: item && item.host_details && Object.keys(item.host_details) && Object.keys(item.host_details).length ? item.host_details : {},
          event_labels: item && item.event_labels,
          attachment: item && item.attachment,
          meeting_location: item && item.meeting_location,
          meeting_type: item.meeting_type,
          meeting_type_display_name: item.meeting_type_display_name
        };
        resArrCal.push(resObjCal);
      });

    setEventsListDataCal(resArrCal);

    uniqueResData && uniqueResData.length > 0 && uniqueResData.map((item, index) => {
      let recDays = [];
      let rec = [];

      if (item && item.weekly_days && item.weekly_days !== null) {
        rec = item && item.weekly_days && item.weekly_days.split(",");
      }
      rec = rec && rec.map(Number);
      if (rec.length > 0) {
        daysOfWeek && daysOfWeek.forEach((day) => {
          if (rec.includes(day.id)) {
            recDays.push(day.label.substring(0, 3));
          }
        });
      }

      let recType = {};
      if (item && item.recurrence_type !== null) {
        recType = recurrenceTypeList.find(
          (obj) => obj.id == item.recurrence_type
        );
      }

      resObj = {};
      resObj = {
        id: item.id,
        start: new Date(item.start_time),
        end: new Date(item.end_time),
        duration: item.duration,
        title: item.topic,
        agenda: item.agenda,
        courseId: item.course,
        recurrenceType: recType.name && recType.name,
        isRecurringEvent: item.meeting_type == 8 ? true : false,
        recurringDays: recDays,
        startURL: item.start_url,
        joinURL: item.join_url,
        is_host: item.is_host,
        assigned_to: item && item.assigned_to && item.assigned_to.length ? item.assigned_to : [],
        host_details: item && item.host_details && Object.keys(item.host_details) && Object.keys(item.host_details).length ? item.host_details : {},
        event_labels: item && item.event_labels,
        attachment: item && item.attachment,
        meeting_location: item && item.meeting_location,
        meeting_type: item.meeting_type,
        meeting_type_display_name: item.meeting_type_display_name
      };
      resArr.push(resObj);
    });
    let arrList = resArr.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });

    setEventsSiderList(arrList);
    setEventsListLoader(false)
  };

  const failureCallBack = (error) => {
    setEventsListData([])
    setEventsListDataCal([])
    setEventsSiderList([])
    setEventsListLoader(false)
  };

  const handleSelectChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleClickOpen = ({ start, end }) => {
    if (domainDetails.name === 'rmbl') {
      if (userDetails && Object.keys(userDetails) && (Object.keys(userDetails).length || !Object.keys(userDetails).length) && !userDetails.zoom_api_key) {
        props.showSnackBar({
          type: 'warning',
          message: 'Please add Zoom API and Secret Keys to start an event!',
          status: false
        })
      } else {
        setStartDate(moment(start).format("YYYY-MM-DD[T]HH:mm"));
        setMemEmptyCheck(false)
        setTitleError(false)
        setTitle("");
        setAgenda("");
        setDuration(30);
        setMeetingType(2);
        setRecType(1);
        setRecInterval(1);
        setApprovalType(0);
        setRecordingType("none");
        setMeetingSettings({
          host_video: false,
          participant_video: false,
          join_before_host: false,
          watermark: false,
          waiting_room: false,
          registrants_email_notification: false,
          meeting_authentication: false,
        });
        setCreateEvent(true);
      }
    } else {
      setStartDate(moment(start).format("YYYY-MM-DD[T]HH:mm"));
      setMemEmptyCheck(false)
      setTitleError(false)
      setTitle("");
      setAgenda("");
      setDuration(30);
      setMeetingType(2);
      setRecType(1);
      setRecInterval(1);
      setApprovalType(0);
      setRecordingType("none");
      setMeetingSettings({
        host_video: false,
        participant_video: false,
        join_before_host: false,
        watermark: false,
        waiting_room: false,
        registrants_email_notification: false,
        meeting_authentication: false,
      });
      setCreateEvent(true);
    }
  };

  const handleTitleChange = (event) => {
    setTitleError(false);
    setTitle(event.target.value);
  };

  //FOR HANDLECHANGE AGENDA
  const handleAgendaChange = (event) => {
    setAgenda(event.target.value);
  };
  //FOR AGNEDA TEXTEDIOTR CHANGE
  const onChangeEditorContent = (data) => {
    if (data.length >= 10000) {
      let text = data && data.length && data.substr(0, 10000);
      setAgenda(text);
    } else {
      setAgenda(data);
    }
  }
  //for rendering the html view
  const createMarkup = data => {
    return {
      __html: data
    };
  }


  const modifyEvent = () => {
    setRenderMeetingInfo(false);
    setCreateEvent(true);
    setModifyEventInfo(true);
  };

  const reScheduleMeeting = (event) => {
    event.preventDefault();
    let error = false;
    if (!meetingId) {
      props.showSnackBar({
        state: false,
        message: "Please select an existing meeting to Re-schedule!",
        type: "error",
      });
      error = true;
    }

    if (title === "") {
      setTitleError(true);
      error = true;
    }

    if (
      (recType == 1 && parseInt(recInterval) > 90) ||
      (recType == 2 && parseInt(recInterval) > 12) ||
      (recType == 3 && parseInt(recInterval) > 3)
    ) {
      setErrRecInterval(true);
      error = true;
    }

    let recurrence_days = [];
    Object.keys(daysCheckBox).map((item, index) => {
      if (daysCheckBox[item] === true) {
        let dataObj = daysOfWeek.find((obj) => obj.name == item);
        let dayId =
          dataObj && Object.keys(dataObj).length > 0 ? dataObj.id : "";
        if (dayId) {
          recurrence_days.push(dayId);
        }
      }
    });

    if (recType == 2 && recurrence_days.length === 0) {
      props.showSnackBar({
        state: false,
        message: "Please select atleast a day for Weekly Recurring!",
        type: "error",
      });
      error = true;
    }

    //for inperson meeting location empty check
    if (meetingType == 9 && !selectedInPersLoc.length) {
      error = true;
      setLocEmptyErr(true)
    }

    //FOR MEMBERS SELECTION PAYLOAD
    let membersArr = []
    if (selectedMembers && selectedMembers.length) {
      selectedMembers.forEach((emp, index) => {
        membersArr.push(emp.id);
      });
    }
    if (selectedGrpMemsList && selectedGrpMemsList.length) {
      selectedGrpMemsList.forEach((item, index) => {
        let mem = item.group_members;
        if (mem.length > 0) {
          mem.forEach((data, index) => {
            membersArr.push(data.id);
          });
        }
      });
    }
    let groupMemArr = membersArr.filter((value, index) => membersArr.indexOf(value) === index)

    if (groupMemArr && !groupMemArr.length) {
      setMemEmptyCheck(true)
      error = true
    }

    if (!error) {
      setInProgress(true);
      setLocEmptyErr(false)
      let settingsObj = {
        approval_type: approvalType,
        auto_recording: recordingType,
        host_video: meetingSettings.host_video,
        participant_video: meetingSettings.participant_video,
        join_before_host: meetingSettings.join_before_host,
        watermark: meetingSettings.watermark,
        waiting_room: meetingSettings.waiting_room,
        registrants_email_notification:
          meetingSettings.registrants_email_notification,
        meeting_authentication: meetingSettings.meeting_authentication,
      };

      let newSchedule = {
        meeting_id: parseInt(meetingId),
        topic: title,
        type: parseInt(meetingType),
        start_time: new Date(startDate).toUTCString(),
        end_date: new Date(startDate).toUTCString(),
        duration: duration,
        agenda: agenda,
        courseId: selectedCourse,
        settings: settingsObj,
        user_id: groupMemArr
      };

      let recurrenceObj = {
        type: meetingType == 8 ? recType : null,
        repeat_interval: meetingType == 8 ? recInterval : null,
      };

      if (recType == 2) {
        recurrenceObj["weekly_days"] = meetingType == 8 ? recurrence_days : [];
      }
      if (recType == 3) {
        let dateVal = new Date(startDate).getDate();
        recurrenceObj["monthly_day"] = meetingType == 8 ? parseInt(dateVal) : null
      }

      if (meetingType == 8) {
        newSchedule["recurrence"] = recurrenceObj;
      }
      let formData = new FormData()
      formData.append('meeting_id', parseInt(meetingId))
      formData.append('topic', title)
      formData.append('type', parseInt(meetingType))
      if (meetingType == 9) {
        formData.append('inperson_meeting_locations', JSON.stringify(selectedInPersLoc))
      }
      formData.append('start_time', new Date(startDate).toUTCString())
      formData.append('end_date', new Date(startDate).toUTCString())
      formData.append('duration', duration)
      formData.append('agenda', agenda)
      formData.append('courseId', selectedCourse)
      formData.append('settings', JSON.stringify(settingsObj))
      formData.append('user_id', JSON.stringify(membersArr))
      formData.append('recurrence', JSON.stringify(recurrenceObj))
      formData.append('event_labels', JSON.stringify([]))
      formData.append('attachment', null)

      modifyMeetingEvent(formData, meetingId, successModifyCB, failureModifyCB);
    }
  };

  const successModifyCB = (res) => {
    props.showSnackBar({
      state: false,
      message: "Event modified successfully!",
      type: "success",
    });
    setInProgress(false);
    fetchEvents();
    setModifyEventInfo(false);
    setSelectedGrpMemsList([])
    setSelectedMembers([])
    setDefaultSelGrpMemList([])
    setSelectedGrp([])
    setSelectedIndividualMems([])
    setCreateEvent(false);
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setDaysCheckBox(daysObj)
  };

  const failureModifyCB = (err) => {
    setInProgress(false);
    props.showSnackBar({
      state: false,
      message: "Something went wrong! While Modifying Event",
      type: "error",
    });
  };

  //FOR CREATE EVENT
  const scheduleMeeting = (event) => {
    event.preventDefault();
    let recurrence_days = [];
    Object.keys(daysCheckBox) && Object.keys(daysCheckBox).length && Object.keys(daysCheckBox).forEach((item, index) => {
      if (daysCheckBox[item] === true) {
        let dataObj = daysOfWeek.find((obj) => obj.name == item);
        let dayId = dataObj && Object.keys(dataObj).length > 0 ? dataObj.id : "";
        if (dayId) {
          recurrence_days.push(dayId);
        }
      }
    });

    //FOR MEMBERS SELECTION PAYLOAD
    let membersArr = []
    if (selectedMembers && selectedMembers.length) {
      selectedMembers.forEach((emp, index) => {
        membersArr.push(emp.id);
      });
    }
    if (selectedGrpMemsList && selectedGrpMemsList.length) {
      selectedGrpMemsList.forEach((item, index) => {
        let mem = item.group_members;
        if (mem.length > 0) {
          mem.forEach((data, index) => {
            membersArr.push(data.id);
          });
        }
      });
    }
    let groupMemArr = membersArr.filter((value, index) => membersArr.indexOf(value) === index)

    let error = false;
    if (title === "") {
      setTitleError(true);
      error = true;
    } else if ((recType == 1 && parseInt(recInterval) > 90) || (recType == 2 && parseInt(recInterval) > 12) || (recType == 3 && parseInt(recInterval) > 3)) {
      setErrRecInterval(true);
      error = true;
      setTitleError(false)
    } else if (recType == 2 && recurrence_days.length === 0) {
      props.showSnackBar({
        state: false,
        message: "Please Select atleast a day for Weekly Recurring!",
        type: "error",
      });
      error = true;
      setTitleError(false)
    } //for inperson meeting location empty check
    else if (meetingType == 9 && !selectedInPersLoc.length) {
      error = true;
      setLocEmptyErr(true)
    } else if (groupMemArr && !groupMemArr.length) {
      setMemEmptyCheck(true)
      setTitleError(false)
      error = true
    } else {
      setMemEmptyCheck(false)
      setTitleError(false)
      setLocEmptyErr(false)
      error = false
      setInProgress(true);
      let settingsObj = {
        approval_type: approvalType,
        auto_recording: recordingType,
        host_video: meetingSettings.host_video,
        participant_video: meetingSettings.participant_video,
        join_before_host: meetingSettings.join_before_host,
        watermark: meetingSettings.watermark,
        waiting_room: meetingSettings.waiting_room,
        registrants_email_notification:
          meetingSettings.registrants_email_notification,
        meeting_authentication: meetingSettings.meeting_authentication,
      };

      let newSchedule = {
        topic: title,
        type: parseInt(meetingType),
        start_time: new Date(startDate).toUTCString(),
        end_date: new Date(startDate).toUTCString(),
        duration: duration,
        agenda: agenda,
        courseId: selectedCourse,
        settings: settingsObj,
        user_id: groupMemArr
      };

      let recurrenceObj = {
        type: recType,
        repeat_interval: recInterval,
      };

      if (recType == 2) {
        recurrenceObj["weekly_days"] = meetingType == 8 ? recurrence_days : [];
      }
      if (recType == 3) {
        let dateVal = new Date(startDate).getDate();
        recurrenceObj["monthly_day"] = meetingType == 8 ? parseInt(dateVal) : null
      }

      if (meetingType == 8) {
        newSchedule["recurrence"] = recurrenceObj;
      }

      let formData = new FormData()
      formData.append('topic', title)
      formData.append('type', parseInt(meetingType))
      if (meetingType == 9) {
        formData.append('inperson_meeting_locations', JSON.stringify(selectedInPersLoc))
      }
      formData.append('start_time', new Date(startDate).toUTCString())
      formData.append('end_date', new Date(startDate).toUTCString())
      formData.append('duration', duration)
      formData.append('agenda', agenda)
      formData.append('courseId', selectedCourse)
      formData.append('settings', JSON.stringify(settingsObj))
      formData.append('user_id', JSON.stringify(membersArr))
      formData.append('recurrence', JSON.stringify(recurrenceObj))
      formData.append('event_labels', JSON.stringify([]))
      formData.append('attachment', null)
      createMeetingEvent(formData, successCreateCB, failureCreateCB);
    }
  };

  const successCreateCB = (res) => {
    props.showSnackBar({
      state: false,
      message: "Event Created Successfully",
      type: "success",
    });
    setInProgress(false);
    fetchEvents();
    setSelectedGrpMemsList([])
    setSelectedMembers([])
    setDefaultSelGrpMemList([])
    setSelectedGrp([])
    setSelectedIndividualMems([])
    setCreateEvent(false);
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setDaysCheckBox(daysObj)
  };

  const failureCreateCB = (err) => {
    setInProgress(false);
    props.showSnackBar({
      state: false,
      message: "Something went wrong! While creating Event",
      type: "error",
    });
  };

  const deleteEvent = () => {
    setInProgress(true);
    if (meetingUniqId) {
      deleteMeetingEvent(meetingUniqId, successDeleteCB, failureDeleteCB);
    } else {
      setInProgress(false);
      handleDialogClose();
      props.showSnackBar({
        state: false,
        message: "EventId not selected",
        type: "error",
      });
    }
  };

  const successDeleteCB = (res) => {
    props.showSnackBar({
      state: false,
      message: "Event Deleted Successfully",
      type: "success",
    });
    setInProgress(false);
    handleDialogClose();
    fetchEvents();
    setSelectedGrpMemsList([])
    setSelectedMembers([])
    setDefaultSelGrpMemList([])
    setSelectedGrp([])
    setSelectedIndividualMems([])
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setCreateEvent(false);
    setCreateEvent(false);
    setRenderMeetingInfo(false);
  };

  const failureDeleteCB = (err) => {
    setInProgress(false);
    handleDialogClose();
    props.showSnackBar({
      state: false,
      message: "Error Deleting Event",
      type: "error",
    });
  };

  const handleStartDateChange = (event) => {
    // let currDtTime = moment(new Date()).format("YYYY-MM-DD");
    // let selDtTime = moment(event.target.value).format("YYYY-MM-DD");
    // let isPastDate = moment(selDtTime).isBefore(currDtTime, "day");
    // let isInValidTime = false;
    // let selTime = new Date(event.target.value).getHours();

    // if (parseInt(selTime) < 9 || parseInt(selTime) > 17) {
    //   isInValidTime = true;
    // }

    // if (isPastDate || isInValidTime) {
    //   setDtTimeErrFlag(true);
    // } else {
    //   setDtTimeErrFlag(false);
    //  // setStartDate(event.target.value);
    // }
    setStartDate(event.target.value);
  };

  const showZoomMeeting = () => {
    let data = {
      meetingId: meetingId,
      password: meetingPassword,
    };
    setZoomProps(data);
    setDisplayZoom(true);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const sortMembers = (membersList) => {
    let sortedList = membersList.sort(function (a, b) {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0;
    })
    return sortedList;
  }

  const handleDurationChange = (event) => {
    // if (
    //   event.target.value.includes(".") ||
    //   event.target.value > 60 ||
    //   event.target.value.includes("-")
    // ) {
    //   setDurErrFlag(true);
    // } else {
    // setDuration(event.target.value);
    //   setDurErrFlag(false);
    // }
    setDuration(event.target.value);
  };

  const openConfirmationDialog = (event) => {
    deleteEvent();
  };

  const clearRenderInfo = () => {
    setRenderMeetingInfo(false);
    setCreateEvent(false);
    setSelectedGrpMemsList([])
    setSelectedMembers([])
    setDefaultSelGrpMemList([])
    setSelectedGrp([])
    setSelectedIndividualMems([])
    setTitleError(false)
    setMemEmptyCheck(false)
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setDaysCheckBox(daysObj)
  };

  //for grps selection handlechange
  const handleSelectGrp = (grpName) => {
    setMemEmptyCheck(false)
    setSelectedGrp(grpName)
    let grpListVal = orgGrpsList
    let existingGrpMembers = [...defaultSelGrpMemList]
    let arr = [...defaultSelGrpMemList]
    if (grpListVal && grpListVal.length > 0) {
      if (grpName && grpName.length) {
        grpName.forEach((item, index) => {
          let groupObj = grpListVal.find((grp) => grp.id === item.id)
          let selObj = existingGrpMembers.find((selTeam) => selTeam.id === item.id)
          if ((defaultSelGrpMemList && !defaultSelGrpMemList.length) && selObj) {
            let grpMembers = selObj.group_members
            let Obj = { group_title: item.group_title, group_members: grpMembers }
            arr.push(Obj)
          } else if (groupObj) {
            let grpMembers = groupObj.group_members
            let Obj = {}
            if (arr && arr.length) {
              const newData = arr.find((data => data.id === item.id))
              if (newData && Object.keys(newData) && Object.keys(newData).length) {
                newData['group_members'] = item.group_members
              } else {
                arr.push({ 'group_title': item.group_title, 'group_members': grpMembers })
              }
            } else {
              Obj = { group_title: item.group_title, group_members: grpMembers }
              arr.push(Obj)
            }
          }
        })
        setSelectedGrpMemsList(arr)
      } else {
        setSelectedGrpMemsList([])
      }
    }
  }
  //FOR SINGLE GRP MEMBER SELECTION
  const handleAddMembers = (members) => {
    setMemEmptyCheck(false)
    setSelectedIndividualMems(members)
    setSelectedMembers(members);
  }

  //on grp member delete from chip
  const handleGrpMemDelete = grpToDelete => {
    let selectedMemList = [...selectedGrpMemsList];
    if (selectedMemList.length > 0) {
      selectedMemList.forEach((item, index) => {
        let removedMemList = [];
        let teamObj = item.group_members.find((member) => member.id === grpToDelete.id);
        if (teamObj) {
          removedMemList = item.group_members.filter((member) => member.id !== grpToDelete.id);
          item["group_members"] = removedMemList;
        }
      });
      setSelectedGrpMemsList(selectedMemList);
    }
  }
  //on indivi delete
  const handleIndividualMemDelete = memberToDelete => {
    let existingMembers = selectedMembers
    let selectedChipMembers = selectIndividualMems
    let newData = existingMembers.filter((member) => member.id !== memberToDelete.id)
    let chipUsers = selectedChipMembers.filter((member) => member.id !== memberToDelete.id)
    setSelectedIndividualMems(newData)
    setSelectedMembers(chipUsers)
  }

  const renderGrpsListChip = (teamsList) => {
    if (teamsList && teamsList.length > 0) {
      let arr = [];
      teamsList.forEach((item, index) => {
        let label = item.name + " - " + item.email;
        let labelName = label && label.length > 40 ? label.substring(0, 35) + "..." : label;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              variant="outlined"
              label={`${labelName} - ${item.role}`}
              deleteIcon={<HighlightOffIcon style={{ color: style.fontColour._blue }} />}
              onDelete={() => {
                handleGrpMemDelete(item);
              }}
              className={classes.selMemChip}
            />
            <br />
          </React.Fragment>
        );
      });
      return arr;
    }
  }

  const onCancelCreateEvent = () => {
    setCreateEvent(false)
    setSelectedGrpMemsList([])
    setSelectedEventLabels([])
    setSelectedMembers([])
    setDefaultSelGrpMemList([])
    setSelectedGrp([])
    setSelectedIndividualMems([])
    setParticipantsList([])
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setTitleError(false)
    setMemEmptyCheck(false)
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDaysCheckBox(daysObj)
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Events</div>
        </Breadcrumbs>
      </Route>
    );
  };

  const getInPersonMeetingLocation = (value, getTagProps) => {
    setSelectedInPersLoc(value)
    setLocEmptyErr(false)
    return value.map((option, index) => (
      <Chip
        style={{ backgroundColor: '#E0E0E0' }}
        variant="outlined"
        label={option}
        {...getTagProps({ index })}
      />
    ))
  }

  const handleEventSearchTerm = event => {
    setFilterSearchTerm(event.target.value)
  }

  const onEventSearch = evnt => {
    evnt.preventDefault();
    const searchTerm = document.getElementById('search Event')
    if (filterSearchTerm === '' || !filterSearchTerm.length) {
      searchTerm.focus()
    } else {
      const payload = {
        participant_filter: filterSearchTerm
      }
      setEventsListLoader(true)
      setFilterApplied(true)
      listEventsCalendar(payload, successCallBack, failureCallBack);
    }
  }

  //ON CLEARING THE SEARCH FILTER
  const clearSearchFilter = () => {
    if (filterApplied && filterSearchTerm) {
      setEventsListLoader(true)
      listEventsCalendar(successCallBack, failureCallBack);
      setFilterSearchTerm('')
      setFilterApplied(false)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <div className={classes.root}>
          <Grid container>
            <Header history={props} />
          </Grid>
        </div>
        <div className={classes.breadCrumbcontainer}>
          <Grid container style={{ marginTop: "5rem", paddingLeft: "1rem" }}>
            {SimpleBreadcrumbs()}
          </Grid>
        </div>
        <Grid container spacing={1}>
          <div id="mainH1Tag" tabIndex="-1" className="accessibility">
            <h1>Events page</h1>
          </div>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper className={classes.siderPaper}>
              <div className={classes.eventsTitle}>Upcoming Events</div>
              {/* <div style={{ textAlign: 'initial' }} onSubmit={onEventSearch} >
                <TextField
                  autoComplete="off"
                  id='search Event'
                  className={classes.searchBox}
                  placeholder={`Find Event (Participants) by typing name here`}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                      notchedOutline: classes.searchBoxOutline
                    }
                  }}
                  variant="outlined"
                  onChange={handleEventSearchTerm}
                  value={filterSearchTerm}
                />
                <IconButton onClick={(event) => { onEventSearch(event) }} size="small" style={{ marginTop: '6px' }}>
                  <SearchIcon className={classes.searchIcon} />
                </IconButton>
                {filterSearchTerm && filterApplied && <div>
                  <Link className={classes.clearFilterWrap} component="button" variant="body2"
                    onClick={() => { clearSearchFilter() }}
                  >
                    Clear Search
                  </Link>
                </div>}
              </div>
              <Divider /> */}
              {eventsListLoader ? (
                <CalenderSiderLoader />
              ) :
                (<div className={mobileView ? classes.siderCntrMob : classes.siderCntr}>
                  {!eventsListLoader && eventsSiderList && eventsSiderList.length ? (eventsSiderList.map((item, index) => {
                    let startTime = item.start;
                    let currTime = new Date();
                    let showIcon = moment(currTime).isAfter(
                      moment(startTime).subtract(1, "hours")
                    );
                    return (
                      <div
                        key={index}
                        className={classes.itemBlock}
                        onClick={() => { renderMeetingDetails(item.id); }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={10} sm={10} md={10} lg={10}>
                            <div className={classes.eventItem}>
                              <div className={classes.itemTitle}>
                                {item && item.title && item.title.length > 30 ? item.title.substring(0, 30) + "..." : item && item.title}
                              </div>
                              <div className={classes.dateCtr}>
                                <div className={classes.dateVal}>
                                  {new Date().getDate() === item.start.getDate() ? (
                                    <div style={{ color: "green", fontWeight: "600", }}>
                                      Today
                                    </div>
                                  ) : (
                                    ` ${moment(item && item.start && item.start).format("MMM DD")} ${moment(item && item.start && item.start).format("ddd")}`
                                  )}
                                </div>
                                <div className={classes.timeCtr}>
                                  <div className={classes.timings}>
                                    {moment(item && item.start && item.start).format("hh:mm a")} &nbsp;-&nbsp; {item && item.isRecurringEvent ? `${item && item.duration && item.duration} Minutes` : moment(item && item.end && item.end).format("hh:mm a")}
                                  </div>
                                </div>
                              </div>
                              {item && item.isRecurringEvent ? (
                                <div className={classes.timeRecCtr}>
                                  <span className={classes.recLabel}>
                                    Type : Recurs&nbsp;
                                  </span>
                                  <span className={classes.recDays}>
                                    {item && item.recurringDays && item.recurringDays.length > 0 ? `every - ${item && item.recurringDays && item.recurringDays.toString()}` : item && item.recurrenceType}
                                  </span>
                                </div>
                              ) : (
                                <div className={classes.timeRecCtr}>
                                  <div className={classes.recLabel}>
                                    Type : {item.meeting_type == 9 ? 'In-person' : 'Scheduled'}
                                  </div>
                                </div>
                              )}
                              <div className={classes.timeRecCtr}>
                                <div className={item && item.is_host ? classes.recHostLabel : classes.recLabel}>
                                  Host :
                                  {item && item.host_details && Object.keys(item.host_details) && Object.keys(item.host_details).length && item.host_details.name && item.host_details.name.length > 30 ?
                                    (item.host_details.name.substring(0)) : (item.host_details.name)
                                  }
                                </div>
                              </div>
                              <div className={classes.recLabel}>
                                {item && item.meeting_location && item.meeting_location.length > 0 ? (
                                  <React.Fragment>
                                    Location :
                                    {item.meeting_location.map((name, index) => (
                                      <span key={index}>{name} {item.meeting_location[index + 1] ? ', ' : ''}</span>
                                    ))}
                                  </React.Fragment>
                                ) : null}
                              </div>
                              <div className={classes.recLabel}>
                                {item && item.event_labels && item.event_labels.length > 0 ?
                                  (item.event_labels.map((item, index) => {
                                    let name = item && item.name && item.name
                                    return (
                                      <Chip
                                        key={index}
                                        variant="outlined"
                                        label={name}
                                        className={classes.evntLabelWrapper}
                                      />
                                    );
                                  })
                                  ) : null}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1}>
                            {showIcon && (
                              <Tooltip title={item.is_host ? "Start Meeting" : "Join Now"}>
                                {item && item.is_host ?
                                  <a href={meetingURL} target="_blank" >
                                    <PlayCircleOutlineIcon
                                      className={classes.event}
                                    />
                                  </a> : <a href={meetingURL} target="_blank">
                                    <PersonAddIcon
                                      className={classes.event}
                                    />
                                  </a>}
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                        <Divider />
                      </div>
                    )
                  }))
                    : (
                      <React.Fragment>
                        {!eventsListLoader && eventsSiderList && !eventsSiderList.length ? (
                          <div className={classes.noDataWrapper}>No upcoming events!</div>
                        ) : null}
                      </React.Fragment>
                    )}
                </div>
                )}
            </Paper>
          </Grid>
          {!renderMeetingInfo ? (
            <Grid item xs={12} sm={6} md={8} lg={9} style={{ paddingTop: '22px' }}>
              {!createEvent ? (
                <React.Fragment>
                  <div style={{ textAlign: "end" }}>
                    {domainDetails.name === 'rmbl' && userDetails && Object.keys(userDetails) && Object.keys(userDetails).length
                      && userDetails.zoom_api_key ? (
                      <Button
                        className={classes.buttons}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          createNewEvent();
                        }}
                      >
                        Create Event
                      </Button>
                    ) : null}
                  </div>
                  <div style={{ margin: "1rem 1rem 0 1rem", overflow: "scroll" }}>
                    <Calendar
                      selectable
                      events={eventsListDataCal}
                      startAccessor="start"
                      endAccessor="end"
                      components={{
                        timeSlotWrapper: ColoredDateCellWrapper,
                      }}
                      className={mobileView ? classes.calendarMob : classes.calendar}
                      localizer={localizer}
                      onSelectSlot={handleClickOpen}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <form autoComplete="off" style={{ paddingBottom: "2rem", margin: "1rem" }}
                    onSubmit={(evnt) => { evnt.preventDefault(); }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        <TextField
                          required
                          variant="outlined"
                          id="Title"
                          name="Title"
                          label="Title"
                          value={title}
                          error={titleError}
                          onChange={handleTitleChange}
                          fullWidth
                        />
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                            <FormControl
                              variant="outlined"
                              className={classes.selectFormControl}
                            >
                              <InputLabel htmlFor="outlined-meeting-native-simple">
                                Meeting Type
                              </InputLabel>
                              <Select
                                native
                                required
                                value={meetingType}
                                onChange={handleMeetingTypeChange}
                                label="Meeting Type"
                                inputProps={{
                                  name: "meetingtype",
                                  id: "outlined-meeting-native-simple",
                                }}
                              >
                                <option value={2}>Scheduled Meeting</option>
                                <option value={8}>
                                  Recurring Meeting with Fixed Time
                                </option>
                                <option value={9}>
                                  In-person Meeting
                                </option>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
                            <TextField
                              id="dateStarttime-local"
                              label="Starts On"
                              type="datetime-local"
                              value={startDate}
                              error={dtTimeErrFlag}
                              helperText={
                                dtTimeErrFlag
                                  ? "Invalid Date/Time"
                                  : "Valid Time: 09:00 AM - 06:00 PM"
                              }
                              onChange={handleStartDateChange}
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItem}>
                            <TextField
                              error={durErrFlag}
                              helperText={durErrFlag ? "Invalid Duration" : ""}
                              id="duration"
                              label="Duration (Minutes)"
                              value={duration}
                              // InputProps={{
                              //   inputProps: {
                              //     max: 60,
                              //     min: 1,
                              //   },
                              // }}
                              type="number"
                              onChange={handleDurationChange}
                              className={classes.textField}
                            />
                          </Grid>
                        </Grid>
                        {meetingType == 9 && (
                          <React.Fragment>
                            <Grid
                              container
                              spacing={1}
                              className={classes.gridCtr}
                            >
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Autocomplete
                                  multiple
                                  id="tags-filled"
                                  options={inpersonMettingOptions.map(option => option.location)}
                                  //value={selectedInPersLoc}
                                  defaultValue={defaultInPernLoacations}
                                  freeSolo
                                  renderTags={(value, getTagProps) => { return getInPersonMeetingLocation(value, getTagProps) }}
                                  renderInput={params => (
                                    <TextField
                                      {...params}
                                      required
                                      error={locEmptyErr}
                                      id="inpersonMetting"
                                      variant="outlined"
                                      name="In-person Meeting"
                                      label="Meeting Location"
                                      placeholder="Type tags here and press 'ENTER' or, to add location"
                                      helperText="Type tags here and press 'ENTER' or, to add location"
                                      fullWidth
                                    //onChange={handleChange('courseTags')}
                                    //value={values.CourseTags}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                        {meetingType == 8 && (
                          <React.Fragment>
                            <Grid container spacing={1} className={classes.gridCtr}>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormControl
                                  variant="outlined"
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel htmlFor="outlined-type-native-simple">
                                    Recurrence Type
                                  </InputLabel>
                                  <Select
                                    native
                                    value={recType}
                                    onChange={handleRecTypeChange}
                                    label="Recurrence Type"
                                    inputProps={{
                                      name: "type",
                                      id: "outlined-type-native-simple",
                                    }}
                                  >
                                    <option value={1}>Daily</option>
                                    <option value={2}>Weekly</option>
                                    <option value={3}>Monthly</option>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                  required={meetingType == 8}
                                  id="outlined-Interval-Text"
                                  label="Interval"
                                  variant="outlined"
                                  value={recInterval}
                                  error={errRecInteval}
                                  onChange={handleIntervalChange}
                                  helperText={`Maximum ${recType == 1
                                    ? "90"
                                    : recType == 2
                                      ? "12"
                                      : "3"
                                    } ${recType == 1
                                      ? "Days"
                                      : recType == 2
                                        ? "Weeks"
                                        : "Months"
                                    }`}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                              {daysOfWeek.length > 0 &&
                                recType == 2 &&
                                daysOfWeek.map((item, index) => {
                                  let name = item.name;
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={daysCheckBox[name]}
                                          onChange={handleDaysCheckBoxChanged}
                                          name={item["name"]}
                                          size="small"
                                          style={{ color: style.fontColour._blue }}
                                        />
                                      }
                                      label={item["label"]}
                                    />
                                  );
                                })}
                            </Grid>
                          </React.Fragment>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                          <div className={classes.autoCompWrapper}>
                            <Autocomplete
                              value={selectedGrp}
                              onChange={(event, grpName) => {
                                handleSelectGrp(grpName);
                              }}
                              multiple
                              id="checkboxes-tags-demo"
                              options={orgGrpsList && orgGrpsList.length > 0 ? orgGrpsList : []}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.group_title}
                              className={classes.grpListWrapper}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, color: style.fontColour._blue }}
                                    checked={selected}
                                  />
                                  <React.Fragment>
                                    <div>
                                      {option.group_title}
                                      <p style={{ fontSize: '11px', color: '#999', fontWeight: 500, marginTop: '-2px' }}>{` (Members count - ${option.members_count}) `}</p>
                                    </div>
                                  </React.Fragment>
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={(meetingType == 8 || meetingType == 2) && membersEmptyCheck}
                                  style={{ color: "red", fontSize: "1rem" }}
                                  variant="outlined"
                                  label="Search for groups..."
                                />
                              )}
                            />
                            <br />
                            <Autocomplete
                              value={selectIndividualMems}
                              onChange={(event, newValue) => {
                                handleAddMembers(newValue);
                              }}
                              multiple
                              id="checkboxes-tags-members-demo"
                              options={orgGrpMemsList && orgGrpMemsList.length > 0 ? orgGrpMemsList : []}
                              getOptionLabel={(option) => option.email}
                              className={classes.grpListWrapper}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={meetingType == 9 && membersEmptyCheck}
                                  style={{ color: "red", fontSize: "1rem" }}
                                  variant="outlined"
                                  label="Search for group members..."
                                />
                              )}
                            />
                          </div>
                        </Grid>
                        {showNormalTextFieldAgenda ? (
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                            <TextField
                              variant="outlined"
                              id="agenda"
                              name="agenda"
                              label="Agenda"
                              value={agenda}
                              helperText="max: 2000 chars"
                              inputProps={{
                                maxLength: 2000
                              }}
                              onChange={handleAgendaChange}
                              fullWidth
                              multiline
                              rows={4}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                            <TextEditor
                              onChangeEditorContent={onChangeEditorContent}
                              helperText={'Type meeting agenda here...'}
                              description={agenda}
                              from="events"
                            />
                            <FormHelperText id="meeting agenda">
                              max: 10,000 chars
                              <span style={{ marginLeft: '10px', color: agenda && agenda.length && agenda.length === 10000 ? 'red' : '' }}>
                                {agenda && agenda.length ? `Char count: ${agenda.length}` : ''}
                              </span>
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <React.Fragment>
                          <FormControl
                            variant="outlined"
                            className={classes.approvalTypeSelect}
                          >
                            <InputLabel htmlFor="outlined-recording-native-simple">
                              Recording
                            </InputLabel>
                            <Select
                              native
                              value={recordingType}
                              onChange={handleRecordingTypeChange}
                              label="Recording Type"
                              inputProps={{
                                name: "recordingtype",
                                id: "outlined-recording-native-simple",
                              }}
                            >
                              <option value="none">Disable Recording</option>
                              {/* <option value="local">Record on Local</option> */}
                              <option value="cloud">Record to Cloud</option>
                            </Select>
                          </FormControl>
                          <Grid container spacing={2} style={{ justifyContent: "center", marginTop: '10px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {selectedGrpMemsList && selectedGrpMemsList.length ? (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Paper elevation={3} className={classes.membersCntr}>
                                    <div className={classes.memCtr}>
                                      {selectedGrpMemsList.map((item, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {item && item.group_members && item.group_members.length > 0 && (
                                              <div className={classes.teamWrapper}>
                                                <div className={classes.teamName}>
                                                  {item.group_title} - Group
                                                </div>
                                                <div>{renderGrpsListChip(item.group_members)}</div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    </div>
                                  </Paper>
                                </Grid>
                              ) : null}
                              {selectedMembers && selectedMembers.length > 0 && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Paper elevation={3} className={classes.membersCntr}>
                                    {selectedMembers.length > 0 && (
                                      <div className={classes.memCtr}>
                                        <div className={classes.teamName}>Selected Members </div>
                                        {selectedMembers.map((item, index) => {
                                          let label = item.name + " - " + item.email;
                                          let labelName = label.length > 40 ? label.substring(0, 35) + "..." : label;
                                          return (
                                            <React.Fragment key={index}>
                                              <Chip
                                                key={index}
                                                variant="outlined"
                                                deleteIcon={<HighlightOffIcon style={{ color: style.fontColour._blue }} />}
                                                label={`${labelName} - ${item.role}`}
                                                onDelete={() => { handleIndividualMemDelete(item) }}
                                                className={classes.selMemChip}
                                              />
                                            </React.Fragment>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </Paper>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.formButtonContainer}>
                          {!modifyEventInfo ? (
                            <Button
                              className={classes.buttons}
                              color="primary"
                              variant="contained"
                              disabled={inProgress || dtTimeErrFlag}
                              onClick={(event) => { scheduleMeeting(event) }}
                            >
                              Confirm
                              {inProgress ? (
                                <CircularProgress size={30} className={classes.buttonProgress} />
                              ) : null}
                            </Button>
                          ) : (
                            <Button
                              className={classes.buttons}
                              color="primary"
                              variant="contained"
                              disabled={inProgress}
                              onClick={(event) => { reScheduleMeeting(event) }}
                            >
                              Re-Schedule
                              {inProgress ? (
                                <CircularProgress size={30} className={classes.buttonProgress} />
                              ) : null}
                            </Button>
                          )}
                          <Button
                            className={classes.buttons}
                            color="primary"
                            variant="contained"
                            onClick={() => { onCancelCreateEvent() }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </React.Fragment>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <Paper elevation={1} style={{ marginTop: "2.1rem" }}>
                <div className={classes.detailsHeader}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} sm={8} md={10} lg={10}>
                      <div className={classes.mtitle}>{title}</div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2}>
                      <div style={{ display: "flex" }}>
                        {isLoggedInUserHost ? (
                          <Tooltip title="Edit">
                            <IconButton onClick={() => { modifyEvent() }} className={classes.iconBtn}>
                              <EditIcon className={classes.editIcon} />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        <Tooltip title="Calendar View">
                          <IconButton onClick={() => { clearRenderInfo() }} className={classes.iconBtn}>
                            <DateRangeIcon className={classes.calIcon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <div className={classes.container}>
                    <div className={classes.flex}>
                      {selectedCourseName ? (
                        <React.Fragment>
                          <div className={classes.label}>Course Name:</div>
                          <div className={classes.value}>{selectedCourseName}</div>
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.container}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Date:</div>
                          <div className={classes.value} style={{ textTransform: "uppercase" }}>
                            {moment(startDate).format("DD-MMM-YYYY hh:mm a")}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Duration:</div>
                          <div className={classes.value}>
                            {duration} &nbsp;Minutes
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider />
                  <div className={classes.container}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Meeting Id:</div>
                          <div className={classes.value}>{meetingId}</div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          {renderStartBtn && (
                            <a href={meetingURL} className={classes.hrefBtn} type="btn" target="_blank">
                              {isLoggedInUserHost ? 'Start Now' : 'Join now'}
                            </a>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider />
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ maxHeight: '150px', overflowY: 'scroll' }}>
                    <div className={classes.container}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Agenda:</div>
                        {showNormalTextFieldAgenda ? (
                          <div className={classes.value}>{agenda}</div>
                        ) : (
                          <div style={{ marginTop: '13px', marginRight: '23px', marginLeft: '-53px', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={createMarkup(agenda)} />
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Divider />
                  {isLoggedInUserHost && participantsList && participantsList.length ? (
                    <div className={classes.container}>
                      <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '150px', overflowY: 'scroll' }}>
                          <div className={classes.label}>Participants:</div>
                          {participantsList && participantsList.length && participantsList.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  label={(item.name + " - " + item.email).length > 30 ? (item.name + " - " + item.email).substring(0, 25) + "..." : (item.name + " - " + item.email)}
                                  className={classes.selMemChip}
                                />
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                  <Divider />
                  {selectedEventLabels && selectedEventLabels.length ? (
                    <React.Fragment>
                      <div className={classes.container}>
                        <Grid container spacing={2} style={{ height: '100px', overflowY: 'scroll' }}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className={classes.label}>Event Labels:</div>
                            {selectedEventLabels && selectedEventLabels.length && selectedEventLabels.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Chip
                                    key={index}
                                    variant="outlined"
                                    label={item.name}
                                    className={classes.selMemChip}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </div>
                      <Divider />
                    </React.Fragment>
                  ) : null}
                  {selectedInPersLoc && selectedInPersLoc.length ? (
                    <React.Fragment>
                      <div className={classes.container}>
                        <Grid container spacing={2} style={{ height: '100px', overflowY: 'scroll' }}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className={classes.label}>Location:</div>
                            {selectedInPersLoc && selectedInPersLoc.length && selectedInPersLoc.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Chip
                                    key={index}
                                    variant="outlined"
                                    label={item}
                                    className={classes.selMemChip}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </div>
                      <Divider />
                    </React.Fragment>
                  ) : null}
                  <div className={classes.container}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Meeting Type:</div>
                          <div className={classes.value}>{meetingTypeName}</div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Recurrence Type:</div>
                          <div className={classes.value}>
                            {recTypeName ? recTypeName : "NA"}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Interval:</div>
                          <div className={classes.value}>
                            {recInterval ? recInterval : "NA"}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Recurrences:</div>
                          <div className={classes.value}>
                            {recurringDays && recurringDays.length > 0
                              ? recurringDays.toString()
                              : "NA"}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Store Recordings:</div>
                          <div className={classes.value} style={{ textTransform: "capitalize" }}>
                            {recordingType}
                          </div>
                        </div>
                      </Grid>
                      {!isLoggedInUserHost ? (
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className={classes.flex}>
                            <div className={classes.label}>Attachment:</div>
                            <div className={classes.value}>
                              {uploadedAttachmentPreview && uploadedAttachmentPreview ? (
                                <React.Fragment>
                                  <Tooltip title="Attachment">
                                    <a href={uploadedAttachmentPreview} target='_blank' edge="start" color="primary" className={classes.calIcon} aria-label="close">
                                      {uploadedAttachmentPreview.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginTop: '-5px' }} /> : uploadedAttachmentPreview.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginTop: '-5px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginTop: '-5px' }} />}
                                    </a>
                                  </Tooltip>
                                </React.Fragment>
                              ) : ('No attachment found')}
                            </div>
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </div>
                  <Divider />
                  <div className={classes.container}>
                    {isLoggedInUserHost ? (
                      <div className={classes.btnCtr}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={inProgress}
                          onClick={() => {
                            handleDialogOpen();
                          }}
                          className={classes.buttons}
                        >
                          Delete Event
                          {!dialogOpen && inProgress ? (
                            <CircularProgress size={30} className={classes.buttonProgress} />
                          ) : null}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            clearRenderInfo();
                          }}
                          className={classes.buttons}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
        {/* <div>{displayZoom && <ZoomRenderer props={zoomProps} />}</div> */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ fontSize: '19px', fontWeight: 500, color: '#999' }}>
              {"Are you sure you want to delete selected Event ?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {inProgress ? (
              <CircularProgress size={20} className={classes.buttonProgress} />
            ) : null}
            <Button
              onClick={() => {
                deleteEvent();
              }}
              disabled={inProgress}
              autoFocus
              color="primary"
            >
              Confirm
            </Button>
            <Button onClick={handleDialogClose} disabled={inProgress} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </React.Fragment >
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(Meetings);

const useStyles = makeStyles((theme) => ({
  teamName: {
    fontSize: "1rem",
    color: "#15528d",
    textAlign: 'initial',
    padding: '0.3rem'
  },
  memCtr: {
    paddingBottom: '1rem'
  },
  calendar: {
    height: 500,
    minWidth: '800px',
    overflowX: 'scroll'
  },
  calendarMob: {
    height: 500,
    minWidth: '600px',
    width: "800px",
    overflowX: 'scroll'
  },
  iconBtn: {
    borderRadius: "5px",
  },
  eventItem: {
    marginLeft: "0.5rem",
  },
  selectFormControl: {
    width: "100%",
  },
  formButtonContainer: {
    marginTop: "1rem",
    textAlign: "center",
  },
  buttons: {
    textTransform: 'none',
    marginRight: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    }
  },
  hrefBtn: {
    borderRadius: '5px',
    padding: '0.5rem 0.8rem',
    textDecoration: 'none',
    backgroundColor: colorStyles.btnColor,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
    color: colorStyles.btnText,
  },
  gridItem: {
    marginTop: "1rem",
  },
  siderCntr: {
    height: "30rem",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  siderCntrMob: {
    height: "15rem",
    overflowY: "scroll",
  },
  gridCtr: {
    marginTop: "1rem",
  },
  textField: {
    width: "100%",
  },
  dateCtr: {
    margin: "0.2rem 0.5rem 0 0",
    display: "flex",
  },
  timeCtr: {
    margin: "0 0.5rem 0.2rem 0.5rem",
    display: "flex",
  },
  timeRecCtr: {
    margin: "0 0.5rem 0.2rem 0",
  },
  dateVal: {
    textAlign: "center",
    color: "#11539f",
    fontSize: "0.8rem",
  },
  eventsTitle: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    padding: "1rem 0 1rem 1rem",
    backgroundColor: "#eaf2f2",
    color: "#11539f",
  },
  itemTitle: {
    fontSize: "1rem",
    color: "#11539f",
    marginTop: "0.3rem",
    textAlign: "initial",
  },
  calIcon: {
    margin: "5px",
    color: style.iconColor._color
  },
  editIcon: {
    color: style.iconColor._color
  },
  event: {
    cursor: "pointer",
    position: "relative",
    left: "25%",
    top: "25%",
    color: style.iconColor._color
  },
  timings: {
    fontSize: "0.8rem",
    color: "#11539f",
    textTransform: "uppercase",
  },
  recLabel: {
    textAlign: "initial",
    fontSize: "0.8rem",
    color: "#424548",
  },
  recHostLabel: {
    fontSize: "0.8rem",
    color: '#2e8eec',
    fontWeight: 500
  },
  recDays: {
    fontSize: "0.8rem",
    color: "#424548",
  },
  approvalTypeSelect: {
    width: "94%",
  },
  container: {
    textAlign: "initial",
    margin: "1rem 0 1rem 0",
  },
  mtitle: {
    textAlign: "initial",
    margin: "0.7rem 0 0 1rem",
    fontSize: "1rem",
    padding: "0.5rem",
  },
  btnCtr: {
    display: "flex",
    justifyContent: "center",
    marginRight: "0.5rem",
    paddingBottom: "0.5rem",
  },
  detailsHeader: {
    backgroundColor: "#eaf2f2",
    borderRadius: "5px",
  },
  label: {
    color: "#11539f",
    fontWeight: 500,
  },
  noDataWrapper: {
    color: "#11539f",
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '10px'
  },
  value: {
    fontWeight: 400,
    color: "#000",
    marginLeft: "0.3rem",
  },
  itemBlock: {
    cursor: "pointer",
  },
  container: {
    textAlign: "initial",
    margin: "1rem 0 1rem 0",
  },
  flex: {
    display: "flex",
  },
  membersCntr: {
    maxHeight: "200px",
    overflowY: 'scroll',
    marginBottom: '11px'
  },
  teamWrapper: {
    margin: "0.5rem",
  },
  selMemChip: {
    color: style.fontColour._blue,
    marginTop: "0.3rem",
    border: "1px solid #2e8eec",
    marginLeft: '2px'
  },
  memCtr: {
    margin: "1rem",
  },
  autoCompWrapper: {
    marginTop: '1rem'
  },
  grpListWrapper: {
    color: style.fontColour._blue,
  },
  evntLabelWrapper: {
    color: '#2e8eec',
    border: '1px solid #2e8eec',
    fontSize: '11px',
    height: '21px',
    marginBottom: '5px',
    marginTop: '10px',
    marginLeft: '3px'
  },
  searchBox: {
    margin: 5,
    marginBottom: '10px',
    width: '282px'
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  searchIcon: {
    color: '#2e8eec',
    fontSize: '34px',
  },
  input: {
    '&::placeholder': {
      fontSize: '13px',
      fontStyle: 'italic',
      fontWeight: 500
    }
  },
  clearFilterWrap: {
    fontSize: '13px',
    color: '#2e8eec',
    marginLeft: '7px',
    marginBottom: '5px'
  },
}));
