import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';


export const enrollCourse = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.enrollment.enrollCourse}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}
