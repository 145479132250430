
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import { getDomainInfo } from '../../Helpers/basic';
import { myCourseListCompleted, myCourseListInProgress, myCourseListFav } from '../../MockData/roleBased';

export const getMyCourses = (payload, successCallBack, failureCallBack) => {

  let domainDet = getDomainInfo();
  var url = '';
  if (domainDet.type === 'b2b') {
    url = `${apiConfig.profile.myCoursesb2b}`;
  } else {
    url = `${apiConfig.profile.myCourses}`;
  }

  let params = new URLSearchParams();
  if (payload.courseType.length > 0) {
    payload.courseType.map(item => {
      params.append('progress_obj', item)
    })

  }

  axios.get(
    url, {
    params: params
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}


export const getMyFavCourses = (successCallBack, failureCallBack) => {

  var url = `${apiConfig.profile.myFavCoursesb2c}`;

  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //successCallBack(myCourseListFav)
      failureCallBack(error)
    })
}