import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { style } from '../../Styles/theme';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

export default function CourseDesc(props) {
    const classes = useStyles();
    const courseDet = props.data;
    const [viewMore, setViewMore] = useState(false);
    const renderAuthor = () => {
        return (
            <div className={classes.athrContainer}>
                {props.authors.length > 0 && <div className={classes.descHeader}>Authors</div>}
                <Grid container className={classes.gridContainer} spacing={6} >
                    {props.authors.length > 0 && props.authors.map((item, iter) => (
                        <Grid key={iter} item xs={12} sm={12} md={6} lg={4} className={classes.avatarContainer}>
                            <Paper elevation={3} className={classes.paper}>
                                <Avatar className={classes.avatar} alt={item.name} src={item.image} />
                                <div className={classes.authName}>{item.name}</div>
                                <div className={classes.authDesc}>{item.description}</div>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
    }



    return (
        <div className={classes.descContainer}>
            <div className={classes.descHeader}>About this Course</div>
            <div className={classes.desc} dangerouslySetInnerHTML={{ __html: courseDet.length > 1000 && viewMore === false ? courseDet.substring(0, 1000) + "..." : courseDet }} />
            {courseDet.length > 1000 && viewMore === false ? <div className={classes.viewMore} onClick={() => { setViewMore(true) }}> Read more</div> : viewMore === true ? <div className={classes.viewMore} onClick={() => { setViewMore(false) }}>View less</div> : null}
            {/* {
                renderAuthor()
            } */}
        </div>
    );
}


const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    viewMore: {
        fontSize: "0.8rem",
        color: style.fontColour._blue,
        padding: "0.1rem 1rem 0.2rem 0",
        textAlign: 'end',
        textDecoration: 'underline',
        cursor: 'pointer',
        backgroundColor: style.backgroundColor._white,
    },
    desc: {
        textAlign: 'justify',
        backgroundColor: style.backgroundColor._white,
        borderRadius: '0.4rem',
        padding: '0.5rem'
    },
    descHeader: {
        padding: "1rem 0 0 0.5rem",
        fontSize: style.fontSize._sideHeading,
        color: style.fontColour._blue,
        backgroundColor: style.backgroundColor._white,
        fontWeight: 500,
        fontFamily: 'Roboto,sans-serif'
    },
    fullList: {
        width: 'auto',
    },
    descContainer: {
        paddingTop: "1rem"
    },
    descSubTitle: {
        fontSize: "1rem",
        paddingTop: "1rem",
        color: style.fontColour._blue
    },
    descContent: {
        fontSize: "1rem",
        paddingTop: "0.5rem",
        color: style.fontColour._grey
    },
    featureTitle: {
        fontSize: "1.5rem",
        paddingTop: "1rem",
        fontWeight: "600",
        color: style.fontColour._grey
    },
    featureRow: {
        display: "flex"
    },
    feature: {
        fontSize: "1rem",
        padding: "0.8rem 0 0 1rem",
        color: style.fontColour._grey
    },
    iconContainer: {
        padding: "0.8rem 0 0 1rem",
    },
    icon: {
        fontSize: "2rem",
        color: style.fontColour._blue,
    },
    paper: {
        backgroundColor: "#fafafa"
    },
    athrContainer: {
        padding: "1rem 0 0 0.5rem",
        justifyContent: "center",
        backgroundColor: style.backgroundColor._white,
        marginTop: "1rem"
    },
    authName: {
        color: style.fontColour._blue,
        padding: "2rem 0 0 0",
        textAlign: "center",
        fontSize: style.fontSize._description
    },
    authDesc: {
        padding: "1rem",
        fontSize: style.fontSize._description,
        justifyContent: "center",
        textAlign: "justify"
    },
    avatarContainer: {
        position: 'relative'
    },
    gridContainer: {
        justifyContent: "center",
        paddingTop: '4rem'
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        position: 'absolute',
        top: '-20px',
        left: '45%',
        border: '2px solid #6bc685'
    }

}));




