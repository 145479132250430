import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
//local imports
import { convertUTCTimeZone } from '../../Helpers/basic';
import { style } from '../../Styles/theme';


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    height: '42px',
    backgroundColor: '#F5F5F5',
  },
  title: {
    flex: 1,
    marginTop: '-24px',
    color: 'black'
  },
  iconWrapper: {
    marginTop: '-24px'
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        width: 'auto',
        paddingLeft: '50px',
        paddingRight: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    marginTop: '20px'
  },
  textWrapper: {
    fontWeight: '600',
    color: style.fontColour._blue,
    fontSize: '19px',
    marginTop: '11px',
    textAlign: 'center'
  },
  detailsHead: {
    fontSize: '15px'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  readingsButton: {
    float: 'right',
    marginLeft: '12px',
    marginTop: '-4px',
    color: style.fontColour._blue
  },
  uploadedAttachement: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  uploadIcon: {
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPopup(props) {
  const classes = useStyles();
  const open = props.openPreview
  const discussionDetails = {
    title: props.previewDetails && props.previewDetails.title ? props.previewDetails.title : '',
    desc: props.previewDetails && props.previewDetails.description ? props.previewDetails.description : '',
    start_date: props.previewDetails && props.previewDetails.start_date ? props.previewDetails.start_date : '',
    end_date: props.previewDetails && props.previewDetails.end_date ? props.previewDetails.end_date : '',
    points: props.previewDetails && props.previewDetails.points ? props.previewDetails.points : 0,
    allowThreadReply: props.previewDetails && props.previewDetails.threaded_reply ? 'True' : 'False',
    canSeeBeforeReply: props.previewDetails && props.previewDetails.preview_reply ? 'True' : 'False',
    attachment: props.previewDetails && props.previewDetails.attachment ? props.previewDetails.attachment : null
  }
  
  function createMarkup(data) {
    return {
      __html: data
    };
  }

  const dateFormat = (due_days) => {
    return(convertUTCTimeZone(due_days))
  }

  return (
    <div>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>discussion details preview popup</h1>
      </div>
      <Dialog fullScreen open={open} onClose={props.closePreview} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title} aria-hidden="true">
              Discussion Preview
            </Typography>
            <IconButton edge="start" className={classes.iconWrapper} onClick={props.closePreview} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main className={classes.paper}>
          <Paper style={{ width: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.textWrapper}>{ discussionDetails.title ? discussionDetails.title : '' }</Typography>
              </Grid>
              {props && props.from !== 'externalType' ? (
                <React.Fragment>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>Start date:</b> </span>
                    <span>{dateFormat(discussionDetails.start_date)}</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>End date:</b> </span>
                    <span>{dateFormat(discussionDetails.end_date)}</span>
                  </Grid>
                </React.Fragment>
              ) : null }
              { discussionDetails.points ? (
                <Grid item xs={12} style={{ marginTop: '16px'}}>
                  <span ><b>Points:</b> </span>
                  <span>{discussionDetails.points} pts</span>
                </Grid>
              ) : null }
              <Grid item xs={12} style={{ marginTop: '16px'}}>
                <span><b>Allow threaded replies:</b> </span>
                <span>{discussionDetails.allowThreadReply}</span>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '16px'}}>
                <span><b>Can see before reply:</b> </span>
                <span>{discussionDetails.canSeeBeforeReply}</span>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '10px'}}>
                <span><b>Description:</b> </span>
                <span className={classes.detailsHead}><div dangerouslySetInnerHTML={createMarkup(discussionDetails.desc)}></div></span>
              </Grid>
              { props && props.from === 'externalType' && discussionDetails.attachment ? (
                <React.Fragment>
                  <span ><b>Attachment:</b> </span>
                  <Tooltip title="Attachment">
                    <a href={discussionDetails.attachment} target='_blank' edge="start" color="primary" className={classes.readingsButton} aria-label="close">
                      {discussionDetails.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec' }}/> : discussionDetails.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec' }}/> : <DescriptionIcon style={{ color: '#2e8eec' }}/>}
                    </a>
                  </Tooltip>
                </React.Fragment>
              ) : null }
            </Grid>
          </Paper>
        </main>
      </Dialog>
    </div>
  );
}