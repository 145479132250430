import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Paper from "@material-ui/core/Paper";
import { loadCSS } from "fg-loadcss";
import { Route } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import SaveIcon from "@material-ui/icons/Save";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SendIcon from "@material-ui/icons/Send";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link'
//Local Imports
import Header from "../Header";
import TextEditor from "../Shared/TextEditor";
import { style } from "../../Styles/theme";
import { bytesToSize, getDomainInfo } from "../../Helpers/basic";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { getAssignmentData, submitAssignment, updateAssignment, deleteAttachment } from "../../Store/Assignments/actionCreator";
import { assignment_file_size, assignment_file_types } from "../../Configs/commonConfig";
import { attachment_msgs, assignment_msgs } from "../Common/messages";

function Assignment(props) {
  const classes = useStyles();
  const [assignmentContent, setAssignmentContent] = useState('');
  const [assignmentTitle, setAssignmentTitle] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [points, setPoints] = useState();
  const [dueDate, setDueDate] = useState();
  const [assignmentDesc, setAssignmentDesc] = useState();
  const [editorFlag, setEditorFlag] = useState(false);
  const [contentDisplayFlag, setContentDisplayFlag] = useState(false);
  const [assignmentStatus, setAssignmentStatus] = useState();
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [submissionId, setSubmissionId] = useState();
  const [progress, setProgress] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteType, setDeleteType] = useState();
  const [title, setTitle] = useState();
  const [id, setId] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [submittedDate, setSubmittedDate] = useState();
  const [submissionType, setSubmissionType] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [fromType, setFromType] = useState('')

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
    fetchAssessmentInfo();

    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("from");
    setFromType(paramVal)
  }, []);

  const handleClose = () => {
    setOpenAlertDialog(false);
  };

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    return (
      <Route>
        {
          <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
            {getDomainInfo().name !== 'prep' ? (
              <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                Home
              </RouterLink>
            ) : (
              <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                Home
              </Link>
            )}
            {fromType !== 'external' ? (
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/assignments/${id}/?type=${paramVal}`}>
                {title} - Assignments List
              </RouterLink>
            ) : (
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/externalassignments`}>
                Assignments List
              </RouterLink>
            )}
            <div style={style.brdCrmbSecondary}>{assignmentTitle && assignmentTitle}</div>
          </Breadcrumbs>
        }
      </Route>
    );
  };

  const submitForm = (event, type) => {
    event.preventDefault();

    setBtnDisabled(true);
    setProgress(true);

    let formData = new FormData();
    formData.append("assignment_id", props.match.params.assignmentId);

    formData.append("content", assignmentContent);
    if (type === "save") {
      formData.append("status", "draft");
    } else if (type === "submit") {
      formData.append("status", "completed");
    }
    for (let i = 0; i <= uploadedFiles.length - 1; i++) {
      formData.append(`attachment_${i + 1}`, uploadedFiles[i].file ? uploadedFiles[i].file : null);
    }

    if (uploadedFiles.length > 0) {
      formData.append("attachment_count", uploadedFiles.length + attachmentCount);
    } else {
      formData.append("attachment_count", attachmentCount);
    }
    if (assignmentStatus === "yet-to-start") {
      submitAssignment(formData, type, assignmentSuccessCB, assignmentFailureCB);
    } else if (assignmentStatus === "draft") {
      updateAssignment(formData, submissionId, type, updateSuccessCB, updateFailureCB);
    }
  };

  const updateSuccessCB = (res, status) => {
    props.showSnackBar({
      state: true,
      message: status === "save" ? assignment_msgs.ASSIGNMENT_SAVED_SUCCESS : assignment_msgs.ASSIGNMENT_SUBMIT_SUCCESS,
      type: "success",
    });
    setUploadedFiles([]);
    setEditorFlag(true);
    fetchAssessmentInfo();
    setBtnDisabled(false);
    setProgress(false);
  };

  const updateFailureCB = () => {
    props.showSnackBar({
      state: true,
      message: assignment_msgs.ASSIGNMENT_SUBMIT_FAILED,
      type: "error",
    });
    setBtnDisabled(false);
    setProgress(false);
  };

  const assignmentSuccessCB = (res, status) => {
    props.showSnackBar({
      state: true,
      message: status === "save" ? assignment_msgs.ASSIGNMENT_SAVED_SUCCESS : assignment_msgs.ASSIGNMENT_SUBMIT_SUCCESS,
      type: "success",
    });
    fetchAssessmentInfo();
    setUploadedFiles([]);
    setEditorFlag(false);
    setContentDisplayFlag(true);
    setBtnDisabled(false);
    setProgress(false);
  };

  const assignmentFailureCB = () => {
    props.showSnackBar({
      state: true,
      message: assignment_msgs.ASSIGNMENT_SUBMIT_FAILED,
      type: "error",
    });
    setBtnDisabled(false);
    setProgress(false);
  };

  const fetchAssessmentInfo = () => {
    let payload = {
      assignment_id: props.match.params.assignmentId,
    };
    getAssignmentData(payload, successCB, failureCB);
  };

  const successCB = (res) => {
    if (Object.keys(res.results.user_data).length > 0) {
      setAssignmentContent(res.results.user_data.content);
      setAssignmentStatus(res.results.user_data.status);
      setFiles(res.results.user_data.attachment);
      setAttachmentCount(res.results.user_data.attachment.length);
      setFileNames(res.results.user_data.file_name);
      setSubmissionId(res.results.user_data.submission_id);
      setSubmittedDate(res.results.user_data.user_modified_date);
      if (res.results.user_data.status !== "completed") {
        setEditorFlag(true);
        setContentDisplayFlag(false);
      } else if (res.results.user_data.status === "completed") {
        setContentDisplayFlag(true);
        setEditorFlag(false);
      }
    } else {
      setAssignmentStatus("yet-to-start");
      setEditorFlag(true);
    }
    setPoints(res.results.points);
    setDueDate(res.results.due_date);
    setAssignmentDesc(res.results.desc);
    setAssignmentTitle(res.results.title);
    setTitle(res.title);
    setId(res.id);
  };

  const failureCB = () => {
    props.showSnackBar({
      state: true,
      message: assignment_msgs.ASSIGNMENT_FETCH_FAILED,
      type: "error",
    });
  };

  const validateFile = (file) => {
    let fileDetails = {
      fileError: false,
      fileErrorMsg: "",
    };

    if (file.size > assignment_file_size) {
      fileDetails = {
        fileError: true,
        fileErrorMsg: attachment_msgs.ATTACHMENT_MAX_FILE_SIZE_WARN,
      };
    } else {
      const allowedExtensions = assignment_file_types;
      const fileExtension = file.name.split(".")[1].toLowerCase();
      if (allowedExtensions.indexOf(fileExtension) === -1) {
        fileDetails = {
          fileError: true,
          fileErrorMsg: attachment_msgs.ATTACHMENT_FILE_TYPE_WARN,
        };
      } else {
        fileDetails = {
          fileError: false,
          fileErrorMsg: "",
          name: file.name,
          size: bytesToSize(file.size),
          type: file.type,
          file: file,
        };
      }
    }
    return fileDetails;
  };

  const onChangeEditorContent = (data) => {
    setAssignmentContent(data);
  };

  const onFileToUploadClick = (id) => {
    document.getElementById(id).click();
  };
  const removeUploadedFile = (index, type) => {
    setOpenAlertDialog(true);
    setDeleteIndex(index);
    setDeleteType(type);
  };

  const deleteSelected = () => {
    if (deleteType === "local") {
      uploadedFiles.splice(deleteIndex, 1);
      setUploadedFiles([...uploadedFiles]);
      props.showSnackBar({
        state: true,
        message: "File Removed.",
        type: "success",
      });
      setOpenAlertDialog(false);
    } else if (deleteType === "remote") {
      let formData = new FormData();
      formData.append("submissionId", submissionId);
      formData.append("index", deleteIndex);
      deleteAttachment(formData, submissionId, successCallBack, failureCallBack);
    }
  };

  const removePreviousUploaded = (fileIndex, type) => {
    setOpenAlertDialog(true);
    setDeleteIndex(fileIndex);
    setDeleteType(type);
  };

  const successCallBack = (res) => {
    setOpenAlertDialog(false);
    props.showSnackBar({
      state: true,
      message: "File deleted Successfully.",
      type: "success",
    });
    fetchAssessmentInfo();
  };

  const failureCallBack = () => {
    setOpenAlertDialog(false);
    props.showSnackBar({
      state: true,
      message: "File Deletion Failed.",
      type: "error",
    });
  };

  const onFileUploadHandler = (event) => {
    let attachment_count = 0;
    if (!event.target.files[0]) {
      return;
    } else {
      let filesArr = [];
      if (uploadedFiles.length > 0) {
        filesArr = [...uploadedFiles];
      }
      let count = attachmentCount + filesArr.length;
      if (count < 3) {
        const validatedFile = validateFile(event.target.files[0]);
        if (validatedFile.fileError === true) {
          props.showSnackBar({
            state: true,
            message: validatedFile.fileErrorMsg,
            type: "error",
          });
        } else {
          filesArr.push(validatedFile);
          attachment_count = attachmentCount + 1;
        }
        setUploadedFiles(filesArr);
      } else {
        props.showSnackBar({
          state: true,
          message: attachment_msgs.ATTACHMENT_MAX_FILE_SIZE_WARN,
          type: "error",
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Header history={props} />
      </Grid>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: "6rem", paddingLeft: "1rem" }}>
          {assignmentTitle && SimpleBreadcrumbs()}
        </Grid>
      </div>
      <div className={classes.headerContainer}>
        <div className={classes.headerBox}>
          <Grid container>
            <Grid item sm={12} xs={12} md={7} lg={7} className={classes.titleContainer}>
              <div className={classes.title}>{assignmentTitle}</div>
            </Grid>
            <Grid item sm={12} xs={12} md={5} lg={5} className={classes.titleContainer}>
              <div style={{ display: "flex" }}>
                <div className={classes.status}>Due : {dueDate != null ? moment(dueDate).format("DD-MMM-YYYY") : "NA"} </div>
                {assignmentStatus && assignmentStatus !== "yet-to-start" ? (
                  <div className={classes.status}>
                    {" "}
                    {assignmentStatus === "draft" ? " Last Modified : " : " Submitted On : "} {moment(submittedDate).format("DD-MMM-YYYY")}{" "}
                  </div>
                ) : (
                  ""
                )}
                <div className={classes.status}>Points : {points} </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.body}>
        <Container maxWidth={false}>
          <Paper elevation={2} className={classes.paper}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={classes.sideHeading}>Assignment Description</div>
                <div className={classes.asgnmtdesc} dangerouslySetInnerHTML={{ __html: assignmentDesc }} />
              </Grid>
            </Grid>
            <Divider />
            <form
              id="main"
              tabIndex="-1"
              autoComplete="off"
              className={classes.form}
              onSubmit={(evnt) => {
                evnt.preventDefault();
              }}
            >
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={12} lg={9}>
                  <div className={classes.sideHeading}>Your Solution</div>
                  <div className={classes.editor}>
                    {editorFlag === true ? <TextEditor onChangeEditorContent={onChangeEditorContent} description={assignmentContent} /> : null}
                    {contentDisplayFlag === true ? (
                      <div className={classes.asgnmtdescDisplay} dangerouslySetInnerHTML={{ __html: assignmentContent }} />
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} className={classes.gridContainer}>
                  <Paper elevation={3} className={classes.filecntr}>
                    <div className={classes.uploadBtnCntr}>
                      <div className={classes.uploadlabel} aria-hidden="true"> Assignment Files: </div>
                      {assignmentStatus !== "completed" && (
                        <React.Fragment>
                          <input
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            accept=".doc,.docx,.pdf,.pptx,.ppt"
                            onChange={onFileUploadHandler}
                          />
                          <Button
                            variant="contained"
                            disabled={btnDisabled}
                            color="primary"
                            component="span"
                            aria-label="Upload attachment (acceptable file formats doc, docx, pdf, pptx, ppt) max 3 files and each file 10MB"
                            className={classes.uploadBtn}
                            onClick={() => {
                              onFileToUploadClick("contained-button-file");
                            }}
                          >
                            <CloudUploadIcon className={classes.uploadIcon} aria-hidden="true" /> Upload
                          </Button>{" "}
                        </React.Fragment>
                      )}
                    </div>
                    {assignmentStatus !== "completed" && <div className={classes.filetxt} aria-hidden="true">(Max:3 Files; Formats:(ppt, doc, docx, pdf, pptx, ppt); Size:10mb each)</div>}
                    <Divider aria-hidden="true" />
                    {assignmentStatus !== "completed" && (
                      <div className={classes.demo}>
                        <List>
                          {uploadedFiles &&
                            uploadedFiles.length > 0 &&
                            uploadedFiles.map((item, index) => {
                              let file_ext = item.name.substring(item.name.lastIndexOf(".") + 1);
                              let file_name = item.name;
                              if (item.name.length > 20) {
                                file_name = item.name.substring(0, 16) + "..." + file_ext;
                              }
                              return (
                                <ListItem key={index}>
                                  <ListItemText primary={file_name} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={() => {
                                        removeUploadedFile(index, "local");
                                      }}
                                      edge="end"
                                      aria-label="delete"
                                    >
                                      <Tooltip title="Delete File" arrow>
                                        <DeleteIcon className={classes.icon} />
                                      </Tooltip>
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    )}
                    {assignmentStatus !== undefined && (assignmentStatus === "completed" || assignmentStatus === "draft") && (
                      <div className={classes.demo}>
                        <List>
                          {attachmentCount !== undefined &&
                            attachmentCount > 0 &&
                            files.map((item, index) => {
                              let file_name = "",
                                file_ext = "";
                              if (fileNames.length > 0) {
                                let name = fileNames[index];
                                if (name !== undefined) {
                                  file_ext = name.substring(name.lastIndexOf(".") + 1);
                                  file_name = name.substring(name.indexOf("_") + 1);
                                  if (file_name.length > 20) {
                                    file_name = file_name.substring(0, 16) + "..." + file_ext;
                                  }
                                }
                              }
                              return (
                                <ListItem key={index}>
                                  <ListItemText primary={file_name === "" ? `File_${index + 1}` : file_name} />
                                  <ListItemSecondaryAction>
                                    {assignmentStatus === "draft" && (
                                      <IconButton
                                        onClick={() => {
                                          removePreviousUploaded(index, "remote");
                                        }}
                                        edge="end"
                                        aria-label="delete"
                                      >
                                        <Tooltip title="Delete File" arrow>
                                          <DeleteIcon className={classes.icon} />
                                        </Tooltip>
                                      </IconButton>
                                    )}
                                    <a href={item}>
                                      <Tooltip title="Download File" arrow>
                                        <CloudDownloadIcon className={classes.downloadIcon} />
                                      </Tooltip>
                                    </a>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                        </List>
                      </div>
                    )}
                  </Paper>
                </Grid>
              </Grid>

              {assignmentStatus !== "completed" && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container className={classes.buttonGridContainer}>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={btnDisabled}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={(evnt) => {
                          submitForm(evnt, "save");
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={btnDisabled}
                        className={classes.button}
                        startIcon={<SendIcon />}
                        onClick={(evnt) => {
                          submitForm(evnt, "submit");
                        }}
                      >
                        Submit
                      </Button>
                      {progress && <CircularProgress size={20} className={classes.progress} color="primary" />}
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          </Paper>
        </Container>
        <Dialog open={openAlertDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete the file?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteSelected} color="primary" autoFocus>
              Ok
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(Assignment);

const useStyles = makeStyles((theme) => ({
  progress: {
    color: style.fontColour._blue,
    marginBottom: '-6px'
  },
  uploadIcon: {
    color: "#FFF",
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  root: {
    backgroundColor: style.backgroundColor._screen,
  },
  form: {
    paddingBottom: "2rem",
  },
  filetxt: {
    textAlign: "center",
    padding: "0.2rem 0 0.2rem 0",
    color: style.fontColour._red,
    fontSize: style.fontSize._smallDesc,
  },
  filecntr: {
    margin: "3.5rem 0.5rem 0 1rem",
  },
  input: {
    display: "none",
  },
  uploadlabel: {
    paddingTop: "0.5rem",
    color: style.fontColour._blue,
  },
  body: {
    paddingTop: "1rem",
  },
  paper: {},
  uploadBtn: {
    marginLeft: "1rem",
    backgroundColor: style.fontColour._blue,
  },
  uploadBtnCntr: {
    display: "flex",
    padding: "1rem 0 0.5rem 1rem",
  },
  buttonGridContainer: {
    justifyContent: "center",
    padding: "1rem",
  },
  buttonContainer: {
    textAlign: "center",
  },
  button: {
    margin: "1rem",
    backgroundColor: style.fontColour._blue,
  },
  icon: {
    color: style.fontColour._tory_blue,
    fontSize: "1rem",
    padding: "0 0.5rem 0 0",
  },
  downloadIcon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
    marginBottom: "-7px",
    marginLeft: "3px",
  },
  sideHeading: {
    padding: "1rem 0 1rem 1rem",
    color: style.fontColour._blue,
    fontSize: style.fontSize._sideHeading,
  },
  gridContainer: {
    justifyContent: "center",
  },
  headerContainer: {
    backgroundColor: "#1c234a",
    borderRadius: "3px",
    marginTop: "1rem",
  },
  headerBox: {
    display: "flex",
  },
  title: {
    padding: "1rem 0 1rem 3rem",
    color: "#FFF",
    fontSize: style.fontSize._heading,
  },
  status: {
    padding: "1.5rem 0 1rem 1rem",
    color: "#FFF",
    fontSize: style.fontSize._labels,
  },
  editor: {
    padding: "0 1rem 0 1rem",
  },
  desc: {
    textAlign: "center",
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._grey,
  },
  asgnmtdesc: {
    padding: "0 1rem 0 1rem",
    fontSize: style.fontSize._desc,
  },
  asgnmtdescDisplay: {
    fontSize: style.fontSize._desc,
    padding: "1rem",
    border: "1px solid",
    borderRadius: "0.3rem",
    borderColor: style.fontColour._blue,
    maxHeight: "20rem",
    overflow: "scroll",
  },
}));
