import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';


export const mockLoginData = {
  token: '0036df086c30c2ade68dba1b76312bc859d0a2cd',
  user: {
    id: '12',
    username: 'kalyan.kumar@continualengine.com',
    email: 'kalyan.kumar@continualengine.com',
    firstname: 'kalyan',
    lastname: 'kumar'
  },
  organization: {
    id: 1,
    name: 'Continual Engine'
  },
  roles: ['Author']
}

export const login = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userAuth.loginUrl}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockLoginData)
    })
}

export const fetchProfile = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userAuth.fetchProfile}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockLoginData)
    })
}

export const updateProfile = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userAuth.updateProfile}`
  axios.put(url, payload)
  .then(function (response){
    successCallBack(response.data)
  })
  .catch(function (error){
    failureCallBack(error)
  })
}