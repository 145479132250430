import ansrLogo from "../Static/ansrsourcelogo_color.png";
import ceLogo from "../Static/ce.png";
import abcLogo from "../Static/abcuniv.png";
import prepLogo from "../Static/prep-logo-white.svg";
import zopSmartLogo from "../Static/zopsmart_logo.png";
import falabellaLogo from "../Static/falabella_logo.png";
import goniyoLogo from "../Static/goniyo_logo.png";
import rmblLogo from "../Static/rmbl_logo_md.png";
import freoLogo from "../Static/freo.svg";
import { courseCategoryList } from '../MockData/mockData';
const jwt = require("jsonwebtoken");
const btoa = require("btoa");


export const cryptCourseId = (data) => {
  let cipherText = btoa(data);
  return cipherText;
}

export const fetchSignedUserInfo = () => {
  const domainDet = getDomainInfo();
  let idToken = "";
  if (domainDet.domainTokenLabel !== undefined) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (domainDet.domainUserLabel === cookiePair[0].trim()) {
        idToken = decodeURIComponent(cookiePair[1]);
      }
    }
  }

  if (idToken) {
    return parseIDToken(idToken);
  } else {
    return {
      isSignedIn: false,
      userName: null,
      email: null,
      displayName: null,
      firstName: null,
      lastName: null,
    };
  }
};

export const parseIDToken = (idToken) => {
  const parsedIDToken = parseJwt(idToken);
  let displayName = parsedIDToken.email.split("@")[0];
  if (parsedIDToken.firstName) {
    displayName = parsedIDToken.firstName;
  }
  if (parsedIDToken.given_name) {
    displayName = parsedIDToken.given_name;
    displayName = parsedIDToken.family_name ? `${displayName} ${parsedIDToken.family_name}` : displayName;
  }
  return {
    isSignedIn: true,
    userName: parsedIDToken.email,
    email: parsedIDToken.email,
    displayName: displayName,
    firstName: parsedIDToken.given_name ? parsedIDToken.given_name : parsedIDToken.firstName,
    lastName: parsedIDToken.family_name ? parsedIDToken.family_name : parsedIDToken.lastName,
    ref: parsedIDToken.ref,
  };
};

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const getDomainInfo = () => {
  switch (window.location.origin) {
    case "https://rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://myathina.com",
        domainTokenLabel: `myathina_token`,
        domainUserLabel: `myathina_user`,
        type: "b2c",
        showExternalAssessments: false
      };
    case "https://rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://moocpedia.com",
        domainTokenLabel: `moocpedia_token`,
        domainUserLabel: `moocpedia_user`,
        type: "b2c",
        showExternalAssessments: false
      };

    case "https://ansr.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://ansr.moocpedia.com",
        hostingURL: "https://ansr.hosting.moocpedia.com",
        renderingURL: "https://ansr.rendering.moocpedia.com",
        domainTokenLabel: `ansr_moocpedia_token`,
        domainUserLabel: `ansr_moocpedia_user`,
        type: "b2b",
        logo: ansrLogo,
        name: "ansrsource",
        showExternalAssessments: false
      };
    case "https://continualengine.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://continualengine.myathina.com",
        hostingURL: "https://continualengine.hosting.myathina.com",
        renderingURL: "https://continualengine.rendering.myathina.com",
        domainTokenLabel: `continualengine_myathina_token`,
        domainUserLabel: `continualengine_myathina_user`,
        type: "b2b",
        logo: ceLogo,
        name: "continualengine",
        showExternalAssessments: false
      };
    case "https://falabella.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://falabella.myathina.com",
        hostingURL: "https://falabella.hosting.myathina.com",
        renderingURL: "https://falabella.rendering.myathina.com",
        domainTokenLabel: `falabella_myathina_token`,
        domainUserLabel: `falabella_myathina_user`,
        type: "b2b",
        logo: falabellaLogo,
        name: "falabella",
        showExternalAssessments: false
      };
    case "https://goniyo.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://goniyo.myathina.com",
        hostingURL: "https://goniyo.hosting.myathina.com",
        renderingURL: "https://goniyo.rendering.myathina.com",
        domainTokenLabel: `goniyo_myathina_token`,
        domainUserLabel: `goniyo_myathina_user`,
        type: "b2b",
        logo: goniyoLogo,
        name: "goniyo",
        showExternalAssessments: false
      };
    case "https://sandbox.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://sandbox.myathina.com",
        hostingURL: "https://sandbox.hosting.myathina.com",
        renderingURL: "https://sandbox.rendering.myathina.com",
        domainTokenLabel: `sandbox_myathina_token`,
        domainUserLabel: `sandbox_myathina_user`,
        type: "b2b",
        logo: ceLogo,
        name: "sandbox",
        showExternalAssessments: false
      };
    case "https://ansrsource.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://ansrsource.myathina.com",
        hostingURL: "https://ansrsource.hosting.myathina.com",
        renderingURL: "https://ansrsource.rendering.myathina.com",
        domainTokenLabel: `ansrsource_myathina_token`,
        domainUserLabel: `ansrsource_myathina_user`,
        type: "b2b",
        logo: ansrLogo,
        name: "ansrsource",
        showExternalAssessments: false
      };
    case "https://goniyo.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://goniyo.moocpedia.com",
        hostingURL: "https://goniyo.hosting.moocpedia.com",
        renderingURL: "https://goniyo.rendering.moocpedia.com",
        domainTokenLabel: `goniyo_moocpedia_token`,
        domainUserLabel: `goniyo_moocpedia_user`,
        type: "b2b",
        logo: goniyoLogo,
        name: "goniyo",
        showExternalAssessments: false
      };
    case "https://falabella.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://falabella.moocpedia.com",
        hostingURL: "https://falabella.hosting.moocpedia.com",
        renderingURL: "https://falabella.rendering.moocpedia.com",
        domainTokenLabel: `falabella_moocpedia_token`,
        domainUserLabel: `falabella_moocpedia_user`,
        type: "b2b",
        logo: falabellaLogo,
        name: "falabella",
        showExternalAssessments: false
      };
    case "https://zopsmart.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://zopsmart.moocpedia.com",
        hostingURL: "https://zopsmart.hosting.moocpedia.com",
        renderingURL: "https://zopsmart.rendering.moocpedia.com",
        domainTokenLabel: `zopsmart_moocpedia_token`,
        domainUserLabel: `zopsmart_moocpedia_user`,
        type: "b2b",
        logo: zopSmartLogo,
        name: "zopsmart",
        showExternalAssessments: false
      };
    case "https://zopsmart.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://zopsmart.myathina.com",
        hostingURL: "https://zopsmart.hosting.myathina.com",
        renderingURL: "https://zopsmart.rendering.myathina.com",
        domainTokenLabel: `zopsmart_myathina_token`,
        domainUserLabel: `zopsmart_myathina_user`,
        type: "b2b",
        logo: zopSmartLogo,
        name: "zopsmart",
        showExternalAssessments: false
      };
    case "https://abcuniversity.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://abcuniversity.moocpedia.com",
        hostingURL: "https://abcuniversity.hosting.moocpedia.com",
        renderingURL: "https://abcuniversity.rendering.moocpedia.com",
        domainTokenLabel: `abcuniversity_moocpedia_token`,
        domainUserLabel: `abcuniversity_moocpedia_user`,
        type: "b2b",
        logo: abcLogo,
        name: "abcuniversity",
        showExternalAssessments: false
      };
    case "https://abcuniversity.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://abcuniversity.myathina.com",
        hostingURL: "https://abcuniversity.hosting.myathina.com",
        renderingURL: "https://abcuniversity.rendering.myathina.com",
        domainTokenLabel: `abcuniversity_myathina_token`,
        domainUserLabel: `abcuniversity_myathina_user`,
        type: "b2b",
        logo: abcLogo,
        name: "abcuniversity",
        showExternalAssessments: false
      };
    case "https://rmbl.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://rmbl.moocpedia.com",
        hostingURL: "https://rmbl.moocpedia.com",
        renderingURL: "https://rmbl.rendering.moocpedia.com",
        domainTokenLabel: `rmbl_moocpedia_token`,
        domainUserLabel: `rmbl_moocpedia_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        showExternalAssessments: false,
        noticeBoardFeature: true
      };
    case "https://rmbl.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://rmbl.myathina.com",
        hostingURL: "https://rmbl.myathina.com",
        renderingURL: "https://rmbl.rendering.myathina.com",
        domainTokenLabel: `rmbl_myathina_token`,
        domainUserLabel: `rmbl_myathina_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        showExternalAssessments: false,
        noticeBoardFeature: true
      };
    case "https://sandboxacademy.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://sandboxacademy.moocpedia.com",
        hostingURL: "https://sandboxacademy.hosting.moocpedia.com",
        renderingURL: "https://sandboxacademy.rendering.moocpedia.com",
        domainTokenLabel: `sandboxacademy_moocpedia_token`,
        domainUserLabel: `sandboxacademy_moocpedia_user`,
        type: "b2b",
        logo: ceLogo,
        name: "sandboxacademy",
        showExternalAssessments: false
      };
    case "https://sandboxacademy.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://sandboxacademy.myathina.com",
        hostingURL: "https://sandboxacademy.hosting.myathina.com",
        renderingURL: "https://sandboxacademy.rendering.myathina.com",
        domainTokenLabel: `sandboxacademy_myathina_token`,
        domainUserLabel: `sandboxacademy_myathina_user`,
        type: "b2b",
        logo: ceLogo,
        name: "sandboxacademy",
        showExternalAssessments: false
      };
    case "https://testce.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://testce.moocpedia.com",
        hostingURL: "https://testce.hosting.moocpedia.com",
        renderingURL: "https://testce.rendering.moocpedia.com",
        domainTokenLabel: `testce_moocpedia_token`,
        domainUserLabel: `testce_moocpedia_user`,
        type: "b2b",
        logo: ceLogo,
        name: "continualengine",
        showExternalAssessments: false
      };
    case "https://sandbox.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://sandbox.moocpedia.com",
        hostingURL: "https://sandbox.hosting.moocpedia.com",
        renderingURL: "https://sandbox.rendering.moocpedia.com",
        domainTokenLabel: `sandbox_moocpedia_token`,
        domainUserLabel: `sandbox_moocpedia_user`,
        type: "b2b",
        logo: ceLogo,
        name: "sandbox",
        showExternalAssessments: false
      };
    case "https://ansrsource.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://ansrsource.moocpedia.com",
        hostingURL: "https://ansrsource.hosting.moocpedia.com",
        renderingURL: "https://ansrsource.rendering.moocpedia.com",
        domainTokenLabel: `ansrsource_moocpedia_token`,
        domainUserLabel: `ansrsource_moocpedia_user`,
        type: "b2b",
        logo: ansrLogo,
        name: "ansrsource",
        showExternalAssessments: false
      };
    //localhosts config
    case "http://ansr.rendering.localhost:3000":
      return {
        domainName: "ansr.rendering.localhost",
        hostName: "http://ansr.localhost",
        hostingURL: "http://ansr.hosting.localhost:3000",
        renderingURL: "http://ansr.rendering.localhost:3000",
        domainTokenLabel: `ansr_localhost_token`,
        domainUserLabel: `ansr_localhost_user`,
        type: "b2b",
        logo: ansrLogo,
        name: "ansrsource",
        showExternalAssessments: false
      };
    case "http://testce.rendering.localhost:3000":
      return {
        domainName: "ansr.rendering.localhost",
        hostName: "http://ansr.localhost",
        hostingURL: "http://ansr.hosting.localhost:3000",
        renderingURL: "http://ansr.rendering.localhost:3000",
        domainTokenLabel: `ansr_localhost_token`,
        domainUserLabel: `ansr_localhost_user`,
        type: "b2b",
        logo: ceLogo,
        name: "continualengine",
        showExternalAssessments: false
      };
    case "http://ansrsource.rendering.localhost:3001":
      return {
        domainName: "ansrsource.rendering.localhost",
        hostName: "http://ansrsource.localhost",
        hostingURL: "http://ansrsource.hosting.localhost:3000",
        renderingURL: "http://ansrsource.rendering.localhost:3000",
        domainTokenLabel: `ansrsource_localhost_token`,
        domainUserLabel: `ansrsource_localhost_user`,
        type: "b2b",
        logo: ansrLogo,
        name: "ansrsource",
        showExternalAssessments: false
      };
    case "http://sandbox.rendering.localhost:3000":
      return {
        domainName: "sandbox.rendering.localhost",
        hostName: "http://sandbox.localhost",
        hostingURL: "http://sandbox.hosting.localhost:3000",
        renderingURL: "http://sandbox.rendering.localhost:3000",
        domainTokenLabel: `sandbox_localhost_token`,
        domainUserLabel: `sandbox_localhost_user`,
        type: "b2b",
        logo: ceLogo,
        name: "sandbox",
        showExternalAssessments: false
      };
    case "https://prep.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://prep.myathina.com",
        hostingURL: "https://prep.hosting.myathina.com",
        renderingURL: "https://prep.rendering.myathina.com/mdb-prep",
        domainTokenLabel: `prep_myathina_token`,
        domainUserLabel: `prep_myathina_user`,
        type: "b2b",
        logo: prepLogo,
        client: "prep",
        name: "prep",
        showExternalAssessments: false
      };
    case "https://prep.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://prepv2.moocpedia.com",
        hostingURL: "https://prep.hosting.moocpedia.com",
        renderingURL: "https://prep.rendering.moocpedia.com/mdb-prep",
        domainTokenLabel: `prep_moocpedia_token`,
        domainUserLabel: `prep_moocpedia_user`,
        type: "b2b",
        logo: prepLogo,
        client: "prep",
        name: "prep",
        showExternalAssessments: false
      };
    case "https://freo.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://freo.moocpedia.com",
        hostingURL: "https://freo.hosting.moocpedia.com",
        renderingURL: "https://freo.rendering.moocpedia.com/mdb-al200821",
        domainTokenLabel: `freo_moocpedia_token`,
        domainUserLabel: `freo_moocpedia_user`,
        type: "b2b",
        logo: freoLogo,
        name: "freo",
        showExternalAssessments: false
      };
    case "http://freo.rendering.localhost:3000":
      return {
        domainName: "myathina.com",
        hostName: "https://freo.moocpedia.com",
        hostingURL: "https://freo.hosting.moocpedia.com",
        renderingURL: "https://freo.rendering.moocpedia.com/mdb-al200821",
        domainTokenLabel: `freo_myathina_token`,
        domainUserLabel: `freo_myathina_user`,
        type: "b2b",
        logo: freoLogo,
        name: "freo",
        showExternalAssessments: false
      };
    case "https://freo.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://freo.myathina.com",
        hostingURL: "https://freo.hosting.myathina.com",
        renderingURL: "https://freo.rendering.myathina.com",
        domainTokenLabel: `freo_myathina_token`,
        domainUserLabel: `freo_myathina_user`,
        type: "b2b",
        logo: freoLogo,
        name: "freo",
        showExternalAssessments: false
      };
    case "https://demo.rendering.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://demo.moocpedia.com",
        hostingURL: "https://demo.hosting.moocpedia.com",
        renderingURL: "https://demo.rendering.moocpedia.com/mdb-aj090621",
        domainTokenLabel: `demo_moocpedia_token`,
        domainUserLabel: `demo_moocpedia_user`,
        type: "b2b",
        logo: ceLogo,
        name: "demo",
        showExternalAssessments: false
      };
    case "http://demo.rendering.localhost:3000":
      return {
        domainName: "myathina.com",
        hostName: "https://demo.moocpedia.com",
        hostingURL: "https://demo.hosting.moocpedia.com",
        renderingURL: "https://demo.rendering.moocpedia.com/mdb-aj090621",
        domainTokenLabel: `demo_myathina_token`,
        domainUserLabel: `demo_myathina_user`,
        type: "b2b",
        logo: ceLogo,
        name: "demo",
        showExternalAssessments: false
      };
    case "https://demo.rendering.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://demo.myathina.com",
        hostingURL: "https://demo.hosting.myathina.com",
        renderingURL: "https://demo.rendering.myathina.com",
        domainTokenLabel: `demo_myathina_token`,
        domainUserLabel: `demo_myathina_user`,
        type: "b2b",
        logo: ceLogo,
        name: "demo",
        showExternalAssessments: false
      };
    case "http://prep.rendering.localhost:3000":
      return {
        domainName: "myathina.com",
        hostName: "https://prepv2.moocpedia.com",
        hostingURL: "https://prep.hosting.moocpedia.com",
        renderingURL: "https://prep.rendering.moocpedia.com/mdb-prep",
        domainTokenLabel: `prep_myathina_token`,
        domainUserLabel: `prep_myathina_user`,
        type: "b2b",
        logo: prepLogo,
        client: "prep",
        name: "prep",
        showExternalAssessments: false
      };
    case "http://continualengine.rendering.localhost:3000":
      return {
        domainName: "myathina.com",
        hostName: "https://continualengine.myathina.com",
        hostingURL: "https://continualengine.hosting.myathina.com",
        renderingURL: "https://continualengine.rendering.myathina.com",
        domainTokenLabel: `continualengine_myathina_token`,
        domainUserLabel: `continualengine_myathina_user`,
        type: "b2b",
        logo: ceLogo,
        name: "continualengine",
        showExternalAssessments: false
      };
    case "http://zopsmart.rendering.localhost:3000":
      return {
        domainName: "myathina.com",
        hostName: "https://zopsmart.myathina.com",
        hostingURL: "https://zopsmart.hosting.myathina.com",
        renderingURL: "https://zopsmart.rendering.myathina.com",
        domainTokenLabel: `zopsmart_myathina_token`,
        domainUserLabel: `zopsmart_myathina_user`,
        type: "b2b",
        logo: zopSmartLogo,
        name: "zopsmart",
        showExternalAssessments: false
      };
    case "http://falabella.rendering.localhost:3000":
      return {
        domainName: "myathina.com",
        hostName: "https://falabella.myathina.com",
        hostingURL: "https://falabella.hosting.myathina.com",
        renderingURL: "https://falabella.rendering.myathina.com",
        domainTokenLabel: `falabella_myathina_token`,
        domainUserLabel: `falabella_myathina_user`,
        type: "b2b",
        logo: falabellaLogo,
        name: "falabella",
        showExternalAssessments: false
      };
    case "http://rendering.moocpedia.localhost:3000":
      return {
        domainName: "moocpedia.localhost",
        hostName: "http://b2c.myathina.localhost:3001",
        domainTokenLabel: `moocpedia_token`,
        domainUserLabel: `moocpedia_user`,
        type: "b2c",
        showExternalAssessments: false
      };
    case "http://rendering.myathina.localhost:3000":
      return {
        domainName: "myathina.localhost",
        hostName: "http://b2c.myathina.localhost:3001",
        domainTokenLabel: `myathina_token`,
        domainUserLabel: `myathina_user`,
        type: "b2c",
        showExternalAssessments: false
      };
    case "http://abcuniversity.rendering.localhost:3000":
      return {
        domainName: "moocpedia.com",
        hostName: "https://abcuniversity.moocpedia.com",
        hostingURL: "https://abcuniversity.hosting.moocpedia.com",
        renderingURL: "https://abcuniversity.rendering.moocpedia.com",
        domainTokenLabel: `abcuniversity_moocpedia_token`,
        domainUserLabel: `abcuniversity_moocpedia_user`,
        type: "b2b",
        logo: abcLogo,
        name: "abcuniversity",
        showExternalAssessments: false
      };
    case "http://rmbl.rendering.localhost:3001":
    case "http://rmbl.rendering.localhost:3000":
      return {
        domainName: "rmbl.localhost",
        hostName: "http://rmbl.localhost:3001",
        hostingURL: "http://rmbl.localhost:3001",
        renderingURL: "http://rmbl.rendering.localhost:3001",
        domainTokenLabel: `localhost_token`,
        domainUserLabel: `localhost_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        showExternalAssessments: false,
        noticeBoardFeature: true
      };
    default:
      return {
        domainName: "moocpedia.com",
        hostName: "https://moocpedia.com",
        domainTokenLabel: `moocpedia_token`,
        domainUserLabel: `moocpedia_user`,
        type: "b2c",
        showExternalAssessments: false
      };
  }
};
export const getLoggedInStatus = () => {
  const domainInfo = getDomainInfo();
  const token = getCookie(domainInfo.domainTokenLabel);
  if (token) {
    return true;
  } else {
    return false;
    //return true
  }
};

export const getUserDetails = () => {
  var domainInfo = getDomainInfo();
  var user = getCookie(domainInfo.domainUserLabel);
  if (user != null) {
    return parseJwt(user);
  } else {
    return {
      roles: [],
    };
  }
};

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getEnv = () => {
  switch (window.location.origin) {
    case "http://localhost:3001":
      return "dev";
    case "https://rendering.myathina.com":
    case "https://continualengine.rendering.myathina.com":
    case "https://sandbox.rendering.myathina.com":
    case "https://prep.rendering.myathina.com":
    case "https://ansr.rendering.myathina.com":
    case "https://zopsmart.rendering.myathina.com":
    case "https://falabella.rendering.myathina.com":
    case "https://sandboxacademy.rendering.myathina.com":
    case "https://goniyo.rendering.myathina.com":
    case "https://rmbl.rendering.myathina.com":
    case "https://demo.rendering.myathina.com":
    case "https://freo.rendering.myathina.com":
    case "https://ansrsource.rendering.myathina.com":
      return "prod";
    case "https://rendering.moocpedia.com":
    case "https://ansr.rendering.moocpedia.com":
    case "https://abcuniversity.rendering.moocpedia.com":
    case "https://testce.rendering.moocpedia.com":
    case "https://zopsmart.rendering.moocpedia.com":
    case "https://sandbox.rendering.moocpedia.com":
    case "https://falabella.rendering.moocpedia.com":
    case "https://sandboxacademy.rendering.moocpedia.com":
    case "https://goniyo.rendering.moocpedia.com":
    case "https://rmbl.rendering.moocpedia.com":
    case "https://demo.rendering.moocpedia.com":
    case "https://freo.rendering.moocpedia.com":
    case "https://asnrsource.rendering.moocpedia.com":
      return "test";
    default:
      return "dev";
  }
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const openInNewTab = (url = "/") => {
  const win = window.open(url, "_blank");
  win.focus();
};

export const redirectTo = (url = "/", newTab) => {
  if (newTab) {
    openInNewTab(url);
  } else {
    window.location = url;
  }
};

export const generateJwtToken = (obj) => {
  return jwt.sign(obj, "shhhhh");
};

export const convertUTCTimeZone = utcStr => {
  var option = { year: 'numeric', month: 'short', day: "2-digit" }
  var indiaTime = new Date(utcStr).toLocaleString("en-US", option);
  return indiaTime.toLocaleString();
}

export const convertUTCDatewithTime = utcStr => {
  var option = { year: 'numeric', month: 'short', day: "2-digit", hour: '2-digit', minute: '2-digit' }
  var indiaTime = new Date(utcStr).toLocaleString("en-US", option);
  return indiaTime.toLocaleString();
}

export const getCourseCategoryName = (id) => {
  let categoryName = courseCategoryList && courseCategoryList.length && courseCategoryList.find((item) => item.id === Number(id))
  return categoryName && categoryName.name
}


export const timeDiffCalc = (dateFuture, dateNow) => {

  var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - (days * 24);
  minutes = minutes - (days * 24 * 60) - (hours * 60);
  seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

  let timeDiff = "";
  if (hours > 0) {
    timeDiff = hours + ":" + minutes + ":" + seconds
  } else {
    timeDiff = minutes + ":" + seconds
  }
  return timeDiff;
}

export const openFreshworksWidget = (type) => {
  if (window.FreshworksWidget) {
    const userDetails = getUserDetails()
    window.FreshworksWidget('identify', 'ticketForm', {
      name: `${userDetails.first_name}  ${userDetails.last_name}`,
      email: userDetails.email,
    });
    window.FreshworksWidget('open', 'ticketForm')
    if (type === 'help') {
      const userDetails = getUserDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails.first_name}  ${userDetails.last_name}`,
        email: userDetails.email,
      });
      window.FreshworksWidget('open')
    } else {
      const userDetails = getUserDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails.first_name}  ${userDetails.last_name}`,
        email: userDetails.email,
      });
      window.FreshworksWidget('open', 'ticketForm')
    }
  }
}
