
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { style } from '../../Styles/theme';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: '1.5rem',
    color: style.fontColour._blue
  },
  header: {
    fontSize: '1rem',
    color: style.fontColour._blue
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconButton: {
    padding: '0 12px 12px 12px',
    marginLeft: '5px'
  }
}));

export default function ReadingMaterialBar(props) {
  const classes = useStyles();
  const readings_data = props.data

  const renderDownoadableFile = (data, index) => {
    let arr = [];
    let fileType = data && data.file_name && data.file_name.split(/\.(?=[^\.]+$)/)[1]
    let type = '';
    if (fileType === 'ppt' || fileType === 'pptx') {
      type = 'ppt';
    } else if (fileType === 'doc' || fileType === 'docx') {
      type = 'doc';
    }
    else if (fileType === 'pdf') {
      type = 'pdf';
    }
    arr.push(
      <React.Fragment key={index} >
        <Tooltip title={fileType}>
          <a className={classes.iconButton} aria-label="click to download attachment" href={data && data.file_url && data.file_url} target={type === 'pdf' ? '_blank' : ''}>{type === 'pdf' ? <PictureAsPdfIcon /> : type === 'ppt' ? <PictureInPictureIcon /> : <DescriptionIcon />}</a>
        </Tooltip>
        <p style={{ marginTop: '2px' }}>{data && data.file_name && data.file_name}</p>
      </React.Fragment>
    )
    return arr;
  }

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          aria-label="reading materials"
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.title}>{`Readings (${readings_data && readings_data.length})`}</div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ display: "grid", marginBottom: '-14px' }}>
          <div>
            {readings_data && readings_data.map((data, index) => (
              <React.Fragment key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.header}>{data.reading_title ? data.reading_title : null}</div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {data.reading_desc ? data.reading_desc : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div style={{ display: 'flex', marginTop: '13px' }}>
                      <div className={classes.header} style={{ paddingTop: '0.2rem' }} aria-hidden="true">Click to Download:</div>
                      <div style={{ display: 'flex' }}>
                        {renderDownoadableFile(data, index)}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Divider style={{ marginBottom: '20px' }} />
              </React.Fragment>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
