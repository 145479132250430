import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from '@material-ui/core/Link';
//Local Imports
import { style } from "../../Styles/theme";
import VimeoPlayer from "../Shared/vimeoPlayer";
import CardLayout from "../Shared/cardLayout";
import ContentCardLayoutLoader from "../Shared/SkeletonLoaders/contentCardLayoutLoader";
import VideoPlayerLoader from "../Shared/SkeletonLoaders/videoPlayerLoader";
import ReadingMaterialBar from "../Shared/readingMaterialBar";
import { getModuleDetails } from "../../Store/Course/actionCreator";
import CommonHeader from "../Shared/commonHeader";
import Header from "../Header";
import { getLoggedInStatus, getDomainInfo } from "../../Helpers/basic";

function createMarkup(desc) {
  return {
    __html: desc,
  };
}
export default function Module(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1280px)");
  const mobileView = !matches;
  const [moduleTitle, setModuleTitle] = useState();
  const [lessonInfo, setLessonInfo] = useState();
  const [moduleDesc, setModuleDesc] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const [headerInfo, setHeaderInfo] = useState();
  const [videoContent, setVideoContent] = useState();
  const [loading, setLoading] = useState(true);
  const [showReadingMaterial, setShowReadingMaterial] = useState(false);
  const [readingMaterial, setReadingMaterial] = useState();
  const [readerCardProps, setReaderCardProps] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [domainInfo, setDomainInfo] = useState(getDomainInfo());
  const [hasAssignments, setHasAssignments] = useState(false);
  const [hasQuiz, setHasQuiz] = useState(false);

  useEffect(() => {
    let loginStatus = getLoggedInStatus();
    setTenantId(props.match.params.tenantId);
    if (loginStatus) {
      setLoading(true);
      let payload = {
        courseId: props.match.params.courseId,
        moduleId: props.match.params.moduleId,
      };
      getModuleDetails(payload, successCallBack, failureCallBack);
    } else {
      props.history.push(`/${tenantId}/course/${props.match.params.courseId}?status=unauthorized`);
    }
  }, []);

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        {
          <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
            {getDomainInfo().name !== 'prep' ? (
              <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                Home
              </RouterLink>
            ) : (
              <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                Home
              </Link>
            )}
            <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${props.match.params.courseId}`}>
              {courseTitle}
            </RouterLink>
            <div style={style.brdCrmbSecondary}>{moduleTitle}</div>
          </Breadcrumbs>
        }
      </Route>
    );
  };

  const successCallBack = (res) => {
    setModuleTitle(res.moduleDetails.title);
    setLessonInfo(res.moduleDetails.lessons);
    setCourseTitle(res.moduleDetails.course_title);
    setModuleDesc(res.moduleDetails.desc);
    setHasAssignments(res.moduleDetails.has_assignments);
    setHasQuiz(res.moduleDetails.has_quiz);
    let headerProps = {
      moduleTitle: res.moduleDetails.title,
      courseTitle: res.moduleDetails.course_title,
      lessons: res.moduleDetails.lessons.length,
    };
    let videoProps = {
      courseId: props.match.params.courseId,
      moduleId: props.match.params.moduleId,
      videoURL: res.moduleDetails.video_url,
      videoThumbnailURL: "",
      type: "module",
    };
    let readingMaterialProps = {
      files: res.moduleDetails.reading_files,
      title: res.moduleDetails.rtitle,
      desc: res.moduleDetails.rdesc,
    };
    if (res && res.moduleDetails && res.moduleDetails.readinginfo && res.moduleDetails.readinginfo.length > 0) {
      let readerObj = {
        readerAvailable: true,
        count: res.moduleDetails.readinginfo.length,
      };
      setReaderCardProps(readerObj);
    }
    setHeaderInfo(headerProps);
    setVideoContent(videoProps);
    setReadingMaterial(res && res.moduleDetails && res.moduleDetails.readinginfo);
    setLoading(false);
  };
  const failureCallBack = () => {
    console.log("Failed");
  };

  const renderReadingMaterial = () => {
    setShowReadingMaterial(true);
  };

  return (
    <div className={classes.root}>
      <Header history={props} />
      <div className={mobileView && domainInfo.type === "b2b" ? classes.breadcrumbContainerSM : classes.breadcrumbContainer}>
        {SimpleBreadcrumbs()}
      </div>
      <div>{moduleTitle && <CommonHeader layout="module" data={headerInfo} />}</div>
      <Container className={classes.container} maxWidth={false}>
        <div className={classes.landscape}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={3} md={3}>
              <List>
                <div style={{ marginTop: "0.5rem" }}>
                  <div className={classes.cardHeader}>Lessons under this Module</div>
                  {lessonInfo && (
                    <CardLayout
                      isReaderAvailable={readerCardProps}
                      renderReadContent={() => {
                        renderReadingMaterial();
                      }}
                      data={lessonInfo}
                      layout="lesson"
                      history={props.history}
                    />
                  )}
                  {hasQuiz && (
                    <ListItem className={classes.listItem}>
                      <Paper
                        component="button"
                        elevation={3}
                        className={classes.card}
                        onClick={() => {
                          props.history.push(`/${tenantId}/assessment/${props.match.params.moduleId}/?type=module`);
                        }}
                      >
                        <Grid container>
                          <Grid item xs={2} sm={2} md={2} lg={2}>
                            <div style={style.iconFrame}>
                              <AssignmentIcon className={classes.icon} />
                            </div>
                          </Grid>
                          <Grid item xs={10} sm={10} md={10} lg={10}>
                            <div className={classes.assmntTitle}>Assessments</div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </ListItem>
                  )}
                  {hasAssignments && (
                    <ListItem className={classes.listItem}>
                      <Paper
                        component="button"
                        elevation={3}
                        className={classes.card}
                        onClick={() => {
                          props.history.push(`/${tenantId}/assignments/${props.match.params.moduleId}/?type=module`);
                        }}
                      >
                        <Grid container>
                          <Grid item xs={2} sm={2} md={2} lg={2}>
                            <div style={style.iconFrame}>
                              <AssignmentIcon className={classes.icon} />
                            </div>
                          </Grid>
                          <Grid item xs={10} sm={10} md={10} lg={10}>
                            <div className={classes.assmntTitle}> Assignments</div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </ListItem>
                  )}
                </div>
              </List>
            </Grid>
            <Grid item xs={12} sm={12} lg={9} md={9}>
              {loading === true ? (
                <ContentCardLayoutLoader />
              ) : (
                <Paper elevation={3} className={classes.descContainer}>
                  {loading === true ? (
                    <VideoPlayerLoader />
                  ) : videoContent &&
                    videoContent.videoURL !== undefined &&
                    videoContent.videoURL !== "" &&
                    videoContent.videoURL.includes("vimeo") ? (
                    <div className={classes.content}>
                      <VimeoPlayer data={videoContent} />
                    </div>
                  ) : (
                    ""
                  )}
                  {showReadingMaterial && readerCardProps.readerAvailable ? (
                    <ReadingMaterialBar data={readingMaterial} />
                  ) : (
                    <React.Fragment>
                      <div className={classes.moduleDescTitle}>About this Module</div>
                      <div className={classes.moduleDescContent} dangerouslySetInnerHTML={createMarkup(moduleDesc)} />
                    </React.Fragment>
                  )}
                </Paper>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: style.backgroundColor._screen,
  },
  listItem: {
    padding: "1px 0 1px 0",
  },
  descContainer: {
    backgroundColor: style.backgroundColor._white,
    marginTop: "2.7rem",
  },
  icon: {
    color: style.fontColour._tory_blue,
    fontSize: style.iconSize._medium,
    padding: "1.3rem 0.5rem 0 0",
  },
  iconFrame: {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
  },
  assmntTitle: {
    padding: "1.5rem 0.5rem 1.5rem 0",
    fontSize: style.fontSize._labels,
    color: style.fontColour._tory_blue,
    textAlign: "left",
  },
  card: {
    cursor: "pointer",
    width: "98%",
    border: "1px solid",
    borderColor: style.fontColour._tory_blue,
    borderRadius: "5px",
    backgroundColor: style.backgroundColor._white,
    marginBottom: "0.3rem",
  },
  cardHeader: {
    fontSize: style.fontSize._labels,
    color: style.fontColour._grey,
    fontWeight: 500,
    paddingBottom: "0.5rem",
    fontFamily: "Roboto,sans-serif",
  },
  landscape: {
    display: "flex",
  },
  breadcrumbContainer: {
    margin: "4.7rem 0 0 1rem",
    padding: "1rem",
  },
  breadcrumbContainerSM: {
    margin: "6rem 0 0.5rem 1rem",
  },
  content: {
    paddingTop: "1rem",
    textAlign: "center",
  },
  videoContainer: {
    paddingTop: "1rem",
  },
  moduleDescTitle: {
    padding: "0.5rem 0 0 1rem",
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    fontWeight: 500,
    fontFamily: "Roboto,sans-serif",
  },
  moduleDescContent: {
    padding: "0.5rem 0 1rem 1rem",
  },
}));
