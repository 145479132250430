import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { loadCSS } from "fg-loadcss";
import { find } from "lodash";
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "react-router-dom";
import Link from '@material-ui/core/Link';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import Collapse from "@material-ui/core/Collapse";
import SubjectIcon from "@material-ui/icons/Subject";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Icon from "@material-ui/core/Icon";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
// Local Imports
import Header from "../Header";
import { style } from "../../Styles/theme";
import VimeoPlayer from "../Shared/vimeoPlayer";
import CommonHeader from "../Shared/commonHeader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ReadingMaterialBar from "../Shared/readingMaterialBar";
import { getLesson, getCourseItemDetails } from "../../Store/Course/actionCreator";
import { getDomainInfo } from "../../Helpers/basic";

const drawerWidth = 360;

export default function Lesson(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1280px)");
  const mobileView = !matches;
  const [courseTitle, setCourseTitle] = useState();
  const [moduleTitle, setModuleTitle] = useState();
  const [lessonTitle, setLessonTitle] = useState();
  const [playerData, setPlayerData] = useState();
  const [siderOpen, setSiderOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [subHeaderList, setSubHeaderList] = useState();
  const [readingMaterial, setReadingMaterial] = useState();
  const [headerInfo, setHeaderInfo] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [navId, setNavId] = useState();
  const [navType, setNavType] = useState();
  const [domainInfo, setDomainInfo] = useState(getDomainInfo());
  const [modHasAssignments, setModHasAssignments] = useState(false);
  const [modHasQuiz, setModHasQuiz] = useState(false);

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
    fetchCourseTopics();
  }, []);

  const handleNavigationToQuiz = () => {
    let navigationUrl = `/${tenantId}/assessment/${navId}/?type=${navType}`;
    props.history.push(navigationUrl);
  };

  const handleNavigationToAssignment = () => {
    let navigationUrl = `/${tenantId}/assignments/${navId}/?type=${navType}`;
    props.history.push(navigationUrl);
  };

  const handleMoreClick = (event, hasAssngmt, hasQuiz, id, type) => {
    setNavId(id);
    setNavType(type);
    setAnchorEl(event.currentTarget);
    setModHasAssignments(hasAssngmt);
    setModHasQuiz(hasQuiz);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? "simple-popover" : undefined;

  const fetchCourseTopics = () => {
    let payload = {
      courseId: props.match.params.courseId,
      moduleId: props.match.params.moduleId,
      lessonId: props.match.params.lessonId,
    };
    getCourseItemDetails(payload, detailsSuccessCB, failureCallBack);
  };

  const handleLessonNav = (lessonId) => {
    let moduleIndex = null;
    subHeaderList.map(function (item) {
      let lessonDetails = find(item.lessons, { lesson_id: lessonId });
      if (lessonDetails !== undefined) {
        moduleIndex = item.module_id;
      }
    });
    moduleIndex !== null && props.history.push(`/${tenantId}/lesson/${props.match.params.courseId}/${moduleIndex}/${lessonId}`);
    window.location.reload(false);
  };

  const handleModuleNav = (moduleId) => {
    props.history.push(`/${tenantId}/module/${props.match.params.courseId}/${moduleId}`);
  };

  const detailsSuccessCB = (res) => {
    setCourseTitle(res.course_title);
    setSubHeaderList(res.modules);
    let moduleId = props.match.params.moduleId;
    let moduleInfo = res.modules.find(function (el) {
      return el.module_id == moduleId;
    });
    setModuleTitle(moduleInfo.module_title);
    let payload = {
      courseId: props.match.params.courseId,
      moduleId: props.match.params.moduleId,
      lessonId: props.match.params.lessonId,
    };
    getLesson(payload, successCallBack, failureCallBack);
  };

  const successCallBack = (res) => {
    setLessonTitle(res.lessonInfo.title);
    let headerProps = {
      isLoggedIn: true,
      isPurchased: res.isPurchased,
      lessonTitle: res.lessonInfo.title,
    };
    let videoPlayerData = {
      courseId: props.match.params.courseId,
      moduleId: props && props.match && props.match.params && props.match.params.moduleId && props.match.params.moduleId,
      videoURL: res.lessonInfo.video_url,
      videoTranscript: res.lessonInfo.transcript,
      lessonId: props.match.params.lessonId,
      type: "lesson",
    };
    let readingMaterialProps = {
      files: res.lessonInfo.reading,
      title: res.lessonInfo.rtitle,
      desc: res.lessonInfo.rdesc,
    };
    setHeaderInfo(headerProps);
    setPlayerData(videoPlayerData);
    setReadingMaterial(res && res.lessonInfo && res.lessonInfo.readinginfo);
  };

  const failureCallBack = () => {
    console.log("Failed");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (index) => {
    setSiderOpen(!siderOpen);
  };

  const lessonAssessment = (lessonId) => {
    props.history.push(`/${tenantId}/assessment/${lessonId}/?type=lesson`);
  };

  const lessonAssignment = (lessonId) => {
    props.history.push(`/${tenantId}/assignments/${lessonId}/?type=lesson`);
  };

  const displayStatusIcon = (status) => {
    switch (status) {
      case "yet-to-start":
        return (
          <div style={style.iconFrame}>
            <Icon
              className="fas fa-arrow-circle-right"
              style={{
                color: style.fontColour._blue,
                fontSize: "1rem",
                padding: "0 2px 0 0",
              }}
            />
          </div>
        );
      case "in-progress":
        return (
          <div style={style.iconFrame}>
            <PauseCircleFilledIcon
              style={{
                color: style.fontColour._blue,
                fontSize: "1rem",
                padding: "0 2px 0 0",
              }}
            />
          </div>
        );
      case "completed":
        return (
          <div style={style.iconFrame}>
            <CheckCircleIcon
              style={{
                color: style.fontColour._green,
                fontSize: "1rem",
                padding: "0 2px 0 0",
              }}
            />
          </div>
        );
      default:
        return (
          <div style={style.iconFrame}>
            <Icon
              className="fas fa-arrow-circle-right"
              style={{
                color: style.fontColour._blue,
                fontSize: "1rem",
                padding: "0 2px 0 0",
              }}
            />
          </div>
        );
    }
  };

  const renderSubListItems = (payload) => {
    let lessonsList = [];
    payload.length > 0 &&
      payload.map((lesson, index) => {
        lessonsList.push(
          <React.Fragment key={index}>
            <ListItem
              button
              className={props.match.params.lessonId == lesson.lesson_id ? classes.currentLesson : classes.nested}
              onClick={() => handleLessonNav(lesson.lesson_id)}
            >
              <ListItemText primary={lesson.lesson_title.length < 25 ? lesson.lesson_title : lesson.lesson_title.substring(0, 25) + ".."} />
              <ListItemSecondaryAction style={{ marginRight: "3rem" }}>
                <div style={{ display: "flex" }}>{displayStatusIcon(lesson.lesson_status)}</div>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem className={classes.listIconItem}>
              <div style={{ display: "flex" }}>
                {lesson.has_assignments && (
                  <IconButton
                    aria-label="lesson level assignments"
                    className={lesson.has_assignment ? classes.assgnmntCtr : classes.assignmntCtrDisabled}
                    onClick={() => {
                      lessonAssignment(lesson.lesson_id);
                    }}
                  >
                    <AssignmentIcon className={classes.workLink} /> <div className={classes.iconText}>Assignments</div>
                  </IconButton>
                )}
                {lesson.has_quiz && (
                  <IconButton
                    aria-label="lesson level quiz"
                    className={lesson.has_quiz ? classes.quizCtr : classes.quizCtrDisabled}
                    onClick={() => {
                      lessonAssessment(lesson.lesson_id);
                    }}
                  >
                    <AssignmentTurnedInIcon className={classes.workLink} /> <div className={classes.iconText}>Assessments</div>
                  </IconButton>
                )}
              </div>
            </ListItem>
          </React.Fragment>
        );
      });
    return lessonsList;
  };

  const renderListItems = () => {
    let modulesList = [];
    subHeaderList &&
      subHeaderList.map((moduleData, index) => {
        moduleData.lessons !== undefined && moduleData.lessons.length > 0
          ? modulesList.push(
            <React.Fragment key={index}>
              <ListItem button onClick={() => handleModuleNav(moduleData.module_id)} className={classes.moduleListItem}>
                <ListItemIcon style={{ minWidth: "35px" }}>
                  <SubjectIcon aria-label="Go to back to module" />
                </ListItemIcon>
                <div className={classes.moduleTitle}>
                  {moduleData.module_title.length < 23 ? moduleData.module_title : moduleData.module_title.substring(0, 23) + ".."}
                </div>
                <ListItemSecondaryAction>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginTop: "13px" }}>{displayStatusIcon(moduleData.module_status)}</div>
                    {moduleData.has_assignments || moduleData.has_quiz ?
                      <IconButton
                        style={{ borderRadius: "5px" }}
                        aria-label="assignments and quiz under module"
                        onClick={(event) => {
                          handleMoreClick(event, moduleData.has_assignments, moduleData.has_quiz, moduleData.module_id, "module");
                        }}
                      >
                        <MoreVertIcon className={classes.moreVertIcon} />
                      </IconButton> : <div style={{ paddingRight: '3rem' }} />}
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={siderOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding aria-label="lessons under module">
                  {renderSubListItems(moduleData.lessons)}
                </List>
              </Collapse>
            </React.Fragment>
          )
          : modulesList.push(
            <React.Fragment>
              <ListItem key={index} button onClick={() => handleModuleNav(moduleData.module_id)} className={classes.moduleListItem}>
                <ListItemIcon style={{ minWidth: "35px" }}>
                  <SubjectIcon />
                </ListItemIcon>
                <div className={classes.moduleTitle}>
                  {moduleData.module_title.length < 23 ? moduleData.module_title : moduleData.module_title.substring(0, 23) + "..."}
                </div>
                <ListItemSecondaryAction>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginTop: "13px" }}>{displayStatusIcon(moduleData.module_status)}</div>
                    {moduleData.has_assignments || moduleData.has_quiz ?
                      <IconButton
                        onClick={(event) => {
                          handleMoreClick(event, moduleData.has_assignments, moduleData.has_quiz, moduleData.module_id, "module");
                        }}
                      >
                        <MoreVertIcon className={classes.moreVertIcon} />
                      </IconButton> : <div style={{ paddingRight: '3rem' }} />}
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          );
      });
    return modulesList;
  };

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        {
          <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
            {getDomainInfo().name !== 'prep' ? (
              <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                Home
              </RouterLink>
            ) : (
              <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                Home
              </Link>
            )}
            <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${props.match.params.courseId}`}>
              {courseTitle}
            </RouterLink>
            <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/module/${props.match.params.courseId}/${props.match.params.moduleId}`}>
              {moduleTitle}
            </RouterLink>
            <div style={style.brdCrmbSecondary}>{lessonTitle}</div>
          </Breadcrumbs>
        }
      </Route>
    );
  };

  const renderLessonDetails = () => {
    return (
      <Container className={classes.container} maxWidth={false}>
        <Grid container className={classes.videoContainer}>
          <Grid item xs={12} sm={12} lg={10} md={12}>
            <div className={classes.videoContent}>{playerData && playerData.videoURL.includes("vimeo") ? <VimeoPlayer data={playerData} /> : ""}</div>
          </Grid>
        </Grid>
        <Grid container className={classes.descContainer}>
          <Grid item xs={12} sm={12} lg={10} md={12}>
            {readingMaterial && readingMaterial.length > 0 && <ReadingMaterialBar data={readingMaterial} />}
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <div>
      <Header history={props} />
      <div className={mobileView && domainInfo.type === "b2b" ? classes.breadcrumbContainerSM : classes.breadcrumbContainer}>
        {SimpleBreadcrumbs()}
      </div>
      <div className={classes.courseHeader}>
        {lessonTitle && <CommonHeader layout="lesson" data={headerInfo} courseTitle={courseTitle} moduleTitle={moduleTitle} />}
      </div>
      {renderLessonDetails()}
      <Fab className={classes.fab} onClick={handleDrawerOpen} color="primary" aria-label="Tree List View For Lessons">
        <ArrowRightIcon />
      </Fab>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        aria-label="Module and lessons under course"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Grid container className={classes.drawerHeaderIcon}>
          <Grid item xs={9} sm={9} lg={10} md={10}>
            <div className={classes.drawerHeader}>Modules</div>
          </Grid>
          <Grid item xs={3} sm={3} lg={2} md={2}>
            <IconButton aria-label="Close modules and lessons view" className={classes.iconButton} onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
        </Grid>
        <List aria-labelledby="modules and lessons under course" className={classes.root}>
          {subHeaderList && renderListItems()}
          <a className="skip-main" href="#lessonview" onClick={handleDrawerClose}>
            close view
          </a>
        </List>
      </Drawer>
      <Popover
        id={id}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handleMoreClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List aria-label="module level assignments and quiz">
          {modHasQuiz && <ListItem
            button
            className={classes.popListItem}
            onClick={() => {
              handleNavigationToQuiz();
            }}
          >
            Assessment
          </ListItem>}

          {modHasAssignments && <ListItem
            button
            className={classes.popListItem}
            onClick={() => {
              handleNavigationToAssignment();
            }}
          >
            Assignment
          </ListItem>}
        </List>
      </Popover>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  listIconItem: {
    padding: "3px 0 3px 0",
    marginLeft: "2rem",
  },

  moduleListItem: {
    //background: '#f5f5f5',
    borderRadius: "5px",
  },
  iconText: {
    fontSize: "12px",
    color: style.fontColour._grey,
  },
  assgnmntCtr: {
    display: "flex",
    marginLeft: "1.5rem",
    background: "red",
    //border:'1px solid #d4d1d3',
    borderRadius: "5px",
    padding: "0.3rem",
    cursor: "pointer",
  },
  assignmntCtrDisabled: {
    display: "flex",
    marginLeft: "1.5rem",
    background: "#ededed",
    borderRadius: "5px",
    padding: "0.3rem",
  },
  quizCtrDisabled: {
    display: "flex",
    marginLeft: "1rem",
    borderRadius: "5px",
    padding: "0.3rem",
    background: "#ededed",
  },
  quizCtr: {
    display: "flex",
    marginLeft: "1rem",
    //background: '#f5f5f5',
    //border:'1px solid #d4d1d3',
    borderRadius: "5px",
    padding: "0.3rem",
    cursor: "pointer",
  },
  workLink: {
    color: style.fontColour._tory_blue,
    fontSize: "1rem",
    margin: "0 0.5rem 0 0.1rem",
  },
  popListItem: {
    color: style.fontColour._tory_blue,
    cursor: "pointer",
    margin: "0.3rem",
    marginRight: "10px",
    borderRadius: "0.5rem",
    "&:hover": {
      backgroundColor: style.backgroundColor._screenLight,
    },
  },
  moreVertIcon: {
    color: style.fontColour._blue,
    fontSize: "1rem",
    padding: "0 0.5rem 0 0",
    cursor: "pointer",
  },
  videoContent: {
    textAlign: "center",
  },
  videoContainer: {
    paddingTop: "3rem",
    justifyContent: "center",
  },
  descContainer: {
    paddingTop: "2rem",
    justifyContent: "center",
  },
  breadcrumbContainer: {
    margin: "4.7rem 0 0 1rem",
    padding: "1rem",
  },
  breadcrumbContainerSM: {
    margin: "8rem 0 0.5rem 1rem",
  },
  fab: {
    margin: "0px",
    top: "15rem",
    right: "auto",
    bottom: "20px",
    position: "fixed",
    color: "#fff",
    backgroundColor: "#0a0b0b4a",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "20%",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "20%",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: 0,
    width: drawerWidth,
  },
  drawerHeader: {
    padding: "0 0 0 1rem",
    fontSize: style.fontSize._heading,
  },
  drawerHeaderIcon: {
    marginTop: "8rem",
    justifyContent: "flex-end",
  },
  moduleTitle: {
    color: style.fontColour._green,
    marginLeft: "2rem",
  },
  nested: {
    marginLeft: "2rem",
  },
  currentLesson: {
    marginLeft: "2rem",
    color: style.fontColour._green,
  },
  moduleTitle: {
    fontSize: style.fontSize._labels,
    fontWeight: 600,
    color: style.fontColour._blue,
  },
  iconButton: {
    borderTopLeftRadius: "20%",
    borderTopRightRadius: "20%",
    borderBottomLeftRadius: "20%",
    borderBottomRightRadius: "20%",
    color: "#999",
    backgroundColor: "#f3f3f3",
  },
  assessment: {
    fontSize: "1.2rem",
    fontWeight: 500,
    color: "#ec8e2e",
  },
  assessmentIcon: {
    fontSize: "1.5rem",
    color: "#ec8e2e",
  },
}));
