import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
//local imports
import { showSnackBar } from '../../Store/AlertMessages/actionCreator';
import { style } from '../../Styles/theme';
import { fetchOrgGrpsList } from '../../Store/Discussions/actionCreator';
import { assignDiscussionToGrps } from '../../Store/Discussions/actionCreator';
import { discussion_msgs } from '../Common/messages';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  membersCntr: {
    maxHeight: "200px",
    overflowY: 'scroll'
  },
  teamWrapper: {
    margin: "0.5rem",
  },
  teamName: {
    fontSize: "1rem",
    color: "#15528d",
  },
  selMemChip: {
    color: style.fontColour._blue,
    marginTop: "0.3rem",
    border: "1px solid #2e8eec",
    marginLeft: '2px'
  },
  titleWrapper: {
    fontSize: "1.2rem",
    color: style.fontColour._blue,
    marginLeft: '10px'
  },
  memCtr: {
    margin: "1rem",
  },
  descWrapper: {
    margin: '0.5rem',
    fontSize: '14px',
    color: '#999',
    paddingBottom: '10px',
    fontWeight: 500
  },
  autoCompWrapper: {
    marginTop: '1rem'
  },
  buttonWrapper: {
    marginTop: '1rem',
    textAlign: 'center'
  },
  button: {
    marginLeft: '0.5rem',
    color: '#fff',
    backgroundColor: style.fontColour._blue,
    '&:hover': {
      color: '#fff',
      backgroundColor: style.fontColour._blue,
    }
  },
  grpListWrapper: {
    color: style.fontColour._blue,
  },
  buttonProgress: {
    marginBottom: '-13px',
    color: style.fontColour._blue,
    marginRight: '12px',
  },
  btnWrapper: {
    textTransform: 'none',
    marginRight: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PublishDiscussion(props){
  const classes = useStyles()
  const maxWidth700 = useMediaQuery('(max-width:700px)');
  const open = props.openAssignGrpsPopup
  const discussionDetails = props && props.data ? props.data : {} 
  const [inProgress, setInprogress] = useState(false)
  const [nullCheck, setNullCheck] = useState(false)
  //entire grps listing setstate
  const [orgGrpsList, setOrgGrpsList] = useState([])
  //entire grp members listing setstate
  const [orgGrpMemsList, setOrgGrpMemsList] = useState([])
  //selected grp from grp selection dropdown set state
  const [selectedGrp, setSelectedGrp] = useState([])

  let defaultSelMembers = []
  if(props && props.data && props.data.published_to && Object.keys(props.data.published_to) && Object.keys(props.data.published_to).length){
    defaultSelMembers = props.data.published_to.group_details && props.data.published_to.group_details
  }
  //selected grp members from grp selection dropdown set state
  const [selectedGrpMemsList, setSelectedGrpMemsList] = useState(defaultSelMembers)
  //selected individual grp members from members selection
  const [selectIndividualMems, setSelectedIndividualMems] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([])

  //for grps listing callbakcs
  const successOrgGrpList = res => {
    setOrgGrpsList(res && res.data)
    if(res && res.data){
      let grpData = res.data
      let grpMemebersData = grpData.flatMap((item)=> item.group_members);
      if(grpMemebersData && grpMemebersData.length){
        setOrgGrpMemsList(grpMemebersData)
      }else{
        setOrgGrpMemsList([])
      }
    }
  }
  const failureOrgGrpList = error => {
    setOrgGrpsList([])
    setOrgGrpMemsList([])
  }

  useEffect(()=>{
    fetchOrgGrpsList({}, successOrgGrpList, failureOrgGrpList)
  },[])

  const handleClose = () => {
    setSelectedGrp([])
    setSelectedGrpMemsList([])
    setSelectedIndividualMems([])
    setSelectedMembers([])
    props.closePopup()
  }

  //on grps drodown selection
  const handleSelectGrp = (grpName) => {
    setNullCheck(false)
    setSelectedGrp(grpName)
    let grpListVal = orgGrpsList
    let existingGrpMembers = [...defaultSelMembers]
    let arr = [...defaultSelMembers]
    if(grpListVal && grpListVal.length > 0){
      if(grpName && grpName.length){
        grpName.forEach((item, index)=>{
          let groupObj = grpListVal.find((grp)=> grp.id === item.id)
          let selObj = existingGrpMembers.find((selTeam) => selTeam.id === item.id)
          if((defaultSelMembers && !defaultSelMembers.length) && selObj){
            let grpMembers = selObj.group_members
            let Obj = { group_title: item.group_title, group_members: grpMembers }
            arr.push(Obj)
          }else if(groupObj){
            let grpMembers = groupObj.group_members
            let Obj = {}
            if(arr && arr.length){
              const newData = arr.find((data => data.id === item.id))
              if(newData && Object.keys(newData) && Object.keys(newData).length){
                newData['group_members'] = item.group_members 
              }else{
                arr.push({'group_title': item.group_title, 'group_members': grpMembers})
              }
            }else{
              Obj = { group_title: item.group_title, group_members: grpMembers }
              arr.push(Obj)
            }
          }
        })
        setSelectedGrpMemsList(arr)
      }else{
        setSelectedGrpMemsList([])
      }
    }
  }

  //for single dropdown selection
  const handleAddMembers = (members) => {
    setNullCheck(false)
    setSelectedIndividualMems(members)
    setSelectedMembers(members);
  }

  //on grp member delete from chip
  const handleGrpMemDelete = grpToDelete => {
    let selectedMemList = [...selectedGrpMemsList];
    if (selectedMemList.length > 0) {
      selectedMemList.forEach((item, index) => {
        let removedMemList = [];
        let teamObj = item.group_members.find((member) => member.email === grpToDelete.email);
        if (teamObj) {
          removedMemList = item.group_members.filter((member) => member.email !== grpToDelete.email);
          item["group_members"] = removedMemList;
        }
      });
      setSelectedGrpMemsList(selectedMemList);
    }
  }
  //on indivi delete
  const handleIndividualMemDelete = memberToDelete => {
    let existingMembers = selectedMembers
    let selectedChipMembers = selectIndividualMems
    let newData = existingMembers.filter((member)=> member.id !== memberToDelete.id)
    let chipUsers = selectedChipMembers.filter((member)=> member.id !== memberToDelete.id)
    setSelectedIndividualMems(newData)
    setSelectedMembers(chipUsers)
  }

  const renderGrpsListChip = (teamsList) => {
    if (teamsList && teamsList.length > 0) {
      let arr = [];
      teamsList.forEach((item, index) => {
        let label = item.name + " - " + item.email;
        let labelName = label && label.length > 40 ? label.substring(0, 35) + "..." : label;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              variant="outlined"
              label={`${labelName} - ${item.role}`}
              deleteIcon={<HighlightOffIcon style={{ color: style.fontColour._blue }} />}
              onDelete={() => {
                handleGrpMemDelete(item);
              }}
              className={classes.selMemChip}
            />
            <br />
          </React.Fragment>
        );
      });
      return arr;
    }
  }

  const getDescription = (desc) => {
    const textDesc = desc && desc.length && desc.replace(/(?:&nbsp;|<[^>]*>?)/gm, '')
    if (textDesc && textDesc.length) {
      if (textDesc.length > 400) {
        return (
          textDesc.substring(0, 350) + '...'
        )
      } else {
        return (
          textDesc
        )
      }
    }
  }

  const successAssignCB = res => {
    setInprogress(false)
    setNullCheck(false)
    props.showSnackBar({
      state: true,
      message: discussion_msgs.SUCCESS_DISCUSSION_ASSIGN,
      type: 'success'
    })
    props.fetchAllListData()
    handleClose()
  }
  const failureAssignCB = err =>{
    setInprogress(false)
    setNullCheck(false)
    props.showSnackBar({
      state: false,
      message: discussion_msgs.ERROR_DISCUSSION_ASSIGN,
      type: 'error'
    })
  }

  const onPublishClick = () => {
    let membersArr = []
    if(selectedMembers && selectedMembers.length){
      selectedMembers.forEach((emp, index) => {
        membersArr.push(emp.id);
      });
    }
    if(selectedGrpMemsList && selectedGrpMemsList.length){
      selectedGrpMemsList.forEach((item, index) => {
        let mem = item.group_members;
        if (mem.length > 0) {
          mem.forEach((data, index) => {
            membersArr.push(data.id);
          });
        }
      });
    }
    let payloadArr = membersArr.filter((value, index) => membersArr.indexOf(value) === index)
    if(payloadArr && payloadArr.length){
      setInprogress(true)
      setNullCheck(false)
      const payload = {
        id: discussionDetails && discussionDetails.id
      }
      let formData = new FormData()
      formData.append('published_to', JSON.stringify(payloadArr))
      assignDiscussionToGrps(payload, formData, successAssignCB, failureAssignCB)
    }else{
      setNullCheck(true)
    }
  }

  return(
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-label="assign to groups"
    >
       <DialogTitle id="alert-dialog-title"> 
       { discussionDetails.mode === 'assign' ? 'Assign To Members Process' : 'Update Members Process' }
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Paper elevation={3} className={classes.courseInfoCntr}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={7} lg={7}>
                  <Typography className={classes.titleWrapper} gutterBottom variant="h5" component="h2">
                    {discussionDetails && discussionDetails.title ? discussionDetails.title : ''}
                  </Typography>
                </Grid>
              </Grid>
              { !maxWidth700 ? (
                <Typography className={classes.descWrapper} variant="body2" color="textSecondary" component="p">
                  {discussionDetails && discussionDetails.description && discussionDetails.description.length ? (
                    getDescription(discussionDetails.description)
                  ) : null}
                </Typography>
              ) : null}
            </Paper>
            <div className={classes.autoCompWrapper}>
              <Autocomplete
                value={selectedGrp}
                onChange={(event, grpName) => {
                  handleSelectGrp(grpName);
                }}
                multiple
                id="checkboxes-tags-demo"
                options={orgGrpsList && orgGrpsList.length > 0 ? orgGrpsList : []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.group_title}
                className={classes.grpListWrapper}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, color: style.fontColour._blue }}
                      checked={selected}
                    />
                      <React.Fragment>
                        <div>
                          {option.group_title}
                          <p style={{ fontSize: '11px', color: '#999', fontWeight: 500, marginTop: '-2px' }}>{` (Members count - ${option.members_count}) `}</p>
                        </div>
                      </React.Fragment>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ color: "red", fontSize: "1rem" }}
                    variant="outlined"
                    label="Search for groups..."
                  />
                )}
              />
              <br />
              <Autocomplete
                value={selectIndividualMems}
                onChange={(event, newValue) => {
                  handleAddMembers(newValue);
                }}
                multiple
                id="checkboxes-tags-members-demo"
                options={orgGrpMemsList && orgGrpMemsList.length > 0 ? orgGrpMemsList : []}
                getOptionLabel={(option) => option.email}
                className={classes.grpListWrapper}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ color: "red", fontSize: "1rem" }}
                    variant="outlined"
                    label="Search for group members..."
                  />
                )}
              />
            </div>
            {nullCheck && <div style={{ textAlign: 'center', fontSize: '0.8rem', color: 'red' }}>{`*Please select atleast one member to assign`}</div>}
            <div className={classes.buttonWrapper}>
              { inProgress ? (
                <CircularProgress size={30} className={classes.buttonProgress} />
              ): null }
              <Button onClick={onPublishClick} disabled={inProgress} className={classes.btnWrapper} variant="contained" autoFocus>
                { discussionDetails.mode === 'assign' ? 'Assign to members' : 'Update Members' }
              </Button>
              <Button onClick={handleClose} disabled={inProgress} style={{ textTransform: 'none' }} variant="outlined">
                Cancel
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              { selectedGrpMemsList && selectedGrpMemsList.length ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper elevation={3} className={classes.membersCntr}>
                    <div className={classes.memCtr}>
                      {selectedGrpMemsList.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item && item.group_members && item.group_members.length > 0 && (
                              <div className={classes.teamWrapper}>
                                <div className={classes.teamName}>
                                  {item.group_title} - Group
                              </div>
                                <div>{renderGrpsListChip(item.group_members)}</div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </Paper>
                </Grid>
              ) : null }
              {selectedMembers && selectedMembers.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper elevation={3} className={classes.membersCntr}>
                    {selectedMembers.length > 0 && (
                      <div className={classes.memCtr}>
                        <div className={classes.teamName}>Selected Members </div>
                        {selectedMembers.map((item, index) => {
                          let label = item.name + " - " + item.email;
                          let labelName = label.length > 40 ? label.substring(0, 35) + "..." : label;
                          return (
                            <React.Fragment key={index}>
                              <Chip
                                key={index}
                                variant="outlined"
                                deleteIcon={<HighlightOffIcon style={{ color: style.fontColour._blue }} />}
                                label={`${labelName} - ${item.role}`}
                                onDelete={()=>{handleIndividualMemDelete(item)}}
                                className={classes.selMemChip}
                              />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { showSnackBar })(PublishDiscussion)