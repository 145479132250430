import React, { useState, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import { postVideoTrackInfo, getVideoTrackInfo, postLessonTrackInfo } from '../../Store/Course/actionCreator';

export default function VimeoPlayer(props) {
    const [startTime, setStartTime] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [lessonStatus, setLessonStatus] = useState();
    const [renderType, setRenderType] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        console.log(props.data, 'vimeo')
        setRenderType(props.data.type);
        setIsError(false);
        if (props.data && props.data.type) {
            fetchVideoTrackingDetails(props.data.type);
        }
        else {
            setStartTime(0.01)
        }
    }, [])

    const fetchVideoTrackingDetails = (type) => {
        let vId = '';
        if (props.data.videoURL !== undefined || props.data.videoURL !== '') {
            vId = props.data.videoURL.substring(props.data.videoURL.lastIndexOf("/") + 1, props.data.videoURL.length);
        }

        let payload = {}
        if (type === 'lesson') {
            payload = {
                video_id: vId,
                lesson_id: props.data.lessonId,
                module_id: props && props.data && props.data.moduleId ? props.data.moduleId : null
            }
        } else if (type === 'module') {
            payload = {
                video_id: vId,
                module_id: props.data.moduleId
            }
        } else if (type === 'course') {
            payload = {
                video_id: vId,
                course_id: props.data.courseId
            }
        }
        console.log(payload)
        getVideoTrackInfo(payload, fetchSuccessCB, fetchFailureCB);
    }

    const fetchSuccessCB = (res) => {
        if (res.isCompleted === 'in-progress') {
            setStartTime(parseInt(res.current_time));
        }
        else if (res.isCompleted === 'completed') {
            setStartTime(0.01);
        }
        setLessonStatus(res.current_status);
    }

    const fetchFailureCB = () => {
        setStartTime(0.01);
    }

    const getPlayTime = (duration) => {
        if (renderType) {
            let vId = '';
            if (props.data.videoURL !== undefined || props.data.videoURL !== '') {
                vId = props.data.videoURL.substring(props.data.videoURL.lastIndexOf("/") + 1, props.data.videoURL.length);
            }
            if (duration.seconds > currentTime + 5) {
                let payload = {
                    video_id: vId,
                    isCompleted: false,
                    current: parseInt(duration.seconds),
                }
                postVideoTrackInfo(payload, successCallBack, failureCallBack);
                setCurrentTime(duration.seconds);
            }
        }
    }

    const onVideoStart = () => {
        if (renderType === 'lesson') {
            let trackPayload = {
                lesson_id: props.data.lessonId,
                course_id: props.data.courseId,
                status: 'in-progress'
            }
            if (lessonStatus !== 'completed') {
                postLessonTrackInfo(trackPayload, successCallBack, failureCallBack);
            }
        } else if (renderType === 'module') {
            let trackPayload = {
                course_id: props.data.courseId,
                module_id: props.data.moduleId,
                status: 'in-progress'
            }
            if (lessonStatus !== 'completed') {
                postLessonTrackInfo(trackPayload, successCallBack, failureCallBack);
            }
        } else if (renderType === 'course') {
            let trackPayload = {
                course_id: props.data.courseId,
                status: 'in-progress'
            }
            if (lessonStatus !== 'completed') {
                postLessonTrackInfo(trackPayload, successCallBack, failureCallBack);
            }
        }
    }

    const onVideoEnd = () => {
        if (renderType) {
            let vId = '';
            if (props.data.videoURL !== undefined || props.data.videoURL !== '') {
                vId = props.data.videoURL.substring(props.data.videoURL.lastIndexOf("/") + 1, props.data.videoURL.length);
            }
            let payload = {
                video_id: vId,
                isCompleted: true,
                current: 0,
            }
            postVideoTrackInfo(payload, successCallBack, failureCallBack);
            let trackPayload = {}
            if (renderType === 'lesson') {
                trackPayload = {
                    lesson_id: props.data.lessonId,
                    course_id: props.data.courseId,
                    status: 'completed'
                }
            } else if (renderType === 'module') {
                trackPayload = {
                    module_id: props.data.moduleId,
                    course_id: props.data.courseId,
                    status: 'completed'
                }
            } else if (renderType === 'course') {
                trackPayload = {
                    course_id: props.data.courseId,
                    status: 'completed'
                }
            }

            if (lessonStatus !== 'completed') {
                postLessonTrackInfo(trackPayload, successCallBack, failureCallBack);
            }
        }
    }

    const successCallBack = (res) => {
        console.log("Video Tracking: Successful")
    }
    const failureCallBack = () => {
        console.log("Video Tracking: Failed")
    }
    return (
        <React.Fragment>
            {!isError ? <React.Fragment>
                {startTime > 0 && <Vimeo
                    video={props.data.videoURL}
                    autoplay={false}
                    controls={true}
                    responsive={true}
                    onError={() => { setIsError(true) }}
                    onPlay={() => { onVideoStart() }}
                    onEnd={() => { onVideoEnd() }}
                    style={{ justifyContent: 'center', padding: '1rem', backgroundColor: '#e9f6f6' }}
                    start={startTime}
                    onTimeUpdate={(duration) => { getPlayTime(duration) }}
                />}
            </React.Fragment> : <div style={{ backgroundColor: '#e9f6f6', textAlign: 'center', padding: '1.5rem' }}><VideocamOffOutlinedIcon style={{ color: '#2e8eec', fontSize: "40px" }} /> <div style={{ color: '#2e8eec', fontSize: '1rem' }} >Oops! Error fetching video.</div><div style={{ color: '#2e8eec', marginTop: '0.3rem', fontSize: '0.9rem' }} >Please try again later.</div></div>}
        </React.Fragment >
    );
}

