import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import { getLoggedInStatus, getDomainInfo } from './Helpers/basic';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        getLoggedInStatus() === true
            ? <Component {...props} />
            : getDomainInfo().name === 'prep' ? window.open("https://console.prep.continualengine.com/login", "_self") : < Redirect to='/' />
    )} />
)