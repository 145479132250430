import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import Link from '@material-ui/core/Link'
import AssignmentIcon from "@material-ui/icons/Assignment";
import DialogContentText from "@material-ui/core/DialogContentText";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ListItem, List } from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
//Local Imports
import Header from "../Header";
import { style } from "../../Styles/theme";
import VimeoPlayer from "../Shared/vimeoPlayer";
import CardLayout from "../Shared/cardLayout";
import CourseHeader from "../Shared/courseHeader";
import CourseDesc from "./courseDesc";
import { courseCategoryList } from "../../MockData/mockData";
import { getCourseDetails } from "../../Store/Course/actionCreator";
import { enrollCourse } from "../../Store/Enrollment/actionCreator";
import CourseDescLoader from "../Shared/SkeletonLoaders/courseDescLoader";
import SiderCardLayoutLoader from "../Shared/SkeletonLoaders/siderCardLayoutLoader";
import { getLoggedInStatus, redirectTo, getDomainInfo } from "../../Helpers/basic";

export default function CourseInfo(props) {
  const matches = useMediaQuery("(min-width:1280px)");
  const showMenu = !matches;
  const classes = useStyles();
  const [state, setState] = useState({ top: false });
  const [loading, setLoading] = useState(true);
  const [courseDesc, setCourseDesc] = useState();
  const [moduleInfo, setModuleInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [courseHeaderInfo, setCourseHeaderInfo] = useState();
  const [videoPlayerProps, setVideoPlayerProps] = useState();
  const [appAuthProps, setAppAuthProps] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [authors, setAuthors] = useState();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [role, setRole] = useState("student");
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [renderModules, setRenderModules] = useState(false);
  const [domainInfo, setDomainInfo] = useState(getDomainInfo());
  const [hasDiscussions, setHasDiscussions] = useState(false);
  const [hasAssignments, setHasAssignments] = useState(false);
  const [hasQuiz, setHasQuiz] = useState(false);

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCourseInfo();
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("status");
    if (paramVal !== undefined && (paramVal === "unauthorized" || paramVal === "notsubscribed")) {
      setModalOpen(true);
      if (paramVal === "unauthorized") {
        setModalContent("Please Login to Continue...");
      } else {
        setModalContent("Please Subscribe this course to Continue...");
      }
    } else {
      setModalOpen(false);
    }

    let logIn = getLoggedInStatus();
    setIsLoggedIn(logIn);
  }, []);

  const handleClose = () => {
    setModalOpen(false);
  };

  const renderEnrollButton = () => {
    const domainDet = getDomainInfo();
    if (domainDet.type == "b2c" && role === "student" && isEnrolled === false) {
      return (
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={() => {
              enrollForCourse();
            }}
            className={classes.button}
            color="primary"
          >
            Enroll Now
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  const enrollForCourse = () => {
    if (isLoggedIn === true) {
      let payload = {
        course_id: parseInt(props.match.params.courseId),
      };
      enrollCourse(payload, successCB, failureCB);
    } else {
      const domainDet = getDomainInfo();
      if (domainDet.type == "b2c") {
        let url = `${domainDet.hostName}/?doSignIn=true&navigateTo=rendering&courseId=${props.match.params.courseId}&tId=${props.match.params.tenantId}`;
        redirectTo(url, true);
      }
    }
  };

  const successCB = (res) => {
    window.location.reload();
  };

  const failureCB = (error) => { };

  const loadCourseInfo = () => {
    setLoading(true);
    let payload = {
      courseId: props.match.params.courseId,
    };
    getCourseDetails(payload, successCallBack, failureCallBack);
  };

  const successCallBack = (res) => {
    setCourseDesc(res.courseDetails.desc);
    setAuthors(res.authors);
    setModuleInfo(res.courseDetails.modules);
    setIsEnrolled(res.enrol);
    setRole(res.role);
    const domainDet = getDomainInfo();
    let loginStatus = getLoggedInStatus();
    if (res.role === "student" && res.enrol === true && loginStatus === true) {
      setRenderModules(true);
    } else if ((res.role === "author" && loginStatus === true) || (domainDet.type === "b2b" && loginStatus === true)) {
      setRenderModules(true);
    }

    let category = courseCategoryList.find(function (el) {
      return el.id === res.courseDetails.category;
    });
    let level = "";
    if (res.courseDetails.level === 1) {
      level = "Beginner";
    } else if (res.courseDetails.level === 2) {
      level = "Intermediate";
    } else if (res.courseDetails.level === 3) {
      level = "Expert";
    }
    let courseHeaderDet = {
      courseId: res.courseDetails.id,
      courseTitle: res.courseDetails.title,
      course_status: res.courseDetails.course_status,
      provider: res.provider !== undefined || res.provider !== null ? res.provider : "",
      isLoggedIn: loginStatus,
      isEnrolled: res.enrol,
      role: res.role,
      level: level,
      category: category.name,
      tags: res.courseDetails && res.courseDetails.tags && res.courseDetails.tags.length && res.courseDetails.tags,
      duration: res.courseDetails.total_duration_course + " Hours",
      modules: res.courseDetails.modules !== undefined ? res.courseDetails.modules.length : res.courseDetails.total_module_count,
      lessons: res.courseDetails.total_lesson_count,
      readings: res.courseDetails.total_reading_count,
    };
    let videoPlayerData = {
      courseId: res.courseDetails.id,
      videoURL: res.courseDetails.video_url,
      transcript_url: res.courseDetails.transcript,
      type: "course",
    };

    setIsLoggedIn(loginStatus);
    let headerProps = {
      isLoggedIn: loginStatus,
      isEnrolled: res.enrol,
      role: res.role,
    };

    setHasDiscussions(res.courseDetails.has_discussions);
    setHasAssignments(res.courseDetails.has_assignments);
    setHasQuiz(res.courseDetails.has_quiz);

    setAppAuthProps(headerProps);
    setVideoPlayerProps(videoPlayerData);
    setCourseHeaderInfo(courseHeaderDet);
    setLoading(false);
  };
  const failureCallBack = () => {
    setLoading(false);
  };

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        {
          <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} />} aria-label="Breadcrumb">
            {getDomainInfo().name !== 'prep' ? (
              <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                Home
              </RouterLink>
            ) : (
              <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                Home
              </Link>
            )}
            <div style={style.brdCrmbSecondary}>{courseHeaderInfo && courseHeaderInfo.courseTitle}</div>
          </Breadcrumbs>
        }
      </Route>
    );
  };

  const toggleDrawer = (side, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const sideList = (side) => {
    return (
      <div className={classes.list} role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
        <div className={classes.siderItemContainer}>
          {isLoggedIn && (
            <List>
              <Grid item sm={12} xs={12} md={12} lg={12}>
                <div style={style.containerPaddingTop}>
                  {moduleInfo && moduleInfo.length > 0 && appAuthProps && (
                    <CardLayout auth={appAuthProps} data={moduleInfo} layout="module" history={props.history} />
                  )}
                </div>
                {hasQuiz && (
                  <ListItem className={classes.listContr}>
                    <Paper
                      component="button"
                      elevation={2}
                      className={classes.card}
                      onClick={() => {
                        props.history.push(`/${tenantId}/assessment/${props.match.params.courseId}/?type=course`);
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          <div className={classes.iconFrame}>
                            <AssignmentTurnedInIcon className={classes.icon} />
                          </div>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <div className={classes.assmntTitle}>Assessments</div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </ListItem>
                )}
                {hasAssignments && (
                  <ListItem className={classes.listContr}>
                    <Paper
                      component="button"
                      elevation={2}
                      className={classes.card}
                      onClick={() => {
                        props.history.push(`/${tenantId}/assignments/${props.match.params.courseId}/?type=course`);
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          <div className={classes.iconFrame}>
                            <AssignmentIcon className={classes.icon} />
                          </div>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <div className={classes.assmntTitle}>Assignments</div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </ListItem>
                )}

                {domainInfo && domainInfo.type === "b2b" && hasDiscussions && (
                  <ListItem className={classes.listItem}>
                    <Paper
                      component="button"
                      elevation={2}
                      className={classes.card}
                      onClick={() => {
                        props.history.push(`/${tenantId}/discussions/${props.match.params.courseId}`);
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                          <div className={classes.iconFrame}>
                            <QuestionAnswerIcon className={classes.icon} />
                          </div>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <div className={classes.assmntTitle}>Discussions</div>
                        </Grid>
                      </Grid>
                    </Paper>
                  </ListItem>
                )}
              </Grid>
            </List>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Container className={classes.gridItemContainer} maxWidth={false}>
        <Grid container>
          <Header history={props} />
        </Grid>
        <div className={showMenu && domainInfo.type === "b2b" ? classes.breadcrumbContainerSM : classes.breadcrumbContainer}>
          {SimpleBreadcrumbs()}
        </div>
        <div className={classes.courseHeader}>
          {loading === false && <CourseHeader showDrawer={toggleDrawer("left", true)} layout="course" data={courseHeaderInfo} history={props} />}
        </div>
        <div className={classes.contentContainer}>
          <Grid container spacing={2} className={classes.gridRespContainer}>
            {isLoggedIn && showMenu ? (
              <Drawer style={{ zIndex: 1 }} open={state.left} onClose={toggleDrawer("left", false)}>
                {sideList("left")}
              </Drawer>
            ) : (
              renderModules && (
                <Grid item xs={12} sm={12} md={9} lg={3}>
                  <Grid container className={classes.gridRespContainer} spacing={2}>
                    <Grid item sm={9} lg={12} md={9}>
                      {loading === true ? (
                        <SiderCardLayoutLoader />
                      ) : (
                        <List>
                          <div className={classes.cardContainer}>
                            {moduleInfo && moduleInfo.length > 0 && appAuthProps && (
                              <React.Fragment>
                                <div className={classes.cardHeader}>Modules Under this Course</div>
                                <CardLayout auth={appAuthProps} data={moduleInfo} layout="module" history={props.history} />
                              </React.Fragment>
                            )}
                            {hasQuiz && (
                              <ListItem className={classes.listItem}>
                                <Paper
                                  component="button"
                                  elevation={2}
                                  className={classes.card}
                                  onClick={() => {
                                    props.history.push(`/${tenantId}/assessment/${props.match.params.courseId}/?type=course`);
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                      <div className={classes.iconFrame}>
                                        <AssignmentTurnedInIcon className={classes.icon} />
                                      </div>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                      <div className={classes.assmntTitle}>Assessments</div>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </ListItem>
                            )}
                            {hasAssignments && (
                              <ListItem className={classes.listItem}>
                                <Paper
                                  component="button"
                                  elevation={2}
                                  className={classes.card}
                                  onClick={() => {
                                    props.history.push(`/${tenantId}/assignments/${props.match.params.courseId}/?type=course`);
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                      <div className={classes.iconFrame}>
                                        <AssignmentIcon className={classes.icon} />
                                      </div>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                      <div className={classes.assmntTitle}>Assignments</div>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </ListItem>
                            )}
                            {domainInfo && domainInfo.type === "b2b" && hasDiscussions && (
                              <ListItem className={classes.listItem}>
                                <Paper
                                  component="button"
                                  elevation={2}
                                  className={classes.card}
                                  onClick={() => {
                                    props.history.push(`/${tenantId}/discussions/${props.match.params.courseId}`);
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                      <div className={classes.iconFrame}>
                                        <QuestionAnswerIcon className={classes.icon} />
                                      </div>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                      <div className={classes.assmntTitle}>Discussions</div>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              </ListItem>
                            )}
                            <Divider orientation="vertical" flexItem />
                          </div>
                        </List>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}

            <Grid item xs={12} sm={12} md={9} lg={isLoggedIn ? 9 : 10}>
              {loading === true ? (
                <CourseDescLoader />
              ) : (
                <div className={classes.contContainer}>
                  <Paper elevation={2} className={classes.desc}>
                    {videoPlayerProps &&
                      videoPlayerProps.videoURL !== undefined &&
                      videoPlayerProps.videoURL !== "" &&
                      videoPlayerProps.videoURL.includes("vimeo") ? (
                      <div className={classes.videoPlayer}>
                        <VimeoPlayer data={videoPlayerProps} />
                      </div>
                    ) : null}
                    {courseDesc && authors && <CourseDesc authors={authors} data={courseDesc} />}
                    {courseDesc !== undefined ? (
                      renderEnrollButton()
                    ) : (
                      <div style={{ textAlign: "center", padding: "2rem" }}>
                        <div>Sorry, we couldn't find any matches.</div>
                        <div> Please try again. </div>
                      </div>
                    )}
                  </Paper>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
      <Dialog open={modalOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Alert..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{modalContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleClose} variant="contained" color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: style.backgroundColor._screen,
  },
  listItem: {
    padding: "1px 0 1px 0",
  },
  listContr: {
    padding: "0.1rem",
  },
  icon: {
    color: style.fontColour._tory_blue,
    fontSize: style.iconSize._medium,
    padding: "1.5rem 0.5rem 0 0",
  },
  contContainer: {
    backgroundColor: style.backgroundColor._screen,
    padding: "1rem 0.5rem 0 0",
    justifyContent: "center",
    marginTop: "1.7rem",
  },
  iconFrame: {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
  },
  assmntTitle: {
    padding: "1.7rem 0 1.5rem 0",
    fontSize: style.fontSize._labels,
    color: style.fontColour._tory_blue,
    textAlign: "left",
  },
  card: {
    cursor: "pointer",
    width: "97%",
    border: "none",
    backgroundColor: style.backgroundColor._white,
    "&:hover": {
      borderLeft: "2px solid",
      borderRadius: "2px",
      borderColor: style.fontColour._tory_blue,
    },
    marginBottom: "0.3rem",
  },
  cardHeader: {
    fontSize: style.fontSize._labels,
    color: style.fontColour._grey,
    fontWeight: 500,
    paddingBottom: "0.5rem",
    fontFamily: "Roboto,sans-serif",
  },
  desc: {
    marginTop: "0.5rem",
  },
  cardContainer: {
    paddingTop: "1rem",
    overflow: "hidden",
    height: "1080px",
    backgroundColor: style.backgroundColor._screen,
    "&:hover": {
      overflow: "scroll",
    },
  },
  contentContainer: {
    padding: "0 1rem 0 1rem",
  },
  courseHeader: {
    marginTop: "1rem",
  },
  videoPlayer: {
    padding: "2.5rem 0 2.5 0",
    textAlign: "center",
  },
  breadcrumbContainer: {
    margin: "1.7rem 0 0 1rem",
  },
  breadcrumbContainerSM: {
    margin: "2rem 0 0 1rem",
  },
  gridItemContainer: {
    padding: "4rem 0 0 0",
  },
  siderItemContainer: {
    margin: "4rem 0.2rem 0.2rem 0.5rem",
    width: "96%",
  },
  gridRespContainer: {
    justifyContent: "center",
  },
  paragraph: {
    textDecoration: "underline",
  },
  buttonContainer: {
    padding: "2rem 0 1rem 0",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginRight: "1rem",
    backgroundColor: style.button._backgroundColor,
  },
  titleContainer: {
    alignSelf: "center",
    paddingTop: "2rem",
  },
  title: {
    paddingLeft: "1rem",
    color: "#FFF",
    fontSize: "3rem",
    fontWeight: 600,
  },
  topContainer: {
    backgroundColor: "#f1f1f1",
    padding: "30px",
    "text-align": "center",
  },
  header: {
    padding: "10px 16px",
    background: "#555",
    color: "#f1f1f1",
  },
  content: {
    padding: "16px",
  },
  sticky: {
    position: "fixed",
    top: 0,
    width: "100%",
  },
  list: {
    marginTop: "1.5rem",
  },
}));
