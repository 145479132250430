import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { fetchEnrolledCourseTitles, createTopic } from '../../Store/Community/actionCreator'
import { showSnackBar } from '../../Store/AlertMessages/actionCreator';
import TextEditor from '../Shared/TextEditor';
import { community_msgs } from '../Common/messages'
import AlertMessages from '../Shared/AlertMessages'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-76px'
  },
}))

function AddTopic(props){
  const classes = useStyles();
  const closeTrigger = props.closeAddTopicForm ? props.closeAddTopicForm : null
  const [courseTitles, setCourseTitles] = React.useState([])
  const [topicTitle, setTopicTitle] = React.useState('')
  const [topicDesc, setTopicDesc] = React.useState('')
  const [selectedCourseTitle, setSelectedCourseTitle] = React.useState('')
  const [inProgress, setInProgress] = React.useState(false)

  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })

  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      topicTitle: {
        error: false
      },
      topicDesc: {
        error: false
      },
      topicCourseTitle: {
        error: false
      }
    }
  )

  const courseTitleSuccessCB = res => {
    setCourseTitles(res.results)
  }
  const courseTitlesFailureCB = error => {
    setCourseTitles([])
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_FETCH_ENROLLED_COURSE_NAMES,
      type: "error",
    });
  }
  useEffect(() => {
    fetchEnrolledCourseTitles({}, courseTitleSuccessCB, courseTitlesFailureCB)
  },[])

  const handleChangeCourseTitle = (event) => {
    setSelectedCourseTitle(event.target.value)
  }

  const handleChangeTopicTitle = (event) => {
    setTopicTitle(event.target.value)
  }

  const changeEditorContent = (responseContent) => {
    setTopicDesc(responseContent);
  };

  const topicCreateSuccssCB = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: community_msgs.SUCCESS_CREATE_TOPIC,
      type: "success",
    });
    props.closeAddTopicForm()
    props.courseTitlesFetch(0)

  }

  const topicCreateFailureCB = err => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_CREATE_TOPIC,
      type: "error",
    });
  }

  const initiateTopic = event => {
    event.preventDefault()
    if(!topicTitle){
      setFormFieldStatus({
        topicTitle: {
          error: true
        },
        topicDesc: {
          error: false
        },
        topicCourseTitle: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic Title!'
      })
    }
    else if(!selectedCourseTitle){
      setFormFieldStatus({
        topicTitle: {
          error: false
        },
        topicDesc: {
          error: false
        },
        topicCourseTitle: {
          error: true
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Course Title!'
      })
    }
    else if(!topicDesc){
      setFormFieldStatus({
        topicTitle: {
          error: false
        },
        topicDesc: {
          error: true
        },
        topicCourseTitle: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic Description!'
      })
    }
    else{
      setFormFieldStatus({
        topicTitle: {
          error: false
        },
        topicDesc: {
          error: false
        },
        topicCourseTitle: {
          error: false
        }
      })
      setValidationError({
        error: false,
        errorMessage: ''
      })
      setInProgress(true)
      const payload = {
        topic_title: topicTitle,
        topic_desc: topicDesc,
        topic_coursetitle: selectedCourseTitle
      }
      createTopic(payload, topicCreateSuccssCB, topicCreateFailureCB)
    }
  }

  return(
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            {validationError.error ? (
              <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block'}}>
                <AlertMessages status="error" message={validationError.errorMessage} />
              </div>
            ) : null}
            <form noValidate autoComplete="off" onSubmit={initiateTopic}>
              <Grid container spacing={2} style={{ marginTop: '10px'}}>
                <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                  <TextField
                    id="title"
                    label="Topic title"
                    helperText="max: 100 chars"
                    required
                    variant="outlined"
                    error={formFieldStatus.topicTitle.error}
                    //defaultValue={values.discussionTitle}
                    inputProps={{
                      maxLength: 100,
                    }}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeTopicTitle}
                  />
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                  <TextField
                    id="coursetitle"
                    select
                    required
                    style={{
                      width: '100%'
                    }}
                    label="Course title"
                    error={formFieldStatus.topicCourseTitle.error}
                    value={selectedCourseTitle}
                    onChange={handleChangeCourseTitle}
                    helperText="Please select course title you want add topic"
                    variant="outlined"
                  >
                    {courseTitles.map((item, i) => {
                      return <MenuItem value={item.title} key={i}>{item.title}</MenuItem>
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px'}}>
                <div style={{ marginTop: '20px', marginBottom: '20px'}}>
                  <Typography style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px' }}>
                    About this topic
                    <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                  </Typography>
                  <TextEditor onChangeEditorContent={changeEditorContent} helperText={'About topic'} description={topicDesc} />
                </div>
              </Grid>
              <Grid container style={{ marginTop: '20px'}}>
                {inProgress ? (
                  <CircularProgress size={30} style={{ marginRight: 10 }}/>
                ) : null}
                <Button variant="contained" color="primary"  onClick={initiateTopic} disabled={inProgress}>
                  Create Topic
                </Button>
                <Button variant="contained" style={{ marginLeft: 20 }} disabled={inProgress} onClick={closeTrigger}>Cancel</Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(AddTopic);