import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadCSS } from "fg-loadcss";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import StarRatingComponent from "react-star-rating-component";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Popover from "@material-ui/core/Popover";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Star from "@material-ui/icons/Star";
import StarHalf from "@material-ui/icons/StarHalf";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LinearProgress from "@material-ui/core/LinearProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//Local Imports
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { style } from "../../Styles/theme";
import { getMyCourses, getMyFavCourses } from "../../Store/Profile/actionCreator";
import { getDomainInfo, redirectTo, cryptCourseId } from "../../Helpers/basic";
import ProfileLoader from '../Shared/SkeletonLoaders/profileLoader'

const BorderLinearProgress = withStyles({
  root: {
    height: 5,
    backgroundColor: "#b0d6f9",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: style.fontColour._blue,
  },
})(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "5px" }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: style.backgroundColor._white,
  },
  progress: {
    marginTop: "0.5rem",
    borderRadius: "0.5rem",
  },
  card: {
    width: 400,
    height: 400,
    cursor: "pointer",
    paddingBottom: '0.5rem',
    border: 0,
  },
  titleLink: {
    color: style.fontColour._blue,
    fontSize: "1rem",
    textDecoration: "none",
    cursor: "pointer",
  },
  tab: {
    color: "#FFF",
  },
  appBar: {
    backgroundColor: style.fontColour._blue,
    borderRadius: "5px",
  },
  media: {
    height: 0,
    margin: "0.5rem 0.5rem 0.5rem 0.5rem",
    paddingTop: "56.25%",
    border: "1px solid #f6f6f6",
  },
  avatar: {
    backgroundColor: style.fontColour._blue,
  },
}));

function MyCourses(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:700px)")
  const [value, setValue] = useState(0);
  const [fetched, setFetched] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [clientType, setClientType] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [favCourseList, setFavCourseList] = useState([]);

  const handleChange = (event, newValue) => {
    setCourseList([])
    fetchMyCoursesList(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
    fetchMyCoursesList(0);
  }, []);

  const handleCourseCardClick = (tenantId) => {
    props.history.push(`/${tenantId}`);
  };

  // const handleClick = (event, courseId) => {
  //     setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? "simple-popover" : undefined;

  const fetchMyCoursesList = (type) => {
    console.log("type", type);
    setFetched(false)
    let domainDet = getDomainInfo();
    setClientType(domainDet.type);
    let courseType = ["favourites"];

    if (domainDet.type === "b2c") {
      if (type === 0) {
        courseType = ["favourites"];
      } else if (type === 1) {
        courseType = ["yet-to-start", "in-progress"];
      } else if (type === 2) {
        courseType = ["completed"];
      }
    } else if (domainDet.type === "b2b") {
      if (type === 0) {
        courseType = ["yet-to-start", "in-progress"];
      } else if (type === 1) {
        courseType = ["completed"];
      }
    }

    if (domainDet.type === "b2c" && type === 0) {
      getMyFavCourses(successCallBack, failureCallBack);
    } else {
      let payload = {
        courseType: courseType,
      };
      getMyCourses(payload, successCB, failureCB);
    }
  };

  const successCallBack = (res) => {
    setFavCourseList(res.results);
    setFetched(true);
  };

  const failureCallBack = (error) => {
    setFetched(true);
    props.showSnackBar(
      {
        state: false,
        message: 'Something went wrong while fetching courses!',
        type: 'error'
      }
    )
  };

  const successCB = (res) => {
    setCourseList(res.results);
    setFetched(true);
  };
  const failureCB = (error) => {
    setFetched(true);
    props.showSnackBar(
      {
        state: false,
        message: 'Something went wrong while fetching courses!',
        type: 'error'
      }
    )
  };

  const renderFavCourseList = () => {
    let arr = [];
    favCourseList.length > 0 &&
      favCourseList.map((item, index) => {
        let cryptedCourseId = cryptCourseId(item.id)
        let URL = item.is_scorm && item.price !== 'FREE' && !item.is_course_purchased ? `https://myathina.com/${item.slug}-course-${cryptedCourseId}` : item.url;
        // let cleanInfo = '';
        // let descPreview = '';
        // if (item.desc !== null) {
        //     cleanInfo = item.desc.replace(/(<([^>]+)>)/ig, '');
        //     descPreview = cleanInfo.length > 100 ? cleanInfo.substring(0, 100) + '...' : cleanInfo;
        // }
        arr.push(
          <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
            <ListItem>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="profile" alt="profile image" className={classes.avatar}>
                      {item.title.substring(0, 1)}
                    </Avatar>
                  }
                  title={
                    <a className={classes.titleLink} href={URL} target="_blank" rel="noopener noreferrer">
                      <div>{item.title.length > 25 ? item.title.substring(0, 25) + "..." : item.title}</div>
                    </a>
                  }
                  subheader={
                    <div style={{ display: "flex" }}>
                      <div>Duration: {item.duration} </div>
                    </div>
                  }
                />
                <CardMedia className={classes.media} image={item.img} title={item.title} />
                <div style={{ margin: "0.5rem" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {item.provider && (
                        <div style={{ display: "flex" }}>
                          <div>by: </div>
                          <div style={{ color: style.fontColour._blue, marginLeft: "0.5rem" }}>{item.provider}</div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div style={{ display: "flex" }}>
                        <div>Ratings: </div>
                        <StarRatingComponent
                          name="rate2"
                          editing={false}
                          renderStarIcon={() => (
                            <span>
                              <Star />
                            </span>
                          )}
                          renderStarIconHalf={() => (
                            <span>
                              <StarHalf />
                            </span>
                          )}
                          starCount={5}
                          value={item.ratings ? item.ratings : 0}
                          starColor={style.fontColour._orange}
                          emptyStarColor="#ccc"
                          className={classes.ratingStar}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {item.price && (
                        <div style={{ display: "flex" }}>
                          <div>Price: </div>
                          <div style={{ color: style.fontColour._blue, marginLeft: "0.5rem" }}>{item.price}</div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </ListItem>
          </Grid>
        );
      });
    return arr;
  };

  const renderCourseList = () => {
    let arr = [];
    courseList.length > 0 &&
      courseList.map((item, index) => {
        // let cleanInfo = '';
        // let descPreview = '';
        // if (item.desc !== null) {
        //     cleanInfo = item.desc.replace(/(<([^>]+)>)/ig, '');
        //     descPreview = cleanInfo.length > 100 ? cleanInfo.substring(0, 100) + '...' : cleanInfo;
        // }
        arr.push(
          <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
            <ListItem>
              <Card
                component="button"
                className={classes.card}
                onClick={() => {
                  !item.isscorm ? handleCourseCardClick(item.tenant_id) : redirectTo(item.scorm_url, true);
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar aria-label="profile" alt="profile image" className={classes.avatar}>
                      {item.title.substring(0, 1)}
                    </Avatar>
                  }
                  title={item.title.length > 35 ? item.title.substring(0, 35) + "..." : item.title}
                  subheader={item.isscorm ? "" : "Includes " + item.modules_count + " Modules, " + item.lesson_count + " lessons"}
                />
                <CardMedia className={classes.media} image={item.banner_url} title={item.title} />
                <CardActions disableSpacing>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {item.status !== "completed" ? (
                        <React.Fragment>
                          {item.isscorm ? (
                            <React.Fragment>
                              <div style={{ fontSize: "0.8rem" }}>Progress: {item.status === "yet-to-start" ? "0%" : `${item.progress_scorm}%`}</div>
                              <BorderLinearProgress
                                className={classes.progress}
                                variant="determinate"
                                color="secondary"
                                value={item.status === "yet-to-start" ? 0 : item.progress_scorm}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div style={{ fontSize: "0.8rem" }}>Progress: {item.status === "yet-to-start" ? "0%" : `${item.completion_rate}%`}</div>
                              <BorderLinearProgress
                                className={classes.progress}
                                variant="determinate"
                                color="secondary"
                                value={item.status === "yet-to-start" ? 0 : item.completion_rate}
                              />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <div style={{ display: "flex" }}>
                          {!item.isscorm ? (
                            <React.Fragment>
                              <Tooltip title="Score" arrow>
                                <Icon className="fas fa-award" style={style.certIconSmall}></Icon>
                              </Tooltip>
                              <div>{item.status === "yet-to-start" ? "0%" : " Score: -"}</div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div style={{ fontSize: "0.8rem" }}>Progress: {`${item.progress_scorm}%`}</div>
                              <BorderLinearProgress
                                className={classes.progress}
                                variant="determinate"
                                color="secondary"
                                value={item.progress_scorm}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {!item.isscorm && (
                        <div style={{ display: "flex" }}>
                          <AccessTimeIcon />
                          <div style={{ marginLeft: "10px", fontSize: "1rem" }}>Duration: {item.course_duration} Hours </div>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </ListItem>
          </Grid>
        );
      });
    return arr;
  };

  return (
    <div>
      {fetched === true ? (
        <div className={classes.root}>
          <AppBar id="main" tabIndex="-1" position="static" className={classes.appBar} color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tab}
              indicatorColor="secondary"
              variant="fullWidth"
              aria-label="My courses listing tab view"
            >
              <Tab label={clientType === "b2c" ? "Favourites" : "My Courses"} {...a11yProps(0)} />
              <Tab label={clientType === "b2c" ? "In-progress" : "Completed"} {...a11yProps(1)} />
              {clientType === "b2c" ? <Tab label="Completed" {...a11yProps(2)} /> : ""}
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0} dir={theme.direction}>
            <List>
              <Grid container spacing={2}>
                {clientType === "b2b" && courseList.length > 0 ? (
                  renderCourseList()
                ) : clientType === "b2c" && favCourseList.length > 0 ? (
                  renderFavCourseList()
                ) : (
                  <Typography style={{ textAlign: "center", fontSize: "18px", marginTop: "10px", marginLeft: "1rem" }}>No Data Found</Typography>
                )}
              </Grid>
            </List>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <List>
              <Grid container spacing={2}>
                {courseList.length > 0 ? (
                  renderCourseList()
                ) : (
                  <Typography style={{ textAlign: "center", fontSize: "18px", marginTop: "10px", marginLeft: "1rem" }}>No Data Found</Typography>
                )}
              </Grid>
            </List>
          </TabPanel>
          {clientType === "b2c" ? (<TabPanel value={value} index={2} dir={theme.direction}>
            <List>
              <Grid container spacing={2}>
                {courseList.length > 0 ? (
                  renderCourseList()
                ) : (
                  <Typography style={{ textAlign: "center", fontSize: "18px", marginTop: "10px", marginLeft: "1rem" }}>No Data Found</Typography>
                )}
              </Grid>
            </List>
          </TabPanel>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div style={{ marginLeft: matches ? '50px' : '104px' }}>
          <ProfileLoader />
        </div>
      )}
      <Popover
        id={id}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List aria-label="Options">
          <ListItem>Remove Course</ListItem>
          <ListItem>Cancel</ListItem>
        </List>
      </Popover>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(MyCourses);
