import axios from "axios";
import { apiConfig } from "../../Configs/apiConfig";

const mockFetchNotices = {
  total: 13,
  notices: [
    {
      notice_id: 1,
      subject: 'Subject A Subject A Subject A Subject A Subject A Subject A Subject A Subject A',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-03-09T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: false,
      is_active: true
    },
    {
      notice_id: 2,
      subject: 'Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-03-09T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: false,
      is_active: true
    },
    {
      notice_id: 3,
      subject: 'Subject C Subject C Subject C Subject C Subject C Subject C Subject C Subject C',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: false,
      is_active: true
    },
    {
      notice_id: 4,
      subject: 'Subject A Subject A Subject A Subject A Subject A Subject A Subject A Subject A',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
    {
      notice_id: 5,
      subject: 'Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
    {
      notice_id: 6,
      subject: 'Subject C Subject C Subject C Subject C Subject C Subject C Subject C Subject C',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
    {
      notice_id: 7,
      subject: 'Subject A Subject A Subject A Subject A Subject A Subject A Subject A Subject A',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
    {
      notice_id: 8,
      subject: 'Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B Subject B',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
    {
      notice_id: 9,
      subject: 'Subject C Subject C Subject C Subject C Subject C Subject C Subject C Subject C',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
    {
      notice_id: 10,
      subject: 'Subject A Subject A Subject A Subject A Subject A Subject A Subject A Subject A',
      message: '<p>this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>. this is <strong>test</strong> <span style="color:rgb(0,153,0);">message</span>.</p>',
      sent_date: '2021-02-20T04:58:30.652406Z',
      sent_by: 'Laukik Sarode',
      sent_to: {
        user_type: {
          'everyone': false,
          'allStudents': false,
          'allMentors': false,
          'allInstructors': false,
          'selectByGroups': true,
          'individual': false
        },
        selectedGroups: [3, 5]
      },
      is_read: true,
      is_active: true
    },
  ]
}

export const fetchMyNotices = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.fetchMyNotices}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      // successCallBack(mockFetchNotices)
    })
}

export const updateViewNoticeStatus = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.updateViewNoticeStatus}${payload.notice_id}/`
  let newPayload = { ...payload }
  delete newPayload.notice_id
  axios.get(url, {
    params: newPayload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack()
    })
}
export const fetchHeaderNoticeCount = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.fetchHeaderNoticeCount}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      // successCallBack(mockFetchNotices)
    })
}
