import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import List from '@material-ui/core/List';
import moment from 'moment';
import { Route } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LinearProgress from "@material-ui/core/LinearProgress";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Paper from "@material-ui/core/Paper";
import DatePicker from "react-datepicker";
import Button from '@material-ui/core/Button';
import "react-datepicker/dist/react-datepicker.css";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import { style } from "../../Styles/theme";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { fetchMyClasses, fetchUsers } from '../../Store/ZoomClasses/actionCreator';
import { myVideos_msgs } from '../Common/messages';
import VimeoPlayer from "../Shared/vimeoPlayer";
import Header from "../Header";
import MyVideosLoader from '../Shared/SkeletonLoaders/myVideosLoader';
import { timeDiffCalc } from "../../Helpers/basic";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(3),
        padding: '3px',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            width: 'auto',
            marginTop: '10px',
            paddingLeft: '50px',
            paddingRight: '50px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    rootMob: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    paper: {
        padding: '3px',
        textAlign: 'center',
        marginTop: '5px',
        width: '100%',
        cursor: 'pointer'
    },
    titleWrapper: {
        fontSize: '29px',
        fontWeight: '500',
        color: style.fontColour._blue,
        marginBottom: '11px',
        marginTop: '11px',
        textAlign: 'center'
    },
    cardWrapper: {
        maxWidth: 270,
        margin: "auto",
        transition: "0.3s",
        "&:hover": {
            boxShadow: "0 10px 50px -12.125px rgba(0,0,0,0.3)",
            transition: 'transform 1s',
            transform: 'translateY(-4px)'
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9,
        cursor: 'pointer'
    },
    topic: {
        fontSize: '14px',
        fontWeight: '400',
        color: style.fontColour._blue,
        textAlign: 'initial',
        lineBreak: 'anywhere'
    },
    buttonProgress: {
        margin: "0.5rem",
        textAlign: 'center',
        color: style.fontColour._blue,
    },
    avatar: {
        backgroundColor: style.fontColour._blue,
    },
    detailsCtr: {
        paddingTop: '1rem',
        marginLeft: '0.5rem'
    },
    item: {
        fontSize: '11px',
        paddingRight: '0.5rem',
        paddingTop: '0.2rem',
        color: style.fontColour._egyptian_blue,
        textAlign: 'initial',
        lineBreak: 'anywhere'
    },
    infoCtr: {
        padding: '2px'
    },
    icn: {
        cursor: 'pointer',
        color: style.fontColour._blue,
        position: "relative",
        left: "25%",
        top: "25%"
    },
    paperCtr: {
        textAlign: 'center'
    },
    filtrCtr: {
        display: 'flex'
    },
    pickerCtr: {
        display: 'flex',
        marginLeft: '1rem'
    },
    datePicker: {
        border: '1px solid',
        borderRadius: '5px',
        width: 100,
        padding: '0.5rem',
        margin: '0.5rem 0.5rem 0 0'
    },
    btn: {
        flexGrow: 1,
        margin: '1rem',
        backgroundColor: style.fontColour._blue,
        color: '#FFF',
        '&:hover': {
            backgroundColor: style.fontColour._blue,
        },
    },
    formControl: {
        margin: '0.5rem 1rem',
        minWidth: 200,
        maxWidth: 300,
    },
    formControlMob: {
        margin: '0.5rem',
        minWidth: 300,
        maxWidth: 350,
    },
    filtrTxt: {
        fontSize: '1rem',
        color: style.fontColour._blue,
        margin: '1.5rem 0 0 0.5rem'
    },
    player: {
        marginTop: '1rem'
    }
}))


function MyClasses(props) {
    const classes = useStyles();
    const morethan700px = useMediaQuery("(max-width:700px)");
    const mobileView = useMediaQuery("(max-width:600px)");
    const lessthan850px = useMediaQuery("(max-width:850px)");
    const [inProgress, setInprogress] = useState(false);
    const [classesData, setClassesData] = useState([]);
    const [playerData, setPlayerData] = useState({});
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(new Date());
    const [personName, setPersonName] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const tenantId = props.match.params.tenantId ? props.match.params.tenantId : '';

    useEffect(() => {
        setInprogress(true)
        fetchAllClasses();
        fetchSharedUsers();
    }, [])

    const fetchSharedUsers = () => {
        fetchUsers({}, successFetchUsers, failureFetchUsers)
    }

    const successFetchUsers = res => {
        setFilterList(res.data)
    }

    const failureFetchUsers = error => {
        setFilterList([]);
    }


    const successFetchClasses = res => {
        setInprogress(false);
        setClassesData(res && res.data);
        if (res && res.data.length > 0) {

            let playerInfo = {
                videoURL: res.data[0].video_url,
                type: "classes",
            };
            setPlayerData(playerInfo);
        }
    }

    const failureFetchClasses = error => {
        setInprogress(false);
        setClassesData([]);
        props.showSnackBar({
            state: true,
            message: myVideos_msgs.ERROR_FETCH_MYVIDEOS,
            type: "error",
        });
    }

    const fetchAllClasses = () => {
        document.getElementById('classlisting') && document.getElementById('classlisting').focus()
        setInprogress(true)
        let payload = {
            start_date: startDate,
            end_date: endDate,
            user_id: personName
        }
        fetchMyClasses(payload, successFetchClasses, failureFetchClasses)
    }


    const handleSelectChange = (event) => {
        setPersonName(event.target.value);
    };

    const SimpleBreadcrumbs = () => {
        return (
            <Route>
                <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
                    <RouterLink style={style.brdCrmbPrimary} to="/">
                        Home
                    </RouterLink>
                    <div style={style.brdCrmbSecondary}>My Classes</div>
                </Breadcrumbs>
            </Route>
        );
    }


    const playRecording = (data) => {
        document.getElementById('viewclass') && document.getElementById('viewclass').focus()
        let playerInfo = {
            videoURL: data.video_url,
            type: "classes",
        };
        setPlayerData(playerInfo);
    }


    return (
        <React.Fragment>
            <div>
                <Grid container>
                    <Header history={props} />
                </Grid>
            </div>
            <div>
                <Grid container style={{ marginTop: morethan700px ? '118px' : "5rem", paddingLeft: "1rem" }}>
                    {SimpleBreadcrumbs()}
                </Grid>
            </div>
            <div className={lessthan850px ? classes.rootMob : classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <div id="classlisting" tabIndex="-1" className="accessibility">
                            <h1>My classes listing</h1>
                        </div>
                        {inProgress ? (
                            <Paper elevation={1} className={classes.paperCtr}>
                                <CircularProgress size={30} className={classes.buttonProgress} />
                            </Paper>
                        ) : (
                            <React.Fragment>
                                {classesData && classesData.length ? (

                                    <Grid container spacing={2}>
                                        {classesData.map((data, index) => {
                                            let startTime = new Date(data.recording_start)
                                            let endTime = new Date(data.recording_end)
                                            let duration = timeDiffCalc(endTime, startTime);
                                            return (
                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: '2px' }} key={index}>
                                                    <Paper className={classes.paper} key={index} elevation={2} onClick={() => { playRecording(data) }}>
                                                        <Grid container spacing={2} style={{ padding: '8px' }}>
                                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                                                <Link component="button" onClick={() => { playRecording(data) }} className={classes.topic}>{data && data.topic && data.topic.length > 35 ? data.topic.substring(0, 35) + "..." : data.topic}</Link>
                                                                <div className={classes.detailsCtr}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={6} md={5} lg={4} style={{ padding: '2px' }} >
                                                                            <div className={classes.item}> {data.host.length > 20 ? data.host.substring(0, 20) + "..." : data.host} </div>
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={3} lg={4} style={{ padding: '2px' }}>
                                                                            <div className={classes.item}> {moment(startTime).format("DD-MM-YY")}</div>
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: '2px' }}>
                                                                            <div className={classes.item}>{duration} Mins </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                <PlayCircleOutlineIcon className={classes.icn} aria-label="Start class"/>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>)
                                        })}
                                    </Grid>

                                ) :
                                    (
                                        <div style={{ fontSize: '16px', padding: '1rem 1rem 12px 1rem', color: style.fontColour._blue }}>No Records Found.</div>
                                    )}
                            </React.Fragment>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={9}>
                        <Paper elevation={1}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} md={4} lg={6} style={{ padding: '1px', textAlign: 'initial' }}>
                                    {filterList.length > 0 && <FormControl className={mobileView ? classes.formControlMob : classes.formControl}>
                                        <InputLabel id="demo-mutiple-checkbox-label" aria-label="filter by names">Filter By</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={personName}
                                            onChange={handleSelectChange}
                                            label="Filter Member"
                                        >
                                            {filterList.length > 0 && filterList.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.mentor_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>}
                                </Grid>
                                <Grid item xs={8} sm={8} md={5} lg={4} style={{ padding: '1px' }}>
                                    <div style={{ display: "flex", margin: "0.8rem" }}>
                                        <DatePicker
                                            aria-label="select meeting filter start date"
                                            className={classes.datePicker}
                                            selected={startDate}
                                            maxDate={new Date()}
                                            onChange={date => setStartDate(date)}
                                            dateFormat="MMMM d, yyyy"
                                            placeholderText="Click to select a start date filter"
                                        />
                                        <DatePicker
                                            aria-label="select meeting filter end date"
                                            className={classes.datePicker}
                                            selected={endDate}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            onChange={date => setEndDate(date)}
                                            dateFormat="MMMM d, yyyy"
                                            placeholderText="Click to select a end date filter"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={2} style={{ padding: '1px', textAlign: 'initial' }}>
                                    <Button variant="outlined" color="primary" disabled={inProgress} className={classes.btn} onClick={() => { fetchAllClasses() }}>Search</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className={classes.player}>
                            <div id="viewclass" tabIndex="-1" className="accessibility">
                                <h1>View Class Video</h1>
                            </div>
                            {playerData.videoURL && <VimeoPlayer data={playerData} />}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = state => {
    return {}
}
export default connect(
    mapStateToProps, { showSnackBar }
)(MyClasses);