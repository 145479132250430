import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Hidden from '@material-ui/core/Hidden';
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Route } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ReplyIcon from "@material-ui/icons/Reply";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddCommentIcon from "@material-ui/icons/AddComment";
import Typography from "@material-ui/core/Typography";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DescriptionIcon from "@material-ui/icons/Description";
import PictureInPictureIcon from "@material-ui/icons/PictureInPicture";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//LOCAL IMPORTS
import Header from "../Header";
import { style, colorStyles } from "../../Styles/theme";
import { convertUTCDatewithTime } from "../../Helpers/basic";
import TextEditor from "../Shared/TextEditor";
import SubRepliesLoader from "../Shared/SkeletonLoaders/subRepliesLoader";
import responseIcon from "../../Static/responseIcon.png";
import AlertMessages from "../Shared/AlertMessages";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import DiscussionCommentLoader from "../Shared/SkeletonLoaders/discussionCommentLoader";
import ResponsesLoader from "../Shared/SkeletonLoaders/assignmentListLoader";
import { discussion_msgs } from "../Common/messages";
import {
  fetchExternalDiscussion,
  fetchExternalResponses,
  fetchExternalResponseReplies,
  fetchExternalPinnedResponses,
  externalPostResponse,
  externalPostReply,
} from "../../Store/Discussions/actionCreator";
import { bytesToSize } from '../../Helpers/basic';
import { assignment_file_size, discussion_file_types } from '../../Configs/commonConfig';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 11,
    backgroundColor: '#2e8eec',
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "4px 4px",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: theme.palette.background.paper,
  },
  responseText: {
    margin: "1rem 2rem",
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
  },
  viewCmntCtr: {
    display: "flex",
  },
  discussionTitle: {
    textAlign: "initial",
    padding: "0.5rem",
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    fontWeight: 600,
  },
  discDesc: {
    fontSize: '14px',
    lineBreak: "anywhere",
    color: 'black'
  },
  editorCtr: {
    marginTop: '10px',
    justifyContent: "center",
  },
  LoadBtnCtr: {
    margin: "0.2rem 0.2rem 0.5rem 0.2rem",
    textAlign: "center",
  },
  btnCtr: {
    margin: "0.2rem 0.2rem 0.5rem 0.2rem",
    textAlign: "center",
  },
  textArea: {
    marginLeft: "5rem",
    width: "90%",
    marginBottom: "0.5rem",
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important",
  },
  btn: {
    marginLeft: "1rem",
  },
  repliesList: {
    width: "90%",
    marginLeft: "2rem",
    backgroundColor: theme.palette.background.paper,
  },
  pinnedList: {
    width: "95%",
    paddingBottom: '0.5rem',
    margin: "1rem 3rem 1rem 1rem"
  },
  topic: {
    width: "95%",
    margin: "1rem 3rem 1rem 1rem",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  avatarCommentsAuthor: {
    backgroundColor: "#f2903d",
    textTransform: "uppercase",
  },
  avatarComments: {
    backgroundColor: "#6cb5f9",
    textTransform: "uppercase",
  },
  avatarReplies: {
    backgroundColor: '#2368a9'
  },
  replyIcon: {
    fontSize: "20px",
    paddingRight: "2px",
  },
  commentIcon: {
    paddingRight: "2px",
  },
  commentText: {
    fontSize: "0.7rem",
    color: style.fontColour._blue,
    cursor: "pointer",
  },
  nameCntr: {
    display: "flex",
  },
  name: {
    marginRight: "0.5rem",
    color: style.fontColour._blue,
  },
  subDate: {
    fontSize: "0.8rem",
    paddingTop: "0.2rem",
    color: "#aeaeae",
  },
  iconWrapper: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
  },
  title: {
    fontSize: "19px",
    fontWeight: "500",
    marginTop: "10px",
    color: style.fontColour._blue,
    textAlign: "left",
    lineBreak: "anywhere",
  },
  readingsButton: {
    float: "left",
    marginTop: "9px",
    color: style.fontColour._blue,
  },
  paperHeader: {
    marginTop: "20px",
    paddingBottom: '20px',
    background: colorStyles.background,
  },
  infoItemsCtr: {
    display: "flex",
  },
  infoItems: {
    padding: "0.5rem",
  },
  infoIcon: {
    color: style.iconColor._color,
    marginTop: "0.5rem",
  },
  infoBtn: {
    padding: "0.5rem",
  },
  descWrapper: {
    textAlign: "left",
    margin: "1rem",
    lineBreak: "anywhere",
  },
  discussionDateWrpper: {
    padding: "0.5rem",
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    border: '2px solid #ccc',
  },
  uploadLabel: {
    marginTop: '5px',
    fontSize: '14px'
  },
  uploadIcon: {
    marginTop: '-3px',
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedVideo: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    cursor: 'pointer'
  },
}));

function Discussion(props) {
  const classes = useStyles();
  const tenantId = props.match.params.tenantId;
  const rowsPerPage = 10;
  const mobileView = useMediaQuery('(max-width:600px)');
  const [inProgress, setInProgress] = useState(true);
  const [subRepliesLoader, setRepliesLoader] = useState(false);
  const [responsesLoader, setResponsesLoader] = useState(true);
  //for entire responses callbacks
  const [responses, setResponses] = useState([]);
  //discussion Details
  const [discussionTitle, setDiscussionTitle] = useState("");
  const [discussionDesc, setDiscussionDesc] = useState("");
  const [discussionAttach, setDiscussionAttach] = useState("");
  const [discussionPoints, setDiscussionPoints] = useState(0);
  const [totalResponseCount, setTotalResponseCount] = useState(0);
  const [pinnedResponses, setPinnedResponses] = useState([]);
  const [selectedReplyListId, setSelectedReplyListId] = useState();
  const [replies, setReplies] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [selectedReplyBtnId, setSelectedReplyBtnId] = useState();
  const [responseContent, setResponseContent] = useState();
  const [replyContent, setReplyContent] = useState();
  const [pageNum, setPageNum] = useState(0);
  const [repliesPageNum, setRepliesPageNum] = useState(0);
  const [totalRepliesCount, setTotalRepliesCount] = useState(0);
  const [allowThreadedReply, setAllowThreadedReply] = useState(false);
  const [previewReply, setPreviewReply] = useState(false);
  const [gradedReply, setGradedReply] = useState(false);
  const [respBtnLdr, setRespBtnLdr] = useState(false);
  const [rplyBtnLdr, setRplyBtnLdr] = useState(false);
  const [validationError, setValidationError] = useState({
    error: false,
    errorMessage: "",
  });
  //FOR ATTACHEMENT UPLOAD
  const [attachmentFileDetails, setAttachmentFileDetails] = React.useState({});
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState('')

  //FOR FILE UPLOAD on responses
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'attachmentfile' && file.size > assignment_file_size) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'attachmentfile') {
            return discussion_file_types
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }
  const onAttachmentUpload = event => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    const attachment = validatedFile('attachmentfile')
    setAttachmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const successResponsesCB = (res) => {
    setResponses(res && res.results && res.results)
    setResponsesLoader(false)
  };
  const failureResponsesCB = (err) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
    setDiscussionTitle("");
    setDiscussionAttach("");
    setDiscussionDesc("");
    setGradedReply(false);
    setDiscussionPoints(0);
    setTotalResponseCount(0);
    setAllowThreadedReply(false);
    setPreviewReply(false);
    setResponses([]);
    setResponsesLoader(false)
  };

  const fetchResponse = (pNo) => {
    setResponsesLoader(true)
    setPageNum(pNo);
    fetchExternalResponses(
      {
        forum_id: props.match.params.discussionId,
        number: rowsPerPage,
        offset: pNo * rowsPerPage,
      },
      successResponsesCB,
      failureResponsesCB
    );
  };

  const pinnedRespSuccessCB = (res) => {
    setPinnedResponses(res && res.results && res.results);
  };

  const pinnedRespFailureCB = (error) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
    setPinnedResponses([]);
  };

  const fetchPinnedResp = () => {
    let payload = {
      forum_id: props.match.params.discussionId,
    };
    fetchExternalPinnedResponses(payload, pinnedRespSuccessCB, pinnedRespFailureCB);
  };

  //DISCUSSION DETAILS FETCH CALLBACKS
  const discussionSuccessCB = res => {
    setInProgress(false);
    let data = res && res.data
    setDiscussionTitle(data && data.title && data.title);
    setDiscussionAttach(data && data.attachment && data.attachment);
    setTotalResponseCount(data && data.reply_count);
    setAllowThreadedReply(data.threaded_reply);
    setPreviewReply(data.preview_reply);
    setGradedReply(data.graded_reply);
    setDiscussionPoints(data.points);
    let desc = "";
    if (data.description.length > 280) {
      desc = data.description.substring(0, 280) + "...";
    } else {
      desc = data.description;
    }
    setDiscussionDesc(desc);
  }
  const discussionFailureCB = error => {
    setInProgress(false);
    props.showSnackBar(
      {
        state: false,
        message: discussion_msgs.LOAD_ERROR_MSG,
        type: 'error'
      }
    )
  }

  useEffect(() => {
    setInProgress(true);
    fetchResponse(0);
    fetchPinnedResp();
    const payload = {
      id: props.match.params.discussionId
    }
    fetchExternalDiscussion(payload, discussionSuccessCB, discussionFailureCB)
  }, []);

  const changeEditorContent = (responseContent) => {
    setResponseContent(responseContent);
    setValidationError({
      error: false,
      errorMessage: "",
    });
  };

  const postRespSuccessCB = (res) => {
    setTotalResponseCount(res && res.reply_count);
    setRespBtnLdr(false);
    setShowEditor(false);
    setResponseContent(null);
    fetchResponse(0);
    setResponseContent(null);
    setUploadedAttachmentPreview('')
    setAttachmentFileDetails({})
    props.showSnackBar({
      state: true,
      message: discussion_msgs.RESPONSE_SUBMIT_SUCCESS,
      type: "success",
    });
  };
  const postRespFailureCB = (error) => {
    setRespBtnLdr(false);
    setShowEditor(false);
    setResponseContent(null);
    props.showSnackBar({
      state: true,
      message: discussion_msgs.RESPONSE_SUBMIT_FAILED,
      type: "error",
    });
  };

  const postResponseData = () => {
    if (!responseContent || responseContent === "") {
      setValidationError({
        error: true,
        errorMessage: "Please add response and then post!",
      });
    } else if (attachmentFileDetails.fileError) {
      setValidationError({
        error: true,
        errorMessage: 'Please add supported file only!'
      })
    }
    else {
      setValidationError({
        error: false,
        errorMessage: "",
      });
      setRespBtnLdr(true);
      let payload = {
        forum_id: props.match.params.discussionId,
        reply_text: responseContent,
      };
      // let formData = new FormData()
      // formData.append('forum_id', Number(props.match.params.discussionId))
      // formData.append('reply_text', responseContent ? responseContent : '')
      // formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
      externalPostResponse(payload, postRespSuccessCB, postRespFailureCB);
    }
  };

  const responseScrollSuccess = (res) => {
    setResponsesLoader(false)
    if (res && res.results && res.results.length) {
      let newData = [...responses]
      res.results.forEach((data, index) => {
        newData.push(data)
      })
      setResponses(newData)
    }
  };
  const responseScrollFailure = (error) => {
    setResponsesLoader(false);
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  const fetchDiscussionResponse = () => {
    setResponsesLoader(true);
    setPageNum(pageNum + 10);
    fetchExternalResponses(
      {
        forum_id: props.match.params.discussionId,
        number: rowsPerPage,
        offset: pageNum + rowsPerPage,
      },
      responseScrollSuccess,
      responseScrollFailure
    );
  };

  //RESPONSE REPLIES CALL BACKS
  const successCB = (res) => {
    setRepliesLoader(false);
    setTotalRepliesCount(res.comment_count);
    setReplies(res.results);
  };

  const failureCB = (error) => {
    setRepliesLoader(false);
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  //FOR FETCHING RESPONSES REPLIES
  const fetchReplies = (responseId) => {
    setRepliesLoader(true);
    setSelectedReplyListId(responseId);
    let payload = {
      reply_id: responseId,
      number: rowsPerPage,
      offset: repliesPageNum * rowsPerPage,
    };
    fetchExternalResponseReplies(payload, successCB, failureCB);
  };

  const handleReplyTextChange = (event) => {
    setReplyContent(event.target.value);
    setValidationError({
      error: false,
      errorMessage: "",
    });
  };

  const postRepSuccessCB = (res) => {
    setRplyBtnLdr(false);
    setShowReplyEditor(false);
    setReplyContent(null);
    fetchResponse(0);
    props.showSnackBar({
      state: true,
      message: 'Comment submitted successfully!',
      type: "success",
    });
    setSelectedReplyListId(null)
  };

  const postRepFailureCB = (error) => {
    setRplyBtnLdr(false);
    setShowReplyEditor(false);
    setReplyContent(null);
    props.showSnackBar({
      state: true,
      message: discussion_msgs.REPLY_SUBMIT_FAILED,
      type: "error",
    });
  };

  //POST REPLY TO A RESPONSE
  const postReplyData = () => {
    if (!replyContent || replyContent === "") {
      setValidationError({
        error: true,
        errorMessage: "Please add something to reply!",
      });
    } else {
      setValidationError({
        error: false,
        errorMessage: "",
      });
      setRplyBtnLdr(true);
      let payload = {
        reply_id: selectedReplyBtnId,
        comment_text: replyContent,
      };
      externalPostReply(payload, postRepSuccessCB, postRepFailureCB);
    }
  };

  const moreRepliesSuccessCB = (res) => {
    setRepliesLoader(false);
    setTotalRepliesCount(res.comment_count);
    if (res && res.results.length > 0) {
      let newData = [...replies];
      res.results.map((data, index) => {
        newData.push(data);
      });
      setReplies(newData);
    }
  };

  const moreRepliesFailureCB = (error) => {
    setRepliesLoader(false);
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  const fetchMoreReplies = () => {
    setRepliesLoader(true);
    setRepliesPageNum(repliesPageNum + 10);
    let payload = {
      reply_id: selectedReplyListId,
      number: rowsPerPage,
      offset: repliesPageNum + rowsPerPage,
    };
    fetchExternalResponseReplies(payload, moreRepliesSuccessCB, moreRepliesFailureCB);
  };

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/externaldiscussions`}>
            Manage Discussions
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Discussion Comments</div>
        </Breadcrumbs>
      </Route>
    );
  };

  //FOR RENDERING PINNED RESPONSES
  const renderPinnedResponses = () => {
    let sublistArr = [];
    {
      pinnedResponses &&
        pinnedResponses.length > 0 &&
        pinnedResponses.map((resp, index) => {
          let avatarName = resp && resp.first_name && resp.first_name.substring(0, 1) + resp && resp.last_name && resp.last_name.substring(0, 1);
          sublistArr.push(
            <div key={index} className={classes.sublistCntr}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatarReplies} alt="Remy Sharp">
                    {avatarName}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginRight: "1rem" }}
                  primary={
                    <div className={classes.nameCntr}>
                      <div className={classes.name}>
                        {`${resp && resp.first_name && resp.first_name} ${resp && resp.first_name && resp.last_name}`}
                      </div>
                      <span className={classes.subDate}>{`(${resp && resp.role})`}</span>&nbsp;&nbsp;
                      <div className={classes.subDate}>{convertUTCDatewithTime(resp.submission_date)}</div>
                    </div>
                  }
                  secondary={<div className={classes.discDesc} dangerouslySetInnerHTML={{ __html: resp.reply_text }} />}
                />
                <ListItemSecondaryAction>
                  {resp && resp.attachment_details && resp.attachment_details.file_name ? (
                    <React.Fragment>
                      {resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-6px', marginLeft: '10px', marginRight: '-14px', fontSize: '22px' }} /> : resp.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-6px', marginLeft: '10px', marginRight: '-14px', fontSize: '22px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-6px', marginLeft: '10px', marginRight: '-14px', fontSize: '22px' }} />}
                      <a href={resp.attachment_details && resp.attachment_details.file_url && resp.attachment_details.file_url} target='_blank' className={classes.uploadedVideo}>
                        {resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name.length > 15 ? (
                          resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name && resp.attachment_details.file_name.substr(0, 15) + '...'
                        ) :
                          resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name && resp.attachment_details.file_name
                        }
                      </a>
                    </React.Fragment>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        });
    }
    return sublistArr;
  };

  //FOR RENDERING RESPONSES
  const renderResponses = () => {
    let responseList = [];
    {
      responses &&
        responses.length > 0 &&
        responses.map((item, index) => {
          let avatarName = item && item.first_name && item.first_name.substring(0, 1) + item && item.last_name && item.last_name.substring(0, 1);
          responseList.push(
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <Grid container>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                    <div style={{ display: 'flex' }}>
                      <ListItemAvatar>
                        <Avatar className={classes.avatarComments} alt="Remy Sharp">
                          {avatarName}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ marginRight: "1rem" }}
                        primary={
                          <div className={classes.nameCntr}>
                            <div className={classes.name}>
                              {`${item && item.first_name && item.first_name} ${item && item.last_name && item.last_name}`}
                            </div>
                            <span className={classes.subDate}>{`(${item && item.role && item.role})`}</span>&nbsp;&nbsp;
                            <div className={classes.subDate}>{convertUTCDatewithTime(item.submission_date)}</div>
                          </div>
                        }
                        secondary={
                          <React.Fragment>
                            <div className={classes.discDesc} dangerouslySetInnerHTML={{ __html: item.reply_text }} />
                            {item && item.comment_count ? (
                              <div className={classes.viewCmntCtr}>
                                {item && item.id !== selectedReplyListId ? (
                                  <div
                                    className={classes.commentText}
                                    onClick={() => {
                                      fetchReplies(item.id);
                                    }}
                                  >
                                    View all {item && item.comment_count} comments
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setSelectedReplyListId(null);
                                    }}
                                    className={classes.commentText}
                                  >
                                    Hide comments
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </React.Fragment>
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'end' }}>
                    {item && item.attachment_details && item.attachment_details.file_name ? (
                      <React.Fragment>
                        {item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-6px', marginLeft: '10px', marginRight: '-15px', fontSize: '22px' }} /> : item.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginLeft: '10px', marginRight: '-15px', fontSize: '20px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-4px', marginLeft: '10px', marginRight: '-15px', fontSize: '21px' }} />}
                        <a href={item.attachment_details && item.attachment_details.file_url && item.attachment_details.file_url} target='_blank' className={classes.uploadedVideo}>
                          {item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name.length > 15 ? (
                            item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name && item.attachment_details.file_name.substr(0, 15) + '...'
                          ) :
                            item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name && item.attachment_details.file_name
                          }
                        </a>
                      </React.Fragment>
                    ) : null}
                  </Grid>
                  {allowThreadedReply && (
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          setShowReplyEditor(true);
                          setShowEditor(false)
                          setSelectedReplyBtnId(item.id);
                          setValidationError({
                            error: false,
                            errorMessage: "",
                          });
                        }}
                        edge="end"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #999",
                          padding: "5px",
                        }}
                        aria-label="delete"
                      >
                        <ReplyIcon className={classes.replyIcon} /> <div style={{ fontSize: "10px" }}>Reply</div>
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </Grid>
              </ListItem>
              {item.id === selectedReplyBtnId && showReplyEditor && (
                <React.Fragment>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={2}
                    value={replyContent}
                    placeholder="Reply to the Response..."
                    variant="outlined"
                    onChange={handleReplyTextChange}
                    className={classes.textArea}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.searchBoxOutline,
                      },
                    }}
                  />
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                    {validationError.error ? (
                      <div style={{ marginBottom: "20px" }}>
                        <AlertMessages status="error" message={validationError.errorMessage} />
                      </div>
                    ) : null}
                  </Grid>
                  <div className={classes.btnCtr}>
                    <Button
                      disabled={rplyBtnLdr}
                      onClick={() => {
                        postReplyData();
                      }}
                      variant="outlined"
                      size="small"
                      className={classes.btn}
                    >
                      {rplyBtnLdr && <CircularProgress size={20} />} Reply
                    </Button>
                    <Button
                      onClick={() => {
                        setShowReplyEditor(false);
                      }}
                      variant="outlined"
                      size="small"
                      className={classes.btn}
                    >
                      Cancel
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {item && item.id === selectedReplyListId && (
                <List className={classes.repliesList}>
                  {subRepliesLoader ? <SubRepliesLoader /> : renderSubReplies()}
                  <div className={classes.LoadBtnCtr}>
                    {replies && replies.length >= totalRepliesCount ? (
                      <div></div>
                    ) : (
                      <Button
                        onClick={() => {
                          fetchMoreReplies();
                        }}
                        disabled={subRepliesLoader}
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.btn}
                      >
                        Load More...
                      </Button>
                    )}
                  </div>
                </List>
              )}
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        });
    }
    return responseList;
  };

  //FOR RENDERING SUB REPLIES TO A RESPONSE
  const renderSubReplies = () => {
    let sublistArr = [];
    {
      replies &&
        replies.length > 0 &&
        replies.map((reply, index) => {
          let avatarName =
            reply && reply.first_name && reply.first_name.substring(0, 1) + reply && reply.last_name && reply.last_name.substring(0, 1);
          sublistArr.push(
            <div key={index} className={classes.sublistCntr}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatarReplies} alt="User Name">
                    {avatarName}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className={classes.nameCntr}>
                      <div className={classes.name}>
                        {`${reply && reply.first_name && reply.first_name} ${reply && reply.last_name && reply.last_name}`}
                      </div>
                      <span className={classes.subDate}>{`(${reply && reply.role && reply.role})`}</span>&nbsp;&nbsp;
                      <div className={classes.subDate}>{convertUTCDatewithTime(reply.submission_date)}</div>
                    </div>
                  }
                  secondary={
                    <React.Fragment>
                      <div className={classes.discDesc}>
                        {reply && reply.comment_text}
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        });
    }
    return sublistArr;
  };

  return (
    <div style={{ marginLeft: "2rem" }}>
      <div className={classes.root}>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: mobileView ? "118px" : "6rem" }}>
          {SimpleBreadcrumbs()}
        </Grid>
      </div>
      {inProgress ? (
        <div style={{ marginTop: "4rem" }}>
          <DiscussionCommentLoader />
        </div>
      ) : (
        <React.Fragment>
          <Grid container >
            <Hidden only={['xs', 'sm']}>
              <Grid item lg={1}>

              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Paper evlevation={2} className={classes.paperHeader}>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    {discussionTitle && (
                      <Grid container spacing={1}>
                        <Grid item sm={12} xs={12} md={8} lg={9} style={{ marginBottom: "10px" }}>
                          <div className={classes.discussionTitle}>{discussionTitle ? discussionTitle : ""}</div>
                          <div className={classes.discussionDateWrpper}>{gradedReply && discussionPoints ? `Points: ${discussionPoints}` : null}</div>
                        </Grid>
                        <Grid item sm={12} xs={12} md={4} lg={3}>
                          <div className={classes.infoItemsCtr}>
                            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                              <p>{`discussion responses count ${totalResponseCount}`}</p>
                            </div>
                            <div className={classes.infoItems}>
                              <Tooltip title={`${totalResponseCount} responses`} aria-hidden="true">
                                <StyledBadge badgeContent={totalResponseCount} color="secondary">
                                  <img src={responseIcon} alt="response count" style={{ height: "24px", marginTop: "11px" }} />
                                </StyledBadge>
                              </Tooltip>
                            </div>
                            {discussionAttach ? (
                              <React.Fragment>
                                <div className={classes.infoItems}>
                                  <Tooltip title="Attachment">
                                    <a
                                      href={discussionAttach && discussionAttach}
                                      target="_blank"
                                      edge="start"
                                      color="primary"
                                      className={classes.readingsButton}
                                      aria-label="download attachment"
                                    >
                                      {discussionAttach &&
                                        discussionAttach.split("?")[0] &&
                                        discussionAttach.split("?")[0].split(/\.(?=[^\.]+$)/)[1] === "pdf" ? (
                                        <PictureAsPdfIcon style={{ color: "#2e8eec" }} />
                                      ) : discussionAttach &&
                                        discussionAttach.split("?")[0] &&
                                        discussionAttach.split("?")[0].split(/\.(?=[^\.]+$)/)[1] === "ppt" ? (
                                        <PictureInPictureIcon style={{ color: "#2e8eec" }} />
                                      ) : (
                                        <DescriptionIcon style={{ color: "#2e8eec" }} />
                                      )}
                                    </a>
                                  </Tooltip>
                                </div>
                              </React.Fragment>
                            ) : null}
                            <div className={classes.infoItems}>
                              {!showEditor && discussionTitle && (
                                <IconButton
                                  onClick={() => {
                                    setShowEditor(true);
                                    setShowReplyEditor(false);
                                    setValidationError({
                                      error: false,
                                      errorMessage: "",
                                    });
                                  }}
                                  edge="end"
                                  style={{ borderRadius: "5px", border: "1px solid #999", padding: "5px", marginTop: '5px' }}
                                  aria-label="delete"
                                >
                                  <AddCommentIcon className={classes.commentIcon} />
                                  <div style={{ fontSize: "10px" }}>Post a Response</div>
                                </IconButton>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.descWrapper} dangerouslySetInnerHTML={{ __html: discussionDesc }}></div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Hidden only={['xs', 'sm',]}>
              <Grid item lg={1}>

              </Grid>
            </Hidden>
          </Grid>

          {showEditor && (
            <React.Fragment>
              <Grid container className={classes.editorCtr}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                    {validationError.error ? (
                      <div style={{ marginBottom: "20px" }}>
                        <AlertMessages status="error" message={validationError.errorMessage} />
                      </div>
                    ) : null}
                  </Grid>
                  <TextEditor onChangeEditorContent={changeEditorContent} helperText={"Add your response here!"} description={responseContent} />
                </Grid>
              </Grid>
              {/* <Grid container style={{ marginTop: '-33px' }}>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} style={{ textAlign: 'left' }}>
                  {attachmentFileDetails.fileError ? (
                    <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                      {attachmentFileDetails.fileErrorErrorMessage}
                    </Typography>
                  ) : null}
                  <div style={{ display: 'flex', marginTop: '11px' }}>
                    <span className={classes.uploadLabel} aria-hidden="true">
                      Upload Attachment
                    </span>
                    <IconButton style={{ padding: '2px 8px' }} aria-label="upload attachment" onClick={() => { onFileToUploadClick('attachmentfile') }}>
                      <CloudUploadIcon className={classes.uploadIcon} />
                    </IconButton>
                    {attachmentFileDetails.file && attachmentFileDetails.file.name && uploadedAttachmentPreview ? (
                      <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                        {attachmentFileDetails.file.name.length > 25 ? (
                          attachmentFileDetails.file.name.substr(0, 20) + '....'
                        ) :
                          attachmentFileDetails.file.name
                        }
                      </a>
                    ) : null}
                  </div>
                  <div style={{ display: 'flex', marginTop: '5px' }}>
                    <span style={{ color: '#999', fontSize: '14px' }}>Optional, max.size: 10MB, format: pdf, docx, ppt</span>
                  </div>
                </Grid>
                <input type="file" accept=".ppt,.pdf,.docx" style={{ display: 'none' }} id="attachmentfile" onChange={onAttachmentUpload} />
              </Grid> */}
              <div className={classes.btnCtr}>
                <Button
                  disabled={respBtnLdr}
                  onClick={() => {
                    postResponseData();
                  }}
                  size="small"
                  variant="outlined"
                  className={classes.btn}
                >
                  {respBtnLdr && <CircularProgress size={20} />} Post
                </Button>
                <Button
                  onClick={() => {
                    setShowEditor(false);
                  }}
                  size="small"
                  variant="outlined"
                  className={classes.btn}
                >
                  Cancel
                </Button>
              </div>
            </React.Fragment>
          )}
          {pinnedResponses && pinnedResponses.length ? (
            <Grid container style={{ justifyContent: "center" }}>
              <Grid item sm={12} xs={12} md={10} lg={10}>
                <Paper evlevation={2} className={classes.paperHeader}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className={classes.responseText}>Pinned Responses</div>
                  </div>
                  <List className={classes.pinnedList}>
                    {pinnedResponses && pinnedResponses.length > 0 ? (
                      <React.Fragment>{renderPinnedResponses()}</React.Fragment>
                    ) : (
                      <div style={{ marginLeft: "3rem" }}>No Responses Found.</div>
                    )}
                  </List>
                </Paper>
              </Grid>
            </Grid>
          ) : null}
          <Grid container style={{ justifyContent: "center", marginTop: "1rem" }}>
            <Grid item sm={12} xs={12} md={10} lg={10}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.responseText}>Responses</div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: style.fontColour._blue,
                    margin: "1rem 2rem",
                  }}
                >
                  Total {totalResponseCount} Responses
                </div>
              </div>
              <List className={classes.root}>
                {responsesLoader ? <ResponsesLoader /> : (
                  <React.Fragment>
                    {responses && responses.length ? (
                      <React.Fragment>
                        {responses && responses.length > 0 && renderResponses()}
                        <div className={classes.LoadBtnCtr}>
                          {responses && responses.length >= totalResponseCount ? (
                            <div>Yay, You Have Read It All.</div>
                          ) : (
                            <Button
                              onClick={() => {
                                fetchDiscussionResponse();
                              }}
                              disabled={responsesLoader}
                              variant="outlined"
                              size="small"
                              color="primary"
                              className={classes.btn}
                            >
                              Load More...
                            </Button>
                          )}
                        </div>
                      </React.Fragment>
                    ) : previewReply === true ? (
                      <div style={{ textAlign: 'center', fontSize: '17px' }}>You Cannot View other's response before adding Yours.</div>
                    ) : (
                      <div style={{ textAlign: 'center', fontSize: '17px' }}>Responses not yet added!</div>
                    )}
                  </React.Fragment>
                )}
              </List>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { showSnackBar })(Discussion);
