import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Route } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Link from '@material-ui/core/Link';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
//local imports
import Header from "../Header";
import { noticeBoard_msgs } from "../../Components/Common/messages";
import { style } from '../../Styles/theme';
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { fetchMyNotices, updateViewNoticeStatus } from "../../Store/NoticeBoard/actionCreator";
import Pagination from "./pagination";
import CalenderSiderLoader from '../Shared/SkeletonLoaders/calenderSiderLoader';
import SentToInfoViewPopup from './sentToInfoViewPopup';

const useStyles = makeStyles((theme) => ({
  eventItem: {
    marginLeft: "0.5rem",
  },
  siderCntr: {
    height: "32rem",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  dateCtr: {
    margin: "0.2rem 0.5rem 0 0",
    display: "flex",
  },
  timeCtr: {
    margin: "0 0.5rem 0.2rem 0.5rem",
    display: "flex",
  },
  timeRecCtr: {
    margin: "0 0.5rem 0.2rem 0",
  },
  dateVal: {
    textAlign: "center",
    color: "#11539f",
    fontSize: "0.8rem",
  },
  eventsTitle: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    padding: "1rem 0 1rem 1rem",
    backgroundColor: "#eaf2f2",
    color: "#11539f",
  },
  itemTitle: {
    textTransform: 'none',
    fontSize: "1rem",
    color: "#11539f",
    marginTop: "0.3rem",
    textAlign: "initial",
  },
  timings: {
    fontSize: "0.8rem",
    color: "#11539f",
    textTransform: "uppercase",
  },
  recLabel: {
    textAlign: "initial",
    fontSize: "0.8rem",
    color: "#424548",
  },
  recHostLabel: {
    fontSize: "0.8rem",
    color: '#2e8eec',
    fontWeight: 500
  },
  container: {
    textAlign: "initial",
    margin: "1rem 0 1rem 0",
  },
  mtitle: {
    textAlign: "initial",
    margin: "0.7rem 0 0 1rem",
    fontSize: "1rem",
    padding: "0.5rem",
  },
  detailsHeader: {
    backgroundColor: "#eaf2f2",
    borderRadius: "5px",
  },
  label: {
    color: "#11539f",
    fontWeight: 500,
  },
  noDataWrapper: {
    color: "#11539f",
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center'
  },
  value: {
    fontWeight: 400,
    color: "#000",
    marginLeft: "0.3rem",
  },
  itemBlock: {
    cursor: "pointer",
    '&.selected': {
      backgroundColor: '#eaf2f2'
    }
  },
  event: {
    margin: "15px",
    color: "#11539f",
    cursor: "pointer",
  },
  container: {
    textAlign: "initial",
    margin: "1rem 0 1rem 0",
  },
  flex: {
    display: "flex",
  },
  attachmentWrapper: {
    paddingBottom: '20px',
    textAlign: 'left',
    marginLeft: '15px'
  },
  clearFilterWrap: {
    fontSize: '13px',
    color: '#2e8eec',
    marginLeft: '7px',
    marginBottom: '5px'
  },
  searchBox: {
    margin: 5,
    marginBottom: '10px',
    width: '290px'
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  searchIcon: {
    color: '#2e8eec',
    fontSize: '34px',
  },
  input: {
    '&::placeholder': {
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: 500
    }
  },
  sentToWrapper: {
    marginLeft: '5px',
    fontSize: '13px'
  }
}));

function NoticeBoard(props) {
  const classes = useStyles();
  const [noticeListLoader, setNoticeListLoader] = useState(true)
  const [noticeList, setNoticeList] = useState([])
  const [totalCount, setTotalCount] = React.useState(0)
  const rowsPerPage = 10
  const offset = 0
  const [page, setPage] = React.useState(0);
  const [renderNotice, setRenderNotice] = React.useState(false);
  const [selectedNotice, setSelectedNotice] = React.useState({
    created_by: {}
  });
  const [filterSearchTerm, setFilterSearchTerm] = React.useState('')
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [sentToInfoPopup, setSetToInfoPopup] = React.useState({
    showPopup: false,
    selectedNoticeData: {}
  })

  const fetchMyNoticesSuccessCallBack = res => {
    setTotalCount(res && res.count)
    setNoticeList(res && res.notices && res.notices)
    setNoticeListLoader(false)
    if (res.count > 0) {
      setRenderNotice(true)
      showNotice(res.notices[0])
    } else {
      setRenderNotice(false)
    }
  }
  const fetchMyNoticesfailureCallBack = err => {
    setNoticeList([])
    setNoticeListLoader(false)
    props.showSnackBar(
      {
        state: false,
        message: noticeBoard_msgs.ERROR_FETCHING_NOTICE,
        type: 'error'
      }
    )
  }

  const fetchNoticesList = () => {
    setNoticeListLoader(true)
    const payload = {
      offset: offset,
      number: 10,
      status: 'active',
      view: 'student',
    }
    fetchMyNotices(payload, fetchMyNoticesSuccessCallBack, fetchMyNoticesfailureCallBack)
  }

  useEffect(() => {
    fetchNoticesList();
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, []);

  const onPagination = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    setNoticeListLoader(true)
    const payload = {
      offset: offset,
      number: number,
      view: 'student',
    }
    fetchMyNotices(payload, fetchMyNoticesSuccessCallBack, fetchMyNoticesfailureCallBack)
  }
  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Notice Board</div>
        </Breadcrumbs>
      </Route>
    );
  };

  const showNotice = notice => {
    document.getElementById('viewnotice') && document.getElementById('viewnotice').focus()
    setRenderNotice(true)
    setSelectedNotice(notice)
    updateViewNoticeStatus({
      notice_id: notice.id,
      is_read: true
    }, () => {
      document.getElementById('notice_title_' + notice.id).style.fontWeight = 'normal'
    }, () => { })

  }
  const createMarkup = data => {
    return {
      __html: data
    };
  }

  const handleSearchNotice = event => {
    setFilterSearchTerm(event.target.value)
  }

  const onNoticeSearch = evnt => {
    evnt.preventDefault();
    const searchTerm = document.getElementById('search Notice')
    if (filterSearchTerm === '' || !filterSearchTerm.length) {
      searchTerm.focus()
    } else {
      setNoticeListLoader(true)
      setFilterApplied(true)
      const payload = {
        offset: offset,
        number: 10,
        view: 'student',
        sent_by_filter: filterSearchTerm
      }
      fetchMyNotices(payload, fetchMyNoticesSuccessCallBack, fetchMyNoticesfailureCallBack)
    }
  }

  //FOR SHOWING THE NOTICE SENT TO INFORMATION
  const onSentToViewInfoClick = (data) => {
    setSetToInfoPopup({
      showPopup: true,
      selectedNoticeData: data
    })
  }
  const closeSentToViewInfoPopup = () => {
    setSetToInfoPopup({
      showPopup: false,
      selectedNoticeData: {}
    })
  }

  const getSentToDetails = (item) => {
    if (item && item.sent_to && item.sent_to.user_type && Object.keys(item.sent_to.user_type) && Object.keys(item.sent_to.user_type).length) {
      if (Object.values(item.sent_to.user_type).filter(value => value === true).length === 2) {
        var res = Object.keys(item.sent_to.user_type).filter(function (value) {
          return item.sent_to.user_type[value] === true;
        })
        if (res.includes('all_students') && res.includes('all_mentors')) {
          return ' All Mentors & Students'
        } else if (res.includes('all_students') && res.includes('all_instructors')) {
          return ' All Instrucotrs & Students'
        } else {
          return ' All Instrucotrs & Mentors'
        }
      } else if (Object.values(item.sent_to.user_type).filter(value => value === true).length === 3) {
        return ' All Instructors, Mentors & Students'
      } else if (item.sent_to.user_type.individual) {
        return ' Individuals'
      } else {
        if (item.sent_to.user_type.everyone) {
          return ' Everyone'
        } else if (item.sent_to.user_type.all_mentors) {
          return ' All Mentors'
        } else if (item.sent_to.user_type.all_students) {
          return ' All Students'
        } else if (item.sent_to.user_type.all_instructors) {
          return ' All Instructors'
        } else {
          return (
            <React.Fragment>
              <Link
                className={classes.sentToWrapper}
                component="button"
                variant="body2"
                onClick={() => {
                  onSentToViewInfoClick(item);
                }}
              >
                View info
              </Link>
            </React.Fragment>
          )
        }
      }
    }
  }

  //ON CLEARING THE SEARCH FILTER
  const clearSearchFilter = () => {
    if (filterApplied && filterSearchTerm) {
      fetchNoticesList()
      setPage(0)
      setFilterSearchTerm('')
      setFilterApplied(false)
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <div className={classes.root}>
          <Grid container>
            <Header history={props} />
          </Grid>
        </div>
        <div className={classes.breadCrumbcontainer}>
          <Grid container style={{ marginTop: "6rem", paddingLeft: "1rem" }}>
            {SimpleBreadcrumbs()}
          </Grid>
        </div>
        <Grid container spacing={1}>
          <div id="mainH1Tag" tabIndex="-1" className="accessibility">
            <h1>Notice Board</h1>
          </div>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Paper className={classes.siderPaper}>
              <div className={classes.eventsTitle}>My Notices</div>
              {/* <div style={{ textAlign: 'initial' }} onSubmit={onNoticeSearch} >
                <TextField
                  autoComplete="off"
                  id='search Notice'
                  className={classes.searchBox}
                  placeholder={'Find notices (Sent By) typing name here'}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                      notchedOutline: classes.searchBoxOutline
                    }
                  }}
                  variant="outlined"
                  onChange={handleSearchNotice}
                  defaultValue={filterSearchTerm}
                />
                <IconButton onClick={(event) => { onNoticeSearch(event) }} size="small" style={{ marginTop: '6px' }}>
                  <SearchIcon className={classes.searchIcon} />
                </IconButton>
                {filterSearchTerm && filterApplied && <div>
                  <Link className={classes.clearFilterWrap} component="button" variant="body2"
                    onClick={() => { clearSearchFilter() }}
                  >
                    Clear Search
                  </Link>
                </div>}
              </div> */}
              {noticeListLoader ? (
                <CalenderSiderLoader />
              ) : (
                <div className={classes.siderCntr}>
                  {noticeList && noticeList.length > 0 ? (
                    noticeList.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={(selectedNotice.id === item.id) ? classes.itemBlock + ' selected' : classes.itemBlock}
                            onClick={() => { showNotice(item); }}
                            id={`notice_${item.id}`}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={10} sm={10} md={10} lg={10}>
                                <div className={classes.eventItem}>
                                  <Link className={classes.itemTitle} href="javascript:void(0)" component="button" onClick={() => { showNotice(item); }} id={`notice_title_${item.id}`} style={{ fontWeight: item.is_read ? 'normal' : 'bold' }}>
                                    {item && item.subject && item.subject.length > 30 ? item.subject.substring(0, 30) + "..." : item && item.subject}
                                  </Link>
                                  <div className={classes.dateCtr}>
                                    <div className={classes.dateVal}>
                                      {new Date().getDate() === new Date(item.created_date).getDate() ? (
                                        <div style={{ color: "green", fontWeight: "600", }}>
                                          Today
                                        </div>
                                      ) : (
                                        ` ${moment(item && item.created_date && item.created_date).format("MMM DD")} ${moment(item && item.created_date && item.created_date).format("ddd")}`
                                      )}
                                    </div>
                                    <div className={classes.timeCtr}>
                                      <div className={classes.timings}>
                                        {moment(item && item.created_date && item.created_date).format("hh:mm a")}
                                      </div>
                                    </div>
                                  </div>
                                  {item && item.modified_date ? (
                                    <React.Fragment>
                                      <div className={classes.recLabel}>
                                        Updated On: {moment(selectedNotice.modified_date).format("DD-MMM-YYYY hh:mm a")}
                                      </div>
                                    </React.Fragment>
                                  ) : null}
                                  <div className={classes.timeRecCtr}>
                                    <div className={classes.recLabel}>
                                      Sent by: {item.created_by.first_name + ' ' + item.created_by.last_name}
                                    </div>
                                  </div>
                                  <div className={classes.timeRecCtr}>
                                    <div className={item && item.is_host ? classes.recHostLabel : classes.recLabel}>
                                      Sent to:
                                      {getSentToDetails(item)}
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <Divider />
                        </React.Fragment>
                      )
                    })) : (
                    <React.Fragment>
                      {!noticeListLoader ? (
                        <div className={classes.noDataWrapper}>No Notices!</div>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              )}
              {totalCount > 10 && !noticeListLoader ? (
                <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} />
              ) : null}
            </Paper>
          </Grid>
          {renderNotice ? (
            <React.Fragment>
              <div id="viewnotice" tabIndex="-1" className="accessibility">
                <h1>View notice</h1>
              </div>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                {noticeListLoader ? (
                  <CalenderSiderLoader type={'viewNotice'} />
                ) : (
                  <React.Fragment>
                    <Paper elevation={1} style={{ marginTop: "2.1rem" }}>
                      <div className={classes.detailsHeader}>
                        <Grid container spacing={1}>
                          <Grid item xs={8} sm={8} md={10} lg={10}>
                            <div className={classes.mtitle}>{selectedNotice.subject}</div>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ marginLeft: "1rem" }}>
                        <div className={classes.container}>
                          {selectedNotice && selectedNotice.modified_date && <div className={classes.flex}>
                            <div className={classes.label}>Last Modified On:</div>
                            <div className={classes.value} style={{ textTransform: "uppercase" }}>
                              {moment(selectedNotice.modified_date).format("DD-MMM-YYYY hh:mm a")}
                            </div>
                          </div>}
                          <div className={classes.flex}>
                            <div className={classes.label}>Sent On:</div>
                            <div className={classes.value} style={{ textTransform: "uppercase" }}>
                              {moment(selectedNotice.created_date).format("DD-MMM-YYYY hh:mm a")}
                            </div>
                          </div>
                          <div className={classes.flex}>
                            <div className={classes.label}>Sent By:</div>
                            <div className={classes.value}>
                              {selectedNotice.created_by.first_name + ' ' + selectedNotice.created_by.last_name}
                            </div>
                          </div>
                        </div>
                        <Divider aria-hidden="true" />
                        <div className={classes.container}>
                          <div className={classes.flex} aria-label="Notice Description" >
                            <div dangerouslySetInnerHTML={createMarkup(selectedNotice.message)} />
                          </div>
                        </div>
                      </div>
                      <div className={classes.attachmentWrapper}>
                        {selectedNotice && selectedNotice.attachment_details && selectedNotice.attachment_details.file_name ? (
                          <React.Fragment>
                            {selectedNotice.attachment_details && selectedNotice.attachment_details.file_name && selectedNotice.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginRight: '5px', fontSize: '19px' }} /> : selectedNotice.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginRight: '5px', fontSize: '19px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginRight: '5px', fontSize: '19px' }} />}
                            <a href={selectedNotice.attachment_details && selectedNotice.attachment_details.file_url && selectedNotice.attachment_details.file_url} target='_blank'>
                              {selectedNotice.attachment_details && selectedNotice.attachment_details.file_name && selectedNotice.attachment_details.file_name && selectedNotice.attachment_details.file_name}
                            </a>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </Paper>
                  </React.Fragment>
                )}
                <a className="skip-main" href="#mainH1Tag">
                  Back to notices
                </a>
              </Grid>
            </React.Fragment>
          ) : null}
          {sentToInfoPopup.showPopup &&
            <SentToInfoViewPopup data={sentToInfoPopup} close={closeSentToViewInfoPopup} history={props.history} match={props.match} />
          }
        </Grid>
      </Container>
    </React.Fragment >
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(NoticeBoard);
