import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const getAssignments = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assignment.assignments}`
    let paramsData = {};
    if (payload.type === 'course') {
        paramsData = {
            course_id: payload.courseId
        }
    } else if (payload.type === 'module') {
        paramsData = {
            module_id: payload.moduleId
        }
    } else if (payload.type === 'lesson') {
        paramsData = {
            lesson_id: payload.lessonId
        }
    }
    axios.get(
        url, {
        params: paramsData
    }).then(function (response) {
        successCallBack(response.data)
    }).catch(function (error) {
        failureCallBack(error)
    })
}

export const getAssignmentData = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assignment.getAssignment}`
    axios.get(
        url, {
        params: {
            assignment_id: payload.assignment_id
        }
    }).then(function (response) {
        successCallBack(response.data)
    })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const getCollectiveAssignments = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assignment.collectiveAssignment}`
    axios.get(url, {
        params: {
            course_id: payload.courseId
        }
    }).then(function (response) {
        successCallBack(response.data)
    })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const submitAssignment = (payload, type, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assignment.submitAssignment}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response.data, type)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}



export const updateAssignment = (payload, submissionId, type, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assignment.updateAssignment}`
    if (submissionId != '') {
        url += `${submissionId}/`
    }
    axios.put(url, payload)
        .then(function (response) {
            successCallBack(response.data, type)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}


export const deleteAttachment = (payload, submissionId, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assignment.deleteAttachment}`
    if (submissionId != '') {
        url += `${submissionId}/`
    }
    axios.patch(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

const mockAssignmentsData = {
    totalCount: 20,
    totalCompleted: 10,
    results: [
        {
            "assignment_id": 29,
            "attachment": "https://cedb-c.s3.amazonaws.com/media/attachment/ab050520/20210107131229981835_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=QMjUTSUolfHJ9iY%2FDTUpmqZT6PE%3D&Expires=1610028801",
            "comment": null,
            "desc": "<p>desc</p>",
            "end_date": "2020-06-03T10:14:49.422000Z",
            "grade_point": null,
            "last_modified": "2020-06-09T07:00:21.074207Z",
            "points": 100,
            "start_date": "2020-06-03T10:14:48.389000Z",
            "status": null,
            "title": "Assignment-1",
            "user_modified_date": null
        },
        {
            "assignment_id": 16,
            "attachment": "",
            "comment": null,
            "desc": "<p>Desc</p>",
            "end_date": "2020-05-26T18:30:00Z",
            "grade_point": null,
            "last_modified": "2020-05-23T10:05:38.718627Z",
            "points": 100,
            "start_date": "2020-05-17T18:30:00Z",
            "status": "draft",
            "title": "Assignment-2",
            "user_modified_date": "2021-01-04T11:58:35.352977Z"
        },
        {
            "assignment_id": 39,
            "attachment": "",
            "comment": "Good",
            "desc": "<p>desc</p>",
            "end_date": "2021-01-10T03:41:07.204000Z",
            "grade_point": 80,
            "last_modified": "2021-01-05T03:41:21.727390Z",
            "points": 100,
            "start_date": "2021-01-05T03:41:06.493000Z",
            "status": "completed",
            "title": "Assignment-3",
            "user_modified_date": "2021-01-05T03:43:53.835293Z"
        },
        {
            "assignment_id": 46,
            "attachment": "",
            "comment": null,
            "desc": "<p>desc</p>",
            "end_date": "2020-06-03T10:14:49.422000Z",
            "grade_point": null,
            "last_modified": "2020-06-09T07:00:21.074207Z",
            "points": 100,
            "start_date": "2020-06-03T10:14:48.389000Z",
            "status": null,
            "title": "Assignment-1",
            "user_modified_date": null
        },
        {
            "assignment_id": 47,
            "attachment": "",
            "comment": null,
            "desc": "<p>Desc</p>",
            "end_date": "2020-05-26T18:30:00Z",
            "grade_point": null,
            "last_modified": "2020-05-23T10:05:38.718627Z",
            "points": 100,
            "start_date": "2020-05-17T18:30:00Z",
            "status": "draft",
            "title": "Draft mode",
            "user_modified_date": "2021-01-04T11:58:35.352977Z"
        },
        {
            "assignment_id": 39,
            "attachment": "",
            "comment": "Good",
            "desc": "<p>desc</p>",
            "end_date": "2021-01-10T03:41:07.204000Z",
            "grade_point": 80,
            "last_modified": "2021-01-05T03:41:21.727390Z",
            "points": 100,
            "start_date": "2021-01-05T03:41:06.493000Z",
            "status": "completed",
            "title": "Assignment-3",
            "user_modified_date": "2021-01-05T03:43:53.835293Z"
        },
        {
            "assignment_id": 27,
            "attachment": "",
            "comment": null,
            "desc": "<p>desc</p>",
            "end_date": "2020-06-03T10:14:49.422000Z",
            "grade_point": null,
            "last_modified": "2020-06-09T07:00:21.074207Z",
            "points": 100,
            "start_date": "2020-06-03T10:14:48.389000Z",
            "status": null,
            "title": "Assignment-1",
            "user_modified_date": null
        },
        {
            "assignment_id": 16,
            "attachment": "",
            "comment": null,
            "desc": "<p>Desc</p>",
            "end_date": "2020-05-26T18:30:00Z",
            "grade_point": null,
            "last_modified": "2020-05-23T10:05:38.718627Z",
            "points": 100,
            "start_date": "2020-05-17T18:30:00Z",
            "status": "draft",
            "title": "Assignment-2",
            "user_modified_date": "2021-01-04T11:58:35.352977Z"
        },
        {
            "assignment_id": 39,
            "attachment": "",
            "comment": "Good",
            "desc": "<p>desc</p>",
            "end_date": "2021-01-10T03:41:07.204000Z",
            "grade_point": 80,
            "last_modified": "2021-01-05T03:41:21.727390Z",
            "points": 100,
            "start_date": "2021-01-05T03:41:06.493000Z",
            "status": "completed",
            "title": "Assignment-3",
            "user_modified_date": "2021-01-05T03:43:53.835293Z"
        },
        {
            "assignment_id": 27,
            "attachment": "",
            "comment": null,
            "desc": "<p>desc</p>",
            "end_date": "2020-06-03T10:14:49.422000Z",
            "grade_point": null,
            "last_modified": "2020-06-09T07:00:21.074207Z",
            "points": 100,
            "start_date": "2020-06-03T10:14:48.389000Z",
            "status": null,
            "title": "Assignment-1",
            "user_modified_date": null
        }
    ]
}
//FOR EXTERNAL ASSIGNMENTS
export const fetchExternalAssignments = (payload, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assignment.fetchExternalAssignments}`
    axios.get(url, {
        params: payload
    })
    .then(function (response){
        successCallBack(response.data)
    })
    .catch(function (error){
        //failureCallBack(error)
        successCallBack(mockAssignmentsData)
    })
}

export const getExternalAssignmentData = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assignment.getExternalAssignment}`
    axios.get(url, {
        params: { assignment_id: payload.assignment_id }
    })
    .then(function (response) {
        successCallBack(response.data)
    })
    .catch(function (error) {
        failureCallBack(error)
    })
}

export const submitExternalAssignment = (payload, type, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assignment.submitExternalAssignment}`
    axios.post(url, payload)
    .then(function (response) {
        successCallBack(response.data, type)
    })
    .catch(function (error) {
        failureCallBack(error)
    })
}



export const updateExternalAssignment = (payload, submissionId, type, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assignment.updateExternalAssignment}`
    if (submissionId != '') {
        url += `${submissionId}/`
    }
    axios.put(url, payload)
    .then(function (response) {
        successCallBack(response.data, type)
    })
    .catch(function (error) {
        failureCallBack(error)
    })
}


export const deleteExternalAttachment = (payload, submissionId, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assignment.deleteExternalAttachment}`
    if (submissionId != '') {
        url += `${submissionId}/`
    }
    axios.patch(url, payload)
    .then(function (response) {
        successCallBack(response.data)
    })
    .catch(function (error) {
        failureCallBack(error)
    })
}

