import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
//LOCAL IMPORTS
import Header from '../Header'
import { style } from "../../Styles/theme";
import { convertUTCTimeZone } from '../../Helpers/basic'
import ListingLoader from '../Shared/SkeletonLoaders/listingLoader'
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { fetchExternalAssessments } from '../../Store/Assessments/actionCreator'
import Pagination from '../ExternalAssignments/pagination'
import AssessmentTest from '../Assessment/assessmentTest'
import AssessmentReport from '../Assessment/report'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  closeWarning: {
    float: "right",
    fontSize: "0.8rem",
  },
  reportContainer: {
    backgroundColor: style.backgroundColor._screen,
  },
  reportIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
  },
  card: {
    marginBottom: "1rem",
  },
  appBar: {
    backgroundColor: "#2e8eec",
  },
  score: {
    color: style.fontColour._blue,
    fontSize: "0.8rem",
    padding: "0.4rem 0 0 0",
    textAlign: "center",
    //display: 'flex'
  },
  breadCrumbcontainer: {
    padding: "5rem 0 1rem 2rem",
    justifyContent: "center",
  },
  report: {
    color: style.fontColour._tory_blue,
    textAlign: "center",
    paddingTop: "1rem",
    cursor: "pointer",
    fontSize: "0.8rem",
    marginTop: "-20px",
    fontFamily: style.fontFamily._appFont,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dialogTitle: {
    flexGrow: 1,
  },
  percentile: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    padding: "0 0 0 0.5rem",
    fontWeight: 600,
  },
  pageContainer: {
    backgroundColor: "#f3f6f7",
  },
  iconButton: {
    color: style.fontColour._green,
    padding: "0.1rem 0 0 0",
    fontSize: "1.4rem",
  },
  icon: {
    color: style.fontColour._green,
    fontSize: "21px",
    marginBottom: '-4px'
  },
  courseTitle: {
    color: style.fontColour._tory_blue,
    backgroundColor: "#fff",
    fontSize: style.fontSize._heading,
    fontFamily: style.fontFamily._appFont,
    padding: "1rem 0 1rem 2rem",
  },
  courseTitleStatus: {
    fontSize: "1.2rem",
    backgroundColor: "#fff",
    color: style.fontColour._tory_blue,
    fontFamily: style.fontFamily._appFont,
    padding: "1.5rem 0 1rem 1.5rem",
  },
  assessmentTitle: {
    color: style.fontColour._blue,
    fontSize: "18px",
    fontFamily: style.fontFamily._appFont,
    padding: "1rem 0 0 2rem",
  },
  subtitleContainer: {
    paddingTop: "1rem",
    display: "flex",
  },
  descContainer: {
    textAlign: "justify",
    justifyContent: "center",
    padding: "1rem 1rem 1rem 2rem",
    fontSize: '14.5px',
    color: '#999',
    fontWeight: 600
  },
  subtitle: {
    color: style.fontColour._blue,
    textAlign: "center",
    paddingLeft: "2rem",
    fontSize: '14.5px',
    fontFamily: style.fontFamily._appFont,
  },
  button: {
    color: style.fontColour._green,
    border: "#6bc685 solid 1px",
    marginTop: '18px'
  },
  container: {
    paddingTop: "2rem",
    width: "96%",
  },
  paper: {
    height: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    paddingTop: "0.5rem",
    paddingBottom: "1rem",
  },
  headerContainer: {
    backgroundColor: "#1c234a",
    borderRadius: "3px",
    marginTop: "1rem",
    paddingTop: "1rem",
  },
  headerBox: {
    display: "flex",
  },
  title: {
    color: "#FFF",
    fontSize: style.fontSize._heading,
    padding: "0 0 1rem 3rem"
  },
  status: {
    padding: "0.5rem 0 1rem 1rem",
    color: "#FFF",
    fontSize: style.fontSize._labels,
  },
  gridContainer: {
    justifyContent: "center",
    marginBottom: '20px',
    marginTop: '10px'
  },
}));

function ExternalAssessments(props) {
  const classes = useStyles();
  const rowsPerPage = 10
  const offset = 0
  const [page, setPage] = useState(0);
  const [tenantId, setTenantId] = useState('')
  const [inProgress, setInProgress] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [completed, setCompleted] = useState(0);
  const [assessmentsData, setAssessmentsData] = useState([])
  const [openAssessmentForm, setOpenAssessmentForm] = useState(false)
  const [selectedAssessment, setSelectedAssessment] = useState({
    id: '',
    title: ''
  })
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [reportProps, setReportProps] = useState();

  const successAssessmentsCB = res => {
    setTotalCount(res && res.totalCount)
    setAssessmentsData(res && res.results && res.results.length && res.results)
    setCompleted(res && res.totalCompleted);
    setInProgress(false)
  }
  const failureAssessmentsCB = error => {
    setInProgress(false)
    setTotalCount(0)
    setCompleted(0)
    setAssessmentsData([])
    props.showSnackBar({
      state: true,
      message: "Something went wrong while fetching assessments!",
      type: "error",
    });
  }

  const fetchAssessments = () => {
    setInProgress(true)
    const payload = {
      offset: offset,
      number: 10
    }
    fetchExternalAssessments(payload, successAssessmentsCB, failureAssessmentsCB)
  }
  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
    fetchAssessments()
  }, []);

  const onPagination = (pageNum) => {
    setInProgress(true)
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    const payload = {
      offset: offset,
      number: number
    }
    fetchExternalAssessments(payload, successAssessmentsCB, failureAssessmentsCB)
  }

  const handleWarningDialogOpen = () => {
    setOpenWarningDialog(true);
  };

  const handleWarningDialogClose = () => {
    setOpenWarningDialog(false);
  };

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Assessments</div>
        </Breadcrumbs>
      </Route>
    );
  }

  const listLoader = () => {
    let children = []
    for (let i = 0; i < 4; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  const openAssessmentTrigger = (testId, testName, ques_count) => {
    if (ques_count > 0) {
      setOpenAssessmentForm(true)
      setSelectedAssessment({
        id: testId,
        title: testName
      })
    } else {
      props.showSnackBar({
        state: true,
        message: "Can't start assessment without questions!",
        type: "error",
      });
    }
  }

  const closeAssessmentForm = () => {
    fetchAssessments()
    setOpenAssessmentForm(false);
  }

  const renderAssessment = () => {
    if (selectedAssessment.id !== "") {
      return (
        <AssessmentTest closeModal={() => { closeAssessmentForm() }} testId={selectedAssessment.id} />
      );
    }
  };

  const viewReport = (test) => {
    setReportOpen(true);
    setReportProps(test);
  };

  const handleReportClose = () => {
    setReportOpen(false);
  };

  const renderReport = () => {
    return <AssessmentReport data={reportProps} />;
  };

  const renderAssessmentsCards = () => {
    let testInfo = [];
    assessmentsData && assessmentsData.length > 0 &&
      assessmentsData.map((test, index) => {
        testInfo.push(
          <ListItem key={index}>
            <Card
              key={index}
              style={{
                backgroundColor: test.status === true ? "#f2f8e6" : "#fff",
                width: "100%",
                marginBottom: "10px",
              }}
              className={classes.card}
            >
              <Grid container>
                <Grid item lg={10} md={10} sm={12} xs={12}>
                  <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={classes.assessmentTitle}>
                        <AssignmentTurnedInIcon className={classes.icon} /> {test && test.title && test.title}
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={classes.subtitleContainer}>
                        {test && test.due_date ? (<div className={classes.subtitle}>Due: {convertUTCTimeZone(test.due_date)}</div>) : ''}
                        <div className={classes.subtitle}>Questions: {test && test.ques_count}</div>
                        <div className={classes.subtitle} style={{ display: "flex" }}>
                          Type:&nbsp;{" "}
                          <div
                            style={{
                              textTransform: "capitalize",
                              color: test.test_type === "practice" ? style.fontColour._blue : style.fontColour._green,
                            }}
                          >
                            {test && test.test_type && test.test_type}
                          </div>
                        </div>
                        <div className={classes.subtitle}>Eligibility: {test && test.percent}%</div>
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.descContainer}>
                    {test && test.desc && test.desc.length > 250 ? (
                      test.desc.substr(0, 200) + '...'
                    ) : (test.desc)}
                  </div>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12} className={classes.buttonContainer}>
                  {!test.status && (
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        openAssessmentTrigger(test.id, test.title, test.ques_count);
                      }}
                      startIcon={<PlayCircleOutlineIcon />}
                    >
                      Start
                    </Button>
                  )}
                  {test.status && (
                    <div className={classes.score}>
                      Your Score <div className={classes.percentile}>{test.score_percent}%</div>{" "}
                    </div>
                  )}
                  {test.status && (
                    <IconButton
                      onClick={() => {
                        viewReport(test);
                      }}
                    >
                      <div className={classes.report}>
                        <AssessmentIcon className={classes.reportIcon} /> View Report{" "}
                      </div>{" "}
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Card>
          </ListItem>
        );
      });
    return testInfo;
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div>
        <Grid container style={{ marginTop: "5rem", paddingLeft: "1rem" }}>
          {SimpleBreadcrumbs()}
        </Grid>
      </div>
      <div className={classes.headerContainer}>
        <div className={classes.headerBox}>
          <Grid container>
            <Grid item sm={12} xs={12} md={8} lg={10} className={classes.titleContainer}>
              <div id="main" tabIndex="-1" className={classes.title} aria-hidden="true" >
                Mandatory - Assessments
              </div>
              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <h1>My Assingnments listing view</h1>
              </div>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={2} className={classes.titleContainer}>
              {assessmentsData && completed > 0 && (
                <div className={classes.status}>
                  Completed {completed} of {totalCount}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      {inProgress ? (
        listLoader()
      ) : (
        <React.Fragment>
          <div className={classes.body}>
            <Container maxWidth={false}>
              <Grid container className={classes.gridContainer}>
                {assessmentsData && assessmentsData.length ? (
                  renderAssessmentsCards()
                ) : (
                  <Typography style={{ textAlign: "center", fontSize: "18px", marginTop: "10px" }}>
                    No Assessments Found
                  </Typography>
                )}
                {totalCount > 10 ? (
                  <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} />
                ) : null}
              </Grid>
            </Container>
            <Dialog fullScreen open={openAssessmentForm} TransitionComponent={Transition}>
              <DialogContent>
                <AppBar position="static" className={classes.appBar}>
                  <Toolbar>
                    <Typography variant="h6" className={classes.dialogTitle}>
                      {selectedAssessment.title}
                    </Typography>
                    <IconButton color="inherit" onClick={handleWarningDialogOpen} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContentText>{openAssessmentForm && renderAssessment()}</DialogContentText>
              </DialogContent>
            </Dialog>
            <Dialog
              open={openWarningDialog}
              onClose={handleWarningDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
                Are you sure?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">If you close or reload, you need to re-take the test again.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleWarningDialogClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    closeAssessmentForm();
                    handleWarningDialogClose();
                  }}
                  color="primary"
                  autoFocus
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={reportOpen}
              onClose={handleReportClose}
              fullWidth={true}
              maxWidth="xl"
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Assessment Report</DialogTitle>
              <DialogContent dividers={true} className={classes.reportContainer}>
                {reportOpen && renderReport()}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleReportClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(ExternalAssessments);