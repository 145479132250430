import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%'
  },
  desktopWrapper: {
    width: '100%',
    paddingLeft: '109px'
  },
  imagWrapper: {
    float: 'left',
    marginRight: '20px'
  },
  txtWrapper: {
    width: '70%'
  }
})
)
export default function SearchListingLoader(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1280px)");
  const mobileView = !matches;
  return(
    <div className={mobileView ? classes.wrapper : classes.desktopWrapper}>
      <div className={classes.txtWrapper}>
        <Skeleton count={3} height="30px" width="100%" color="#ccc" />
      </div>
    </div>
  )
}