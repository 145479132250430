import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SpeedIcon from "@material-ui/icons/Speed";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { loadCSS } from "fg-loadcss";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import ListItem from '@material-ui/core/ListItem'
import List from "@material-ui/core/List";
//LOCAL IMPORTS
import Header from "../Header";
import { style } from "../../Styles/theme";
import { fetchExternalAssignments } from "../../Store/Assignments/actionCreator";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { convertUTCTimeZone } from '../../Helpers/basic'
import ListingLoader from '../Shared/SkeletonLoaders/listingLoader'
import Pagination from './pagination'
import { assignment_msgs } from "../Common/messages";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    fontStyle: "Italic",
    color: "#938d8d",
    padding: "0.5rem",
  },
  itemTitle: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    padding: "0.2rem 0 0.2rem 0 ",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  gradeValue: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._tory_blue,
    padding: "1.2rem 0 0 0",
  },
  gradePoint: {
    fontSize: style.fontSize._description,
    color: style.fontColour._blue,
    padding: "1.5rem 0.2rem 0 0.2rem",
  },
  itemInfo: {
    padding: "0.2rem",
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._tory_blue,
  },
  asIcon: {
    textAlign: "center",
  },
  speedIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.3rem",
    padding: "1.3rem 0.5rem 0.5rem 0",
    marginTop: '3px'
  },
  downloadIcon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0.5rem 0.5rem 0 0",
    marginLeft: "10px",
    marginTop: "4px",
  },
  icon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0.5rem 0.5rem 0 0",
  },
  listContainer: {
    marginTop: "1rem",
    cursor: "pointer",
  },
  startIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
  },
  completedIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
  },
  draftIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.5rem",
  },
  gridContainer: {
    justifyContent: "center",
  },
  headerContainer: {
    backgroundColor: "#1c234a",
    borderRadius: "3px",
    marginTop: "1rem",
    paddingTop: "1rem",
  },
  headerBox: {
    display: "flex",
  },
  title: {
    padding: "0 0 1rem 3rem",
    color: "#FFF",
    fontSize: style.fontSize._heading,
  },
  status: {
    padding: "0.5rem 0 1rem 1rem",
    color: "#FFF",
    fontSize: style.fontSize._labels,
  },
}));

function ExternalAssignments(props){
  const classes = useStyles();
  const rowsPerPage = 10
  const offset = 0
  const [page, setPage] = useState(0);
  const [tenantId, setTenantId] = useState('')
  const [inProgress, setInProgress] = useState(true)
  const [completed, setCompleted] = useState(0);
  const [totalCount, setTotalCount] = useState(0)
  const [assignmentsData, setAssignmentsData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);
  const [popoverContent, setPopoverContent] = useState('');

  const assignmentsSuccessCB = res => {
    setTotalCount(res && res.totalCount)
    setAssignmentsData(res && res.results && res.results.length && res.results)
    setCompleted(res && res.totalCompleted);
    setInProgress(false)
  }
  const assignmentsFailureCB = err => {
    setTotalCount(0)
    setAssignmentsData([])
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: assignment_msgs.ASSIGNMENT_LIST_FETCH_FAILED,
      type: "error",
    });
  }

  const fetchAssignments = () => {
    setInProgress(true)
    const payload = {
      offset: offset,
      number: 10
    }
    fetchExternalAssignments(payload, assignmentsSuccessCB, assignmentsFailureCB )
  }

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
    fetchAssignments()
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, []);

  const onPagination = (pageNum) => {
    setInProgress(true)
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    const payload = {
      offset: offset,
      number: number
    }
    fetchExternalAssignments(payload, assignmentsSuccessCB, assignmentsFailureCB )
  }

  const handlePopoverOpen = (event, brief) => {
    setPopoverContent(brief);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Assignments</div>
        </Breadcrumbs>
      </Route>
    );
  }

  const handleListItemClick = (event, assignmentId, startsOn) => {
    const currDate = new Date();
    const today = moment(currDate).format("YYYY-MM-DD");
    const startDate = moment(startsOn).format("YYYY-MM-DD");
    const status = moment(today).isSameOrAfter(startDate, "day");
    if (status) {
      props.history.push(`/${tenantId}/externalassignments/${assignmentId}/?from=external`);
    } else {
      props.showSnackBar({
        state: true,
        message: "Can't Start Assignment before start date.",
        type: "error",
      });
    }
  }

  const displayStatusIcon = (status, assignmentId, startDate) => {
    switch (status) {
      case "yet-to-start":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Start" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <Icon className="fas fa-arrow-circle-right" className={classes.startIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
      case "draft":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Draft" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <AssignmentLateIcon className={classes.draftIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
      case "completed":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Completed" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <CheckCircleIcon className={classes.completedIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
      default:
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Start" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <Icon className="fas fa-arrow-circle-right" style={style.faIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
    }
  };

  const listLoader = () => {
    let children = []
    for (let i = 0; i < 4; i++) {
     children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader/></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  return(
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: "5rem", paddingLeft: "1rem" }}>
          {SimpleBreadcrumbs()}
        </Grid>
      </div>
      <div className={classes.headerContainer}>
        <div className={classes.headerBox}>
          <Grid container>
            <Grid item sm={12} xs={12} md={8} lg={10} className={classes.titleContainer}>
              <div id="main" tabIndex="-1" className={classes.title} aria-hidden="true" >
                Mandatory - Assignments
              </div>
              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <h1>My Assingnments listing view</h1>
              </div>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={2} className={classes.titleContainer}>
              {assignmentsData && completed > 0 && (
                <div className={classes.status}>
                  Completed {completed} of {totalCount}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      { inProgress ? (
        listLoader()
      ) : (
        <React.Fragment>
          <div className={classes.body}>
            <Container maxWidth={false}>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} sm={12} md={12} lg={10} style={{ marginBottom: '33px' }}>
                  <List>
                    {assignmentsData && assignmentsData.length ? (
                      <React.Fragment>
                        {assignmentsData.length && assignmentsData.map((item, index)=>{
                          return (
                            <React.Fragment key={index}>
                              <ListItem>
                                <Divider />
                                <Grid container className={classes.listContainer}>
                                  <Grid item sm={1} xs={1} md={1} lg={1} className={classes.asIcon}>
                                    <AssignmentIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item sm={6} xs={6} md={7} lg={7} className={classes.itemContainer}>
                                    <div className={classes.itemTitle} 
                                      onClick={(event) => {
                                      handleListItemClick(event, item.assignment_id, item.start_date)}}
                                    >
                                      {item && item.title && item.title}
                                    </div>
                                    <div className={classes.itemInfo}>
                                      { item && item.start_date ? `Start Date: ${convertUTCTimeZone(item.start_date)}` : 'NA'} &nbsp;&nbsp;
                                      {item && item.end_date ? `Due Date: ${convertUTCTimeZone(item.end_date)}` : 'NA'} &nbsp;&nbsp;
                                      {item && `Points: ${item.points}`} &nbsp;&nbsp;
                                      {item && item.status && item.status !="yet-to-start" && item.user_modified_date != null ? 
                                        item.status === "draft" ?
                                          `Last Modified: ${convertUTCTimeZone(item && item.user_modified_date)}` :
                                          `Submitted On: ${convertUTCTimeZone(item && item.user_modified_date)}` : "" }
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} md={2} lg={2} className={classes.itemContainer}>
                                    {item && item.grade_point != null && (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                          <Tooltip title="Your Score" arrow>
                                            <SpeedIcon className={classes.speedIcon} />
                                          </Tooltip>
                                          <div className={classes.gradeValue}>{item && item.grade_point}</div>
                                          <div className={classes.gradePoint}>pts</div>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} xs={2} md={2} lg={2} className={classes.itemContainer}>
                                    <div style={{ display: "flex" }}>
                                      {displayStatusIcon(item && item.status, item && item.assignment_id, item && item.start_date)}
                                      {item && item.attachment && (
                                        <Tooltip title="Download Material" arrow>
                                          <a href={item && item.attachment}>
                                            <CloudDownloadIcon className={classes.downloadIcon} />
                                          </a>
                                        </Tooltip>
                                      )}
                                      {item && item.comment && (
                                        <IconButton
                                          aria-owns={anchorOpen ? "mouse-over-popover" : undefined}
                                          aria-haspopup="true"
                                          onMouseEnter={(e) => {
                                            handlePopoverOpen(e, item.comment);
                                          }}
                                          onMouseLeave={handlePopoverClose}
                                          edge="end"
                                          aria-label="remarks"
                                        >
                                          <Icon className="fas fa-comment-dots" style={style.faIcon}></Icon>
                                        </IconButton>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          )
                        })}
                      </React.Fragment>
                    ) : (
                      <Typography style={{textAlign: "center", fontSize: "18px", marginTop: "10px"}}>
                        No Assignments Found
                      </Typography>
                    ) }
                  </List>
                  { totalCount > 10 ? (
                    <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount}/>
                  ) : null }
                </Grid>
              </Grid>
            </Container>
          </div>
        </React.Fragment>
      ) }
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={anchorOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popoverContent}>" {popoverContent} "</div>
      </Popover>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(ExternalAssignments);