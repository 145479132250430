import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import PageHeaderLogo from "../../Static/myAthinaLogo.png";
import MyAthinaLogoSVG from '../../Static/myAthina_Desktop.svg';
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PeopleIcon from "@material-ui/icons/People";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EventIcon from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import { colorStyles, style } from "../../Styles/theme";
import vtuLogo from '../../Static/vtu_logo.png';
import { getLoggedInStatus, getDomainInfo, redirectTo, getUserDetails, fetchSignedUserInfo, openFreshworksWidget } from "../../Helpers/basic";
import { fetchHeaderNoticeCount } from "../../Store/NoticeBoard/actionCreator";
import PrepTrainingLogo from './prepTraining.svg';

export default function Header(props) {
  const classes = useStyles();
  const showCommunityMyVideosMenu = false
  const matches = useMediaQuery("(max-width:600px)");
  const loggedIn = getLoggedInStatus();
  const domainInfo = getDomainInfo()
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tenantId, setTenantId] = useState(props.history.match.params.tenantId);
  const [avatarContent, setAvatarContent] = useState();
  const [userName, setUserName] = useState("User");
  const [badgeCount, setBadgeCount] = useState(0);
  const [noticeCountInprogress, setNoticeCountInprogress] = useState(true);

  let clientImg = "";
  if (domainInfo.logo) {
    clientImg = domainInfo.logo;
  }
  const fetchHeaderNoticeCountSuccessCallBack = res => {
    setBadgeCount(res.unread)
    setNoticeCountInprogress(false)
  }
  const fetchHeaderNoticeCountFailureCallBack = error => {
    setBadgeCount(0)
    setNoticeCountInprogress(false)
  }
  useEffect(() => {
    let userInfo = getUserDetails();
    let avatar = "";
    if (userInfo.user) {
      if (userInfo.user.first_name !== null && userInfo.user.last_name !== null) {
        avatar = userInfo.user.first_name.substring(0, 1) + userInfo.user.last_name.substring(0, 1);
      } else if (userInfo.user.first_name !== null) {
        avatar = userInfo.user.first_name.substring(0, 1);
      }
      setUserName(userInfo.user.first_name);
    } else if (userInfo.firstName) {
      if (userInfo.firstName !== null && userInfo.lastName !== null) {
        avatar = userInfo.firstName.substring(0, 1) + userInfo.lastName.substring(0, 1);
      } else if (userInfo.firstName !== null) {
        avatar = userInfo.firstName.substring(0, 1);
      }
      setUserName(userInfo.firstName);
    } else if (userInfo.first_name) {
      if (userInfo.first_name !== null && userInfo.last_name !== null) {
        avatar = userInfo.first_name.substring(0, 1) + userInfo.last_name.substring(0, 1);
      } else if (userInfo.first_name !== null) {
        avatar = userInfo.first_name.substring(0, 1);
      }
      setUserName(userInfo.first_name);

      if (window.FreshworksWidget) {
        window.FreshworksWidget('hide')
      }
    }

    setAvatarContent(avatar);
    setTenantId(props.history.match.params.tenantId);
    setNoticeCountInprogress(true)
    fetchHeaderNoticeCount({}, fetchHeaderNoticeCountSuccessCallBack, fetchHeaderNoticeCountFailureCallBack)
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const doLogout = () => {
    const cookieDetails = getDomainInfo();
    document.cookie = cookieDetails.domainTokenLabel + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" + cookieDetails.domainName;
    document.cookie = cookieDetails.domainUserLabel + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" + cookieDetails.domainName;
    if (cookieDetails.type === "b2c") {
      window.location.reload();
    } else {
      redirectTo(cookieDetails.hostName, false);
    }
  };

  //for mycourses listing
  const onProfileClick = () => {
    return redirectTo(`/${tenantId}/profile`, false);
  };

  //for user profile
  const onUserProfileClick = () => {
    return redirectTo(`/${tenantId}/userprofile`, false);
  }

  const onEventsClick = () => {
    return redirectTo(`/${tenantId}/meetings`, false);
  };

  const onStudentCommunityClick = () => {
    return redirectTo(`/${tenantId}/community`, false);
  };

  const onMyVideosClick = () => {
    return redirectTo(`/${tenantId}/myvideos`, false)
  }

  const onDiscussionsClick = () => {
    return redirectTo(`/${tenantId}/externaldiscussions`, false)
  }

  const onMyClassesClick = () => {
    return redirectTo(`/${tenantId}/myclasses`, false)
  }
  const onNoticeBoardClick = () => {
    return redirectTo(`/${tenantId}/noticeboard`, false)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const popOverOpen = Boolean(anchorEl);
  const id = popOverOpen ? "simple-popover" : undefined;

  const getTenantLogo = () => {
    const name = getDomainInfo().name;
    let userInfo = getUserDetails();
    let userDept = userInfo.department && Object.keys(userInfo.department).length > 0 && userInfo.department.id;
    switch (name) {
      case 'abcuniversity':
        return (
          <div style={{ margin: "0.2rem" }}>
            <img src={clientImg} style={{ height: "60px", width: "90px", marginRight: "15px" }} alt={`${name} logo`} />
          </div>
        )
      case 'zopsmart':
        return (
          <div style={{ margin: "0.3rem" }}>
            <img src={clientImg} style={{ height: "40px", width: "120px", marginTop: "5px", marginRight: "15px", backgroundColor: "#000" }} alt={`${name} logo`} />
          </div>
        )
      case 'falabella':
        return (
          <div>
            <img src={clientImg} style={{ width: "123px", marginLeft: "5px", marginRight: "15px", marginTop: '16px' }} alt={`${name} logo`} />
          </div>
        )
      case 'goniyo':
        return (
          <div>
            <img src={clientImg} style={{ width: "50px", marginLeft: "37px", marginRight: "15px", marginTop: '22px' }} alt={`${name} logo`} />
          </div>
        )
      case 'sandboxacademy':
      case 'continualengine':
      case 'sandbox':
      case 'demo':
        return (
          <div>
            <img src={clientImg} style={{ width: "100px", margin: "5px 10px" }} alt={`${name} logo`} />
          </div>
        )
      case 'prep':
        if (userDept == 4) {
          return (<img src={vtuLogo} style={{ width: 128, margin: '15px 15px 0 5px' }} alt={`${name} logo`} />)
        } else {
          return (<img src={PrepTrainingLogo} style={{ height: "56px", width: "130px", marginTop: 8, marginRight: "10px", marginLeft: "4px" }} alt={`${name} logo`} />)
        }
      case 'rmbl':
        return (
          <img src={clientImg} className={matches ? classes.rmblLogoMob : classes.rmblLogo} alt={`${name} logo`} />
        )
      case 'freo':
        return (
          <img src={clientImg} style={{ width: 90, marginLeft: 15 }} alt={`${name} logo`} />
        )
      case 'ansrsource':
        return (
          <img src={clientImg} style={{ height: "35px", width: "130px", margin: "21px 11px 0px 5px" }} alt={`${name} logo`} />
        )
      default:
        return (
          <div style={{ margin: "0.3rem" }}>
            <img src={clientImg} style={{ height: "40px", width: "130px", marginTop: '10px', marginRight: "5px", marginLeft: "2px" }} alt={`${name} logo`} />
          </div>
        )

    }
  }

  const externalAssessmetsClick = type => {
    if (type === 'assignments') {
      redirectTo(`/${tenantId}/externalassignments`)
    } else {
      redirectTo(`/${tenantId}/externalassessments`)
    }
  }

  const renderBadgeContent = () => {
    return badgeCount > 0 ? (
      <div className={badgeCount > 10 ? classes.badgeValueUp : classes.badgeValue}>{badgeCount}</div>
    ) : null
  }

  //for freshdesk help
  const openFeedBack = (type) => {
    openFreshworksWidget(type)
    setAnchorEl(null);

  }

  const showExternalAssessments = domainInfo.showExternalAssessments
  return (
    <div style={style.root}>
      <AppBar className={classes.appBar} style={{ background: colorStyles.headerBg }}>
        <a className="skip-main" href="#main">
          Skip to main content
        </a>
        {domainInfo.type == "b2b" ? (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={3} md={3} lg={1}>
                {clientImg ? getTenantLogo() : null}
              </Grid>
              {getDomainInfo().name !== 'prep' ? (
                <Grid item xs={5} sm={6} md={3} lg={3}>
                  {getDomainInfo().name === 'falabella' ? (
                    <div style={{ marginTop: "1.0rem", marginLeft: '20px' }}>
                      <img
                        className={matches ? classes.myAthinaLogoMob : classes.myAthinaLogo}
                        src={MyAthinaLogoSVG}
                        onClick={() => {
                          redirectTo(domainInfo.hostName, true);
                        }}
                        alt="Myathina Logo"
                      />
                    </div>
                  ) : (
                    <React.Fragment>
                      {getDomainInfo().name === 'goniyo' ? (
                        <div style={{ marginTop: "1.0rem", marginLeft: '-25px' }}>
                          <img
                            className={matches ? classes.myAthinaLogoMob : classes.myAthinaLogo}
                            src={MyAthinaLogoSVG}
                            onClick={() => {
                              redirectTo(domainInfo.hostName, true);
                            }}
                            alt="Myathina Logo"
                          />
                        </div>
                      ) : (
                        <React.Fragment>
                          {getDomainInfo().name === 'ansrsource' ? (
                            <div style={{ marginTop: "0.3rem", marginLeft: '30px' }}>
                              <img
                                className={matches ? classes.myAthinaLogoMob : classes.myAthinaLogo}
                                src={MyAthinaLogoSVG}
                                onClick={() => {
                                  redirectTo(domainInfo.hostName, true);
                                }}
                                alt="Myathina Logo"
                              />
                            </div>
                          ) : (
                            <div style={{ margin: "0.3rem", marginLeft: '20px' }}>
                              <img
                                className={matches ? classes.myAthinaLogoMob : classes.myAthinaLogo}
                                src={MyAthinaLogoSVG}
                                onClick={() => {
                                  redirectTo(domainInfo.hostName, true);
                                }}
                                alt="Myathina Logo"
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </Grid>
              ) : null}
              <Grid item xs={3} sm={3} md={6} lg={8}>
                <div className={classes.sectionDesktop}>
                  <div style={{ display: "flex" }}>
                    {domainInfo.noticeBoardFeature ? (
                      <React.Fragment>
                        <div className={classes.grow} />
                        <IconButton onClick={onNoticeBoardClick} aria-label={`Notifications${badgeCount}`} component="span">
                          <Tooltip title="Notifications panel">
                            <Badge
                              className={classes.badgeCount}
                              badgeContent={renderBadgeContent()}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <NotificationsIcon className={classes.noticeIcon} />
                            </Badge>
                          </Tooltip>
                        </IconButton>
                      </React.Fragment>
                    ) : null}

                    {getDomainInfo().name !== 'prep' && isLoggedIn === true ? (
                      <IconButton aria-label="profile and logout" className={classes.margin} size="small" onClick={handleClick}>
                        <MoreVertIcon style={{ fontSize: "30px", paddingTop: "5px" }} aria-describedby={id} />
                      </IconButton>
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={7} sm={7} md={10} lg={10}>
              {getDomainInfo().name === 'falabella' ? (
                <div style={{ marginTop: "1.0rem", marginLeft: "-104px" }}>
                  <img
                    style={{ width: '7rem' }}
                    src={MyAthinaLogoSVG}
                    onClick={() => {
                      redirectTo(domainInfo.hostName, true);
                    }}
                    alt="Myathina Logo"
                  />
                </div>
              ) : (
                <div style={{ margin: "0.3rem" }}>
                  <img
                    style={style.logoImg}
                    src={MyAthinaLogoSVG}
                    onClick={() => {
                      redirectTo(domainInfo.hostName, true);
                    }}
                    alt="Myathina Logo"
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={5} sm={5} md={2} lg={2}>
              <div className={classes.sectionDesktop}>
                <div style={{ display: "flex" }}>
                  {isLoggedIn === true ? (
                    <React.Fragment>
                      <div style={{ color: style.fontColour._blue, margin: "0.8rem 0.7rem 0.5rem 0rem" }}>Hi {userName}&nbsp;!</div>
                      {avatarContent !== "" && (
                        <Avatar style={{ alignSelf: "end", backgroundColor: "#205f9d", textTransform: "uppercase" }}>
                          {avatarContent !== "" ? avatarContent : ""}
                        </Avatar>
                      )}
                      <IconButton aria-label="profile and logout" className={classes.margin} size="small" onClick={handleClick}>
                        <MoreVertIcon style={{ fontSize: "30px", paddingTop: "5px" }} aria-describedby={id} />
                      </IconButton>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </AppBar>
      <Popover
        id={id}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List aria-label="profile and logout options">
          {isLoggedIn && (
            <ListItem>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  onUserProfileClick();
                }}
              >
                <div style={{ display: "flex" }}>
                  {avatarContent !== "" ? (
                    <Avatar style={{ alignSelf: "end", backgroundColor: "#205f9d", textTransform: "uppercase", width: '27px', height: '27px', marginRight: '7px' }}>
                      {avatarContent !== "" ? avatarContent : ""}
                    </Avatar>
                  ) : <AccountCircleIcon className={classes.poperListIcon} />}
                  <div className={classes.poperListItem}>Hi {userName}&nbsp;!</div>
                </div>
              </IconButton>
            </ListItem>
          )}

          {getDomainInfo().name === 'rmbl' ? (
            <ListItem>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  onNoticeBoardClick();
                }}
              >
                <div style={{ display: "flex" }}>
                  <NotificationsIcon className={classes.poperListIcon} />
                  <div className={classes.poperListItem}>Notice Board</div>
                </div>
              </IconButton>
            </ListItem>
          ) : null}
          <ListItem>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                onProfileClick();
              }}
            >
              <div style={{ display: "flex" }}>
                <MenuBookIcon className={classes.poperListIcon} />
                <div className={classes.poperListItem}>My Courses</div>
              </div>
            </IconButton>
          </ListItem>
          {getDomainInfo().name === 'rmbl' ? (
            <ListItem>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  onMyClassesClick();
                }}
              >
                <div style={{ display: "flex" }}>
                  <VideoLibraryIcon className={classes.poperListIcon} />
                  <div className={classes.poperListItem}>My Classes</div>
                </div>
              </IconButton>
            </ListItem>
          ) : null}
          {getDomainInfo().name === 'sandboxacademy' || getDomainInfo().name === 'rmbl' && (<ListItem>
            <IconButton
              className={classes.iconButton}
              onClick={() => {
                onEventsClick();
              }}
            >
              <div style={{ display: "flex" }}>
                <EventIcon className={classes.poperListIcon} />
                <div className={classes.poperListItem}>My Events</div>
              </div>
            </IconButton>
          </ListItem>)}
          {showExternalAssessments ? (
            <React.Fragment>
              <ListItem>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => { externalAssessmetsClick('assignments') }}
                >
                  <div style={{ display: "flex" }}>
                    <AssignmentIcon className={classes.poperListIcon} />
                    <div className={classes.poperListItem}>My Assignments</div>
                  </div>
                </IconButton>
              </ListItem>
              <ListItem>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => { externalAssessmetsClick('assessments') }}
                >
                  <div style={{ display: "flex" }}>
                    <AssignmentTurnedInIcon className={classes.poperListIcon} />
                    <div className={classes.poperListItem}>My Assessments</div>
                  </div>
                </IconButton>
              </ListItem>
            </React.Fragment>
          ) : null}
          {getDomainInfo().name === 'rmbl' && (
            <ListItem>
              <IconButton
                className={classes.iconButton}
                onClick={() => { onDiscussionsClick() }}
              >
                <div style={{ display: "flex" }}>
                  <QuestionAnswerIcon className={classes.poperListIcon} />
                  <div className={classes.poperListItem}>Discussions</div>
                </div>
              </IconButton>
            </ListItem>
          )}
          {showCommunityMyVideosMenu ? (
            <React.Fragment>
              <ListItem>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    onStudentCommunityClick();
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <PeopleIcon className={classes.poperListIcon} />
                    <div className={classes.poperListItem}>Community</div>
                  </div>
                </IconButton>
              </ListItem>
              <ListItem>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    onMyVideosClick();
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <OndemandVideoIcon className={classes.poperListIcon} />
                    <div className={classes.poperListItem}>Videos</div>
                  </div>
                </IconButton>
              </ListItem>
            </React.Fragment>
          ) : null}
          <ListItem>
            <IconButton
              onClick={() => {
                doLogout();
              }}
              capture
              className={classes.iconButton}
            >
              <React.Fragment>
                <PowerSettingsNewIcon className={classes.poperListIcon} />
                {domainInfo.type === "b2c" ? (
                  <Link className={classes.link} to={`/`}>
                    Logout
                  </Link>
                ) : (
                  <div className={classes.poperListItem}>Logout</div>
                )}
              </React.Fragment>
            </IconButton>
          </ListItem>
        </List>
        {domainInfo.name === "rmbl" ? (
          <div className={classes.pageFooter}>
            <a href="javascript:void(0)" color="inherit" onClick={() => { openFeedBack('feedback') }} style={{ color: 'blue', fontSize: '14.5px', marginBottom: '20px', textDecoration: 'none' }}>
              Feedback
            </a>
          </div>
        ) : null}
      </Popover>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      //zIndex: 1,

    }),

    "box-shadow": "none",
    backgroundColor: "#FFF",
    color: "#3f51b5",
    border: "none",
  },
  root: {
    display: "flex",
  },
  sectionDesktop: {
    float: "right",
    marginRight: "0.5rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  avatar: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  list: {
    marginTop: "4rem",
  },
  iconButton: {
    padding: "5px",
    borderRadius: "5px"
  },
  icon: {
    color: style.fontColour._green,
    fontSize: "1.8rem",
  },
  buttonContainer: {
    paddingRight: "1rem",
  },
  button: {
    marginRight: "1rem",
    backgroundColor: style.button._backgroundColor,
  },
  poperListItem: {
    color: style.fontColour._blue,
    paddingRight: "0.2rem",
    fontSize: "1.0rem",
    marginTop: '5px'
  },
  poperListIcon: {
    color: style.fontColour._blue,
    padding: "0.1rem 0.5rem 0 0",
    fontSize: "1.5rem"
  },
  link: {
    color: style.fontColour._tory_blue,
    textDecoration: "none",
    fontSize: "1.1rem",
    marginLeft: "0.2rem",
  },
  noticeIcon: {
    color: 'blue',
    fontSize: '27px'
  },
  badgeCount: {
    color: '#fff',
    fontSize: '16px'
  },
  badgeValue: {
    backgroundColor: '#e12b02',
    borderRadius: "20px",
    fontWeight: 700,
    color: '#fafafa',
    padding: "3px 5px"
  },
  badgeValueUp: {
    backgroundColor: '#e12b02',
    borderRadius: "20px",
    fontWeight: 700,
    color: '#fafafa',
    padding: "7px 5px"
  },
  rmblLogoMob: {
    width: 60,
    height: 40,
    margin: '8px 5px 0 25px'
  },
  rmblLogo: {
    height: 62,
    margin: '12px 5px 0 25px'
  },
  myAthinaLogo: {
    width: 175,
    height: 60,
    margin: '5px 5px 0 5px'
  },
  myAthinaLogoMob: {
    width: 130,
    height: 40,
    margin: '5px 5px 0 5px'
  },
  pageFooter: {
    marginTop: '6px',
    marginBottom: '8px',
    display: 'inline-block',
    width: '100%',
    textAlign: 'center'
  }
}));
