import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoginForm from './login';
import { getDomainInfo, getLoggedInStatus, redirectTo } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '23px',
    marginLeft: 10
  },
  logoImg: {
    width: '44px',
    marginTop: '25px'
  },
  buttonProgress: {
    marginBottom: '10px',
    marginRight: '10px'
  },
  submit: {
    marginTop: '10px'
  }
}));
function Login(props) {
  const classes = useStyles();
  const [renderLogin, setRenderLogin] = useState(false);

  useEffect(() => {
    const loggedInStatus = getLoggedInStatus();
    const domainDet = getDomainInfo();
    if (loggedInStatus === true) {
      props.history.push(`/mdb-aa050520/profile`)
    } else {
      if (domainDet.type === 'b2c') {
        const url = `${domainDet.hostName}/?doSignIn=true&navigateTo=rendering&tId=mdb-aa050520`;
        redirectTo(url, false);
      }
      else {
        redirectTo(domainDet.hostName, false);
        //setRenderLogin(true)
      }
    }
    window.FreshworksWidget('hide')
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {renderLogin && <LoginForm history={props.history} />}
      </div>
    </Container>
  )
}

export default Login;