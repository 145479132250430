import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ListItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import Link from '@material-ui/core/Link'
//Local Imports
import Header from "../Header";
import Report from "./report";
import AssessmentTest from "./assessmentTest";
import { style } from "../../Styles/theme";
import { getTests } from "../../Store/Assessments/actionCreator";
import AssignmentListLoader from '../Shared/SkeletonLoaders/assignmentListLoader';
import { getDomainInfo } from '../../Helpers/basic';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Assessment(props) {
  const classes = useStyles();
  const [title, setTitle] = useState();
  const [tests, setTests] = useState([]);
  const [courseTitle, setCourseTitle] = useState();
  const [moduleTitle, setModuleTitle] = useState();
  const [courseId, setCourseId] = useState();
  const [lessonTitle, setLessonTitle] = useState();
  const [moduleId, setModuleId] = useState();
  const [open, setOpen] = useState(false);
  const [assessmentId, setAssessmentId] = useState();
  const [assessmentName, setAssessmentName] = useState();
  const [reportOpen, setReportOpen] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [reportProps, setReportProps] = useState();
  const [completed, setCompleted] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    setInProgress(true)
    setTenantId(props.match.params.tenantId);
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    if (paramVal !== undefined && paramVal === "course") {
      fetchCourseTests();
    } else if (paramVal !== undefined && paramVal === "module") {
      fetchModuleTests();
    } else if (paramVal !== undefined && paramVal === "lesson") {
      fetchLessonTests();
    }
  }, []);

  const handleWarningDialogOpen = () => {
    setOpenWarningDialog(true);
  };

  const handleWarningDialogClose = () => {
    setOpenWarningDialog(false);
  };

  const handleReportClose = () => {
    setReportOpen(false);
  };

  const handleClose = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    if (paramVal !== undefined && paramVal === "course") {
      fetchCourseTests();
    } else if (paramVal !== undefined && paramVal === "module") {
      fetchModuleTests();
    } else if (paramVal !== undefined && paramVal === "lesson") {
    }
    setOpen(false);
  };

  const fetchCourseTests = () => {
    let payload = {
      id: props.match.params.id,
      type: "course",
    };
    setInProgress(true)
    getTests(payload, successCallBack, failureCallBack);
  };

  const fetchModuleTests = () => {
    let payload = {
      id: props.match.params.id,
      type: "module",
    };
    setInProgress(true)
    getTests(payload, successCallBack, failureCallBack);
  };

  const fetchLessonTests = () => {
    let payload = {
      id: props.match.params.id,
      type: "lesson",
    };
    setInProgress(true)
    getTests(payload, successCallBack, failureCallBack);
  };

  const successCallBack = (res) => {
    setTests(res.results);
    setCompleted(res.results.length > 0 && res.results.filter(({ status }) => status === true).length);
    if (res.course_title != "" && res.course_title != undefined) {
      setCourseTitle(res.course_title);
    }
    if (res.course_id != "" && res.course_id != undefined) {
      setCourseId(res.course_id);
    }
    if (res.module_title != "" && res.module_title != undefined) {
      setModuleTitle(res.module_title);
    }
    if (res.module_id != "" && res.module_id != undefined) {
      setModuleId(res.module_id);
    }
    if (res.lesson_title != "" && res.lesson_title != undefined) {
      setLessonTitle(res.lesson_title);
    }
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    let title = paramVal === "course" ? res.course_title : paramVal === "module" ? res.module_title : res.lesson_title;
    setTitle(title);
    setInProgress(false);
  };

  const failureCallBack = (error) => {
    setInProgress(false);
  };

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    if (paramVal === "module") {
      if (courseTitle !== undefined && courseId !== undefined && moduleTitle !== undefined) {
        return (
          <Route>
            <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
              {getDomainInfo().name !== 'prep' ? (
                <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                  Home
                </RouterLink>
              ) : (
                <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                  Home
                </Link>
              )}
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${courseId}`}>
                {courseTitle && courseTitle}
              </RouterLink>
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/module/${courseId}/${props.match.params.id}`}>
                {moduleTitle && moduleTitle}
              </RouterLink>
              <div style={style.brdCrmbSecondary}>Assessments</div>
            </Breadcrumbs>
          </Route>
        );
      }
    } else if (paramVal === "course") {
      if (courseTitle !== undefined) {
        return (
          <Route>
            <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbPrimary} fontSize="small" />} aria-label="Breadcrumb">
              {getDomainInfo().name !== 'prep' ? (
                <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                  Home
                </RouterLink>
              ) : (
                <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                  Home
                </Link>
              )}
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${props.match.params.id}`}>
                {courseTitle && courseTitle}
              </RouterLink>
              <div style={style.brdCrmbSecondary}>Assessments</div>
            </Breadcrumbs>
          </Route>
        );
      }
    } else if (paramVal === "lesson") {
      if (courseTitle !== undefined && courseId !== undefined && moduleTitle !== undefined && moduleId !== undefined && lessonTitle !== undefined) {
        return (
          <Route>
            <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
              {getDomainInfo().name !== 'prep' ? (
                <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                  Home
                </RouterLink>
              ) : (
                <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                  Home
                </Link>
              )}
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${courseId}`}>
                {courseTitle && courseTitle}
              </RouterLink>
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/module/${courseId}/${moduleId}`}>
                {moduleTitle && moduleTitle}
              </RouterLink>
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/lesson/${courseId}/${moduleId}/${props.match.params.id}`}>
                {lessonTitle && lessonTitle}
              </RouterLink>
              <div style={style.brdCrmbSecondary}>Assessments</div>
            </Breadcrumbs>
          </Route>
        );
      }
    }
  };

  const viewReport = (test) => {
    setReportOpen(true);
    setReportProps(test);
  };

  const renderReport = () => {
    return <Report data={reportProps} />;
  };

  const renderModal = (testId, testName) => {
    setOpen(true);
    setAssessmentId(testId);
    setAssessmentName(testName);
  };

  const renderAssessment = () => {
    if (assessmentId !== "") {
      return (
        <AssessmentTest
          closeModal={() => {
            handleClose();
          }}
          testId={assessmentId}
        />
      );
    }
  };

  const renderTestCards = () => {
    let testInfo = [];
    tests.length > 0 &&
      tests.map((test, index) => {
        testInfo.push(
          <ListItem key={index}>
            <Card
              style={{
                backgroundColor: test.status === true ? "#f2f8e6" : "#fff",
                width: "100%",
                marginBottom: "10px",
              }}
              className={classes.card}
            >
              <Grid container>
                <Grid item lg={10} md={10} sm={12} xs={12}>
                  <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={classes.assessmentTitle}>
                        {index + 1}. {test.title}
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={classes.subtitleContainer}>
                        <div className={classes.subtitle}>Questions: {test.ques_count}</div>
                        <div className={classes.subtitle} style={{ display: "flex" }}>
                          Type:&nbsp;{" "}
                          <div
                            style={{
                              textTransform: "capitalize",
                              color: test.test_type === "practice" ? style.fontColour._blue : style.fontColour._green,
                            }}
                          >
                            {test.test_type}
                          </div>
                        </div>
                        {test.percent ? <div className={classes.subtitle}>Eligibility: {test.percent}%</div> : null}
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.descContainer}>{test.desc}</div>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12} className={classes.buttonContainer}>
                  {!test.status && (
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        renderModal(test.id, test.title);
                      }}
                      startIcon={<PlayCircleOutlineIcon />}
                    >
                      Start
                    </Button>
                  )}
                  {test.status && (
                    <div className={classes.score}>
                      Your Score <div className={classes.percentile}>{test.score_percent}%</div>{" "}
                    </div>
                  )}
                  {test.status && (
                    <IconButton
                      onClick={() => {
                        viewReport(test);
                      }}
                    >
                      <div className={classes.report}>
                        <AssessmentIcon className={classes.reportIcon} /> View Report{" "}
                      </div>{" "}
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Card>
          </ListItem>
        );
      });
    return testInfo;
  };

  return (
    <div className={classes.pageContainer}>
      <Header history={props} />
      <div className={classes.breadCrumbcontainer}>{SimpleBreadcrumbs()}</div>
      <Container className={classes.container} maxWidth={false}>
        <Grid container className={classes.titleContainer}>
          <Grid item lg={9} md={8} sm={12} xs={12}>
            <div id="main" tabIndex="-1" className={classes.courseTitle}>
              {title} - Assessment
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12} style={{ backgroundColor: "#fff" }}>
            {tests.length > 0 && (
              <div className={classes.courseTitleStatus}>
                Completed {completed} Out of {tests.length}{" "}
              </div>
            )}
          </Grid>
        </Grid>
        {inProgress ? (
          <AssignmentListLoader />
        ) : (
          <List>
            {tests.length > 0 ? renderTestCards() : <Typography style={{ textAlign: "center", fontSize: "18px" }}>No Assessments Found</Typography>}
          </List>
        )}
      </Container>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <DialogContent>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {assessmentName}
              </Typography>
              <IconButton color="inherit" onClick={handleWarningDialogOpen} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContentText>{open && renderAssessment()}</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openWarningDialog}
        onClose={handleWarningDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">If you close or reload, you need to re-take the test again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleWarningDialogClose();
            }}
            color="primary"
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={reportOpen}
        onClose={handleReportClose}
        fullWidth={true}
        maxWidth="xl"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Assessment Report</DialogTitle>
        <DialogContent dividers={true} className={classes.reportContainer}>
          {reportOpen && renderReport()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReportClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  closeWarning: {
    float: "right",
    fontSize: "0.8rem",
  },
  reportContainer: {
    backgroundColor: style.backgroundColor._screen,
  },
  reportIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
  },
  card: {
    marginBottom: "1rem",
  },
  appBar: {
    backgroundColor: "#2e8eec",
  },
  score: {
    color: style.fontColour._blue,
    fontSize: "0.8rem",
    padding: "0.4rem 0 0 0",
    textAlign: "center",
    //display: 'flex'
  },
  breadCrumbcontainer: {
    padding: "6rem 0 1rem 2rem",
    justifyContent: "center",
  },
  report: {
    color: style.fontColour._tory_blue,
    textAlign: "center",
    paddingTop: "1rem",
    cursor: "pointer",
    fontSize: "0.8rem",
    marginTop: "-20px",
    fontFamily: style.fontFamily._appFont,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  title: {
    flexGrow: 1,
  },
  percentile: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    padding: "0 0 0 0.5rem",
    fontWeight: 600,
  },
  pageContainer: {
    backgroundColor: "#f3f6f7",
  },
  iconButton: {
    color: style.fontColour._green,
    padding: "0.1rem 0 0 0",
    fontSize: "1.4rem",
  },
  icon: {
    padding: "0.2rem 0.5rem 0 0",
    color: style.fontColour._green,
    fontSize: "1.5rem",
  },
  courseTitle: {
    color: style.fontColour._tory_blue,
    backgroundColor: "#fff",
    fontSize: style.fontSize._heading,
    fontFamily: style.fontFamily._appFont,
    padding: "1rem 0 1rem 2rem",
  },
  courseTitleStatus: {
    fontSize: "1.2rem",
    backgroundColor: "#fff",
    color: style.fontColour._tory_blue,
    fontFamily: style.fontFamily._appFont,
    padding: "1.5rem 0 0.7rem 1.5rem",
  },
  assessmentTitle: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    fontFamily: style.fontFamily._appFont,
    padding: "1rem 0 0 2rem",
  },
  subtitleContainer: {
    paddingTop: "1rem",
    display: "flex",
  },
  descContainer: {
    textAlign: "justify",
    justifyContent: "center",
    padding: "1rem 1rem 1rem 2rem",
  },
  subtitle: {
    color: style.fontColour._blue,
    textAlign: "center",
    paddingLeft: "2rem",
    fontSize: style.fontSize._sideHeading,
    fontFamily: style.fontFamily._appFont,
  },
  button: {
    fontSize: "1rem",
    color: style.fontColour._green,
    border: "#6bc685 solid 1px",
  },
  container: {
    paddingTop: "2rem",
    width: "96%",
  },
  titleContainer: {
    paddingBottom: "1rem",
  },
  paper: {
    height: "100%",
  },
  buttonContainer: {
    textAlign: "center",
    paddingTop: "0.5rem",
    paddingBottom: "1rem",
  },
}));
