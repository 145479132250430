import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SpeedIcon from "@material-ui/icons/Speed";
import MessageIcon from "@material-ui/icons/Message";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { loadCSS } from "fg-loadcss";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Link from '@material-ui/core/Link'
//Local Imports
import Header from "../Header";
import { style } from "../../Styles/theme";
import { getAssignments } from "../../Store/Assignments/actionCreator";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { assignment_msgs } from "../Common/messages";
import AssignmentListLoader from '../Shared/SkeletonLoaders/assignmentListLoader';
import { getDomainInfo } from '../../Helpers/basic';

function Assignment(props) {
  const classes = useStyles();
  const [assignmentInfo, setAssignmentInfo] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState();
  const [completed, setCompleted] = useState();
  const [title, setTitle] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const [moduleTitle, setModuleTitle] = useState();
  const [courseId, setCourseId] = useState();
  const [lessonTitle, setLessonTitle] = useState();
  const [moduleId, setModuleId] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [fetchType, setFetchType] = useState();
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
    fetchAssignmentInfo();
  }, []);

  const handlePopoverOpen = (event, brief) => {
    setPopoverContent(brief);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const anchorOpen = Boolean(anchorEl);

  const fetchAssignmentInfo = () => {
    setInProgress(true)
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    let payload = {};
    if (paramVal !== undefined && paramVal === "course") {
      payload = {
        courseId: props.match.params.id,
        type: "course",
      };
    } else if (paramVal !== undefined && paramVal === "module") {
      payload = {
        moduleId: props.match.params.id,
        type: "module",
      };
    } else if (paramVal !== undefined && paramVal === "lesson") {
      payload = {
        lessonId: props.match.params.id,
        type: "lesson",
      };
    }
    getAssignments(payload, successCB, failureCB);
  };

  const successCB = (res) => {
    setAssignmentInfo(res.results);
    if (res.extra_details.length > 0) {
      if (res.extra_details[0].course_title != "" && res.extra_details[0].course_title != undefined) {
        setCourseTitle(res.extra_details[0].course_title);
      }
      if (res.extra_details[0].course_id != "" && res.extra_details[0].course_id != undefined) {
        setCourseId(res.extra_details[0].course_id);
      }
      if (res.extra_details[0].module_title != "" && res.extra_details[0].module_title != undefined) {
        setModuleTitle(res.extra_details[0].module_title);
      }
      if (res.extra_details[0].module_id != "" && res.extra_details[0].module_id != undefined) {
        setModuleId(res.extra_details[0].module_id);
      }
      if (res.extra_details[0].lesson_title != "" && res.extra_details[0].lesson_title != undefined) {
        setLessonTitle(res.extra_details[0].lesson_title);
      }
    }
    setCompleted(res.results.filter(({ status }) => status === "completed").length);
    if (res.lesson_title != "" && res.lesson_title != undefined) {
      setLessonTitle(res.lesson_title);
    }
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    let title =
      paramVal === "course"
        ? res.extra_details[0].course_title
        : paramVal === "module"
          ? res.extra_details[0].module_title
          : res.extra_details[0].lesson_title;
    setTitle(title);
    setInProgress(false);
  };

  const failureCB = () => {
    props.showSnackBar({
      state: true,
      message: assignment_msgs.ASSIGNMENT_LIST_FETCH_FAILED,
      type: "error",
    });
    setInProgress(false);
  };

  const handleListItemClick = (event, assignmentId, startsOn) => {
    const currDate = new Date();
    const today = moment(currDate).format("YYYY-MM-DD");
    const startDate = moment(startsOn).format("YYYY-MM-DD");
    const status = moment(today).isSameOrAfter(startDate, "day");
    if (status) {
      let urlParams = new URLSearchParams(window.location.search);
      let paramVal = urlParams.get("type");
      props.history.push(`/${tenantId}/assignment/${assignmentId}/?type=${paramVal}`);
    } else {
      props.showSnackBar({
        state: true,
        message: "Cannot Start Assignment before start date.",
        type: "error",
      });
    }
  };

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let paramVal = urlParams.get("type");
    if (paramVal === "module") {
      if (courseTitle !== undefined && courseId !== undefined && moduleTitle !== undefined) {
        return (
          <Route>
            <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
              {getDomainInfo().name !== 'prep' ? (
                <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                  Home
                </RouterLink>
              ) : (
                <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                  Home
                </Link>
              )}
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${courseId}`}>
                {courseTitle && courseTitle}
              </RouterLink>
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/module/${courseId}/${props.match.params.id}`}>
                {moduleTitle && moduleTitle}
              </RouterLink>
              <div style={style.brdCrmbSecondary}>Assignments</div>
            </Breadcrumbs>
          </Route>
        );
      }
    } else if (paramVal === "course") {
      if (courseTitle !== undefined) {
        return (
          <Route>
            <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
              {getDomainInfo().name !== 'prep' ? (
                <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                  Home
                </RouterLink>
              ) : (
                <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                  Home
                </Link>
              )}
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${props.match.params.id}`}>
                {courseTitle && courseTitle}
              </RouterLink>
              <div style={style.brdCrmbSecondary}>Assignments</div>
            </Breadcrumbs>
          </Route>
        );
      }
    } else if (paramVal === "lesson") {
      if (courseTitle !== undefined && courseId !== undefined && moduleTitle !== undefined && moduleId !== undefined && lessonTitle !== undefined) {
        return (
          <Route>
            <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
              {getDomainInfo().name !== 'prep' ? (
                <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
                  Home
                </RouterLink>
              ) : (
                <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
                  Home
                </Link>
              )}
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${courseId}`}>
                {courseTitle && courseTitle}
              </RouterLink>
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/module/${courseId}/${moduleId}`}>
                {moduleTitle && moduleTitle}
              </RouterLink>
              <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/lesson/${courseId}/${moduleId}/${props.match.params.id}`}>
                {lessonTitle && lessonTitle}
              </RouterLink>
              <div style={style.brdCrmbSecondary}>Assignments</div>
            </Breadcrumbs>
          </Route>
        );
      }
    }
  };

  const displayStatusIcon = (status, assignmentId, startDate) => {
    switch (status) {
      case "yet-to-start":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Start" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <Icon className="fas fa-arrow-circle-right" className={classes.startIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
      case "draft":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Draft" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <AssignmentLateIcon className={classes.draftIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
      case "completed":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Completed" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <CheckCircleIcon className={classes.completedIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
      default:
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Start" arrow>
              <IconButton
                onClick={(event) => {
                  handleListItemClick(event, assignmentId, startDate);
                }}
              >
                <Icon className="fas fa-arrow-circle-right" style={style.faIcon} />
              </IconButton>
            </Tooltip>
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: "6rem", paddingLeft: "1rem" }}>
          {assignmentInfo && SimpleBreadcrumbs()}
        </Grid>
      </div>
      <div className={classes.headerContainer}>
        <div className={classes.headerBox}>
          <Grid container>
            <Grid item sm={12} xs={12} md={8} lg={10} className={classes.titleContainer}>
              <div id="main" tabIndex="-1" className={classes.title}>
                {title}- Assignments
              </div>
            </Grid>
            <Grid item sm={12} xs={12} md={4} lg={2} className={classes.titleContainer}>
              {assignmentInfo && (
                <div className={classes.status}>
                  Completed {completed} of {assignmentInfo.length}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.body}>
        <Container maxWidth={false}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              {inProgress ? (
                <AssignmentListLoader />
              ) : (
                <List>
                  {assignmentInfo && assignmentInfo.length > 0 ? (
                    <React.Fragment>
                      {assignmentInfo &&
                        assignmentInfo.length > 0 &&
                        assignmentInfo.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ListItem>
                                <Divider />
                                <Grid container className={classes.listContainer}>
                                  <Grid item sm={1} xs={1} md={1} lg={1} className={classes.asIcon}>
                                    <AssignmentIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item sm={6} xs={6} md={7} lg={7} className={classes.itemContainer}>
                                    <div
                                      className={classes.itemTitle}
                                      onClick={(event) => {
                                        handleListItemClick(event, item.assignment_id, item.start_date);
                                      }}
                                    >
                                      {item.title}
                                    </div>
                                    <div className={classes.itemInfo}>
                                      Start Date: {item.start_date ? moment(item.start_date).format("DD-MMM-YYYY") : "NA"} &nbsp;&nbsp; Due Date:{" "}
                                      {moment(item.end_date).format("DD-MMM-YYYY")} &nbsp;&nbsp; Pts: {item.points} &nbsp;&nbsp;{" "}
                                      {item.status && item.status != "yet-to-start" && item.user_modified_date != null
                                        ? item.status === "draft"
                                          ? "Last Modified : " + moment(item.user_modified_date).format("DD-MMM-YYYY")
                                          : "Submitted On : " + moment(item.user_modified_date).format("DD-MMM-YYYY")
                                        : ""}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} md={2} lg={2} className={classes.itemContainer}>
                                    {item.grade_point != null && (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                          <Tooltip title="Your Score" arrow>
                                            <SpeedIcon className={classes.speedIcon} />
                                          </Tooltip>
                                          <div className={classes.gradeValue}>{item.grade_point}</div>
                                          <div className={classes.gradePoint}>pts</div>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} xs={2} md={2} lg={2} className={classes.itemContainer}>
                                    <div style={{ display: "flex" }}>
                                      {displayStatusIcon(item.status, item.assignment_id, item.start_date)}
                                      {item.attachment && (
                                        <Tooltip title="Download Material" arrow>
                                          <a href={item.attachment}>
                                            <CloudDownloadIcon className={classes.downloadIcon} />
                                          </a>
                                        </Tooltip>
                                      )}
                                      {item.comment && (
                                        <IconButton
                                          aria-owns={anchorOpen ? "mouse-over-popover" : undefined}
                                          aria-haspopup="true"
                                          onMouseEnter={(e) => {
                                            handlePopoverOpen(e, item.comment);
                                          }}
                                          onMouseLeave={handlePopoverClose}
                                          edge="end"
                                          aria-label="remarks"
                                        >
                                          <Icon className="fas fa-comment-dots" style={style.faIcon}></Icon>
                                        </IconButton>
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  ) : (
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        marginTop: "10px",
                      }}
                    >
                      No Assignments Found
                    </Typography>
                  )}
                </List>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={anchorOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popoverContent}>" {popoverContent} "</div>
      </Popover>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(Assignment);

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    fontStyle: "Italic",
    color: "#938d8d",
    padding: "0.5rem",
  },
  itemTitle: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    padding: "0.2rem 0 0.2rem 0 ",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  remarks: {
    fontSize: style.fontSize._description,
    color: style.fontColour._blue,
    padding: "1.4rem 0.5rem 0 1.5rem",
    textDecoration: "underline",
    cursor: "pointer",
  },
  gradeValue: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._tory_blue,
    padding: "1.2rem 0 0 0",
  },
  gradePoint: {
    fontSize: style.fontSize._description,
    color: style.fontColour._blue,
    padding: "1.5rem 0.2rem 0 0.2rem",
  },
  itemInfo: {
    padding: "0.2rem",
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._tory_blue,
  },
  asIcon: {
    textAlign: "center",
  },
  speedIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.3rem",
    padding: "1.3rem 0.5rem 0.5rem 0",
  },
  downloadIcon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0.5rem 0.5rem 0 0",
    marginLeft: "10px",
    marginTop: "4px",
  },
  icon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0.5rem 0.5rem 0 0",
  },
  result: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
  },
  listContainer: {
    marginTop: "1rem",
    cursor: "pointer",
  },
  pointsIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.5rem",
    padding: "0 0 0 0.2rem",
  },
  startIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  completedIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  draftIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  draftTxt: {
    padding: "1.2rem 0.5rem 1rem 1rem",
    fontSize: "0.8rem",
    color: style.fontColour._tory_blue,
  },
  gridContainer: {
    justifyContent: "center",
  },
  inline: {
    display: "inline",
    paddingRight: "0.5rem",
    color: style.fontColour._tory_blue,
  },

  headerContainer: {
    backgroundColor: "#1c234a",
    borderRadius: "3px",
    marginTop: "1rem",
    paddingTop: "1rem",
  },
  headerBox: {
    display: "flex",
  },
  title: {
    padding: "0 0 1rem 3rem",
    color: "#FFF",
    fontSize: style.fontSize._heading,
  },
  status: {
    padding: "0.5rem 0 1rem 1rem",
    color: "#FFF",
    fontSize: style.fontSize._labels,
  },
}));
