import axios from 'axios'
import { apiConfig } from '../../Configs/apiConfig'
import Python from '../../Static/dummyCourseImgs/python.jpg'
import java from '../../Static/dummyCourseImgs/java.jpg'
import javascript from '../../Static/dummyCourseImgs/javascript.jpg'
import reactjs from '../../Static/dummyCourseImgs/reactjs.jpg'

export const mockListVideos = {
  results: [
    {
      id: 1,
      title: 'Python for Data science',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 2,
      level: 1,
      tags: ["Python","Development"],
      thumbnail_url: Python,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200508103006903822_python_download.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HSv7gGIqBx0CWXSbhZrTVsOUhDo%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-05-08T11:05:49.540000Z',
      views_count: '120',
      duration: '10:30',
      completion_rate: 100,
    },
    {
      id: 6,
      title: 'Object Oriented Programming in Java',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 4,
      level: 2,
      tags: ["Java"],
      thumbnail_url: java,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-10-08T11:05:49.540000Z',
      views_count: '800',
      duration: '01:05:00',
      completion_rate: 0,
    },
    {
      id: 8,
      title: 'Reactjs frontend development',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 6,
      level: 2,
      tags: ["Reactjs"],
      thumbnail_url: reactjs,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-10-08T11:05:49.540000Z',
      views_count: '900',
      duration: '01:00:00',
      completion_rate: 0,
    },
    {
      id: 2,
      title: 'Object Oriented Programming in Java',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 2,
      level: 2,
      tags: ["java","Development"],
      thumbnail_url: java,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-05-08T11:05:49.540000Z',
      views_count: '1K',
      duration: '30:00',
      completion_rate: 10,
    },
    {
      id: 3,
      title: 'HTML, CSS, and Javascript for Web Developers',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 4,
      level: 3,
      tags: ["javascript"],
      thumbnail_url: javascript,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200512142753606619_javascript_beginer_tutorial.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=AeCacZjpb0bBNlK%2BqVQ%2BAMO1V0Q%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-07-08T11:05:49.540000Z',
      views_count: '1.5K',
      duration: '10:00',
      completion_rate: 20,
    },
    {
      id: 4,
      title: 'Test video4',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 2,
      level: 2,
      tags: ["Reactjs"],
      thumbnail_url: reactjs,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507153842400913_reactjs.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=iBRJvw0XULtRHoW2jp0UVvwQwYE%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-09-08T11:05:49.540000Z',
      views_count: '150',
      duration: '10:20',
      completion_rate: 100,
    },
    {
      id: 1,
      title: 'Python for Data Science and AI',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 1,
      level: 1,
      tags: ["Python","Development"],
      thumbnail_url: Python,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200508103006903822_python_download.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HSv7gGIqBx0CWXSbhZrTVsOUhDo%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-05-08T11:05:49.540000Z',
      views_count: '120',
      duration: '10:30',
      completion_rate: 10,
    }
  ]
}

const mockVideoData = {
  results : {
    id: 5,
    title: 'python for data science',
    description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
    category: 4,
    level: 2,
    tags: ["Python", "Data science", "Python tutorial"],
    thumbnail_url: java,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
    video_url: 'https://vimeo.com/416228177',
    transcript: 'test.srt',
    created_date: '2020-10-08T11:05:49.540000Z',
    views_count: '800',
    duration: '01:05:10',
    completion_rate: 0
  }
}

export const fetchMyVideos = (payLoad, successCallBack, failureCallBack) => {
  successCallBack(mockListVideos)
  // const url =`${apiConfig.myVideos.fetchMyVideos}`
  // axios.get(url)
  // .then(function (response) {
  //   successCallBack(response.data)
  // })
  // .catch(function (error) {
  //   //failureCallBack(error)
  //   successCallBack(mockListVideos)
  // })
}

export const fetchVideoDetails = (payload, successCallBack, failureCallBack) => {
  successCallBack(mockVideoData)
  // const url = `${apiConfig.myVideos.fetchVideoDetails}`
  // axios.get( url, {
  //   params: payload
  // })
  // .then(function (response) {
  //   successCallBack(response.data)
  // })
  // .catch(function (error) {
  //   //failureCallBack(error)
  //   successCallBack(mockVideoData)
  // })
}