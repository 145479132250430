import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from 'react-skeleton-loader';
import AssignmentListLoader from './assignmentListLoader';


function DiscussionCommentLoader(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <div className={classes.cntr}>
                    <Skeleton widthRandomness={0} height="80px" width="100%" color="#ccc" />
                    <Skeleton widthRandomness={0} height="50px" width="100%" color="#ccc" />
                </div>

            </div>
            <div className={classes.txtWrapper}>
                <AssignmentListLoader />
            </div>
        </React.Fragment>
    )
}
const useStyles = makeStyles({
    wrapper: {
        width: '90%'
    },
    cntr: {
        marginTop: '1rem'
    },
    txtWrapper: {
        width: '90%'
    }
})

export default DiscussionCommentLoader;