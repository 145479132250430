import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import { authors, courseInfo } from '../../MockData/mockData';
import { courseInfoUnAuthorized, courseInfoAuthorized } from '../../MockData/roleBased';

export const getCourses = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.courses}`
  axios.get(
    url
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //successCallBack(courseInfo)
      failureCallBack(error)
    })
}

export const getCourseDetails = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.courseInfo}`
  axios.get(
    url, {
    params: {
      id: payload.courseId
    }
  }
  )
    .then(function (response) {
      let resp = {
        courseDetails: response.data.results,
        //authors are dummy need to change once we add authors view in course desc page
        authors: authors,
        enrol: response.data.enrol,
        role: response.data.role,
        provider: response.data.provided_by
      }
      successCallBack(resp)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const getModuleDetails = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.moduleInfo}`
  axios.get(
    url, {
    params: {
      course_id: payload.courseId,
      module_id: payload.moduleId
    }
  }
  )
    .then(function (response) {
      let resp = {
        isPurchased: true,
        moduleDetails: response.data.results,
        status: "YetToStart",
        userRole: 'Reader'
      }
      successCallBack(resp)
    })
    .catch(function (error) {
      failureCallBack(error)

    })
}

export const getScore = (payload, successCallBack, failureCallBack) => {

  const url = `${apiConfig.course.userScore}`
  const header = { headers: { 'Authorization': '' } }
  axios.get(
    url, {
    params: {
      course_id: payload.courseId
    },
    header
  }
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}


export const getLesson = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.lessonDetails}`
  axios.get(
    url, {
    params: {
      lesson_id: payload.lessonId
    }
  }
  )
    .then(function (response) {
      let resp = {
        isPurchased: true,
        lessonInfo: response.data.results
      }
      successCallBack(resp)
    })
    .catch(function (error) {
      failureCallBack(error)

    })
}


export const getCourseItemDetails = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.courseBrief}`
  axios.get(
    url, {
    params: {
      id: payload.courseId
    }
  }
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}


export const postVideoTrackInfo = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.videoTracking.postTrackInfo}`
  axios.post(
    url, payload
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}


export const getVideoTrackInfo = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.videoTracking.getTrackInfo}`
  axios.get(
    url, {
    params: payload
  }
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const postLessonTrackInfo = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.lessonTracking.postLessonTrackInfo}`
  axios.post(
    url, payload
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING SINGLE COURSE INSTRUCTORS LIST
export const fetchCourseContributors = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.course.fetchCourseContributors}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

