import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { closeSnackBar } from '../../../Store/AlertMessages/actionCreator';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: '#fff',
    borderLeft: '10px solid green',
    color: 'green'
  },
  error: {
    backgroundColor: '#fff',
    borderLeft: '10px solid red',
    color: 'red'
  },
  warning: {
    backgroundColor: '#fff',
    borderLeft: '10px solid #FF9800',
    color: '#FF9800'
  },
}));
function SnackBarmessages(props) {
  const classes = useStyles();
  const state = {
    open: props.state,
    vertical: 'top',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const handleClose = () => {
    props.closeSnackBar()
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={props.state}
        onClose={handleClose}
        autoHideDuration={props.type !== 'error' ? 5000 : 2000}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes[props.type]
          }
        }}
        message={props.type === 'error' ? <span id="message-id">{props.message}</span> : <span id="message-id">{props.message}</span>}
      />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    alertReducer: state.alertReducer
  }
}
export default connect(
  mapStateToProps, { closeSnackBar }
)(SnackBarmessages);
