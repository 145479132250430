import axios from 'axios'
import { apiConfig } from '../../Configs/apiConfig';

export const mockEnrolledCourseTitles = {
  results: [
    {
      id: 1,
      title: 'Python basics'
    },
    {
      id: 2,
      title: 'ReactJs'
    },
    {
      id: 3,
      title: 'AngularJs'
    },
    {
      id: 4,
      title: 'Web developement'
    },
    {
      id:5,
      title: 'Angular'
    }
  ]
}

export const mockMyCourseTopics = {
  results: [
    {
      id: 1,
      title: 'Python basics'
    },
    {
      id: 2,
      title: 'ReactJs'
    },
    {
      id: 3,
      title: 'AngularJs'
    },
    {
      id: 4,
      title: 'Web developement'
    },
    {
      id:5,
      title: 'Angular'
    }
  ],
  total_count: 10
}

export const MockMyCourseTopics = {
  results: [
    {
      id: 1,
      topic_title: 'This is my first test topic',
      created_date: '2020-06-02T19:02:01.560057Z',
      first_name: 'Peter',
      last_name: 'Joe',
      topic_desc: '<p>Studies have <i>already</i> shown that <b>video games</b> can lead to better hand-eye coordination, as well as better snap decision making Because in video games, you have to make those choices. You have to live with them.</p>',
      views: 200,
      likes: 100,
      replies: 20,
    },
    {
      id: 2,
      topic_title: 'This is my second test topic',
      created_date: '2020-06-02T19:02:01.560057Z',
      first_name: 'Jhon',
      last_name: 'Kamal',
      topic_desc: '<p>Studies have <i>already</i> shown that <b>video games</b> can lead to better hand-eye coordination, as well as better snap decision making Because in video games, you have to make those choices. You have to live with them.</p>',
      views: 100,
      likes: 10,
      replies: 3,
    }
  ],
  total_count: 10
}

export const mockFetchQuestionsAsked = {
  results: [
    {
      id: 1,
      topic_title: 'This is my first test topic',
      created_date: '2020-06-02T19:02:01.560057Z',
      first_name: 'Peter',
      last_name: 'Joe',
      topic_desc: '<p>Studies have <i>already</i> shown that <b>video games</b> can lead to better hand-eye coordination, as well as better snap decision making Because in video games, you have to make those choices. You have to live with them.</p>',
      views: 100,
      likes: 10,
      replies: 4,
      course_title: 'Python Basics'
    },
    {
      id: 2,
      topic_title: 'This is for testing the flow',
      created_date: '2020-06-02T19:02:01.560057Z',
      first_name: 'Jhon',
      last_name: 'Kamal',
      topic_desc: '<p>Studies have <i>already</i> shown that <b>video games</b> can lead to better hand-eye coordination, as well as better snap decision making Because in video games, you have to make those choices. You have to live with them.</p>',
      views: 200,
      likes: 100,
      replies: 20,
      course_title: 'Web Development'
    }
  ],
  total_count: 10
}

export const fetchEnrolledCourseTitles = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.community.fetchEnrolledCourseTitles}`
  axios.get(url)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack(mockEnrolledCourseTitles)
  })
}

export const createTopic = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.community.createTopic}`
  axios.post(url,payLoad)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack()
  })
}

export const fetchMyCourseTitles = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.community.fetchMyCourseTitles}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack(mockMyCourseTopics)
  })
}

export const fetchMyCourseTopics = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.community.fetchMyCourseTopics}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack(MockMyCourseTopics)
  })
}

export const fetchQuestionsAsked = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.community.fetchQuestionsAsked}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack(mockFetchQuestionsAsked)
  })
}