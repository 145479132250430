import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MyCourses from './myCourses';
import Header from '../Header';
import { style } from '../../Styles/theme';
import { getLoggedInStatus } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
    container: {
        margin: "7rem 0.5rem 0.5rem 0.5rem",
    },
    root: {
        backgroundColor: style.backgroundColor._screenLight
    }
}));


export default function UserProfile(props) {
    const classes = useStyles();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        let status = getLoggedInStatus();
        setIsLoggedIn(status);
    }, [])

    return (
        <div className={classes.root}>
            <Header history={props} />
            <div className={classes.container}>
                {isLoggedIn && <MyCourses history={props.history} />}
            </div>
        </div>
    )

}