import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { style } from "../../Styles/theme";
import { showSnackBar } from '../../Store/AlertMessages/actionCreator';
import { convertUTCDatewithTime } from '../../Helpers/basic'
import CommunityTopicLoader from '../Shared/SkeletonLoaders/communityTopicLoader'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '80px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-76px'
  },
  likesWrapper: {
    display:'flex',
    flexDirection:'column',
    marginLeft: '10px',
    marginBottom: '-20px'
  },
  avatarWrapper: {
    backgroundColor: style.fontColour._blue,
  },
  topicInfo: {
    fontSize: '19px',
    fontWeight: '500',
    color: style.fontColour._egyptian_blue,
    textAlign: 'left',
    marginTop: '0px'
  },
  courseTitle: {
    fontSize: '25px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'center',
    lineBreak: 'anywhere'
  }
}))

function MyCourseTopics(props){
  const classes = useStyles();
  const myCourseId = props.myCourseId ? props.myCourseId :''
  const closeView = props.closeCourseTopicsView ? props.closeCourseTopicsView : ''
  const myCourseTopics = props.myCourseTopics ? props.myCourseTopics : []
  const myCourseTopicsLength = props.myCourseTopicsLength ? props.myCourseTopicsLength : ''
  const myCourseTopicsBtnPrgs = props.myCourseTopicsBtnPrgs ? props.myCourseTopicsBtnPrgs : false
  const myCourseData = props.myCourseData ? props.myCourseData : {}
  const courseTopicsLoader = props.courseTopicsLoader ? props.courseTopicsLoader : false
 
  function createMarkup(data) {
    return {
      __html: data
    };
  }

  const dateTimeFormat = (due_days) => {
    return(convertUTCDatewithTime(due_days))
  }

  const onLoadMoreClick = () => {
    props.loadMoreMyCourseTopics()
  }

  let btnValid = false
  if(myCourseTopicsBtnPrgs){
    btnValid = true
  }
  else if(myCourseTopics.length >= myCourseTopicsLength){
    btnValid = true
  }
  return(
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.courseTitle}>{myCourseData && myCourseData.title ? `Topics Under ${myCourseData.title}` : ''}</Typography>
            <Divider/>
            { courseTopicsLoader ? (
              <CommunityTopicLoader/>
            ) : (
              <React.Fragment>
                { myCourseTopics && myCourseTopics.length ? (
                  <React.Fragment>
                    <List>
                      <React.Fragment>
                        {myCourseTopics.map((data, index) => (
                          <React.Fragment key={index}>
                            <ListItem>
                              <Grid container>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                  <div style={{ display: 'flex'}}>
                                    <ListItemAvatar>
                                      <Avatar className={classes.avatarWrapper}>
                                        {data.first_name && data.first_name.substring(0, 1)}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText className={classes.topicInfo} primary={`${data.first_name && data.first_name} ${data.last_name && data.last_name}`} secondary={dateTimeFormat(data.created_date && data.created_date)} />
                                  </div>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                  <div style={{display:'flex', marginBottom: '-26px'}}>
                                    <div className={classes.likesWrapper}>
                                      <span>{data.views}</span>
                                      <h5 style={{ marginTop: '2px'}}>Views</h5>
                                    </div>
                                    <div className={classes.likesWrapper}>
                                      <span>{data.likes}</span>
                                      <h5 style={{ marginTop: '2px'}}>Likes</h5>
                                    </div>
                                    <div className={classes.likesWrapper}>
                                      <span>{data.replies}</span>
                                      <h5 style={{ marginTop: '2px'}}>Replies</h5>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Link component="button" style={{ textDecoration: 'none', cursor: 'pointer', fontSize: '20px', fontWeight: '500', lineBreak: 'anywhere'}}>
                                    {data.topic_title && data.topic_title.length > 70 ? (
                                      data.topic_title.substr(0,65) + '...'
                                    ) : (
                                      data.topic_title
                                    )}
                                  </Link>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div style={{ textAlign: 'initial', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={createMarkup( data.topic_desc && data.topic_desc )}></div>
                                </Grid>
                              </Grid>
                            </ListItem>
                            <Divider/>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    </List>
                    {myCourseTopics.length >= 2 ? (
                      <React.Fragment>
                        {myCourseTopicsBtnPrgs ? (
                          <CircularProgress size={20} style={{ marginBottom: '-9px'}} />
                        ) : null }
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                          <Button disabled={btnValid} style={{ textTransform: 'none', flexGrow: 1, textAlign: 'center'}} size="small" color="primary" onClick={() => {onLoadMoreClick()}}>
                            View More
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : null  }
                  </React.Fragment>
                ) : null }
              </React.Fragment>
            ) }
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(MyCourseTopics);