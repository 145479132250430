export const reading = [
  {
    id: 1,
    title: "Machine Learning and Benefits",
    desc:
      "Machine Learning is a Form of AI that Enables a System to Learn from Data. Learn More. Collaborate Across Teams and Scale at the Speed Your Business Requires with IBM®. Guided Experience. Data Driven Solutions. Schedule a Consultation.",
    file1: "http://ciml.info/dl/v0_8/ciml-v0_8-all.pdf",
    file2: "https://sample-videos.com/ppt/Sample-PPT-File-500kb.ppt",
    file3: "https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.doc",
  },
];

export const courseInfo = [
  {
    id: 1,
    title: "Machine Learning",
    img: "https://media.geeksforgeeks.org/wp-content/cdn-uploads/machineLearning3.png",
  },
  {
    id: 2,
    title: "Python",
    img: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
  },
];

export const authors = [
  {
    id: "23",
    name: "Charles Severance",
    image:
      "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-instructor-photos.s3.amazonaws.com/c8/7a3210ea1e11e8a81d2b76c2c4aa10/Steve-Oney-cropped.jpg?auto=format%2Ccompress&dpr=1&w=112&h=",
    description:
      "Charles Severance (a.k.a. Dr. Chuck) is a Clinical Professor at the University of Michigan School of Information, where he teaches various technology-oriented courses including programming, database design, and Web development. Chuck has written a number of books including Using Google App Engine, and Python for Everybody.His research field is in the building of learning management systems such as Sakai, Moodle, Blackboard, ANGEL, and others. He was the chief architect for the Sakai Project, a learning management system used at about 300 schools worldwide and wrote the book Sakai: Free as in Freedom, that describes his experiences as one of the leaders of the project.  ",
  },
  {
    id: "24",
    name: "Paul Resnick",
    image:
      "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera-instructor-photos.s3.amazonaws.com/c3/f96c40ea1b11e8931fddf70eb768e1/Paul-Resnick-cropped.jpg?auto=format%2Ccompress&dpr=1&w=112&h=",
    description:
      "Paul Resnick is the Michael D. Cohen Collegiate Professor of Information and Associate Dean for Research and Faculty Affairs at the University of Michigan School of Information. He previously worked as a researcher at AT&T Labs and AT&T Bell Labs, and as an Assistant Professor at the MIT Sloan School of Management.He received the master's and Ph.D. degrees in Electrical Engineering and Computer Science from MIT, and a bachelor's degree in mathematics from the University of Michigan. Professor Resnick's research focuses on SocioTechnical Capital, productive social relations that are enabled by the ongoing use of information and communication technology.",
  },
];

export const lesson1 = {
  id: 1,
  title: "Why Python",
  video_url: "ml",
  transcript: "dsdsdssd",
};

export const moduleDetails1 = {
  results: {
    id: 20,
    title: "Introduction to Python",
    desc:
      "A modular design can be characterized by functional partitioning into discrete scalable and reusable modules, rigorous use of well-defined modular interfaces, and making use of industry standards for interfaces. In this context modularity is at the component level, and has a single dimension, component slottability. A modular system with this limited modularity is generally known as a platform system that uses modular components. Examples are car platforms or the USB port in computer engineering platforms.",
    banner_url:
      "https://cedb-c.s3.amazonaws.com/media/modules/banner/20200319092746860163_Screenshot20200119at7.32.37PM.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=eVc4csFf55Q5w7gi4fZvLsxPCvg%3D&Expires=1584966550",
    video_url: "",
    transcript:
      "https://cedb-c.s3.amazonaws.com/media/modules/transcript/20200319092810277013_singapore_metro.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=LH1QxGy%2FQaPCfRSlJ0NqpfY0IK8%3D&Expires=1584966550",
    days: 1,
    hours: 1,
    minutes: 10,
    course_title: "Python Basic Programming",
    lessons: [
      {
        lesson_id: 1,
        title: "Why Python",
        brief:
          "Python is dynamically typed and garbage-collected. It supports multiple programming paradigms, including procedural, object-oriented, and functional programming.",
      },
      {
        lesson_id: 2,
        title: "Python Basics",
        brief:
          "Python interpreters are available for many operating systems. A global community of programmers develops and maintains CPython, an open source reference implementation. ",
      },
    ],
  },
};

export const courseDetails1 = {
  results: {
    id: 16,
    title: "Python Basic Programming ",
    desc:
      "The program manager has oversight of the purpose and status of the projects in a program and can use this oversight to support project-level activity to ensure the program goals are met by providing a decision-making capacity that cannot be achieved at project level or by providing the project manager with a program perspective when required, or as a sounding board for ideas and approaches to solving project issues that have program impacts.",
    banner_url:
      "https://cedb-c.s3.amazonaws.com/https%3A//s3.us-east-1.amazonaws.com/cedb-c/media/hosting/banner/20200302075235224197_appleteacher.001.png?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=EwuoU5hN70Xx0Ns1Ovxq6cmjLps%3D&Expires=1584966628",
    video_url: "",
    transcript: null,
    level: 1,
    category: 2,
    tags: [],
    duration: 157,
    lessons: 30,
    modules: [
      {
        module_id: 11,
        title: "Introduction to Python",
        desc: " march 03",
        days: 0,
        hours: 3,
        minutes: 15,
      },
      {
        module_id: 10,
        title: "Variables and Loops",
        desc: " march 03",
        days: 0,
        hours: 2,
        minutes: 20,
      },
      {
        module_id: 9,
        title: "Django and API",
        desc: " march 03",
        days: 0,
        hours: 1,
        minutes: 30,
      },
      {
        module_id: 8,
        title: "Pattern matching and Algorithms",
        desc: "chapter desc march 03",
        days: 0,
        hours: 1,
        minutes: 10,
      },
    ],
  },
};

export const courseCategoryList = [
  {
    id: 1,
    name: "Finance & Accounting",
  },
  {
    id: 2,
    name: "Development",
  },
  {
    id: 3,
    name: "Business",
  },
  {
    id: 4,
    name: "IT & Software",
  },
  {
    id: 5,
    name: "Personal Development",
  },
  {
    id: 6,
    name: "Design",
  },
  {
    id: 7,
    name: "Marketing",
  },
  {
    id: 8,
    name: 'Information Security'
  }
];

export const chapterInfo = [
  {
    id: 1,
    courseTitle: "Machine Learining",
    chapters: [
      {
        id: 1,
        title: "Introduction to Machine learning",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "ml",
        status: "InProgress",
      },
      {
        id: 2,
        title: "Machine learning and Internal Methods",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "ml",
        status: "Completed",
      },
      {
        id: 3,
        title: "Pattern Maching",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "ml",
        status: "YetToStart",
      },
      {
        id: 4,
        title: "Importance Of Algorithims",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "ml",
        status: "YetToStart",
      },
      {
        id: 5,
        title: "Python: An Introduction",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "ml",
        status: "YetToStart",
      },
      {
        id: 6,
        title: "Usage Of GPU",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "ml",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 2,
    courseTitle: "Python",
    chapters: [
      {
        id: 1,
        title: "Introduction to Python",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "",
        status: "InProgress",
      },
      {
        id: 2,
        title: "Python and Variables",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "",
        status: "Completed",
      },
      {
        id: 3,
        title: "Delimiters",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "",
        status: "YetToStart",
      },
      {
        id: 4,
        title: "Django",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "",
        status: "YetToStart",
      },
      {
        id: 5,
        title: "API Using Django",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "",
        status: "YetToStart",
      },
      {
        id: 6,
        title: "Postgres",
        duration: "1 Minute",
        bannerImage: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
        transcriptUrl: "",
        video_url: "",
        status: "YetToStart",
      },
    ],
  },
];

export const lessonPromo = [
  {
    id: 1,
    video_url: "ml",
  },
  {
    id: 2,
    video_url: "",
  },
  {
    id: 3,
    video_url: "ml",
  },
  {
    id: 4,
    video_url: "ml",
  },
  {
    id: 5,
    video_url: "ml",
  },
  {
    id: 6,
    video_url: "ml",
  },
];

export const lessonInfo = [
  {
    id: 1,
    lessonInfo: [
      {
        id: 1,
        title: "Introduction to Python",
        duration: "1 Minute",
        link: "sdadssas",
        status: "InProgress",
      },
      {
        id: 2,
        title: "Python and Variables",
        duration: "1 Minute",
        link: "sdadssas",
        status: "Completed",
      },
      {
        id: 3,
        title: "Delimiters",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 4,
        title: "Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 2,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 3,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 3,
        title: "Mysql",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 3,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 3,
        title: "Mysql",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 3,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 3,
        title: "Mysql",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 3,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 3,
        title: "Mysql",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 3,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 3,
        title: "Mysql",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
  {
    id: 3,
    lessonInfo: [
      {
        id: 1,
        title: "API Using Django",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 2,
        title: "Postgres",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
      {
        id: 3,
        title: "Mysql",
        duration: "1 Minute",
        link: "sdadssas",
        status: "YetToStart",
      },
    ],
  },
];

export const lesson = [
  {
    id: 1,
    title: "Introduction to python",
    video_url: "ml",
    video_transcript: "dsdsdssd",
    banner_url: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
    description: "",
  },
  {
    id: 2,
    video_url: "",
    title: "Introduction to ",
    video_transcript: "dsdsdssd",
    banner_url: "dfdfdfd",
    description: "",
  },
  {
    id: 3,
    video_url: "",
    title: "Introduction to Python3",
    video_transcript: "dsdsdssd",
    banner_url: "dfdfdfd",
    description: "",
  },
  {
    id: 4,
    video_url: "",
    title: "Introduction to Python4",
    video_transcript: "dsdsdssd",
    banner_url: "dfdfdfd",
    description: "",
  },
  {
    id: 5,
    video_url: "",
    title: "Introduction to Python",
    video_transcript: "dsdsdssd",
    banner_url: "dfdfdfd",
    description: "",
  },
  {
    id: 6,
    video_url: "",
    title: "Introduction to Python",
    video_transcript: "dsdsdssd",
    banner_url: "dfdfdfd",
    description: "",
  },
];

export const scorecardDetails = {
  gemsStarted: 159,
  gemsCompleted: 122,
  aces: 3,
  whip: 5,
  rank: 34,
};

export const courseDescription = [
  {
    id: 1,
    title: "Machine Learning",
    banner_image: "https://www.aquatechtrade.com/-/media/websites/aquatech/aqd/images/news/aqd_machine-learning_1140x400.ashx?mw=900&mh=615",
    banner_video: {
      video_url: "",
      transcript_file_url: "",
    },
    details: {
      description: [
        {
          id: "3732",
          desc_title: "GEM SELECTION DESCRIPTION",
          desc_subtitle: "Your Cluster of Gem Suggests you are looking for better communicte with.",
          description:
            "The program manager has oversight of the purpose and status of the projects in a program and can use this oversight to support project-level activity to ensure the program goals are met by providing a decision-making capacity that cannot be achieved at project level or by providing the project manager with a program perspective when required, or as a sounding board for ideas and approaches to solving project issues that have program impacts.",
        },
        {
          id: "3211",
          desc_title: "GEM Selection Description",
          desc_subtitle: "Your Cluster of Gem Suggests.",
          description:
            "The program manager has oversight of the purpose and status of the projects in a program and can use this oversight to support project-level activity to ensure the program goals are met by providing a decision-making capacity that cannot be achieved at project level or by providing the project manager with a program perspective when required, or as a sounding board for ideas and approaches to solving project issues that have program impacts.",
        },
      ],
      feature_list: [
        {
          id: "1",
          title: "Aptitude Goals",
          features: [
            "Ensure that component parts fit together properly ",
            "Develop the plan bringing together the information on projects",
            "In democracies, voluntary associations, interest groups, and associational activity can",
          ],
        },
        {
          id: "2",
          title: "Resource Goals",
          features: [
            "Ensure that component parts fit together properly ",
            "Develop the plan bringing together the information on projects",
            "Approval ratings generated through public opinion polling create a measure of job performance during",
          ],
        },
      ],
      author: [
        {
          id: "23",
          name: "John Williams",
          image: "",
          description:
            "Creating an AI course for business leaders so they can speak the same language as their engineers is challenging, but working with a variety of companies to understand the practical, technical, and commercial hurdles was incredibly insightful. Now, these learnings are available to all leaders who want to understand more about how to leverage Artificial Intelligence to advance their business.",
          link: "",
        },
        {
          id: "24",
          name: "Peter Parker",
          image: "",
          description:
            "Creating an AI course for business leaders so they can speak the same language as their engineers is challenging, but working with a variety of companies to understand the practical, technical, and commercial hurdles was incredibly insightful. Now, these learnings are available to all leaders who want to understand more about how to leverage Artificial Intelligence to advance their business.",
          link: "",
        },
      ],
    },
  },
  {
    id: 2,
    title: "Python basics",
    banner_image: "https://cdn.educba.com/academy/wp-content/uploads/2019/03/what-is-python.jpg",
    banner_video: {
      video_url: "",
      transcript_file_url: "",
    },
    details: {
      description: [
        {
          id: "3732",
          desc_title: "GEM SELECTION DESCRIPTION",
          desc_subtitle: "Your Cluster of Gem Suggests you are looking for better communicte with.",
          description:
            "The program manager has oversight of the purpose and status of the projects in a program and can use this oversight to support project-level activity to ensure the program goals are met by providing a decision-making capacity that cannot be achieved at project level or by providing the project manager with a program perspective when required, or as a sounding board for ideas and approaches to solving project issues that have program impacts.",
        },
      ],
      feature_list: [
        {
          id: "1",
          title: "Aptitude Goals",
          features: [
            "Ensure that component parts fit together properly ",
            "Develop the plan bringing together the information on projects",
            "In democracies, voluntary associations, interest groups, and associational activity can",
          ],
        },
      ],
      author: [
        {
          id: "23",
          name: "John Williams",
          image: "",
          description:
            "Creating an AI course for business leaders so they can speak the same language as their engineers is challenging, but working with a variety of companies to understand the practical, technical, and commercial hurdles was incredibly insightful. Now, these learnings are available to all leaders who want to understand more about how to leverage Artificial Intelligence to advance their business.",
          link: "",
        },
        {
          id: "24",
          name: "Peter Parker",
          image: "",
          description:
            "Creating an AI course for business leaders so they can speak the same language as their engineers is challenging, but working with a variety of companies to understand the practical, technical, and commercial hurdles was incredibly insightful. Now, these learnings are available to all leaders who want to understand more about how to leverage Artificial Intelligence to advance their business.",
          link: "",
        },
      ],
    },
  },
];

export const courseDetails = {
  courseId: 1,
  course_title: "Python Basic Programming",
  modules: [
    {
      module_id: 1,
      module_title: "Introduction to Python",
      lessons: [
        {
          lesson_id: 1,
          lesson_title: "Why Python",
        },
        {
          lesson_id: 2,
          lesson_title: "Python Basics",
        },
      ],
    },
    {
      module_id: 2,
      module_title: "Variables and Loops",
      lessons: [
        {
          lesson_id: 5,
          lesson_title: "Data types",
        },
        {
          lesson_id: 6,
          lesson_title: "Objects and Dictionaries",
        },
        {
          lesson_id: 7,
          lesson_title: "Loops and Conditions",
        },
        {
          lesson_id: 8,
          lesson_title: "Examples",
        },
      ],
    },
    {
      module_id: 3,
      module_title: "Django and API",
      lessons: [
        {
          lesson_id: 9,
          lesson_title: "API Introduction",
        },
        {
          lesson_id: 10,
          lesson_title: "Specs of Django",
        },
        {
          lesson_id: 11,
          lesson_title: "SOAP vs REST",
        },
        {
          lesson_id: 12,
          lesson_title: "Object Relational Model",
        },
      ],
    },
  ],
};

//----------------------------------------------API Response-------------------------------------------

export const courseDetailInfo = {
  id: 1,
  title: "Machine Learning",
  banner_url: "https://miro.medium.com/max/840/1*RJMxLdTHqVBSijKmOO5MAg.jpeg",
  video_url: null,
  desc: `<p><a href="http://myathina.com/">Do commanded</a> an <strong>Information content </strong>we <span style="background-color:rgb(0,0,255);color:rgb(255,0,0);"><i><u>disposing do</u></i>.</span> Indulgence <span style="background-color:rgb(255,0,0);">ten remarkably nor are impression </span>Python strives for a simpler, less-cluttered syntax and grammar while giving developers a choice in their coding methodology. In contrast to Perl's "there is more than one way to do it" motto, Python embraces a "there should be one—and preferably only one—obvious way to do it" design philosophy.</strong></p>
    <p>Rather than having all of its functionality built into its core, Python was designed to be highly extensible. This compact modularity has made it particularly popular as a means of adding programmable interfaces to existing applications. Van Rossum's vision of a small core language with a large standard library and easily extensible interpreter stemmed from his frustrations with ABC, which espoused the opposite approach.</p>`,
  transcript: null,
  modules: [
    {
      module_id: 1,
      title: "Introduction to Machine learning",
      days: 0,
      hours: 0,
      minutes: 1,
    },
    {
      module_id: 2,
      title: " Machine learning",
      days: 0,
      hours: 0,
      minutes: 1,
    },
    {
      module_id: 3,
      title: "Python",
      days: 0,
      hours: 0,
      minutes: 1,
    },
    {
      module_id: 4,
      title: "Pattern Matching",
      days: 0,
      hours: 0,
      minutes: 1,
    },
    {
      module_id: 5,
      title: "Django",
      days: 0,
      hours: 0,
      minutes: 1,
    },
  ],
};
