import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Route } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ReplyIcon from "@material-ui/icons/Reply";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Link from '@material-ui/core/Link';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddCommentIcon from "@material-ui/icons/AddComment";
import { fetchResponses, fetchResponseReplies, postResponse, postReply, fetchPinnedResponses } from "../../Store/Discussions/actionCreator";
import Header from "../Header";
import { style } from "../../Styles/theme";
import TextEditor from "../Shared/TextEditor";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import DiscussionCommentLoader from "../Shared/SkeletonLoaders/discussionCommentLoader";
import { discussion_msgs } from "../Common/messages";
import AlertMessages from "../Shared/AlertMessages";

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import Typography from "@material-ui/core/Typography";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { bytesToSize, getDomainInfo } from '../../Helpers/basic';
import { assignment_file_size, discussion_file_types } from '../../Configs/commonConfig';

function Discussion(props) {
  const classes = useStyles();
  const [responses, setResponses] = useState([]);
  const [discussionTitle, setDiscussionTitle] = useState();
  const [discussionDesc, setDiscussionDesc] = useState();
  const [selectedReplyListId, setSelectedReplyListId] = useState();
  const [replies, setReplies] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [selectedReplyBtnId, setSelectedReplyBtnId] = useState();
  const [fetchResponseFlag, setFetchResponseFlag] = useState(false);
  const [responseContent, setResponseContent] = useState('');
  const [replyContent, setReplyContent] = useState('');
  const [totalResponseCount, setTotalResponseCount] = useState(0);
  const rowsPerPage = 10;
  const [pageNum, setPageNum] = useState(0);
  const [repliesPageNum, setRepliesPageNum] = useState(0);
  const [pinnedResponses, setPinnedResponses] = useState([]);
  const [totalRepliesCount, setTotalRepliesCount] = useState(0);
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [courseId, setCourseId] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const [allowThreadedReply, setAllowThreadedReply] = useState(false);
  const [previewReply, setPreviewReply] = useState(false);
  const [respBtnLdr, setRespBtnLdr] = useState(false);
  const [rplyBtnLdr, setRplyBtnLdr] = useState(false);

  //FOR ATTACHEMENT UPLOAD
  const [attachmentFileDetails, setAttachmentFileDetails] = React.useState({});
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState();
  const [validationDiscussionError, setValidationDiscussionError] = React.useState({
    error: false,
    errorMessage: ''
  })

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    fetchResponse(0);
    fetchPinnedResp();
  }, []);

  const fetchResponse = (pNo) => {
    setPageNum(pNo);
    fetchResponses(
      {
        forum_id: props.match.params.discussionId,
        number: rowsPerPage,
        offset: pNo * rowsPerPage,
      },
      successCallBack,
      failureCallBack
    );
  };

  const fetchDiscussionResponse = () => {
    setPageNum(pageNum + 10);
    fetchResponses(
      {
        forum_id: props.match.params.discussionId,
        number: rowsPerPage,
        offset: pageNum + rowsPerPage,
      },
      responseScrollSuccess,
      responseScrollFailure
    );
  };

  const successCallBack = (res) => {
    setTotalResponseCount(res.reply_count);
    setResponses(res.results.replies);
    setCourseTitle(res.course_title);
    setCourseId(res.course_id);
    setAllowThreadedReply(res.threaded_reply);
    setPreviewReply(res.preview_reply);
    setDiscussionTitle(res.results.title);
    let desc = "";
    if (res.results.description.length > 280) {
      desc = res.results.description.substring(0, 280) + "...";
    } else {
      desc = res.results.description;
    }
    setDiscussionDesc(res.results.description);
    setFetchResponseFlag(true);
  };

  const failureCallBack = (error) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
    setFetchResponseFlag(true);
  };

  const responseScrollSuccess = (res) => {
    setCourseTitle(res.course_title);
    setPreviewReply(res.preview_reply);
    setCourseId(res.course_id);
    setTotalResponseCount(res.reply_count);
    if (res && res.results.replies.length > 0) {
      let newData = [...responses];
      res.results.replies.map((data, index) => {
        newData.push(data);
      });
      setResponses(newData);
    }
  };
  const responseScrollFailure = (error) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  const fetchPinnedResp = () => {
    let payload = {
      forum_id: props.match.params.discussionId,
    };
    fetchPinnedResponses(payload, pinnedRespSuccessCB, pinnedRespFailureCB);
  };

  const pinnedRespSuccessCB = (res) => {
    setPinnedResponses(res.results);
  };

  const pinnedRespFailureCB = (error) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  const fetchReplies = (responseId) => {
    setSelectedReplyListId(responseId);
    let payload = {
      reply_id: responseId,
      number: rowsPerPage,
      offset: repliesPageNum * rowsPerPage,
    };
    fetchResponseReplies(payload, successCB, failureCB);
  };

  const successCB = (res) => {
    setTotalRepliesCount(res.comment_count);
    setReplies(res.results);
  };

  const failureCB = (error) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  const fetchMoreReplies = () => {
    setRepliesPageNum(repliesPageNum + 10);
    let payload = {
      reply_id: selectedReplyListId,
      number: rowsPerPage,
      offset: repliesPageNum + rowsPerPage,
    };
    fetchResponseReplies(payload, moreRepliesSuccessCB, moreRepliesFailureCB);
  };

  const moreRepliesSuccessCB = (res) => {
    setTotalRepliesCount(res.total_comment_count);

    if (res && res.results.length > 0) {
      let newData = [...replies];
      res.results.map((data, index) => {
        newData.push(data);
      });
      setReplies(newData);
    }
  };

  const moreRepliesFailureCB = (error) => {
    props.showSnackBar({
      state: true,
      message: discussion_msgs.LOAD_ERROR_MSG,
      type: "error",
    });
  };

  const changeEditorContent = (responseContent) => {
    setResponseContent(responseContent);
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
  };

  const handleReplyTextChange = (event) => {
    setReplyContent(event.target.value);
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
  };

  const postResponseData = () => {
    if (responseContent === '' || !responseContent.length) {
      setValidationDiscussionError({
        error: true,
        errorMessage: 'Please add response and then post!'
      })
    } else if (attachmentFileDetails.fileError) {
      setValidationDiscussionError({
        error: true,
        errorMessage: 'Please add supported file only!'
      })
    }
    else {
      setRespBtnLdr(true);
      let payload = {
        forum_id: props.match.params.discussionId,
        reply_text: responseContent,
      };
      // let formData = new FormData()
      // formData.append('forum_id', Number(props.match.params.discussionId))
      // formData.append('reply_text', responseContent ? responseContent : '')
      // formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
      postResponse(payload, postRespSuccessCB, postRespFailureCB);
    }
  };

  const postRespSuccessCB = (res) => {
    setRespBtnLdr(false);
    setShowEditor(false);
    setResponseContent('');
    fetchResponse(0);
    setResponseContent('');
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: true,
      message: discussion_msgs.RESPONSE_SUBMIT_SUCCESS,
      type: "success",
    });
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
  };

  const postRespFailureCB = (error) => {
    setRespBtnLdr(false);
    setShowEditor(false);
    setResponseContent('');
    props.showSnackBar({
      state: true,
      message: discussion_msgs.RESPONSE_SUBMIT_FAILED,
      type: "error",
    });
  };

  const postReplyData = () => {
    if (replyContent === '' || !replyContent.length) {
      setValidationDiscussionError({
        error: true,
        errorMessage: 'Please add reply and then post!'
      })
    } else {
      setRplyBtnLdr(true);
      let payload = {
        reply_id: selectedReplyBtnId,
        comment_text: replyContent,
      };
      postReply(payload, postRepSuccessCB, postRepFailureCB);
    }
  };

  const postRepSuccessCB = (res) => {
    setRplyBtnLdr(false);
    setShowReplyEditor(false);
    setReplyContent('');
    fetchResponse(0);
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: true,
      message: discussion_msgs.RESPONSE_SUBMIT_SUCCESS,
      type: "success",
    });
  };

  const postRepFailureCB = (error) => {
    setRplyBtnLdr(false);
    setShowReplyEditor(false);
    setReplyContent('');
    props.showSnackBar({
      state: true,
      message: discussion_msgs.REPLY_SUBMIT_FAILED,
      type: "error",
    });
  };


  //FOR FILE UPLOAD on
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'attachmentfile' && file.size > assignment_file_size) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'attachmentfile') {
            return discussion_file_types
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }
  const onAttachmentUpload = event => {
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    const attachment = validatedFile('attachmentfile')
    setAttachmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          {getDomainInfo().name !== 'prep' ? (
            <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
              Home
            </RouterLink>
          ) : (
            <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
              Home
            </Link>
          )}
          <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${courseId}`}>
            {courseTitle}
          </RouterLink>
          <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/discussions/${courseId}`}>
            {courseTitle} - Discussions
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Discussion Comments</div>
        </Breadcrumbs>
      </Route>
    );
  };

  const renderResponses = () => {
    let responseList = [];
    {
      responses.length > 0 &&
        responses.map((item, index) => {
          let avatarName = item && item.first_name && item.first_name.substring(0, 1) + item && item.last_name && item.last_name.substring(0, 1);
          let isAuthor = item && item.role_user && item.role_user && item.role_user.includes("Author");
          let itemText = item && item.reply_data && item.reply_data.length > 1000 ? item.reply_data.substring(0, 1000) + "..." : item.reply_data;
          responseList.push(
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <Grid container>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                    <div style={{ display: 'flex' }}>
                      <ListItemAvatar>
                        <Avatar className={isAuthor ? classes.avatarCommentsAuthor : classes.avatarComments} alt="Remy Sharp">
                          {avatarName}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ marginRight: "1rem" }}
                        primary={
                          <div className={classes.nameCntr}>
                            <div className={classes.name}>{item.first_name + " " + item.last_name}</div>
                            {isAuthor && <div className={classes.authorTxt}>(Author) </div>}
                            <div className={classes.subDate}>{moment(item.submission_date).format("DD-MMM-YYYY HH:MM")}</div>
                          </div>
                        }
                        secondary={
                          <React.Fragment>
                            <div className={classes.discDesc} dangerouslySetInnerHTML={{ __html: item.reply_data }} />
                            {item.has_replies && (
                              <div className={classes.viewCmntCtr}>
                                {item.id !== selectedReplyListId ? (
                                  <div
                                    className={classes.commentText}
                                    onClick={() => {
                                      fetchReplies(item.id);
                                    }}
                                  >
                                    View all {item.comment_count} comments
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setSelectedReplyListId(null);
                                    }}
                                    className={classes.commentText}
                                  >
                                    Hide comments
                                  </div>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'end' }}>
                    {item && item.attachment_details && item.attachment_details.file_name ? (
                      <React.Fragment>
                        {item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px', fontSize: '22px' }} /> : item.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px', fontSize: '22px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px', fontSize: '22px' }} />}
                        <a href={item.attachment_details && item.attachment_details.file_url && item.attachment_details.file_url} target='_blank' className={classes.uploadedVideo}>
                          {item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name.length > 15 ? (
                            item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name && item.attachment_details.file_name.substr(0, 15) + '...'
                          ) :
                            item.attachment_details && item.attachment_details.file_name && item.attachment_details.file_name && item.attachment_details.file_name
                          }
                        </a>
                      </React.Fragment>
                    ) : null}
                  </Grid>
                  {allowThreadedReply && (
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          setShowReplyEditor(true);
                          setSelectedReplyBtnId(item.id);
                        }}
                        edge="end"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #999",
                          padding: "5px",
                        }}
                        aria-label="delete"
                      >
                        <ReplyIcon className={classes.replyIcon} /> <div style={{ fontSize: "10px" }}>Reply</div>
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </Grid>
              </ListItem>
              {item.id === selectedReplyBtnId && showReplyEditor && (
                <React.Fragment>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    value={replyContent}
                    placeholder="Reply to the Response..."
                    variant="outlined"
                    onChange={handleReplyTextChange}
                    className={classes.textArea}
                  />
                  <div className={classes.btnCtr}>
                    {validationDiscussionError.error ? (
                      <p style={{ fontSize: '13px', color: '#ee5250' }}>
                        Please add something to post!
                      </p>
                    ) : null}
                    <Button
                      onClick={() => {
                        setShowReplyEditor(false);
                      }}
                      variant="contained"
                      size="small"
                      className={classes.btn}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={rplyBtnLdr}
                      onClick={() => {
                        postReplyData();
                      }}
                      variant="contained"
                      size="small"
                      className={classes.btn}
                    >
                      {rplyBtnLdr && <CircularProgress size={20} />} Submit
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {item.id === selectedReplyListId && (
                <List className={classes.repliesList}>
                  {renderSubReplies()}
                  <div className={classes.LoadBtnCtr}>
                    {replies.length >= totalRepliesCount ? (
                      <div></div>
                    ) : (
                      <Button
                        onClick={() => {
                          fetchMoreReplies();
                        }}
                        variant="outlined"
                        size="small"
                        className={classes.btn}
                      >
                        Load More...
                      </Button>
                    )}
                  </div>
                </List>
              )}
              <Divider variant="inset" component="li" />
            </React.Fragment>
          );
        });
    }
    return responseList;
  };

  const renderPinnedResponses = () => {
    let sublistArr = [];
    {
      pinnedResponses.length > 0 &&
        pinnedResponses.map((resp, index) => {
          let avatarName = resp && resp.first_name && resp.first_name.substring(0, 1) + resp && resp.last_name && resp.last_name.substring(0, 1);

          sublistArr.push(
            <div key={index} className={classes.sublistCntr}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatarTopic} alt="Remy Sharp">
                    {avatarName}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ marginRight: "1rem" }}
                  primary={
                    <div className={classes.nameCntr}>
                      <div className={classes.name}>{resp.first_name + " " + resp.last_name}</div>
                      <div className={classes.subDate}>{moment(resp.submission_date).format("DD-MMM-YYYY HH:MM")}</div>
                    </div>
                  }
                  secondary={<div className={classes.discDesc} dangerouslySetInnerHTML={{ __html: resp.reply_text }} />}
                />
                <ListItemSecondaryAction>
                  {resp && resp.attachment_details && resp.attachment_details.file_name ? (
                    <React.Fragment>
                      {resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px', fontSize: '22px' }} /> : resp.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px', fontSize: '22px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px', fontSize: '22px' }} />}
                      <a href={resp.attachment_details && resp.attachment_details.file_url && resp.attachment_details.file_url} target='_blank' className={classes.uploadedVideo}>
                        {resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name.length > 15 ? (
                          resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name && resp.attachment_details.file_name.substr(0, 15) + '...'
                        ) :
                          resp.attachment_details && resp.attachment_details.file_name && resp.attachment_details.file_name && resp.attachment_details.file_name
                        }
                      </a>
                    </React.Fragment>
                  ) : null}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        });
    }
    return sublistArr;
  };

  const renderSubReplies = () => {
    let sublistArr = [];
    {
      replies.length > 0 &&
        replies.map((reply, index) => {
          let avatarName = reply.first_name.substring(0, 1) + reply.last_name.substring(0, 1);
          let isAuthor = reply.role_user && reply.role_user.includes("Author");
          sublistArr.push(
            <div key={index} className={classes.sublistCntr}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={isAuthor ? classes.avatarCommentsAuthor : classes.avatarComments} alt="Remy Sharp">
                    {avatarName}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className={classes.nameCntr}>
                      <div className={classes.name}>{reply.first_name + " " + reply.last_name}</div>
                      {isAuthor && <div className={classes.authorTxt}>(Author) </div>}
                      <div className={classes.subDate}>{moment(reply.submission_date).format("DD-MMM-YYYY HH:MM")}</div>
                    </div>
                  }
                  secondary={reply.comment_text}
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          );
        });
    }
    return sublistArr;
  };

  return (
    <div style={{ marginLeft: "2rem" }}>
      <div className={classes.root}>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: "6rem", paddingLeft: "1rem" }}>
          {discussionTitle && SimpleBreadcrumbs()}
        </Grid>
      </div>
      {fetchResponseFlag ? (
        <React.Fragment>
          <Grid container style={{ justifyContent: "center", marginTop: "2.5rem" }}>
            <Grid item sm={12} xs={12} md={10} lg={10}>
              {discussionTitle && (
                <div className={classes.topicContainer}>
                  <div className={classes.discussionTitle}>{discussionTitle}</div>
                  <div className={classes.discDesc} dangerouslySetInnerHTML={{ __html: discussionDesc }} />
                  <div className={classes.topicBtn}>
                    {!showEditor && discussionTitle && (
                      <IconButton
                        onClick={() => {
                          setShowEditor(true);
                        }}
                        edge="end"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #999",
                          padding: "5px",
                        }}
                        aria-label="delete"
                      >
                        <AddCommentIcon className={classes.commentIcon} /> <div style={{ fontSize: "10px" }}>Post a Response</div>
                      </IconButton>
                    )}
                  </div>
                  <Divider variant="inset" component="div" />
                </div>
              )}

              <List className={classes.topic}>
                {/* {discussionTitle && <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatarTopic}><QuestionAnswerIcon /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText style={{ marginRight: '1rem' }}
                                        primary={<div className={classes.discussionTitle}>{discussionTitle}</div>}
                                        secondary={<div className={classes.discDesc} dangerouslySetInnerHTML={{ __html: discussionDesc }} />}
                                    />
                                </ListItem>}

                                {!showEditor && discussionTitle && <ListItemSecondaryAction>
                                    <IconButton onClick={() => { setShowEditor(true) }} edge="end" style={{ borderRadius: '5px', border: '1px solid #999', padding: '5px' }} aria-label="delete">
                                        <AddCommentIcon className={classes.commentIcon} /> <div style={{ fontSize: '10px' }}>Post a Response</div>
                                    </IconButton>
                                </ListItemSecondaryAction>}
                                <Divider variant="inset" component="li" /> */}
                {showEditor && (
                  <React.Fragment>
                    <Grid container spacing={2} className={classes.editorCtr}>
                      {validationDiscussionError.error ? (
                        <div style={{ marginBottom: '20px' }}>
                          <AlertMessages status="error" message={validationDiscussionError.errorMessage} />
                        </div>
                      ) : null}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextEditor onChangeEditorContent={changeEditorContent} description={responseContent} />
                      </Grid>
                    </Grid>
                    {/* <div style={{ marginTop: '-38px' }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                        {attachmentFileDetails.fileError ? (
                          <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                            {attachmentFileDetails.fileErrorErrorMessage}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ display: 'flex', marginTop: '11px', fontSize: '14px' }}>
                          <span className={classes.uploadLabel} aria-hidden="true">
                            Upload Attachment
                          </span>
                          <IconButton style={{ padding: '2px 8px' }} aria-label="upload attachment" onClick={() => { onFileToUploadClick('attachmentfile') }}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                          </IconButton>
                          {attachmentFileDetails.file && attachmentFileDetails.file.name && uploadedAttachmentPreview ? (
                            <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                              {attachmentFileDetails.file.name.length > 25 ? (
                                attachmentFileDetails.file.name.substr(0, 20) + '....'
                              ) :
                                attachmentFileDetails.file.name
                              }
                            </a>
                          ) : null}
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                          <span style={{ color: '#999', fontSize: '14px' }}>Optional, max.size: 10MB, format: pdf, docx, ppt</span>
                        </div>
                      </Grid>
                      <input type="file" accept=".ppt,.pdf,.docx" style={{ display: 'none' }} id="attachmentfile" onChange={onAttachmentUpload} />
                    </div> */}
                    <div className={classes.btnCtr}>
                      <Button
                        onClick={() => {
                          setShowEditor(false);
                        }}
                        variant="contained"
                        size="small"
                        className={classes.btn}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={respBtnLdr}
                        onClick={() => {
                          postResponseData();
                        }}
                        variant="contained"
                        size="small"
                        className={classes.btn}
                      >
                        {respBtnLdr && <CircularProgress size={20} />} Submit
                      </Button>
                    </div>
                    <Divider />
                  </React.Fragment>
                )}
              </List>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item sm={12} xs={12} md={10} lg={10}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.responseText}>Pinned Responses</div>
              </div>
              <List className={classes.pinnedList}>
                {fetchResponseFlag && pinnedResponses.length > 0 ? (
                  <React.Fragment>{renderPinnedResponses()}</React.Fragment>
                ) : (
                  <div style={{ marginLeft: "3rem" }}>No Responses Found.</div>
                )}
              </List>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center", marginTop: "1rem" }}>
            <Grid item sm={12} xs={12} md={10} lg={10}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.responseText}>Responses</div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    color: style.fontColour._blue,
                    marginRight: "4rem",
                  }}
                >
                  Total {totalResponseCount} Responses
                </div>
              </div>
              <List className={classes.root}>
                {fetchResponseFlag && responses.length > 0 ? (
                  <React.Fragment>
                    {responses.length > 0 && renderResponses()}
                    <div className={classes.LoadBtnCtr}>
                      {responses.length >= totalResponseCount ? (
                        <div>Yay, You Have Read It All.</div>
                      ) : (
                        <Button
                          onClick={() => {
                            fetchDiscussionResponse();
                          }}
                          variant="outlined"
                          size="small"
                          className={classes.btn}
                        >
                          Load More...
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                ) : previewReply === true ? (
                  <div>You Cannot View other's response before adding Yours.</div>
                ) : (
                  <div>No Responses Found.</div>
                )}
              </List>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        <div style={{ marginTop: "4rem", marginLeft: "1rem" }}>
          <DiscussionCommentLoader />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(Discussion);

const useStyles = makeStyles((theme) => ({
  responseText: {
    marginLeft: "4rem",
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
  },
  topicContainer: {
    textAlign: "center",
  },
  discDesc: {
    lineBreak: "anywhere",
  },
  breadCrumbcontainer: {},
  viewCmntCtr: {
    display: "flex",
  },
  discussionTitle: {
    fontSize: style.fontSize._sideHeading,
    fontWeight: 600,
  },
  discDesc: {
    fontSize: style.fontSize._labels,
  },
  editorCtr: {
    justifyContent: "center",
  },
  LoadBtnCtr: {
    margin: "0.2rem 0.2rem 0.5rem 0.2rem",
    textAlign: "center",
  },
  btnCtr: {
    margin: "0.2rem 0.2rem 0.5rem 0.2rem",
    textAlign: "end",
  },
  textArea: {
    marginLeft: "5rem",
    width: "90%",
    marginBottom: "0.5rem",
  },
  btn: {
    marginLeft: "1rem",
    textTransform: 'none',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  root: {
    width: "100%",
    maxWidth: "65rem",
    marginLeft: "4rem",
    backgroundColor: theme.palette.background.paper,
  },
  repliesList: {
    width: "90%",
    marginLeft: "2rem",
    backgroundColor: theme.palette.background.paper,
  },
  pinnedList: {
    width: "100%",
    margin: "1rem 3rem 1rem 1rem",
    maxWidth: "65rem",
    backgroundColor: theme.palette.background.paper,
  },
  topic: {
    width: "100%",
    margin: "1rem 3rem 1rem 1rem",
    maxWidth: "70rem",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  avatarTopic: {
    backgroundColor: "#f2903d",
  },
  avatarCommentsAuthor: {
    backgroundColor: "#f2903d",
    textTransform: "uppercase",
  },
  avatarComments: {
    backgroundColor: "#6cb5f9",
    textTransform: "uppercase",
  },
  replyIcon: {
    fontSize: "10px",
    paddingRight: "2px",
  },
  commentIcon: {
    paddingRight: "2px",
  },
  commentText: {
    fontSize: "0.7rem",
    color: style.fontColour._blue,
    cursor: "pointer",
  },
  nameCntr: {
    display: "flex",
  },
  name: {
    marginRight: "0.5rem",
  },
  subDate: {
    fontSize: "0.8rem",
    paddingTop: "0.2rem",
    color: "#aeaeae",
  },
  authorTxt: {
    fontSize: "0.8rem",
    paddingTop: "0.2rem",
    marginRight: "0.5rem",
    color: style.fontColour._tory_blue,
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    border: '2px solid #ccc',
  },
  uploadLabel: {
    marginTop: '5px'
  },
  uploadIcon: {
    marginTop: '-3px',
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedVideo: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    cursor: 'pointer',
    fontSize: '13px'
  },
}));
