import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from 'react-skeleton-loader';


function TopicsLoader(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.cntr}>
          <Skeleton count={2} height="30px" width="100%" color="#ccc" />
          <Skeleton count={5} height="20px" width="100%" color="#ccc" />
        </div>
      </div>
    </React.Fragment>
  )
}
const useStyles = makeStyles({
  wrapper: {
      width: '100%'
  },
  cntr: {
      marginTop: '1rem'
  },
  txtWrapper: {
      marginLeft: '2rem',
      width: '100%'
  }
})

export default TopicsLoader;