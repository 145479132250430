import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import List from '@material-ui/core/List';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
//local imports
import Header from '../Header';
import { style } from "../../Styles/theme";
import ListingLoader from '../Shared/SkeletonLoaders/discussionLoader';
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import AddDiscussion from './addDiscussion';
import { fetchExternalTabCounts, fetchExternalDiscussions } from '../../Store/Discussions/actionCreator';
import { discussion_msgs } from '../Common/messages';
import TabView from './tabView';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    backgroundColor: style.backgroundColor._lightWhite,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  headerContainer: {
    backgroundColor: "#1c234a",
    borderRadius: "3px",
    marginTop: "1rem",
    paddingTop: "1rem",
  },
  headerBox: {
    display: "flex",
  },
  title: {
    color: "#FFF",
    fontSize: style.fontSize._heading,
    padding: "0 0 1rem 3rem"
  },
}))

function ExternalDiscussionBoard(props){
  const classes = useStyles();
  let rowsPerPage = 10
  let offset = 0
  const morethan700px = useMediaQuery("(max-width:700px)");
  const [page, setPage] = useState(0)
  const [inProgress, setInProgress] = useState(true)
  const [tabCounts, setTabCounts] = useState({
    created: 0,
    assigned: 0
  })
  const [discussionList, setDiscussionList] = useState([])
  const [selectedMode, setSelectedMode] = useState('')
  const [paginationLoader, setPaginationLoader] = useState(false)
  const [selectedDiscussion, setSelectedDiscussion] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)

  //tab counts callbacks
  const successTabCounts = res => {
    setInProgress(false)
    setTabCounts({
      created: res && res.created_discussions,
      assigned: res && res.assigned_discussions
    })
  }
  const failureTabCounts = err => {
    setInProgress(false)
    setTabCounts({
      created: 0,
      assigned: 0
    })
    props.showSnackBar(
      {
       state: false,
       message: 'Sorry something went wrong! While fetching tab counts',
       type: 'error'
      }
    )
  }
  const fetchTabCounts = () => {
    const payload = {
      count: 'True'
    }
    fetchExternalTabCounts(payload, successTabCounts, failureTabCounts)
  }

  const successFetchDiscussions = res => {
    setDiscussionList(res && res.data && res.data)
    setPaginationLoader(false)
  }
  const failureFetchDiscussions = error => {
    setDiscussionList([])
    setPaginationLoader(false)
    props.showSnackBar(
      {
       state: false,
       message: discussion_msgs.ERROR_FETCHING_DISCUSSIONS,
       type: 'error'
      }
    )
  }

  const getDiscussions = (type) => {
    setPaginationLoader(true)
    const payload = {
      offset: offset,
      number: 10,
      type: type ? type : 'created'
    }
    fetchExternalDiscussions(payload, successFetchDiscussions, failureFetchDiscussions)
  }

  useEffect(()=>{
    setInProgress(true)
    getDiscussions('created')
    fetchTabCounts()
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  },[])

  //FOR ADDING DISCUSSION
  const triggerAddDiscussion = () => {
    setSelectedMode('add')
  }
  //FOR CLOSING DISCUSSION FORM
  const closeAddDiscussionForm = () => {
    setSelectedMode('')
    setSelectedDiscussion({})
  }
  //FOR EDIT DISCUSSION
  const triggerEditDiscussion = data => {
    setSelectedMode('edit')
    setSelectedDiscussion(data)
  }

  const onTabChange = tabKey => {
    setSelectedTab(tabKey)
    setPaginationLoader(true)
    setPage(0)
    if(tabKey === 0){
      getDiscussions('created')
    }else{
      getDiscussions('assigned')
    }
  }

  const onPagination = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    setPaginationLoader(true)
    const payload = {
      offset: offset,
      number: number,
      type: selectedTab === 0 ? 'created' : 'assigned'
    }
    fetchExternalDiscussions(payload, successFetchDiscussions, failureFetchDiscussions)
  }


  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Discussions</div>
        </Breadcrumbs>
      </Route>
    );
  }

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 2; i++) {
     children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader/></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  return(
    <div>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Manage Discussions view</h1>
      </div>
      <Grid container>
        <Header history={props} />
      </Grid>
      <Grid container style={{ marginTop: morethan700px ? '118px' : "6rem", paddingLeft: '10px' }}>
        {SimpleBreadcrumbs()}
      </Grid>
      <div className={classes.headerContainer}>
        <div className={classes.headerBox}>
          <Grid container>
            <Grid item sm={12} xs={12} md={8} lg={10} className={classes.titleContainer}>
              <div id="main" tabIndex="-1" className={classes.title} aria-hidden="true" >
                External - Discussions
              </div>
              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <h1>My Discussions listing view</h1>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            { inProgress ? (
              listLoader()
            ) : (
              <React.Fragment>
                { discussionList && (discussionList.length || tabCounts.created || tabCounts.assigned) && selectedMode === '' ? (
                  <div style={{ textAlign: 'right', marginTop: '21px', marginRight: '21px', marginBottom: '10px' }}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      startIcon={<QuestionAnswerIcon />}
                      onClick={triggerAddDiscussion}
                    >
                      <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
                      Create Discussion
                    </Button>
                  </div>
                ) : null }
                { !inProgress && selectedMode === '' ? (
                  <Paper className={classes.paper}>
                    <Typography className={classes.titleWrapper} aria-hidden="true">Manage Discussions</Typography>
                    <Divider style={{ marginBottom: '20px'}} aria-hidden="true"/>
                    { !paginationLoader && (!tabCounts.created && !tabCounts.assigned) ? (
                      <React.Fragment>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                          <Typography style={{ marginTop: '12px', color: '#546e7a'}}>Please click here to create discussions and get started</Typography>
                          <Typography style={{ fontSize: '13px', marginTop: '3px', marginBottom: '21px', color: '#9e9e9e', fontWeight: '500'}}>Discussions you create will show up here.</Typography>
                        </div>
                        <Button
                          variant="contained"
                          className={classes.button}
                          startIcon={<QuestionAnswerIcon />}
                          onClick={triggerAddDiscussion}
                        >
                          <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
                          Create Discussion
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TabView
                          tabCounts={tabCounts}
                          fetchTabCounts={fetchTabCounts}
                          discussionList={discussionList}
                          onTabChange={onTabChange}
                          paginationLoader={paginationLoader}
                          onPagination={onPagination}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          match={props.match}
                          history={props.history}
                          listAllDiscussions={getDiscussions}
                          cancelAddDiscussion={closeAddDiscussionForm}
                          triggerEditDiscussion={triggerEditDiscussion}
                        />
                      </React.Fragment>
                    )}
                  </Paper>
                ) : null }
              </React.Fragment>
            ) }
          </Grid>
        </Grid>
        {selectedMode === 'add' ? (
          <AddDiscussion
            listAllDiscussions={getDiscussions}
            cancelAddDiscussion={closeAddDiscussionForm}
            fetchTabCounts={fetchTabCounts}
            history={props.history}
            match={props.match}
          />
        ) : null}
        { selectedMode === 'edit' ? (
          <AddDiscussion
            listAllDiscussions={getDiscussions}
            cancelAddDiscussion={closeAddDiscussionForm}
            history={props.history}
            match={props.match}
            discussionDetails={selectedDiscussion}
          />
        ) : null }
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(ExternalDiscussionBoard)