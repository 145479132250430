import { getEnv } from "../Helpers/basic";

const apiDomain = (env) => {
  switch (env) {
    case "prod":
      return "//api.myathina.com/";
    case "dev":
    case "test":
    default:
      return "https://cmns.api.moocpedia.com/";
  }
};

const domain = apiDomain(getEnv());

export const apiConfig = {
  userAuth: {
    loginUrl: `${domain}accounts/auth/`,
    fetchProfile: `${domain}accounts/auth/profile/`,
    updateProfile: `${domain}accounts/profile/updatekeys/`
  },
  course: {
    courses: `${domain}course/`,
    courseInfo: `${domain}hosting/course/display/`,
    moduleInfo: `${domain}hosting/module/`,
    courseBrief: `${domain}hosting/display/`,
    lessonDetails: `${domain}hosting/lesson/`,
    fetchCourseContributors: `${domain}hosting/course/instructors/` //FOR FETCHING CONTRIBUTORS OF A COURSE USING COURSE ID
  },
  enrollment: {
    enrollCourse: `${domain}hosting/course/enroll/`,
  },
  profile: {
    myCourses: `${domain}hosting/course/progress/`,
    myCoursesb2b: `${domain}hosting/course/org/progress/`,
    myFavCoursesb2c: `${domain}favourite/profile/list/`,
  },
  videoTracking: {
    postTrackInfo: `${domain}video/log/`,
    getTrackInfo: `${domain}video/log/`,
  },
  lessonTracking: {
    postLessonTrackInfo: `${domain}hosting/status/`,
  },
  reading: {
    getReading: `${domain}getReading/`,
  },
  assessment: {
    tests: `${domain}hosting/assessment/user/data/`,
    getQuestions: `${domain}hosting/assessment/render/`,
    submitAssessment: `${domain}hosting/assessment/result/`,
    getReport: `${domain}hosting/assessment/result/`,
    //for external assessments
    fetchExternalAssessments: `${domain}assessments/external/`,
    fetchSingleAssessment: `${domain}assessment/external`
  },
  assignment: {
    assignments: `${domain}assignment/view/`,
    getAssignment: `${domain}assignment/overview/`,
    submitAssignment: `${domain}assignment/answer/`,
    updateAssignment: `${domain}assignment/answer/update/`,
    deleteAttachment: `${domain}assignment/attachment/delete/`,
    collectiveAssignment: `${domain}assignment/render/collective/assignment/`,
    //FOR EXTERNAL ASSIGNMENTS
    fetchExternalAssignments: `${domain}externalassignment/listing/`,
    getExternalAssignment: `${domain}externalassignment/fetch/`,
    submitExternalAssignment: `${domain}externalassingmnet/submit/`,
    updateExternalAssignment: `${domain}externalassignment/update/`,
    deleteExternalAttachment: `${domain}externalassignment/delete/`,
  },
  discussions: {
    discussionsList: `${domain}discussion/forum/view/`,
    fetchResponses: `${domain}discussion/forum/listing/`,
    fetchReplies: `${domain}discussion/comment/sublist/`,
    postResponse: `${domain}discussion/reply/`,
    postReply: `${domain}discussion/comment/`,
    fetchPinnedResponses: `${domain}discussion/pin/listing`,
    //EXTERNAL DISCUSSIONS
    createExternalDiscussion: `${domain}discussion/external/`, //FOR CREATING DISCUSSION
    updateExternalDiscussion: `${domain}discussion/external/`, //FOR UPDATING DISCUSSION
    deleteExternalDiscussion: `${domain}discussion/external/`, //FOR DISCUSSION DELETE
    fetchAllExternalDiscussions: `${domain}discussion/external/`, //FOR FETCHING ALL THE DISCUSSIONS
    assignDiscussionToGrps: `${domain}discussion/external/`, //for assigning discussion to grps
    publishExternalDiscussion: `${domain}discussion/external/`, //FOR PUBLISH/UNPUBLISH DISCUSSION
    fetchExternalTabCounts: `${domain}discussion/external/`, //FOR FETCHING TAB COUNTS
    fetchExternalDiscussion: `${domain}discussion/external/`, //FOR FETCHING SINGLE DISCUSSOIN DETAILS
    externalPostResponse: `${domain}discussion/external/reply/`, //FOR POSTING REPLY TO A DISCUSSION
    fetchExternalResponses: `${domain}discussion/external/reply/`, //FOR FETCHING DISCUSSION RESPONSES
    fetchOrgGrpsList: `${domain}accounts/group_list/`, //for dropdown grp structure selection
    fetchStudentMentors: `${domain}accounts/student_mentor_list/`, //for fetching students mentor list
    externalPostReply: `${domain}discussion/external/comment/`, //FOR POSTING REPLY TO A response
    fetchExternalPinnedResponses: `${domain}discussion/external/pin/listing/`, //FOR FETCHING EXTERNAL PINNED RESPONSES
    fetchExternalResponseReplies: `${domain}discussion/external/comment/listing/`, //FOR FETCHING RESPONSE REPLIES
  },
  community: {
    fetchEnrolledCourseTitles: `${domain}community/enrolledcourses/`, //FOR DROPDOWN MENU input user key
    createTopic: `${domain}community/create/`, //FOR CREATING TOPIC input userkey,
    fetchMyCourseTitles: `${domain}community/mycoursetitles/`, //FOR LEFTMENU PANEL input userid,
    fetchMyCourseTopics: `${domain}community/mycoursetopics/`, //FOR FETCHING ONE PARTICULAR COURSE TOPICS input courseid
    fetchQuestionsAsked: `${domain}community/myquestions/`, //FOE FETCHING QUESTIONS ASKED BY STUDENT input user key
  },
  meetings: {
    createEvent: `${domain}live_events/live_event/`,
    modifyEvent: `${domain}live_events/live_event/`,
    getEventList: `${domain}live_events/list_event/`,
    deleteEvent: `${domain}live_events/live_event/`,
    classesAssignedToMe: `${domain}live_events/shared_recordings/`,
    fetchUsers: `${domain}accounts/mentor_list/`
  },
  myVideos: {
    fetchMyVideos: `${domain}user/myvideos/`,
    fetchVideoDetails: `${domain}user/video/`
  },
  noticeBoard: {
    fetchMyNotices: `${domain}notices/notice/`,
    updateViewNoticeStatus: `${domain}notices/update_notice_read_status/`,
    fetchHeaderNoticeCount: `${domain}notices/notice_read_count/`,
  }
};
