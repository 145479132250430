//Assignment Messages
export const assignment_msgs = {
    ASSIGNMENT_SAVED_SUCCESS: 'Assignment Saved Successfully.',
    ASSIGNMENT_SUBMIT_SUCCESS: 'Assignment Submitted Successfully.',
    ASSIGNMENT_SAVED_FAILED: 'Something went wrong.Please Try again later.',
    ASSIGNMENT_SUBMIT_FAILED: 'Something went wrong while updaing course details.',
    ASSIGNMENT_FETCH_FAILED: 'Something went wrong while fetching assignment details.',
    ASSIGNMENT_LIST_FETCH_FAILED: 'Something went wrong while fetching assignment list'

}

// Attachment Error Messages
export const attachment_msgs = {
    ATTACHMENT_MAX_FILE_SIZE_WARN: 'File cannot be larger than 10 MB.',
    ATTACHMENT_MAX_FILES_WARN: 'Upload Limit Reached! Maximum 3 Files.',
    ATTACHMENT_FILE_TYPE_WARN: 'Only [.pdf, .ppt, .doc] File types allowed.'
}

// Discussion Error Messages
export const discussion_msgs = {
    LOAD_ERROR_MSG: 'Some Issue Fetching Data.',
    RESPONSE_SUBMIT_SUCCESS: 'Response Submitted Successfully.',
    RESPONSE_SUBMIT_FAILED: 'Response Submission Failed.',
    REPLY_SUBMIT_SUCCESS: 'Reply Submitted Successfully.',
    REPLY_SUBMIT_FAILED: 'Reply Submitted Successfully.',
    ERROR_FETCHING_DISCUSSIONS: 'Something went wrong while fetching discussions!',
    SUCCESS_DISCUSSION_UPDATE: 'Discussion updated successfully',
    ERROR_UPDATE_DISCUSSION: 'Something went wrong! while updating discussion!',
    SUCCESS_DISCUSSION_CREATE: 'Discussion created successfully',
    ERROR_CREATE_DISCUSSION: 'Something went wrong! while creating discussion!',
    ERROR_DELETE_DISCUSSION: 'Something went wrong! while deleting discussion!',
    SUCCESS_DELETE_DISCUSSION: 'Discussion deleted successfully!',
    SUCCESS_DISCUSSION_PUBLISH: 'Discussion published successfully!',
    SUCCESS_DISCUSSION_UNPUBLISH: 'Discussion unpublished successfully!',
    ERROR_DISCUSSION_PUBLISH: 'Something went wrong! while publishing discussion!',
    ERROR_DISCUSSION_UNPUBLISH: 'Something went wrong! while unpublishing discussion!',
    ERROR_DISCUSSION_ASSIGN: 'Something went wrong while assigning to members!',
    SUCCESS_DISCUSSION_ASSIGN: 'Discussion assigned successfully'
}

//Community Messages
export const community_msgs = {
    SUCCESS_CREATE_TOPIC: 'Topic Successfully created!',
    ERROR_CREATE_TOPIC: 'Something went wrong while creating topic!',
    ERROR_FETCH_ENROLLED_COURSE_NAMES: 'Something went wrong while fetching course titles',
    ERROR_FETCH_COURSE_TOPICS: 'Something went wrong while fetching course topics',
    ERROR_FETCH_ASKED_QUESTIONS_LIST: 'Something went wrong while fetching your topics'
}

export const myVideos_msgs = {
    ERROR_FETCH_MYVIDEOS: 'Something went wrong while fetching videos!',
    ERROR_FETCH_VIDEO_DATA: 'Something went wrong while fetching video details!'
}

export const ERROR_FETCHING_PROFILE = 'Something went wrong while fetching user details'

export const noticeBoard_msgs = {
    ERROR_FETCHING_NOTICE: 'Something went wrong while fetching notices. Please try again!'
}
