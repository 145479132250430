import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Header from "../Header";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import AddTopic from './addTopic'
import { fetchMyCourseTitles, fetchMyCourseTopics, fetchQuestionsAsked } from '../../Store/Community/actionCreator'
import { community_msgs } from '../Common/messages'
import { style } from "../../Styles/theme";
import CourseTopicsListView from './courseTopicsListView'
import { convertUTCDatewithTime } from '../../Helpers/basic'
import CommunityTopicLoader from '../Shared/SkeletonLoaders/communityTopicLoader'
import { ListItemAvatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '30px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  button: {
    textTransform: 'none',
    flexGrow: 1,
    float:'right',
  },
  topicsWrapper: {
    height: '500px',
    overflow: 'auto',
    border: '18px',
    width: '83%',
    boxShadow: '1px 2px 3px 1px #ccc',
    borderRadius: '5px',
    marginTop: '20px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '22px'
  },
  courseTitle: {
    fontSize: '25px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'center',
    lineBreak: 'anywhere'
  },
  topicInfo: {
    fontSize: '19px',
    fontWeight: '500',
    color: style.fontColour._egyptian_blue,
    textAlign: 'left',
    marginTop: '0px'
  },
  avatarWrapper: {
    backgroundColor: style.fontColour._blue,
  },
  likesWrapper: {
    display:'flex',
    flexDirection:'column',
    marginLeft: '10px',
    marginBottom: '-20px'
  },
}));

function StudentCommunity(props){
  const classes = useStyles();
  const [addTopicMode, setAddTopicMode] = React.useState(false)
  const [myCourseTitles, setMyCourseTitles] = React.useState([])
  const [myCourseBtnPrgs, setMyCourseBtnPrgs] = React.useState(false)
  const [myCoursePageNum, setMyCoursePageNum] = React.useState(0)
  const [myCourseTitlesLength, setMyCourseTitlesLength] = React.useState('')
  const [courseTopicsListView, setCourseTopicsListView] = React.useState(false)
  const [myCourseId, setMyCourseId] = React.useState('')
  const [myCourseTopics, setMyCourseTopics] = React.useState([])
  const [myCourseTopicsLength, setMyCourseTopicsLength] = React.useState('')
  const [myCourseTopicsBtnPrgs, setMyCourseTopicsBtnPrgs] = React.useState(false)
  const [myCourseTopicsPageNum, setMyCourseTopicsPageNum] = React.useState(0)
  const [myCourseData, setMyCourseData] = React.useState({})
  const [leftMenuLoader, setLeftMenuLoader] = React.useState(false)
  const [courseTopicsLoader, setTopicsLoader] = React.useState(false)
  const [askedQuestionsList, setAskedQuestionsList] = React.useState([])
  const [askedQuestionsLength, setAskedQuestionsLength] = React.useState([])
  const [showAskedQuesView, setShowAskedQuesView] = React.useState(true)
  const topicsrowsPerPage = 10
  const rowsPerPage = 5

  const triggerAddTopic = () => {
    setAddTopicMode(true)
  }
  const closeAddTopicForm = () => {
    setAddTopicMode(false)
  }

  const successCouresTitlesCB = res => {
    setLeftMenuLoader(false)
    setMyCourseTitlesLength(res.total_count)
    setMyCourseTitles(res.results)
  }
  const failureCourseTitlesCB = error => {
    setLeftMenuLoader(false)
    setMyCourseTitles([])
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_FETCH_ENROLLED_COURSE_NAMES,
      type: "error",
    });
  }
  const courseTitlesFetch = (pageNum) => {
    setLeftMenuLoader(true)
    const payload = {
      number: rowsPerPage,
      offset: pageNum * rowsPerPage
    }
    fetchMyCourseTitles(payload, successCouresTitlesCB, failureCourseTitlesCB)
  }

  //ASKED QUESTIONS FATCH CALLBACKS
  const successQuestionsAskedCB = res => {
    setTopicsLoader(false)
    setAskedQuestionsLength(res.total_count)
    setAskedQuestionsList(res.results)
  }
  const failureQuestionsAskedCB = error => {
    setTopicsLoader(false)
    setAskedQuestionsList([])
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_FETCH_ASKED_QUESTIONS_LIST,
      type: "error",
    });
  }
  const askedQuestionsFetch = (pageNum) => {
    setTopicsLoader(true)
    const payload = {
      number: topicsrowsPerPage,
      offset: pageNum * topicsrowsPerPage
    }
    fetchQuestionsAsked(payload, successQuestionsAskedCB, failureQuestionsAskedCB)
  }

  useEffect(() => {
    courseTitlesFetch(0)
    askedQuestionsFetch(0)
  },[])

  //COURSE TOPICS FETCH UNDER ONE COURSE
  const successCourseTopicsCB = res => {
    setTopicsLoader(false)
    setMyCourseTopicsLength(res.total_count)
    setMyCourseTopics(res.results)
    setCourseTopicsListView(true)
  }
  const failureCourseTopicsCB = error => {
    setTopicsLoader(false)
    setMyCourseTopics([])
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_FETCH_COURSE_TOPICS,
      type: "error",
    });
  }

  const myCourseTopicsFetch = (pageNum, courseId) => {
    setTopicsLoader(true)
    const payload = {
      courseId: courseId,
      number: topicsrowsPerPage,
      offset: pageNum * topicsrowsPerPage
    }
    fetchMyCourseTopics(payload, successCourseTopicsCB, failureCourseTopicsCB)
  }

  const onCourseTitleClick = (data) => {
    setMyCourseTopicsPageNum(0)
    setMyCourseId(data.id)
    setMyCourseData(data)
    myCourseTopicsFetch(0, data.id)
    closeQuestionsAskedView()
  }

  const closeCourseTopicsView = () => {
    setCourseTopicsListView(false)
    setMyCourseTopics([])
    setMyCourseData({})
  }

  //FOR VIEW MORE ON COURSE TITLES IN LEFT PANEL
  const successMoreMyCourseTitles = res => {
    setMyCourseBtnPrgs(false)
    if(res && res.results.length){
      let newData =[...myCourseTitles]
      {res.results.map((data, index)=>{
        newData.push(data)
      })}
      setMyCourseTitles(newData)
    }
  }
  const failureMoreMyCourseTitles = error => {
    setMyCourseBtnPrgs(false)
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_FETCH_COURSE_TOPICS,
      type: "error",
    });
  }
  const loadMoreMyCourseTitles = () => {
    setMyCourseBtnPrgs(true)
    setMyCoursePageNum(myCoursePageNum + 10)
    const payload = {
      number: rowsPerPage,
      offset: myCoursePageNum + rowsPerPage
    }
    fetchMyCourseTitles(payload, successMoreMyCourseTitles, failureMoreMyCourseTitles)
  }

  //FOR LOAD MORE ON COURSE TOPICS UNDER ONE COURSE
  const successMoreTopicsCB = res => {
    setMyCourseTopicsBtnPrgs(false)
    if(res && res.results.length){
      let newData =[...myCourseTopics]
      {res.results.map((data, index)=>{
        newData.push(data)
      })}
      setMyCourseTopics(newData)
    }
  }
  const failureMoreTopicsCB = error => {
    setMyCourseTopicsBtnPrgs(false)
    props.showSnackBar({
      state: true,
      message: community_msgs.ERROR_FETCH_ENROLLED_COURSE_NAMES,
      type: "error",
    });
  }
  const loadMoreMyCourseTopics = () => {
    setMyCourseTopicsBtnPrgs(true)
    setMyCourseTopicsPageNum(myCourseTopicsPageNum + 10)
    const payload = {
      courseId: myCourseId,
      number: topicsrowsPerPage,
      offset: myCourseTopicsPageNum + topicsrowsPerPage
    }
    fetchMyCourseTopics(payload, successMoreTopicsCB, failureMoreTopicsCB)
  }

  const onQuestionsAskedClick = () => {
    setShowAskedQuesView(true)
    closeCourseTopicsView()
  }
  const closeQuestionsAskedView = () => {
    setShowAskedQuesView(false)
  }

  const dateTimeFormat = (due_days) => {
    return(convertUTCDatewithTime(due_days))
  }
  function createMarkup(data) {
    return {
      __html: data
    };
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Student Discussions</div>
        </Breadcrumbs>
      </Route>
    );
  }

  let btnValid = false
  if(myCourseBtnPrgs){
    btnValid = true
  }
  else if(myCourseTitles.length >= myCourseTitlesLength){
    btnValid = true
  }
  return(
    <React.Fragment>
      <div>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: "5rem", paddingLeft: "1rem" }}>
          {SimpleBreadcrumbs()}
        </Grid>
      </div>
      <div style={{ marginRight: '15px'}}>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={triggerAddTopic}
        >
          New Topic
        </Button>
      </div>
      <div className={classes.root}>
        { !addTopicMode ? (
          <Grid container>
            <Grid item xs={8} sm={8} md={2} lg={2} xl={2}>
              <div className={classes.topicsWrapper}>
                <Typography style={{ padding: '0.2rem', fontWeight: '500', color: style.fontColour._egyptian_blue, textAlign: 'center'}}>My Courses</Typography>
                <Divider/>
                { leftMenuLoader ? (
                  <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress size={25} style={{ marginTop: '10px'}} />
                  </div>
                ) : (
                  <React.Fragment>
                    {myCourseTitles && myCourseTitles.length ? (
                      <React.Fragment>
                        <List>
                          {myCourseTitles.map((data, index) => (
                            <ListItem key={index}>
                              <Link component="button" onClick={() => { onCourseTitleClick(data) }} style={{ textDecoration: 'none', color: myCourseData && myCourseData.title === data.title ? style.fontColour._grey : style.fontColour._egyptian_blue , cursor: 'pointer', fontSize: myCourseData && myCourseData.title === data.title ? '18px' : '15px', fontWeight: '500', lineBreak: 'anywhere'}}>
                                {data.title && data.title.length > 25 ? (
                                  data.title.substr(0,20) + '...'
                                ) : (
                                  data.title
                                )}
                              </Link>
                            </ListItem>
                          ))}
                        </List>
                        {myCourseTitles.length >= 2 ? (
                          <React.Fragment>
                            {myCourseBtnPrgs ? (
                              <CircularProgress size={20} style={{ marginBottom: '-9px'}} />
                            ) : null }
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                              <Button disabled={btnValid} style={{ textTransform: 'none', flexGrow: 1, textAlign: 'center'}} size="small" color="primary" onClick={() => {loadMoreMyCourseTitles()}}>
                                View More
                              </Button>
                            </div>
                          </React.Fragment>
                        ) : null  }
                        <Divider/>
                      </React.Fragment>
                    ) : (
                      <Typography style={{ fontSize: '15px', textAlign: 'center'}}>No data Found</Typography>
                    ) }
                  </React.Fragment>
                ) }
                <Typography style={{ padding: '0.2rem', fontWeight: '500', color: style.fontColour._egyptian_blue, textAlign: 'center'}}>My Content</Typography>
                <Divider/>
                <List>
                  <ListItem>
                    <Link component="button" onClick={() => {onQuestionsAskedClick()}} style={{ color: showAskedQuesView ? style.fontColour._grey : style.fontColour._egyptian_blue, textDecoration: 'none', cursor: 'pointer', fontSize: showAskedQuesView ? '18px' : '15px', fontWeight: '500', lineBreak: 'anywhere'}}>
                      Questions Asked
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component="button" style={{ textDecoration: 'none', cursor: 'pointer', fontSize: '15px', fontWeight: '500', lineBreak: 'anywhere'}}>
                      Questions Answered
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component="button" style={{ textDecoration: 'none', cursor: 'pointer', fontSize: '15px', fontWeight: '500', lineBreak: 'anywhere'}}>
                      My Bookmarks
                    </Link>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              { courseTopicsListView ? (
                <CourseTopicsListView history={props.history} match={props.match} myCourseId={myCourseId} myCourseTopics={myCourseTopics} myCourseTopicsLength={myCourseTopicsLength} closeCourseTopicsView={closeCourseTopicsView} 
                  loadMoreMyCourseTopics={loadMoreMyCourseTopics}
                  myCourseTopicsBtnPrgs={myCourseTopicsBtnPrgs} 
                  myCourseData={myCourseData}
                  courseTopicsLoader={courseTopicsLoader}
                />
              ) : null }
              { showAskedQuesView ? (
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.courseTitle}> Your Questions </Typography>
                      <Divider/>
                      { courseTopicsLoader ? (
                        <CommunityTopicLoader/>
                      ) : (
                        <React.Fragment>
                          {askedQuestionsList && askedQuestionsList.length ? (
                            <React.Fragment>
                              <List>
                                { askedQuestionsList && askedQuestionsList.map((data, index) => (
                                  <React.Fragment>
                                    <ListItem>
                                      <Grid container>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                          <div style={{ display: 'flex'}}>
                                            <ListItemAvatar>
                                              <Avatar className={classes.avatarWrapper}>
                                                {data.first_name && data.first_name.substring(0, 1)}
                                              </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText className={classes.topicInfo} primary={`${data.first_name && data.first_name} ${data.last_name && data.last_name}`} secondary={dateTimeFormat(data.created_date && data.created_date)} />
                                          </div>
                                        </Grid>
                                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                          <div style={{display:'flex', marginBottom: '-26px'}}>
                                            <div className={classes.likesWrapper}>
                                              <span>{data.views}</span>
                                              <h5 style={{ marginTop: '2px'}}>Views</h5>
                                            </div>
                                            <div className={classes.likesWrapper}>
                                              <span>{data.likes}</span>
                                              <h5 style={{ marginTop: '2px'}}>Likes</h5>
                                            </div>
                                            <div className={classes.likesWrapper}>
                                              <span>{data.replies}</span>
                                              <h5 style={{ marginTop: '2px'}}>Replies</h5>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Link component="button" style={{ textDecoration: 'none', cursor: 'pointer', fontSize: '20px', fontWeight: '500', lineBreak: 'anywhere'}}>
                                            {data.topic_title && data.topic_title.length > 70 ? (
                                              data.topic_title.substr(0,65) + '...'
                                            ) : (
                                              data.topic_title
                                            )}
                                          </Link>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <div style={{ textAlign: 'initial', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={createMarkup( data.topic_desc && data.topic_desc )}></div>
                                        </Grid>
                                      </Grid>
                                    </ListItem>
                                    <Divider/>
                                  </React.Fragment>
                                ))}
                              </List>
                            </React.Fragment>
                          ) : null }
                        </React.Fragment>
                      ) }
                    </Paper>
                  </Grid>
                </Grid>
              ) : null }
            </Grid>
          </Grid>
        ) : null }
      </div>
      { addTopicMode ? (
        <AddTopic closeAddTopicForm={closeAddTopicForm} courseTitlesFetch={courseTitlesFetch}/>
      ) : null }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(StudentCommunity);