import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'
import { zoomClasses } from './mockJSON';

export const fetchMyClasses = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.meetings.classesAssignedToMe}`
    axios.get(url, {
        params: payLoad
    })
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const fetchUsers = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.meetings.fetchUsers}`
    axios.get(url)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}
