import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const getDiscussionList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.discussionsList}`
  axios.get(
    url, {
    params: payload
  }).then(function (response) {
    successCallBack(response.data)
  }).catch(function (error) {
    failureCallBack(error)
  })
}

const mockDisRes = {
  "results": {
    "id": 10,
    "title": "Test discussion",
    "description": "<p>About discussion</p>",
    "replies": [
      {
        "id": 15,
        "first_name": "Kalyan",
        "last_name": "Kumar",
        "submission_date": "2021-06-14T13:54:47.850880Z",
        "reply_data": "<p>response 123</p>",
        "has_replies": false,
        "attachment_details": {
          "file_name": "POSH.pdf",
          "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
        },
        "comment_count": 0,
        "role_user": [
          "Student"
        ]
      },
      {
        "id": 14,
        "first_name": "Kalyan",
        "last_name": "Kumar",
        "submission_date": "2021-06-14T13:00:04.032453Z",
        "reply_data": "<p>response 345</p>",
        "has_replies": false,
        "attachment_details": {
          "file_name": "POSH.ppt",
          "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
        },
        "comment_count": 0,
        "role_user": [
          "Student"
        ]
      }
    ]
  },
  "reply_count": 2,
  "threaded_reply": true,
  "preview_reply": true,
  "course_id": 2,
  "course_title": "Python Basics"
}
export const fetchResponses = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchResponses}`
  axios.get(
    url, {
    params: payload
  }).then(function (response) {
    successCallBack(response.data)
    //successCallBack(mockDisRes)
  }).catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchResponseReplies = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchReplies}`
  axios.get(
    url, {
    params: payload
  }).then(function (response) {
    successCallBack(response.data)
  }).catch(function (error) {
    failureCallBack(error)
  })
}

const mockPin = {
  "results": [
    {
      "id": 14,
      "first_name": "Kalyan",
      "last_name": "Kumar",
      "submission_date": "2021-06-14T15:01:50.379038Z",
      "reply_text": "<p>response</p>",
      "attachment_details": {
        "file_name": "POSH.ppt",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    }
  ],
  "pin_count": 1
}

export const fetchPinnedResponses = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchPinnedResponses}`
  axios.get(
    url, {
    params: payload
  }).then(function (response) {
    successCallBack(response.data)
    //successCallBack(mockPin)
  }).catch(function (error) {
    failureCallBack(error)
  })
}

export const postResponse = (payload, successCB, failureCB) => {
  const url = `${apiConfig.discussions.postResponse}`
  axios.post(
    url, payload).then(function (response) {
      successCB(response.data)
    }).catch(function (error) {
      failureCB(error)
    })
}

export const postReply = (payload, successCB, failureCB) => {
  const url = `${apiConfig.discussions.postReply}`
  axios.post(
    url, payload).then(function (response) {
      successCB(response.data)
    }).catch(function (error) {
      failureCB(error)
    })
}

//FOR EXTERNAL DISCUSSION API CALLBACKS ----------------------------------------------

//create external discussion
export const createExternalDiscussion = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.createExternalDiscussion}`
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//update external discussion
export const updateExternalDiscussion = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.updateExternalDiscussion}${payload.id}/`
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR ASSIGNING DISCUSSION TO A GROUPS
export const assignDiscussionToGrps = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.assignDiscussionToGrps}${payload.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//delete external discussion
export const deleteExternalDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.deleteExternalDiscussion}${payload.id}/`
  axios.delete(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//fetch tab counts
export const fetchExternalTabCounts = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalTabCounts}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//fetch all the external discussions
export const fetchExternalDiscussions = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchAllExternalDiscussions}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//publish external discussion
export const publishExternalDiscussion = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.publishExternalDiscussion}${payload.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//fetch single discussion details
export const fetchExternalDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalDiscussion}${payload.id}/`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//for posting reply to a discussion
export const externalPostResponse = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalPostResponse}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockRes = {
  "results": [
    {
      "id": 96,
      "first_name": "Kalyan",
      "last_name": "Kumar",
      "submission_date": "2021-06-15T07:10:11.495189Z",
      "reply_text": "<p>reply</p>",
      "has_comments": false,
      "comment_count": 0,
      "role": "Student",
      "attachment_details": {
        "file_name": "POSH.pdf",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    },
    {
      "id": 95,
      "first_name": "Kalyan",
      "last_name": "Kumar",
      "submission_date": "2021-06-15T07:08:46.369144Z",
      "reply_text": "<p>response</p>",
      "has_comments": false,
      "comment_count": 0,
      "role": "Student",
      "attachment_details": {
        "file_name": "POSH.ppt",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    },
    {
      "id": 94,
      "first_name": "Kalyan",
      "last_name": "Kumar",
      "submission_date": "2021-06-15T06:57:04.192183Z",
      "reply_text": "<p>response</p>",
      "has_comments": false,
      "comment_count": 0,
      "role": "Student",
      "attachment_details": {
        "file_name": "POSH.docx",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    }
  ]
}

//fetch external discussion responses
export const fetchExternalResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockRes)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING ENTIRE ORG GRPS LIST STRUCTURE FOR DROPDOWN SELECTION
export const fetchOrgGrpsList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchOrgGrpsList}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING STUDENTS MENTOR LIST
export const fetchStudentMentors = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchStudentMentors}${payload.student_id}/`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockExPin = {
  "results": [
    {
      "id": 96,
      "first_name": "Kalyan",
      "last_name": "Kumar",
      "submission_date": "2021-06-15T07:15:42.967365Z",
      "reply_text": "<p>reply</p>",
      "role": "Student",
      "attachment_details": {
        "file_name": "POSH.ppt",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    }
  ],
  "pin_count": 1
}
//FOR FETCHING EXTERNAL PINNED RESPONSES
export const fetchExternalPinnedResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalPinnedResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockExPin)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//for fetching external sub replies
export const fetchExternalResponseReplies = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalResponseReplies}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//for posting reply to a discussion
export const externalPostReply = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalPostReply}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}