import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Route } from "react-router-dom"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link as RouterLink } from "react-router-dom"
import Paper from '@material-ui/core/Paper'
import { style } from "../../Styles/theme"
import { showSnackBar } from "../../Store/AlertMessages/actionCreator"
import { fetchVideoDetails } from '../../Store/MyVideos/actionCreator'
import Header from "../Header"
import { myVideos_msgs } from '../Common/messages'
import CourseHeader from '../Shared/courseHeader'
import VimeoPlayer from "../Shared/vimeoPlayer";
import CourseLoader from '../Shared/SkeletonLoaders/courseDescLoader'

function createMarkup(desc) {
  return {
    __html: desc,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '3px',
    textAlign: 'center',
    width: '100%'
  },
  progress: {
    marginTop: "0.5rem",
    borderRadius: "10px",
  },
  paperWrapper: {
    marginTop: '36px',
    marginBottom: '30px'
  },
  videoPlayer: {
    padding: "2.5rem 0 2.5 0",
    textAlign: "center",
  },
  videoDescTitle: {
    padding: "0.5rem 0 0 1rem",
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    fontWeight: 500,
    fontFamily: "Roboto,sans-serif",
  },
  videoDescContent: {
    padding: "0.5rem 0 1rem 1rem",
  },
}))

function VideoDetails(props) {
  const classes = useStyles()
  const matches = useMediaQuery("(max-width:700px)");
  const [inProgress, setInprogress] = React.useState(false)
  const [videoData, setVideoData] = React.useState({})
  const tenantId = props.match.params.tenantId ? props.match.params.tenantId : ''
  const [videoPlayerProps, setVideoPlayerProps] = React.useState();

  const successFetchVideo = res => {
    setInprogress(false)
    setVideoData(res && res.results)
    let videoPlayerData = {
      videoURL: res && res.results && res.results.video_url ? res.results.video_url : '',
      transcript_url: res && res.results && res.results.transcript ? res.results.transcript : '',
      type: "lesson",
    };
    setVideoPlayerProps(videoPlayerData)
  }
  const failureFetchVideo = error => {
    setInprogress(false)
    setVideoData([])
    props.showSnackBar({
      state: true,
      message: myVideos_msgs.ERROR_FETCH_VIDEO_DATA,
      type: "error",
    });
  }

  const fetchVideoData = () => {
    const payload = {
      video_id: props.match.params.videoId
    }
    fetchVideoDetails(payload, successFetchVideo, failureFetchVideo)
  }

  useEffect(()=>{
    setInprogress(true)
    fetchVideoData()
  },[])

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/myvideos`}>
            My videos
          </RouterLink>
          <div style={style.brdCrmbSecondary}>{videoData && videoData.title ? videoData.title : 'Video'}</div>
        </Breadcrumbs>
      </Route>
    );
  }
  let level = "";
  if (videoData && videoData.level === 1) {
    level = "Beginner";
  } else if (videoData && videoData.level === 2) {
    level = "Intermediate";
  } else if (videoData && videoData.level === 3) {
    level = "Expert";
  }
  return(
    <React.Fragment>
      <div>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div>
        <Grid container style={{ marginTop: matches ? '118px' : "5rem", paddingLeft: "1rem" }}>
          {SimpleBreadcrumbs()}
        </Grid>
      </div>
      { inProgress ? (
        <Grid container style={{ marginTop: '10px'}}>
          <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <CourseLoader/>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          <div style={{ marginTop: '6px'}}>
            <CourseHeader type='myVideos' data={videoData} level={level}/>
          </div>
          <Grid container>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Paper elevation={2} className={classes.paperWrapper}>
                {videoPlayerProps &&
                videoPlayerProps.videoURL !== undefined &&
                videoPlayerProps.videoURL !== "" &&
                videoPlayerProps.videoURL.includes("vimeo") ? (
                  <div className={classes.videoPlayer}>
                    <VimeoPlayer data={videoPlayerProps} />
                  </div>
                ) : null}
                <React.Fragment>
                  <div className={classes.videoDescTitle}>About this Video</div>
                  <div className={classes.videoDescContent} dangerouslySetInnerHTML={createMarkup(videoData && videoData.description)} />
                </React.Fragment>
              </Paper>
            </Grid>
          </Grid>
        </React.Fragment>
      ) }
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(VideoDetails);