import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from 'react-skeleton-loader';

function VideoPlayerLoader(props) {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div>
                <Skeleton height="450px" width="100%" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
        </div>
    )
}
const useStyles = makeStyles({
    wrapper: {
        width: '100%'
    }
})

export default VideoPlayerLoader;