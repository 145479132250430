import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Local Imports
import { style } from "../../Styles/theme";
import { getReport } from "../../Store/Assessments/actionCreator";

export default function Report(props) {
  const classes = useStyles();
  const [assessmentSummary, setAssessmentSummary] = useState([]);
  useEffect(() => {
    let payload = {
      assessmentId: props.data.id,
    };
    getReport(payload, successCallBack, failureCallBack);
  }, [props]);

  const successCallBack = (res) => {
    setAssessmentSummary(res);
  };

  const renderAnswerContainer = (type, options, answer) => {
    if (type === "objective") {
      return (
        <FormControl component="fieldset">
          <RadioGroup aria-label="answers" value={answer.toString()}>
            {options.map((item, index) => {
              return (
                <FormControlLabel
                  key={index}
                  className={classes.radioOptions}
                  value={item.id}
                  control={<Radio color="secondary" />}
                  label={
                    <div dangerouslySetInnerHTML={{ __html: item && item.option && item.option }} />
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    } else if (type === "checkbox") {
      {
        return (
          <FormControl component="fieldset">
            <Grid container>
              {options.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                    <FormControlLabel
                      value={index}
                      control={
                        <Checkbox
                          id={`check_${index}`}
                          color="secondary"
                          checked={answer.indexOf(index) > -1}
                        />
                      }
                      label={
                        <div dangerouslySetInnerHTML={{ __html: item && item.option && item.option }} />
                      }
                      labelPlacement="end"
                      className={classes.checkOptions}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormControl>
        );
      }
    } else if (type === "text") {
      return (
        <React.Fragment>
          <div className={classes.textArea}>Ans:</div>
          <div style={{ paddingLeft: '30px' }}>{
            <div dangerouslySetInnerHTML={{ __html: answer && answer }} />
          }</div>
        </React.Fragment>
      )

    }
  };

  const failureCallBack = () => { };

  const renderCard = () => {
    let dataArr = [];
    assessmentSummary &&
      assessmentSummary.map((item, index) => {
        let checkBoxAns = [],
          answer = [],
          checkBoxOpt = [],
          checkboxUserAnsr = [],
          checkboxUserOption = [];
        let crtAns;
        if (item.type === "checkbox") {
          let selectedCorrectAnswers = item.user_answer;
          let formatedCorrectAnswers = [];
          for (let i in selectedCorrectAnswers) {
            formatedCorrectAnswers.push(Number(selectedCorrectAnswers[i]));
          }
          answer = formatedCorrectAnswers.toString();

          if (item.correct_answer.length > 0) {
            item.correct_answer &&
              item.correct_answer.forEach((iter, index) => {
                checkBoxAns.push(iter);
                checkBoxOpt.push(item.options[parseInt([iter])].option);
              });
            item.user_answer &&
              item.user_answer.forEach((iter) => {
                checkboxUserAnsr.push(iter);
                checkboxUserOption.push(item.options[parseInt([iter])].option);
              })
            checkboxUserAnsr = checkboxUserAnsr.toString();
            checkBoxAns = checkBoxAns.toString();
          }
        } else if (item.type === "objective") {
          answer = item.user_answer;
          crtAns = item.correct_answer;
        } else if (item.type === 'text') {
          answer = item.user_answer;
          crtAns = item.correct_answer
        }

        dataArr.push(
          <React.Fragment key={index}>
            <br />
            <Card className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={8}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className={classes.questionNo}>
                        Question: {index + 1} of {assessmentSummary.length}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {item.scenario && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.label}>Scenario</div>
                        <div className={classes.scenario}>{item.scenario}</div>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className={classes.label}>Question</div>
                      <div className={classes.question}>{item.question} </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {renderAnswerContainer(item.type, item.options, answer)}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={4}>
                  <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                    <h1>{`Your answer is ${item.iscorrect && item.iscorrect ? 'correct' : 'wrong'} `}</h1>
                  </div>
                  {/* <div style={{ display: "flex", paddingLeft: "1rem" }}>
                    <div className={classes.resultStatus}>Your answer: </div>
                    <div className={classes.answersWrapper}>
                      {item && item.user_answer && item.type === 'checkbox' ? (
                        // checkboxUserOption.toString()
                        <div style={{ marginTop: '-16px' }} dangerouslySetInnerHTML={{ __html: checkboxUserOption && checkboxUserOption.toString() }} />
                      ) : null}
                      {item && item.user_answer && item.type === 'objective' ? (
                        // item.options[parseInt(answer)].option
                        <div style={{ marginTop: '-16px' }} dangerouslySetInnerHTML={{ __html: item && item.options && item.options[parseInt(answer)] && item.options[parseInt(answer)].option }} />
                      ) : null}
                      {item && item.user_answer && item.type === 'text' ? item.user_answer : null}
                    </div>
                  </div> */}
                  {item && item.type !== 'text' ? (
                    <div style={{ display: "flex", paddingLeft: "1rem" }} aria-hidden="true">
                      <div className={classes.resultStatus}>Your answer is : </div>
                      <div className={classes.resultIcon}>
                        {item.iscorrect && item.iscorrect ? (
                          <CheckCircleIcon className={classes.crctIcon} aria-label="Correct" />
                        ) : (
                          <CancelIcon className={classes.wrngIcon} aria-label="Wrong" />
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div style={{ paddingLeft: "1rem" }}>
                    {item.type !== "text" && (
                      <React.Fragment>
                        <div className={classes.ansLabel}>Correct Answer:</div>
                        <div className={classes.correctAns}>
                          {item.correct_answer && item.type === "checkbox"
                            ? (
                              //checkBoxOpt.toString()
                              <div style={{ marginTop: '-16px' }} dangerouslySetInnerHTML={{ __html: checkBoxOpt && checkBoxOpt.toString() }} />
                            )
                            : null}
                          {item.correct_answer && item.type === "objective"
                            ? (
                              // item.options[parseInt(crtAns)].option
                              <div style={{ marginTop: '-16px' }} dangerouslySetInnerHTML={{ __html: item && item.options && item.options[parseInt(crtAns)] && item.options[parseInt(crtAns)].option }} />
                            )
                            : null}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </React.Fragment>
        );
      });
    return dataArr;
  };

  return (
    <React.Fragment>
      <Grid container className={classes.rprtHeader}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <div className={classes.assmntTitle}>{props.data.title}</div>
          <div className={classes.assmntDesc}>{props.data.desc}</div>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <div className={classes.score}>
            Your Score
            <div className={classes.percentile}>
              {props.data.score_percent}%
            </div>
          </div>
        </Grid>
      </Grid>
      {assessmentSummary && renderCard()}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  score: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0.4rem 0 0 0",
    textAlign: "center",
    //display: 'flex'
  },
  percentile: {
    color: style.fontColour._green,
    fontSize: "2rem",
    padding: "0 0 0 0.5rem",
    fontWeight: 600,
  },
  rprtHeader: {
    backgroundColor: "#fff",
  },
  crctIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    marginTop: '3px'
  },
  wrngIcon: {
    color: style.fontColour._red,
    fontSize: "1.5rem",
    marginTop: '3px'
  },
  resultGrid: {
    backgroundColor: "#fff",
  },
  correctAns: {
    padding: "0.5rem 0 0 0",
    color: style.fontColour._darkBlue,
    fontSize: style.fontSize._sideHeading,
    fontFamily: style.fontFamily._appFont,
  },
  answersWrapper: {
    color: style.fontColour._darkBlue,
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '5px',
    fontFamily: style.fontFamily._appFont,
  },
  resultStatus: {
    color: style.fontColour._green,
    paddingTop: "0.2rem",
    fontSize: style.fontSize._sideHeading,
    fontFamily: style.fontFamily._appFont,
  },
  ansLabel: {
    padding: "0 1rem 0 0",
    color: style.fontColour._green,
    fontSize: style.fontSize._sideHeading,
    fontFamily: style.fontFamily._appFont,
  },
  resultIcon: {
    paddingLeft: "0.5rem",
  },
  resultContainer: {
    padding: "1rem 1rem 1rem 1rem",
  },
  root: {
    width: "100%",
  },
  card: {
    padding: "1rem",
  },
  title: {
    color: style.fontColour._darkBlue,
    fontSize: style.fontSize._heading,
    textAlign: "center",
    fontFamily: style.fontFamily._appFont,
  },
  container: {
    paddingTop: "4rem",
  },
  questionNo: {
    padding: "0 0 0 2rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
  },
  label: {
    padding: "1rem 0 0 2rem",
    color: style.fontColour._blue,
    fontSize: "1.2rem",
  },
  scenario: {
    padding: "0.5rem 0 0 2rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
  },
  assmntDesc: {
    padding: "0.5rem 0 1rem 2rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
    textAlign: "justify",
  },
  assmntTitle: {
    padding: "0.5rem 0 1rem 2rem",
    color: style.fontColour._darkBlue,
    fontSize: "2rem",
  },
  question: {
    padding: "0.5rem 0 0 2rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
  },
  radioOptions: {
    padding: "0.5rem 0 0 2rem",
    color: style.fontColour._blue,
    fontSize: "1rem",
  },
  checkOptions: {
    padding: "0.5rem 0 0 2rem",
    color: style.fontColour._blue,
    fontSize: "1rem",
  },
  textArea: {
    padding: "0.5rem 0 0 2rem",
    color: style.fontColour._blue,
    fontSize: "1rem",
    width: "70%",
  },
  textAreaAnswer: {
    padding: "0.5rem 0 0 0",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
    width: "90%",
  },
}));
