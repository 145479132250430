import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const getQuestions = (payload, successCallBack, failureCallBack) => {

    const url = `${apiConfig.assessment.getQuestions}`
    axios.get(
        url, {
        params: {
            assessment_id: payload.assessmentId
        },
    }
    )
        .then(function (response) {
            let resp = {
                isCourseCompleted: true,
                questions: response.data.results
            }
            successCallBack(resp)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}


export const getTests = (payload, successCallBack, failureCallBack) => {
    let param = {};
    if (payload.type === 'course') {
        param = {
            course_id: payload.id
        }
    } else if (payload.type === 'module') {
        param = {
            module_id: payload.id
        }
    }
    else if (payload.type === 'lesson') {
        param = {
            lesson_id: payload.id
        }
    }
    const url = `${apiConfig.assessment.tests}`
    axios.get(
        url, {
        params: param
    }).then(function (response) {
        successCallBack(response.data)
    })
        .catch(function (error) {
            failureCallBack(error)
        })
}


export const submitAssessment = (payload, successCallBack, failureCallBack) => {


    const url = `${apiConfig.assessment.submitAssessment}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}



export const getReport = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.assessment.getReport}`
    axios.get(
        url, {
        params: {
            assessment_id: payload.assessmentId
        }
    }).then(function (response) {
        successCallBack(response.data.results)
    }).catch(function (error) {
        failureCallBack(error)
    })
}

const mockAssessmentsData = {
    totalCount: 20,
    totalCompleted: 10,
    results: [
        {
            "id": 102, //ASSESSMENT ID
            "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
            "percent": 90,
            "ques_count": 2,
            "score_percent": 0,
            "status": false,
            "test_type": "mandatory",
            "title": "Mock Mandatory Test",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 87,
            "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "percent": 100,
            "ques_count": 3,
            "score_percent": 34,
            "status": true,
            "test_type": "mandatory",
            "title": "Assessment Test-1",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 99,
            "desc": "desc",
            "percent": 90,
            "ques_count": 3,
            "score_percent": 100,
            "status": true,
            "test_type": "mandatory",
            "title": "Assessment Test-2",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 13,
            "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "percent": 100,
            "ques_count": 3,
            "score_percent": 34,
            "status": true,
            "test_type": "mandatory",
            "title": "Assessment Test-1",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 103,
            "desc": "desc",
            "percent": 90,
            "ques_count": 1,
            "score_percent": 100,
            "status": true,
            "test_type": "mandatory",
            "title": "Test assessment 08",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 104,
            "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "percent": 100,
            "ques_count": 3,
            "score_percent": 34,
            "status": true,
            "test_type": "mandatory",
            "title": "Obj test type",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 103,
            "desc": "desc",
            "percent": 90,
            "ques_count": 1,
            "score_percent": 0,
            "status": false,
            "test_type": "mandatory",
            "title": "Assessment Test-2",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 13,
            "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "percent": 100,
            "ques_count": 3,
            "score_percent": 34,
            "status": true,
            "test_type": "mandatory",
            "title": "Assessment Test-1",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 99,
            "desc": "desc",
            "percent": 90,
            "ques_count": 1,
            "score_percent": 0,
            "status": false,
            "test_type": "mandatory",
            "title": "Assessment Test-2",
            "due_date": "2020-06-03T10:14:49.422000Z"
        },
        {
            "id": 13,
            "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "percent": 100,
            "ques_count": 3,
            "score_percent": 34,
            "status": true,
            "test_type": "mandatory",
            "title": "Assessment Test-1",
            "due_date": "2020-06-03T10:14:49.422000Z"
        }
    ]
}

//for external assessments
export const fetchExternalAssessments = (payload, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assessment.fetchExternalAssessments}`
    axios.get(url, {
        params: payload
    })
    .then(function (response){
        successCallBack(response.data)
    })
    .catch(function (error){
        //failureCallBack(error)
        successCallBack(mockAssessmentsData)
    })
}

const mockAssessmentData = {
    "id": 13,
    "desc": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    "percent": 100,
    "ques_count": 3,
    "score_percent": 34,
    "status": true,
    "test_type": "mandatory",
    "title": "Assessment Test-1",
    "due_date": "2020-06-03T10:14:49.422000Z"
}

export const fetchSingleAssessment = (payload, successCallBack, failureCallBack) => {
    let url = `${apiConfig.assessment.fetchSingleAssessment}`
    axios.get(url, {
        params: payload
    })
    .then(function (response){
        successCallBack(response.data)
    })
    .catch(function (error){
        //failureCallBack(error)
        successCallBack(mockAssessmentData)
    })
}



