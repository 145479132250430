import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../router";
import Home from "../Components/Home/home";
import CourseInfo from "../Components/CourseInfo";
import Module from "../Components/Module";
import Login from "../Components/Login";
import Lesson from "../Components/Lesson";
import { connect } from "react-redux";
import Assessment from "../Components/Assessment";
import AssignmentList from "../Components/Assignment";
import Assignment from "../Components/Assignment/assignment";
import DiscussionList from "../Components/DiscussionBoard";
import DiscussionBoard from "../Components/DiscussionBoard/discussion";
import Community from "../Components/Community";
import Meetings from "../Components/Meetings";
import NoticeBoard from "../Components/NoticeBoard";
import Try from "./try";
import Profile from "../Components/Profile";
import MyVideos from "../Components/MyVideos";
import MyClasses from "../Components/ZoomClasses";
import VideoView from '../Components/MyVideos/video';
import ExternalAssessmentsList from '../Components/ExternalAssessments'
import ExternalAssignmentsList from '../Components/ExternalAssignments'
import ExternalAssessmentTest from '../Components/ExternalAssessments/assessmentTest';
import ExternalDiscussionsList from '../Components/ExternalDiscussions';
import ExternalDiscussionBoard from '../Components/ExternalDiscussions/discussionBoard';
import UserProfileView from '../Components/UserProfile';
import SnackBarmessages from "../Components/Shared/AlertMessages/snackBarMessages";
import { getLoggedInStatus, getDomainInfo } from '../Helpers/basic';

function Layout(props) {
  const { alertReducer } = props;
  const loggedInStatus = getLoggedInStatus();
  const domainInfo = getDomainInfo();
  useEffect(() => {
    if (!loggedInStatus && domainInfo.name === 'prep') {
      window.open("https://console.prep.continualengine.com/login", "_self")
    }
  }, [loggedInStatus])
  return (
    <React.Fragment>
      <Switch>
        <Route history={props.history} path="/:tenantId/ansrCourse1/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse2/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse3/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse4/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse5/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse6/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse7/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse8/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse9/index.html" />
        <Route history={props.history} path="/:tenantId/ansrCourse10/scormcontent/index.html" />
        <Route history={props.history} path="/:tenantId/caseys1/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_49/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_50/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_51/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_52/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_53/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_54/story.html" />
        <Route history={props.history} path="/:tenantId/caseys_55/story.html" />
        <Route history={props.history} path="/:tenantId/demo7/story.html" />
        <Route history={props.history} path="/:tenantId/demo8/story.html" />
        <Route history={props.history} path="/:tenantId/demo9/story.html" />
        <Route history={props.history} path="/:tenantId/demo10/story.html" />
        <Route history={props.history} path="/:tenantId/demo11/story.html" />
        <Route history={props.history} path="/:tenantId/demo12/story.html" />
        <Route history={props.history} path="/:tenantId/demo13/story.html" />
        <Route history={props.history} path="/:tenantId/demo14/story.html" />
        <Route history={props.history} path="/:tenantId/demo15/story.html" />
        <Route history={props.history} path="/:tenantId/demo16/story.html" />
        <Route history={props.history} path="/:tenantId/demo17/story.html" />
        <Route history={props.history} path="/:tenantId/demo18/story.html" />
        <Route history={props.history} path="/:tenantId/demo19/story.html" />
        <Route history={props.history} exact path="/" component={Login} />
        <Route history={props.history} path="/:tenantId/course/:courseId" component={CourseInfo} />
        <PrivateRoute history={props.history} path="/:tenantId/dashboard" component={Home} />
        <PrivateRoute history={props.history} path="/:tenantId/module/:courseId/:moduleId" component={Module} />
        <PrivateRoute history={props.history} path="/:tenantId/lesson/:courseId/:moduleId/:lessonId" component={Lesson} />
        <PrivateRoute history={props.history} path="/:tenantId/assessment/:id" component={Assessment} />
        <PrivateRoute history={props.history} path="/:tenantId/assignments/:id" component={AssignmentList} />
        <PrivateRoute history={props.history} path={["/:tenantId/assignment/:assignmentId", "/:tenantId/externalassignments/:assignmentId"]} component={Assignment} />
        <PrivateRoute history={props.history} path="/:tenantId/discussions/:id" component={DiscussionList} />
        <PrivateRoute history={props.history} path="/:tenantId/discussion/:discussionId" component={DiscussionBoard} />
        <PrivateRoute history={props.history} path="/:tenantId/profile" component={Profile} />
        <PrivateRoute history={props.history} path="/:tenantId/community" component={Community} />
        <PrivateRoute history={props.history} path="/:tenantId/meetings" component={Meetings} />
        <PrivateRoute history={props.history} path="/:tenantId/myvideos" component={MyVideos} />
        <PrivateRoute history={props.history} path="/:tenantId/myclasses" component={MyClasses} />
        <PrivateRoute history={props.history} path="/:tenantId/video/:videoId" component={VideoView} />
        <PrivateRoute history={props.history} exact path="/:tenantId/externalassignments" component={ExternalAssignmentsList} />
        <PrivateRoute history={props.history} exact path="/:tenantId/externalassessments" component={ExternalAssessmentsList} />
        <PrivateRoute history={props.history} exact path="/:tenantId/externalassessments/:assessmentId" component={ExternalAssessmentTest} />
        <PrivateRoute history={props.history} exact path="/:tenantId/externaldiscussions" component={ExternalDiscussionsList} />
        <PrivateRoute history={props.history} exact path="/:tenantId/externaldiscussion/:discussionId" component={ExternalDiscussionBoard} />
        <PrivateRoute history={props.history} path="/:tenantId/noticeboard" component={NoticeBoard} />
        <PrivateRoute history={props.history} path="/:tenantId/userprofile" component={UserProfileView} />
        <Route path="/try" component={Try} />
      </Switch>
      <SnackBarmessages state={alertReducer.state} message={alertReducer.message} type={alertReducer.type} />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    alertReducer: state.alertReducer,
  };
};
export default connect(mapStateToProps, {})(Layout);
