import React, { useEffect } from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from '@material-ui/core/Grid'
import Box from "@material-ui/core/Box";
import List from '@material-ui/core/List'
import { Route } from "react-router-dom"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { Link as RouterLink } from "react-router-dom"
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LinearProgress from "@material-ui/core/LinearProgress"
import VisibilityIcon from '@material-ui/icons/Visibility'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AdjustIcon from '@material-ui/icons/Adjust';
import { getCourseCategoryName } from '../../Helpers/basic'
import { style } from "../../Styles/theme"
import AthenaImage from '../../Static/myAthinaCourse.png'
import { filter } from 'lodash'
import { showSnackBar } from "../../Store/AlertMessages/actionCreator"
import { fetchMyVideos } from '../../Store/MyVideos/actionCreator'
import { myVideos_msgs } from '../Common/messages'
import Header from "../Header"
import MyVideosLoader from '../Shared/SkeletonLoaders/myVideosLoader'

const BorderLinearProgress = withStyles({
  root: {
    height: 7,
    backgroundColor: "#b0d6f9",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: style.fontColour._blue,
  },
})(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "5px" }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: '3px',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      marginTop: '10px',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: '3px',
    textAlign: 'center',
    width: '100%'
  },
  titleWrapper: {
    fontSize: '29px',
    fontWeight: '500',
    color: style.fontColour._blue,
    marginBottom: '11px',
    marginTop: '11px',
    textAlign: 'center'
  },
  cardWrapper: {
    maxWidth: 270,
    margin: "auto",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 10px 50px -12.125px rgba(0,0,0,0.3)",
      transition: 'transform 1s',
      transform: 'translateY(-4px)'
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    cursor: 'pointer'
  },
  cardTitle: {
    fontSize: '17px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'initial',
    lineBreak: 'anywhere'
  },
  progress: {
    marginTop: "0.5rem",
    borderRadius: "10px",
  },
  appBar: {
    backgroundColor: style.fontColour._blue,
    borderRadius: "5px",
  },
  avatar: {
    backgroundColor: style.fontColour._blue,
  },
  tab: {
    color: "#FFF",
  },
}))

function MyVideos(props) {
  const classes = useStyles()
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const morethan700px = useMediaQuery("(max-width:700px)");
  const [inProgress, setInprogress] = React.useState(false)
  const [videosData, setVideosData] = React.useState([])
  const tenantId = props.match.params.tenantId ? props.match.params.tenantId : ''
  const [videosSegData, setVideosSegData] = React.useState({
    completedVideos: [],
    pendingVideos: [] 
  })
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const videosSegregation = results => {
    return {
      pendingVideos: filter(results, function(data){
        return data && data.completion_rate < 100 
      }),
      completedVideos: filter(results, function(data){
        return data && data.completion_rate >= 100
      })
    }
  }

  const successFetchVideos = res => {
    setInprogress(false)
    setVideosData(res && res.results)
    const getSegregation = videosSegregation(res && res.results && res.results)
    setVideosSegData(getSegregation)
  }
  const failureFetchVideos = error => {
    setInprogress(false)
    setVideosData([])
    props.showSnackBar({
      state: true,
      message: myVideos_msgs.ERROR_FETCH_MYVIDEOS,
      type: "error",
    });
  }

  const listAllVideos = () => {
    fetchMyVideos({}, successFetchVideos, failureFetchVideos)
  }

  useEffect(()=>{
    setInprogress(true)
    listAllVideos()
  },[])

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>My Videos</div>
        </Breadcrumbs>
      </Route>
    );
  }

  const openVideoClick = (VideoId) => {
    props.history.push(`/${tenantId}/video/${VideoId}`)
  }

  const getProgressBar = (data) => {
    return(
      <React.Fragment>
        <div style={{ fontSize: style.fontSize._description, color: style.fontColour._grey}}>Progress: {`${ data && data.completion_rate && data.completion_rate}%`}</div>
        <BorderLinearProgress
          aria-label="watch progress"
          className={classes.progress}
          variant="determinate"
          color="secondary"
          value={data && data.completion_rate && data.completion_rate}
        />
      </React.Fragment>
    )
  }

  const loadAlterImage = evnt => {
    evnt.target.src = AthenaImage
  }
  const renderVideoList = (type) => {
    let arr = []
    let videoDataValue = []
    if(type === 'pending'){
      videoDataValue = videosSegData && videosSegData.pendingVideos && videosSegData.pendingVideos
    }else{
      videoDataValue = videosSegData && videosSegData.completedVideos && videosSegData.completedVideos
    }
    videoDataValue.length > 0 && arr.push(
      <React.Fragment key={type}>
        { videoDataValue && videoDataValue.length ? (
          <React.Fragment>
            <Grid container spacing={3} style={{ marginTop: '11px'}}>
              {videoDataValue.map((data, index)=>(
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={4} md={3} lg={3} xl={3} style={{ marginBottom: '10px'}}>
                    <Card className={classes.cardWrapper}>
                      <div style={{position: 'relative'}} >
                        <CardMedia
                          onError={loadAlterImage}
                          className={classes.media}
                          image={data && data.thumbnail_url ? data.thumbnail_url : AthenaImage }
                          title={data && data.title && data.title}
                          alt={data && data.title && data.title}
                          onClick={() => { openVideoClick(data.id)}}
                        />
                        <div style={{
                          position: 'absolute', 
                          color: 'white', 
                          top: 126, 
                          left: '86%',
                          backgroundColor: '#000000',
                          borderRadius: '3px',
                          paddingLeft: '3px',
                          paddingRight: '3px',
                          transform: 'translateX(-50%)'
                        }} >{data && data.duration && data.duration}</div>
                      </div>
                      <div>
                        { data && data.category && data.category ? (
                          <React.Fragment>
                            <AdjustIcon aria-label="views count" style={{ fontSize: '13px', color: style.fontColour._blue, marginBottom: '-2px', marginLeft: '13px', marginTop: '13px', marginRight: '3px' }}/><span style={{ fontSize: '13px'}}>
                            {getCourseCategoryName(data && data.category && data.category )}</span>
                          </React.Fragment>
                        ) : null }
                      </div>
                      <CardContent className={classes.cardTitle}>
                        { data && data.title && data.title.length > 28 ? (
                          data.title.substr(0,26) + '...'
                        ) : (
                          data && data.title
                        ) }
                      </CardContent>
                      <CardContent>
                        {getProgressBar(data && data)}
                      </CardContent>
                      <CardActions>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingLeft: '13px', paddingRight: '13px', marginBottom: '10px' }}>
                            <Button
                              aria-label="watch video"
                              onClick={() => { openVideoClick(data.id)}}
                              size="small"
                              variant="outlined"
                              startIcon={ data && data.completion_rate && data.completion_rate > 0 && type !== 'completed' ? <PlayCircleOutlineIcon/> : null }
                              style={{ color: style.fontColour._blue, border: '1px solid #2e8eec', fontSize: type === 'completed' ? '12px' : ''}}>
                              { type === 'completed' ? 'Watch again' : data && data.completion_rate && data.completion_rate > 0 ? 'Resume' : 'Watch now' }
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: '3px'}}>
                            <VisibilityIcon aria-label="views count" style={{ fontSize: '21px', color: style.fontColour._blue, marginBottom: '-5px' }}/> <span style={{ fontSize: '15px', marginTop: '4px', fontWeight: 400, color: style.fontColour._blue }}>{`${ data && data.views_count && data.views_count} views`}</span>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </React.Fragment>
        ) : (
          <div style={{ fontSize: '16px', marginBottom: '12px', color: style.fontColour._blue}}>No data found</div>
        )}
      </React.Fragment>
    )
    return arr;
  }

  return(
    <React.Fragment>
      <div>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div>
        <Grid container style={{ marginTop: morethan700px ? '118px' : "5rem", paddingLeft: "1rem" }}>
          {SimpleBreadcrumbs()}
        </Grid>
      </div>
      <div className={classes.root}>
        { inProgress ? (
          <MyVideosLoader/>
        ) : (
          <React.Fragment>
            <AppBar id="main" tabIndex="-1" position="static" className={classes.appBar} color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tab}
                indicatorColor="secondary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="My Videos" {...a11yProps(0)} />
                <Tab label="Completed" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <List>
                <Grid container spacing={2}>
                  { videosSegData && videosSegData.pendingVideos && videosSegData.pendingVideos.length ? (
                    renderVideoList('pending')
                  ) : (
                    <div style={{ fontSize: '19px', width: '100%', textAlign: 'center', marginTop: '17px'}}>No data found</div>
                  )}
                </Grid>
              </List>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <List>
                <Grid container spacing={2}>
                { videosSegData && videosSegData.completedVideos && videosSegData.completedVideos.length ? (
                  renderVideoList('completed')
                ) : (
                  <div style={{ fontSize: '19px', width: '100%', textAlign: 'center', marginTop: '17px'}}>No data found</div>
                )}
                </Grid>
              </List>
            </TabPanel>
          </React.Fragment>
        ) }
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(MyVideos);