import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SubjectIcon from "@material-ui/icons/Subject";
import IconButton from "@material-ui/core/IconButton";
import { style } from "../../Styles/theme";

export default function CommonHeader(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1280px)");
  const showMenu = !matches;

  const displayHeader = () => {
    if (props.layout === "course") {
      return (
        <Grid container>
          {props.data !== undefined && (
            <React.Fragment>
              <Grid item sm={2} xs={2} lg={1}>
                {showMenu ? (
                  <IconButton
                    className={classes.icon}
                    aria-label="module"
                    onClick={props.showDrawer}
                  >
                    <SubjectIcon />
                  </IconButton>
                ) : null}
              </Grid>
              <Grid
                item
                sm={4}
                xs={10}
                lg={10}
                className={classes.titleContainer}
              >
                <h1 id="main" tabIndex="-1" className={classes.title}>
                  {props.data.courseTitle}
                </h1>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      );
    } else if (props.layout === "module") {
      return (
        <Grid container>
          {props.data !== undefined && (
            <React.Fragment>
              <Grid
                item
                sm={12}
                xs={12}
                md={7}
                lg={8}
                className={classes.titleContainer}
              >
                <h1 id="main" tabIndex="-1" className="accessibility">
                  {props.data.courseTitle}
                </h1>
                <div className={classes.moduleTitle}>
                  {props.data.moduleTitle}
                </div>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={5}
                lg={4}
                className={classes.titleContent}
              >
                <div style={{ display: "flex" }}>
                  <div className={classes.mduration}>
                    Includes: {props.data.lessons} lessons
                  </div>
                </div>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      );
    } else if (props.layout === "lesson") {
      return (
        <Grid container>
          {props.data !== undefined && (
            <React.Fragment>
              <Grid
                item
                sm={12}
                xs={12}
                md={8}
                lg={8}
                className={classes.titleContainer}
              >
                <h1 id="main" tabIndex="-1" className="accessibility">
                  {props.courseTitle}
                </h1>
                <h2 className="accessibility">{props.moduleTitle}</h2>
                <div className={classes.lessonTitle}>
                  {props.data.lessonTitle}
                </div>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      );
    }
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerBox}>{displayHeader()}</div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#1c234a",
    //backgroundColor: style.fontColour._green,//#6bc685
    borderRadius: "3px",
  },
  titleContent: {
    paddingTop: "0.5rem",
  },
  headerBox: {
    display: "flex",
  },
  iconContainer: {
    height: "100%",
    justifyContent: "center",
    display: "flex",
    paddingRight: "2rem",
  },
  iconFrame: {
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
  },
  icon: {
    height: "100%",
    color: "#FFF",
    fontSize: "1.5rem",
  },
  iconText: {
    padding: "0rem 0.5rem 0 0",
    color: "#FFF",
    fontSize: "1.5rem",
  },
  title: {
    paddingLeft: "1rem",
    color: "#FFF",
    fontSize: "2rem",
  },
  mduration: {
    color: "#FFF",
    fontSize: style.fontSize._labels,
    textAlign: "center",
    padding: "1rem 0 1rem 1rem",
    cursor: "pointer",
  },
  duration: {
    color: "#FFF",
    fontSize: style.fontSize._labels,
    //textAlign: "center",
    padding: "1rem 0 1rem 1rem",
    cursor: "pointer",
  },
  quiz: {
    color: "#FFF",
    fontSize: style.fontSize._labels,
    textAlign: "end",
    padding: "1.5rem 0 1rem 1rem",
    cursor: "pointer",
  },
  assignment: {
    color: "#FFF",
    fontSize: style.fontSize._labels,
    textAlign: "left",
    padding: "1.5rem 0 1rem 1rem",
    cursor: "pointer",
  },
  moduleTitle: {
    padding: "1rem 0 0 1rem",
    color: "#FFF",
    fontSize: "24px",
  },
  lessonTitle: {
    padding: "1rem 0 1rem 1rem",
    color: "#FFF",
    fontSize: "23px",
    marginTop: "2px",
  },
}));
