import React from 'react';
import Skeleton from 'react-skeleton-loader';
import Grid from '@material-ui/core/Grid';


function contentCardLayoutLoader(props) {

    return (
        <div>
            <Skeleton height="80px" width="100%" widthRandomness={0} heightRandomness={0} color="#ccc" />
            <br />
            <br />
            <div>
                <Skeleton count={4} widthRandomness={0} heightRandomness={0} width="100%" color="#ccc" />
            </div>
            <br />
            <div style={{ 'display': 'flex' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} md={4}>
                        <Skeleton height="80px" width="100%" widthRandomness={0} heightRandomness={0} color="#ccc" />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <Skeleton height="80px" width="100%" widthRandomness={0} heightRandomness={0} color="#ccc" />
                    </Grid>
                    <Grid item xs={12} lg={4} md={4}>
                        <Skeleton height="80px" width="100%" widthRandomness={0} heightRandomness={0} color="#ccc" />
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}


export default contentCardLayoutLoader;