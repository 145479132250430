import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SpeedIcon from "@material-ui/icons/Speed";
import Badge from "@material-ui/core/Badge";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CommentIcon from "@material-ui/icons/Comment";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { Route } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { loadCSS } from "fg-loadcss";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { ListItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import Link from '@material-ui/core/Link';
//Local Imports
import Header from "../Header";
import { style } from "../../Styles/theme";
import { getDiscussionList } from "../../Store/Discussions/actionCreator";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import AssignmentListLoader from "../Shared/SkeletonLoaders/assignmentListLoader";
import { getDomainInfo } from '../../Helpers/basic';

function Discussions(props) {
  const classes = useStyles();
  const [discussionList, setDiscussionList] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState();
  const [completed, setCompleted] = useState();
  const [title, setTitle] = useState();
  const [courseTitle, setCourseTitle] = useState();
  const [moduleTitle, setModuleTitle] = useState();
  const [courseId, setCourseId] = useState();
  const [lessonTitle, setLessonTitle] = useState();
  const [moduleId, setModuleId] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);
  const [fetchType, setFetchType] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
    fetchDiscussionsInfo();
  }, []);

  const onPrepHomeClick = () => {
    const domainInfo = getDomainInfo();
    window.open(domainInfo.hostName, '_self');
  }

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          {getDomainInfo().name !== 'prep' ? (
            <RouterLink style={style.brdCrmbPrimary} to={"/" + tenantId + "/profile"}>
              Home
            </RouterLink>
          ) : (
            <Link style={{ color: '#3073AF', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }} onClick={() => { onPrepHomeClick() }}>
              Home
            </Link>
          )}
          <RouterLink style={style.brdCrmbPrimary} to={`/${tenantId}/course/${props.match.params.id}`}>
            {title && title}
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Discussions</div>
        </Breadcrumbs>
      </Route>
    );
  };

  const fetchDiscussionsInfo = () => {
    setLoading(true);
    let payload = {};
    payload = {
      course_id: props.match.params.id,
    };
    getDiscussionList(payload, successCB, failureCB);
  };

  const handleListItemClick = (event, discussionId, startsOn) => {
    // const currDate = new Date();
    // const today = moment(currDate).format('YYYY-MM-DD');
    // const startDate = moment(startsOn).format('YYYY-MM-DD');
    // const status = moment(today).isSameOrAfter(startDate, 'day');
    // if (status) {

    props.history.push(`/${tenantId}/discussion/${discussionId}`);
    // }
    // else {
    //     props.showSnackBar(
    //         {
    //             state: true,
    //             message: "Cannot Start Assignment before start date.",
    //             type: 'error'
    //         }
    //     )
    // }
  };

  const successCB = (res) => {
    setDiscussionList(res.results.discussions);
    setTitle(res.results.course_title);
    setLoading(false);
  };

  const failureCB = (error) => {
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container>
          <Header history={props} />
        </Grid>
      </div>
      <div className={classes.breadCrumbcontainer}>
        <Grid container style={{ marginTop: "6rem", paddingLeft: "1rem" }}>
          {discussionList && SimpleBreadcrumbs()}
        </Grid>
      </div>
      {!loading && (
        <div className={classes.headerContainer}>
          <div className={classes.headerBox}>
            <Grid container>
              <Grid item sm={12} xs={12} md={8} lg={10} className={classes.titleContainer}>
                <div id="main" tabIndex="-1" className={classes.title}>
                  {title}- Discussions
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <div className={classes.body}>
        <Container maxWidth={false}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              {loading ? (
                <AssignmentListLoader />
              ) : (
                <List>
                  {discussionList && discussionList.length > 0 ? (
                    <React.Fragment>
                      {discussionList &&
                        discussionList.length > 0 &&
                        discussionList.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ListItem>
                                <Divider />
                                <Grid container className={classes.listContainer}>
                                  <Grid item sm={1} xs={1} md={1} lg={1} className={classes.asIcon}>
                                    <QuestionAnswerIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item sm={6} xs={6} md={7} lg={7} style={{ marginTop: '-18px' }} >
                                    <Link
                                      component='button'
                                      className={classes.itemTitle}
                                      onClick={(event) => {
                                        handleListItemClick(event, item.id, item.start_date);
                                      }}
                                    >
                                      {item.title}
                                    </Link>
                                    <div className={classes.itemInfo}>
                                      Start Date: {item.start_date ? moment(item.start_date).format("DD-MMM-YYYY") : "NA"} &nbsp;&nbsp; Due Date:{" "}
                                      {moment(item.end_date).format("DD-MMM-YYYY")} &nbsp;&nbsp;{" "}
                                      {item.points && <React.Fragment>Pts: {item.points} &nbsp;&nbsp;</React.Fragment>}
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} md={2} lg={2} className={classes.itemContainer}>
                                    {item.score_user != null && (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                          <Tooltip title="Your Score" arrow aria-label={`Your score ${item.score_user}`}>
                                            <SpeedIcon className={classes.speedIcon} />
                                          </Tooltip>
                                          <div className={classes.gradeValue}>{item.score_user}</div>
                                          <div className={classes.gradePoint}>pts</div>
                                        </div>
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} xs={2} md={2} lg={2} className={classes.itemContainer}>
                                    <div style={{ display: "flex" }}>
                                      {item.attachment && (
                                        <Tooltip title="Download Material" arrow>
                                          <a href={item.attachment}>
                                            <CloudDownloadIcon className={classes.downloadIcon} />
                                          </a>
                                        </Tooltip>
                                      )}
                                      <IconButton aria-label={`Total Responses count ${item.reply_count}`}>
                                        <Badge badgeContent={item.reply_count} color="secondary" >
                                          <CommentIcon className={classes.commentIcon} />
                                        </Badge>
                                      </IconButton>
                                    </div>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  ) : (
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: "18px",
                        marginTop: "10px",
                      }}
                    >
                      No Discussions Found
                    </Typography>
                  )}
                </List>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(Discussions);

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },

  popoverContent: {
    fontStyle: "Italic",
    color: "#938d8d",
    padding: "0.5rem",
  },
  itemTitle: {
    textTransform: 'none',
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    padding: "0.2rem 0 0.2rem 0 ",
  },
  remarks: {
    fontSize: style.fontSize._description,
    color: style.fontColour._blue,
    padding: "1.4rem 0.5rem 0 1.5rem",
    textDecoration: "underline",
    cursor: "pointer",
  },
  gradeValue: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._tory_blue,
    padding: "1.2rem 0 0 0",
  },
  gradePoint: {
    fontSize: style.fontSize._description,
    color: style.fontColour._blue,
    padding: "1.5rem 0.2rem 0 0.2rem",
  },
  itemInfo: {
    padding: "0.2rem",
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._tory_blue,
  },
  asIcon: {
    textAlign: "center",
  },
  speedIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.3rem",
    padding: "1.3rem 0.5rem 0.5rem 0",
  },
  downloadIcon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    padding: "0.5rem 0.5rem 0 0",
    marginLeft: "10px",
    marginTop: "4px",
  },
  icon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
  },
  commentIcon: {
    color: style.fontColour._blue,
    fontSize: "2rem",
  },
  result: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
  },
  listContainer: {
    marginTop: "1rem",
    cursor: "pointer",
  },
  pointsIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.5rem",
    padding: "0 0 0 0.2rem",
  },
  startIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  completedIcon: {
    color: style.fontColour._green,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  draftIcon: {
    color: style.fontColour._tory_blue,
    fontSize: "1.5rem",
    padding: "0 0.5rem 0 0",
  },
  draftTxt: {
    padding: "1.2rem 0.5rem 1rem 1rem",
    fontSize: "0.8rem",
    color: style.fontColour._tory_blue,
  },
  gridContainer: {
    justifyContent: "center",
  },
  inline: {
    display: "inline",
    paddingRight: "0.5rem",
    color: style.fontColour._tory_blue,
  },

  headerContainer: {
    backgroundColor: "#1c234a",
    borderRadius: "3px",
    marginTop: "1rem",
    paddingTop: "1rem",
  },
  headerBox: {
    display: "flex",
  },
  title: {
    padding: "0 0 1rem 3rem",
    color: "#FFF",
    fontSize: style.fontSize._heading,
  },
  status: {
    padding: "0.5rem 0 1rem 1rem",
    color: "#FFF",
    fontSize: style.fontSize._labels,
  },
}));
