import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
//Local Imports
import myAthinaLogo from '../../Static/myAthina_Desktop.svg';
import { login, fetchProfile } from "../../Store/Auth/actionCreator";
import AlertMessages from "../Shared/AlertMessages";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { generateJwtToken, getDomainInfo } from "../../Helpers/basic";
import { ERROR_FETCHING_PROFILE } from "../Common/messages";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  myAthinaLogo: {
    width: "180px",
    verticalAlign: "top",
    marginTop: "23px",
    marginLeft: 10,
  },
  logoImg: {
    width: "44px",
    marginTop: "25px",
  },
  buttonProgress: {
    marginBottom: "10px",
    marginRight: "10px",
  },
  submit: {
    marginTop: "10px",
  },
}));

function LoginForm(props) {
  const classes = useStyles();
  const [userName, setUserName] = React.useState("");
  const [passWord, setPassWord] = React.useState("");
  const [inProgress, setInprogress] = React.useState(false);
  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: "",
  });
  const domainDetails = getDomainInfo();
  let clientImg = "";
  if (domainDetails.logo) {
    clientImg = domainDetails.logo;
  }

  const onUserNamehandleChange = (event) => {
    setUserName(event.target.value);
  };
  const onPasswordhandleChange = (event) => {
    setPassWord(event.target.value);
  };

  const successFetchProfile = (res) => {
    var cookieUserValue = generateJwtToken(res);
    const domainDetails = getDomainInfo();
    let myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);
    document.cookie =
      domainDetails.domainUserLabel +
      "=" +
      cookieUserValue +
      ";expires=" +
      myDate +
      ";domain=" +
      domainDetails.domainName;
  };

  const failureFetchProfile = (error) => {
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_PROFILE,
      type: "error",
    });
    let cookieDetails = getDomainInfo();
    document.cookie =
      cookieDetails.domainTokenLabel +
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" +
      cookieDetails.domainName;
    props.history.push("/");
  };

  const successCallBack = (res) => {
    setInprogress(false);
    const domainDetails = getDomainInfo();
    if (res.key) {
      let cookieTokenValue = res.key;
      let myDate = new Date();
      myDate.setMonth(myDate.getMonth() + 1);
      document.cookie =
        domainDetails.domainTokenLabel +
        "=" +
        cookieTokenValue +
        ";expires=" +
        myDate +
        ";domain=" +
        domainDetails.domainName;
      fetchProfile({}, successFetchProfile, failureFetchProfile);
      props.history.push(`/mdb-aa050520/profile`);
    }
    window.FreshworksWidget('hide')
  };
  const failureCallBack = (error) => {
    setValidationError({
      error: true,
      errorMessage: "Invalid credentials",
    });
    setInprogress(false);
  };
  const onLogin = (event) => {
    event.preventDefault();
    setValidationError({
      error: false,
      errorMessage: "",
    });

    setInprogress(true);
    if (userName !== "" && passWord !== "") {
      const payload = {
        user_name: userName,
        pwd: passWord,
      };
      login(payload, successCallBack, failureCallBack);
    } else {
      setValidationError({
        error: true,
        errorMessage: "Please fill all required fields.",
      });
      setInprogress(false);
    }
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {clientImg ? (
              <img
                src={clientImg}
                style={{ height: "40px", width: "180px", marginRight: "15px" }}
                alt="client.myathina logo"
              />
            ) : null}
            <img
              src={myAthinaLogo}
              className={classes.myAthinaLogo}
              alt="myathina logo"
            />
          </Typography>
          <Typography style={{ margin: 10 }}>
            Welcome to {window.location.host}
          </Typography>
          {validationError.error ? (
            <div
              style={{
                marginTop: "20px",
                width: "62%",
                marginBottom: "18px",
                display: "inline-block",
              }}
            >
              <AlertMessages
                status="error"
                message={validationError.errorMessage}
              />
            </div>
          ) : null}
          <form
            className={classes.form}
            noValidate
            onSubmit={(evnt) => {
              evnt.preventDefault();
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="userName"
              label="Email Address"
              name="email"
              onChange={onUserNamehandleChange}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={onPasswordhandleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(event) => {
                onLogin(event);
              }}
              disabled={inProgress}
            >
              {inProgress && (
                <CircularProgress
                  size={25}
                  className={classes.buttonProgress}
                />
              )}
              Sign In
            </Button>
          </form>
        </div>
      </Container>
    );
  };
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { showSnackBar })(LoginForm);
