import React, { useEffect, useState } from 'react';
import { loadCSS } from 'fg-loadcss';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SubjectIcon from '@material-ui/icons/Subject';
import { IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Typography from '@material-ui/core/Typography';
//Local Imports
import { style } from '../../Styles/theme';
import { redirectTo, getDomainInfo, getLoggedInStatus, convertUTCDatewithTime } from '../../Helpers/basic';
import { enrollCourse } from '../../Store/Enrollment/actionCreator';
import { getCourseCategoryName } from '../../Helpers/basic'
import { fetchCourseContributors } from '../../Store/Course/actionCreator';
import GoniyoGreenLogo from '../../Static/niyoGreenLogo.png'

export default function CourseHeader(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:1280px)');
    const showMenu = !matches;
    const [courseContributors, setCourseContributors] = useState([])

    const domainDetails = getDomainInfo();
    let clientImg = '';
    if (domainDetails.logo) {
        clientImg = domainDetails.logo
    }

    //FOR FETCHING SINGLE COURSE already added INSTRUCOTRS LIST BASED ON COURSE ID CALLBACKS
    const successCourseInstrList = res => {
        let contributors = [];
        if (res && res.data && res.data.length) {
            res.data.forEach((instructor) => {
                contributors.push(instructor);
            })
        }
        setCourseContributors(contributors);
    }
    const failureCourseInstrList = err => {
        setCourseContributors([]);
    }

    useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
        if (domainDetails && domainDetails.type === "b2b") {
            const payload = {
                course_id: props && props.data && props.data.courseId && Number(props.data.courseId)
            }
            fetchCourseContributors(payload, successCourseInstrList, failureCourseInstrList)
        }
    }, []);

    const enrollForCourse = () => {
        const loggedIn = getLoggedInStatus();
        const courseIdData = props && props.data && props.data.courseId
        if (loggedIn === true) {
            let payload = {
                course_id: courseIdData
            }
            enrollCourse(payload, successCB, failureCB);
        } else if (loggedIn === false) {
            let domainDet = getDomainInfo();
            if (domainDet.type === 'b2c') {
                let url = `${domainDet.hostName}/?doSignIn=true&navigateTo=rendering&courseId=${courseIdData}&tId=${props.history.match.params.tenantId}`;
                redirectTo(url, false)
            }
            else {
                props.history.push('/')
            }
        }
    }


    const successCB = res => {
        window.location.reload();
    }

    const failureCB = error => {

    }

    const displayIcon = () => {
        if (props.data.course_status === 'yet-to-start') {
            return (
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="#fff"
                        className={classes.button}
                        endIcon={<PlayCircleOutlineIcon className={classes.icon} />}
                    >
                        Start Course
                    </Button>
                </div>
            )
        } else if (props.data.course_status === 'completed') {
            return (
                <div>

                </div>
            )
        }
        else if (props.data.course_status === 'in-progress') {
            return (
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="#fff"
                        className={classes.button}
                        endIcon={<PlayCircleOutlineIcon className={classes.icon} />}
                    >
                        Resume Course
                    </Button>
                </div>
            )
        }
    }

    const renderButton = () => {
        let domainDet = getDomainInfo();
        if (domainDet.type === 'b2c' && props.data.role === 'student' && props.data.isEnrolled === false) {
            return <div className={classes.buttonContainer}><Button variant="contained" className={classes.button} onClick={() => { enrollForCourse() }} color="primary">Enroll Now</Button></div>
        }
    }

    const getProviderLogo = () => {
        const name = getDomainInfo().name
        console.log(name)
        switch (name) {
            case 'abcuniversity':
                return (<img src={clientImg} style={{ height: "42px", width: "68px", marginLeft: "10px", marginTop: '24px' }} alt={`${name} logo`} />)
            case 'falabella':
                return (<img src={clientImg} style={{ width: "123px", margin: "10px 4px 0px 4px", backgroundColor: '#fff', borderRadius: '3px' }} alt={`${name} logo`} />)
            case 'rmbl':
                return (<img src={clientImg} style={{ height: "62px", margin: "10px 0px 0px 10px" }} alt={`${name} logo`} />)
            case 'goniyo':
                return (<img src={GoniyoGreenLogo} style={{ height: "50px", widht: '50px', margin: "19px 15px 0px 5px" }} alt={`${name} logo`} />)
            case 'continualengine':
                return (<img src={clientImg} style={{ height: "62px", margin: "10px 0px 0px 10px" }} alt={`${name} logo`} />)
            case 'sandbox':
                return (<img src={clientImg} style={{ height: "62px", margin: "10px 0px 0px 10px" }} alt={`${name} logo`} />)
            case 'demo':
                return (<img src={clientImg} style={{ height: "62px", margin: "10px 0px 0px 10px" }} alt={`${name} logo`} />)
            case 'freo':
                return (<img src={clientImg} style={{ width: "90px", margin: "16px 15px 0px 5px" }} alt={`${name} logo`} />)
            case 'ansrsource':
                return (<img src={clientImg} style={{ height: "38px", width: "130px", margin: "24px 15px 0px 5px" }} alt={`${name} logo`} />)
            default:
                return (<img src={clientImg} style={{ height: "60px", width: "160px", margin: "10px 15px 0 5px" }} alt={`${name} logo`} />)
        }
    }
    const displayHeader = () => {
        return (
            <React.Fragment>
                <Grid container>
                    {props && props.type && props.type === 'myVideos' ? (
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                {props.data !== undefined &&
                                    <Grid container>
                                        <Grid item sm={11} xs={11} md={11} lg={11} className={classes.titleContainer}>
                                            <h1 id="main" tabIndex="-1" className={classes.title}>{props.data && props.data.title && props.data.title}</h1>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container>
                                    <Grid item xs={12} sm={3} md={2} lg={2} >
                                        <div className={classes.secondaryInfo}>Level: {props.data !== undefined && props.level ? props.level : 'NA'}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2} lg={2}>
                                        <div className={classes.secondaryInfo}>Duration: {props.data !== undefined && props.data.duration ? props.data.duration : 'NA'}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={7} lg={8} >
                                        <div className={classes.secondaryInfo}>Category: {props.data !== undefined && getCourseCategoryName(props.data && props.data.category && props.data.category)}</div>
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    {props.data !== undefined && props.data.tags && props.data.tags.length ? (
                                        <React.Fragment>
                                            <Grid item sm={12} xs={12} md={12} lg={12}>
                                                <div className={classes.secondaryInfo}>
                                                    Tags:
                                                    {props.data !== undefined && props.data.tags && props.data.tags.length && props.data.tags.map((data, index) => (
                                                        <React.Fragment key={index}>
                                                            <Chip label={data} variant="outlined" color="primary" style={{ marginTop: '6px', marginLeft: '10px', marginRight: '10px', color: '#ffff', border: '1px solid #ffff' }} />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </React.Fragment>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className={classes.providerContainer}>
                                    <div className={classes.providerLabel}>Provided By: </div>
                                    {clientImg ? (
                                        <React.Fragment>
                                            {getProviderLogo()}
                                        </React.Fragment>
                                    ) : props.data && props.data.provider !== null && props.data.provider !== undefined ? <div className={classes.providerName}>{props.data.provider}</div> : <div className={classes.providerName}>NA</div>}
                                </div>
                                <div className={classes.dateWrapper}>Published Date: {convertUTCDatewithTime(props.data && props.data.created_date && props.data.created_date)}</div>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                {props.data !== undefined &&
                                    <Grid container>
                                        {showMenu ? <Grid item sm={1} xs={1} md={1} lg={1}>
                                            <IconButton className={classes.headerIcon} aria-label="module" onClick={props.showDrawer}><SubjectIcon style={{ fontSize: 30 }} /></IconButton>
                                        </Grid> : null}
                                        <Grid item sm={11} xs={11} md={11} lg={11} className={classes.titleContainer}>
                                            <h1 id="main" tabIndex="-1" className={classes.title}>{props.data.courseTitle}</h1>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container>
                                    {domainDetails && domainDetails.name !== 'rmbl' ? (
                                        <Grid item xs={12} sm={3} md={2} lg={2} >
                                            <div className={classes.secondaryInfo}>Level: {props.data !== undefined && props.data.level ? props.data.level : 'NA'}</div>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12} sm={3} md={2} lg={2}>
                                        <div className={classes.secondaryInfo}>Duration: {props.data !== undefined && props.data.duration ? props.data.duration : 'NA'}</div>
                                    </Grid>
                                    {domainDetails && domainDetails.name !== 'rmbl' ? (
                                        <Grid item xs={12} sm={6} md={7} lg={8} >
                                            <div className={classes.secondaryInfo}>Category: {props.data !== undefined && props.data.category ? props.data.category : 'NA'}</div>
                                        </Grid>
                                    ) : null}
                                    {domainDetails && domainDetails.name === 'rmbl' ? (
                                        <React.Fragment>
                                            {courseContributors && courseContributors.length && courseContributors.length > 0 ? (
                                                <Grid item xs={12} sm={6} md={7} lg={8} >
                                                    <div style={{ display: 'flex' }}>
                                                        <div className={classes.secondaryInfo}>Instructors: </div>
                                                        {courseContributors && courseContributors.length > 0 && courseContributors.map((item, index) => {
                                                            return (
                                                                <div className={classes.secondaryInfo} key={index}>{item.instructor_name}{courseContributors[index + 1] ? ',' : ''}</div>
                                                            )
                                                        })}
                                                    </div>
                                                </Grid>
                                            ) : null}
                                        </React.Fragment>
                                    ) : (
                                        <Typography style={{ marginTop: '16px' }} variant="body2" color="textSecondary" component="p">
                                            <span className={classes.secondaryInfo}>Instructor: </span>
                                            <span className={classes.secondaryInfo}>{courseContributors && courseContributors.length && courseContributors[0] && courseContributors[0].instructor_name && courseContributors[0].instructor_name}</span>
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid container >
                                    {props.data !== undefined && props.data.tags && props.data.tags.length ? (
                                        <React.Fragment>
                                            <Grid item sm={12} xs={12} md={12} lg={12}>
                                                <div className={classes.secondaryInfo}>
                                                    Tags:
                                                    {props.data !== undefined && props.data.tags && props.data.tags.length && props.data.tags.map((data, index) => (
                                                        <React.Fragment key={index}>
                                                            <Chip label={data} variant="outlined" color="primary" style={{ height: '26px', marginLeft: '5px', marginRight: '-3px', color: '#ffff', border: '1px solid #ffff' }} />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </Grid>
                                        </React.Fragment>
                                    ) : null}
                                </Grid>
                                <Grid container >
                                    <Grid item sm={12} xs={12} md={12} lg={12}>
                                        <div className={classes.secondaryInfo}>Includes: {props.data !== undefined && props.data.modules ? props.data.modules : '0'} modules, {props.data !== undefined && props.data.lessons ? props.data.lessons : '0'} lessons, {props.data !== undefined && props.data.readings ? props.data.readings : '0'} readings </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className={classes.providerContainer}>
                                    <div className={classes.providerLabel}>Provided By: </div>
                                    {clientImg ? (
                                        <React.Fragment>
                                            {getProviderLogo()}
                                        </React.Fragment>
                                    ) : props.data && props.data.provider !== null && props.data.provider !== undefined ? <div className={classes.providerName}>{props.data.provider}</div> : <div className={classes.providerName}>NA</div>}
                                </div>
                                <div className={classes.providerContainer}>
                                    {props.data && renderButton()}
                                </div>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <div className={classes.headerContainer}>
            <div className={classes.headerBox}>
                {displayHeader()}
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    providerContainer: {
        display: 'flex',
        marginLeft: '0.5rem'
    },
    providerLabel: {
        color: "#FFF",
        fontSize: "1rem",
        paddingTop: "2rem",
        fontWeight: 500
    },
    providerName: {
        color: "#FFF",
        fontSize: "1rem",
        fontWeight: 500,
        paddingTop: "2rem",
        marginLeft: "0.3rem"
    },
    headerIcon: {
        height: "100%",
        color: "#FFF",
        padding: "1.8rem 1rem 0.5rem 0"
    },
    buttonContainer: {
        padding: "1rem 0 1rem 0",
    },
    progress: {
        display: 'flex',
        marginBottom: '1rem',

    },
    progressIcon: {
        color: style.fontColour._blue,
        padding: "0 0 0 1rem"
    },
    button: {
        backgroundColor: style.button._backgroundColor,
        color: '#FFF'
    },
    secondaryInfo: {
        color: "#FFF",
        fontSize: style.fontSize._labels,
        padding: "1rem 0 0 0.5rem"
    },
    headerContainer: {
        backgroundColor: "#1c234a",
        borderRadius: '3px'
    },
    headerBox: {
        padding: "0 0 1rem 1rem"
    },
    iconContainer: {
        display: "flex",
        padding: "0.5rem 0 0 0"
    },
    iconFrame: {
        justifyContent: "center",
    },
    iconButton: {
        color: "#FFF",
        fontSize: "1.5rem"
    },
    icon: {
        color: "#FFF",
        fontSize: "1.5rem"
    },
    iconText: {
        padding: "0rem 0.5rem 0 0",
        color: "#FFF",
        fontSize: "1.5rem"
    },
    title: {
        color: "#FFF",
        fontSize: "27px",
        fontWeight: 500,
        marginTop: '-10px',
        marginBottom: '1px'
    },
    titleContainer: {
        padding: "2rem 0 0 0.5rem",
    },
    dateWrapper: {
        color: "#FFF",
        fontSize: "1rem",
        paddingTop: "2rem",
        fontWeight: 500,
        marginLeft: '8px'
    },
}));
