import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { getCourses } from '../../Store/Course/actionCreator';
import { Link } from 'react-router-dom';
import { getLoggedInStatus } from '../../Helpers/basic';

function Home(props) {
    const classes = useStyles();
    const [courses, setCourses] = useState();
    const [tenantId, setTenantId] = useState(props.match.params.tenantId);

    useEffect(() => {
        setTenantId(props.match.params.tenantId)
        loadCourseData();
    })

    const loadCourseData = () => {
        getCourses({}, successCallBack, failureCallBack)
    }
    const successCallBack = res => {
        setCourses(res);
    }
    const failureCallBack = () => {
    }

    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth={false}>
                <Grid item xs={6}>
                    <Header props={props.history} />
                </Grid>
                <br />
                <Grid item xs={4}>
                    <div className={classes.cardContainer}>
                        {courses && courses.map((course, index) => {
                            return (<Card key={index} className={classes.card}>
                                <img alt={course.title} style={{ height: "160px", width: "140px" }} src={course.img} />
                                <div className={classes.courseInfo}>
                                    <Link to={`/${tenantId}/course/${course.id}`}>{course.title}</Link>
                                </div>
                            </Card>)
                        })}
                    </div>
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: "60px"
    },
    root: {
        display: 'flex',
    },
    card: {
        height: "200px",
        width: "140px",
        padding: "20px",
        marginLeft: "10px"

    },
    cardContainer: {
        display: 'flex',

    },
    courseInfo: {
        padding: "20px"
    }
}));

export default Home;
