import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { connect } from 'react-redux';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import BlockIcon from '@material-ui/icons/Block';
import Badge from '@material-ui/core/Badge';
//LOCAL IMPORTS
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../Store/AlertMessages/actionCreator';
import Pagination from '../ExternalAssignments/pagination';
import responseIcon from '../../Static/responseIcon.png';
import DeleteDiscussion from './deleteConfirm';
import PreviewForm from './preview';
import PublishPopup from './publishPopup';
import AssignToGrpsPopup from './assignToGrpsPopup';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 11,
    backgroundColor: '#2e8eec',
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '4px 4px',
  },
}))(Badge);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: '20px 5px 20px 3px' }} p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: style.backgroundColor._white,
  },
  headerTab: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  dataCount: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: '1.1rem'
  },
  tableHeadingCell: {
    padding: '12px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#777',
    backgroundColor: '#f9f9f9'
  },
  tableCell: {
    padding: '3px',
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  loadWrapper: {
    textAlign: 'left',
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '8px',
    color: style.fontColour._blue,
    marginLeft: '16px'
  },
  tabCtr: {
    color: style.backgroundColor._tabHeaderFnt
  },
  link: {
    color: style.fontColour._blue
  },
  tableContainer: {
    maxHeight: 800
  },
  icon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    marginRight: '10px'
  },
}));

function TabView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const scrollView = useMediaQuery('(max-width:640px)');
  const [value, setValue] = useState(0);
  const [previewData, setPreivewData] = React.useState({})
  const [openPreview, setOpenPreview] = React.useState(false)
  const [openPublishPopup, setOpenPublishPopup] = React.useState(false)
  const [openUnPublishPopup, setOpenUnPublishPopup] = React.useState(false)
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false)
  const [openAssignGrpsPopup, setOpenAssignGrpsPopup] = React.useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onTabChange(newValue)
  };

  const handleChangeIndex = index => {
    setValue(index);
  }

  const onDiscussionClick = (data) => {
    props.history.push(`externaldiscussion/${data.id}`)
  }

  const onPreviewTrigger = (data) => {
    setPreivewData(data)
    setOpenPreview(true)
  }
  const closePreview = () => {
    setOpenPreview(false)
    setPreivewData({})
  }
  const triggerDiscussionEdit = (data) => {
    props.triggerEditDiscussion(data)
  }

  const onDeleteTrigger = (data) => {
    setOpenDeletePopup(true)
    setPreivewData(data)
  }

  const closeDeletePopup = () => {
    setOpenDeletePopup(false)
    setPreivewData({})
  }

  const onPublishTrigger = (data) => {
    setPreivewData(data)
    setOpenPublishPopup(true)
  }
  const closePublishPopup = () => {
    setOpenPublishPopup(false)
    setPreivewData({})
  }

  const onUnPublishTrigger = (data) => {
    setPreivewData(data)
    setOpenUnPublishPopup(true)
  }
  const closeUnPublishPopup = () => {
    setOpenUnPublishPopup(false)
    setPreivewData({})
  }

  const onAssignGrpsTrigger = (data, type) => {
    let selData = {...data, 'mode': type }
    setPreivewData(selData)
    setOpenAssignGrpsPopup(true)
  }
  const closeAssignGrpsPopup = () => {
    setOpenAssignGrpsPopup(false)
    setPreivewData({})
    // props.listAllDiscussions()
  }

  const tabViewHeader = {
    createdDiscussions: {
      label: 'Created Discussions',
      count: props && props.tabCounts && props.tabCounts.created,
      list: props && props.discussionList && props.discussionList,
      name: 'created'
    },
    assignedDiscussions: {
      label: 'Assigned Discussions',
      count: props && props.tabCounts && props.tabCounts.assigned,
      list: props && props.discussionList && props.discussionList,
      name: 'assigned'
    }
  }

  const tabObj = tabViewHeader
  const renderTabs = () => {
    const label = (str, count) => {
      return (
        <div>
          <span className={classes.tabName}>{str}</span>
          <span className={classes.dataCount}>({count ? count : 0})</span>
        </div>
      )
    }
    const tabs = Object.keys(tabObj).map(key =>
      <Tab className={classes.headerTab} label={label(tabObj[key].label, tabObj[key].count)} {...a11yProps(key)} key={key} />
    )
    return tabs
  }

  const getTabData = (data, key) => {
    return(
      <React.Fragment>
        {data && data.length ? (
          <React.Fragment>
            {data && data.length && data.map((row, reqKey) => (
              <TableRow key={reqKey} >
                <TableCell scope="row" align="center" className={classes.tableCell}>
                  {row.published ? (
                    <Link component="button" href="javascript:void(0)" onClick={()=>{onDiscussionClick(row)}} className={classes.link}>
                      {row.title && row.title.length > 30 ? (
                        row.title.substr(0, 25) + '...'
                      ) : (
                        row.title && row.title
                      )}
                    </Link>
                  ) : (
                    <React.Fragment>
                      {row.title && row.title.length > 30 ? (
                        row.title.substr(0, 25) + '...'
                      ) : (
                        row.title && row.title
                      )}
                    </React.Fragment>
                  )}
                </TableCell>
                { value === 0 ? (
                  <React.Fragment>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      <IconButton aria-label="Preview Discussion" onClick={() => { onPreviewTrigger(row) }} className={classes.icon}>
                        <VisibilityIcon style={{ fontSize: 17 }} />
                      </IconButton>
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      <IconButton aria-label="Edit Discussion" disabled={row.published} onClick={() => { triggerDiscussionEdit(row) }} className={classes.icon}>
                        <EditIcon style={{ fontSize: 17 }} />
                      </IconButton>
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      <IconButton aria-label="Delete Discussion" onClick={() => { onDeleteTrigger(row) }} className={classes.icon}>
                        <DeleteIcon style={{ fontSize: 17 }} />
                      </IconButton>
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      { row.published_to && Object.keys(row.published_to) && !row.published_to.assigned_members_count ? ( 
                        <Link component="button" href="javascript:void(0)" aria-label="assign discussion to groups" onClick={()=>{onAssignGrpsTrigger(row, 'assign')}} className={classes.link}>
                          Assign
                        </Link>
                       ) : ( 
                        <Link component="button" href="javascript:void(0)" aria-label="assigned group members count" onClick={()=>{onAssignGrpsTrigger(row, 'editAssign')}} className={classes.link}>
                          {row && row.published_to && row.published_to.assigned_members_count && `${row.published_to.assigned_members_count} - Members(s)`} 
                        </Link>
                       )} 
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      <StyledBadge badgeContent={row.reply_count} max={9} color='secondary' aria-label={`Total responses count ${row.reply_count}`}>
                        <img src={responseIcon} aria-hidden="true" alt={`total response count ${row.reply_count}`} style={{ height: '24px', marginTop: '11px'}} aria-hidden="true"/>
                      </StyledBadge>
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      {row.published === false ? (
                        <Tooltip title="Publish">
                          <IconButton aria-label="Publish Discussion" onClick={() => { onPublishTrigger(row) }} className={classes.icon}>
                            <PublishIcon style={{ fontSize: 17 }} />
                          </IconButton>
                        </Tooltip>
                      ) : (
                          <Tooltip title="Unpublish">
                            <IconButton aria-label="UnPublish Discussion" onClick={() => { onUnPublishTrigger(row) }} className={classes.icon}>
                              <BlockIcon style={{ fontSize: 17 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                     <TableCell scope="row" align="center" className={classes.tableCell}>
                      {row && row.points ? row.points : 'NA'}
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      {row && row.created_by && row.created_by.name ? `${row.created_by.name} (${row.created_by.role})` : 'NA' }
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      <StyledBadge badgeContent={row.reply_count} max={9} color='secondary' aria-label={`Total responses count ${row.reply_count}`}>
                        <img src={responseIcon} aria-hidden="true" alt={`total response count ${row.reply_count}`} style={{ height: '24px', marginTop: '11px'}} aria-hidden="true"/>
                      </StyledBadge>
                    </TableCell>
                    <TableCell scope="row" align="center" className={classes.tableCell}>
                      {row && row.user_score !== 'null' ? row.user_score : 'NA'}
                    </TableCell>
                  </React.Fragment>
                ) }
              </TableRow>
            ))}
          </React.Fragment>
        ) : (
          <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'left', paddingLeft: '13px' }}>No data found</div>
        ) }
      </React.Fragment>
    )
  }

  const renderTabContentPanel = () => {
    const tabs = Object.keys(tabObj).map(key =>
      <TabPanel key={key} value={key} index={key} dir={theme.direction}>
        {tabObj[key] && tabObj[key].count ? (
          <React.Fragment>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} stickyHeader aria-label="Discussions listing">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableHeadingCell}>Title</TableCell>
                    { key === 'createdDiscussions' ? (
                      <React.Fragment>
                        <TableCell align="center" className={classes.tableHeadingCell}>Preview</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Edit</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Delete</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Assigned To</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Responses Count</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Publish/Unpublish</TableCell>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TableCell align="center" className={classes.tableHeadingCell}>Points</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>
                          Assigned By
                        </TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Responses Count</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>User Score</TableCell>
                      </React.Fragment>
                    ) }
                  </TableRow>
                </TableHead>
                <TableBody>
                  { props.paginationLoader ? (
                    <TableRow>
                      <TableCell className={classes.loadWrapper}>Loading...</TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      { tabObj[key] && tabObj[key].list && tabObj[key].list.length ? (
                        <React.Fragment>
                          {getTabData(tabObj[key].list, key)}
                        </React.Fragment>
                      ) : (
                        <TableRow>
                          <TableCell>No data found</TableCell>
                        </TableRow>
                      ) }
                    </React.Fragment>
                  ) }
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>No data found</div>
          </React.Fragment>
        )}
      </TabPanel >
    )
    return tabs
  }

  const getTabCount = () => {
    if(value === 0){
      return props && props.tabCounts && props.tabCounts.created
    }else{
      return props && props.tabCounts && props.tabCounts.assigned
    }
  }
  return(
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: style.backgroundColor._tabHeaderBg }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabCtr}
          variant={scrollView ? "scrollable" : 'fullWidth'}
          scrollButtons="auto"
          aria-label="External discussion tab view listing"
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {renderTabContentPanel()}
      </SwipeableViews>
      { !props.paginationLoader && getTabCount() > 10 ? (
        <Pagination onPagination={props.onPagination} enableRowsPerPage={false} page={props.page} rowsPerPage={props.rowsPerPage} totalCount={getTabCount()}/>
      ) : null }
      {openPreview ? (
        <PreviewForm
          openPreview={openPreview}
          closePreview={closePreview}
          previewDetails={previewData}
          from={'externalType'}
        />
      ) : null}
      {openUnPublishPopup ? (
        <PublishPopup
          match={props.match}
          history={props.history}
          listAllDiscussions={props.listAllDiscussions}
          discussionData={previewData}
          openMode='unPublish'
          from={'externalType'}
          openUnPublishPopup={openUnPublishPopup}
          closeUnpublish={closeUnPublishPopup}
        />
      ) : null}
      { openPublishPopup ? (
        <PublishPopup
          match={props.match}
          history={props.history}
          listAllDiscussions={props.listAllDiscussions}
          discussionData={previewData}
          openMode='publish'
          from={'externalType'}
          openPublishPopup={openPublishPopup}
          closePublish={closePublishPopup}
        />
      ) : null }
      { openDeletePopup ? (
        <DeleteDiscussion
          history={props.history}
          match={props.match}
          from={"externalType"}
          discussionData={previewData}
          openDeleteConfirm={openDeletePopup}
          closeDeleteDialog={closeDeletePopup}
          listAllDiscussions={props.listAllDiscussions}
          fetchTabCounts={props.fetchTabCounts}
        />
      ) : null }
      { openAssignGrpsPopup ? (
        <AssignToGrpsPopup
          match={props.match}
          history={props.history}
          fetchAllListData={props.listAllDiscussions}
          data={previewData}
          openAssignGrpsPopup={openAssignGrpsPopup}
          closePopup={closeAssignGrpsPopup}
        />
      ) : null }
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(TabView);