import React, { useState, useEffect } from "react";
import { loadCSS } from "fg-loadcss";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PollIcon from "@material-ui/icons/Poll";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { style } from "../../Styles/theme";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { getTenantId } from "../../Helpers/basic";

import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
function createMarkup(desc) {
  return {
    __html: desc,
  };
}

function CardLayout(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState();
  const [tenantId, setTenantId] = useState(props.match.params.tenantId);

  useEffect(() => {
    setTenantId(props.match.params.tenantId);
    loadCSS("https://use.fontawesome.com/releases/v5.12.0/css/all.css", document.querySelector("#font-awesome-css"));
  }, []);

  const anchorOpen = Boolean(anchorEl);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePopoverOpen = (event, brief) => {
    setPopoverContent(brief);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const displayStatusIcon = (status) => {
    switch (status) {
      case "yet-to-start":
        return (
          <div style={style.iconFrame}>
            <p style={style.iconTxt} aria-hidden="true" >Start</p>
            <Icon aria-label="start" className="fas fa-arrow-circle-right" style={style.icon} />
          </div>
        );

      case "in-progress":
        return (
          <div style={style.iconFrame}>
            <p style={style.iconTxt} aria-hidden="true" >Resume</p>
            <PollIcon aria-label="Resume" style={style.icon} />
          </div>
        );

      case "completed":
        return (
          <div style={style.iconFrame}>
            <Tooltip title="Completed" arrow>
              <CheckCircleIcon aria-label="completed" style={style.completedIcon} />
            </Tooltip>
          </div>
        );

      default:
        return (
          <div style={style.iconFrame}>
            <p style={style.iconTxt}>Start</p>
            <Icon className="fas fa-arrow-circle-right" style={style.icon} />
          </div>
        );
    }
  };

  const onModuleCardClick = (moduleId) => {
    props.history.push(`/${tenantId}/module/${props.match.params.courseId}/${moduleId}`);
  };

  const onLessonCardClick = (lessonId) => {
    props.history.push(`/${tenantId}/lesson/${props.match.params.courseId}/${props.match.params.moduleId}/${lessonId}`);
  };

  const renderCard = () => {
    let data = props.data;
    if (props.layout === "module") {
      return (
        data !== null &&
        data.length > 0 &&
        data.map((moduleInfo, index) => {
          let duration = "";
          if (moduleInfo.days !== 0) {
            let label = moduleInfo.days > 1 ? " days " : " day ";

            duration = duration + moduleInfo.days + label;
          }
          if (moduleInfo.hours !== 0) {
            let label = moduleInfo.hours > 1 ? " hours " : " hour ";
            duration = duration + moduleInfo.hours + label;
          }
          if (moduleInfo.minutes !== 0) {
            let label = moduleInfo.minutes > 1 ? " mins " : " min ";
            duration = duration + moduleInfo.minutes + label;
          }

          return (
            <ListItem className={classes.listItem} key={index}>
              <Paper
                elevation={3}
                className={classes.card}
                onClick={() => {
                  onModuleCardClick(moduleInfo.module_id);
                }}
              >
                <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                  <p>{`Module ${index + 1}`}</p>
                  <p>{moduleInfo && moduleInfo.title && moduleInfo.title}</p>
                  <p>{`Module Duration ${duration}`}</p>
                </div>
                <Grid container>
                  <Grid item xs={8} sm={8} md={8} lg={8} aria-hidden="true">
                    <div style={{ display: "flex", marginLeft: "0.3rem" }}>
                      <div className={classes.index} >{index + 1}.</div>
                      <div className={classes.title}>
                        {moduleInfo.title.length < 22 ? moduleInfo.title : moduleInfo.title.substring(0, 22) + "..."}
                      </div>
                    </div>
                    <div className={classes.duration}>{duration}</div>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button onClick={()=>{onModuleCardClick(moduleInfo.module_id)}} size="small" className={classes.iconContainer} aria-label="start module">{displayStatusIcon(moduleInfo.status, moduleInfo.id)}</Button>
                  </Grid>
                </Grid>
              </Paper>
            </ListItem>
          );
        })
      );
    } else if (props.layout === "lesson") {
      return (
        data !== null &&
        data.length > 0 &&
        data.map((courseModule, index) => {
          let cleanInfo = "";
          let descPreview = "";
          let showViewMore = false;
          if (courseModule.desc !== null) {
            cleanInfo = courseModule.desc.replace(/(<([^>]+)>)/gi, "");
            descPreview = cleanInfo.length < 35 ? cleanInfo : cleanInfo.substring(0, 35);
            showViewMore = cleanInfo.length < 35 ? false : true;
          }
          return (
            <ListItem className={classes.listItem}>
              <Paper
                elevation={3}
                id={index}
                key={index}
                className={classes.card}
                onClick={() => {
                  onLessonCardClick(courseModule.lesson_id);
                }}
              >
                <Grid container>
                  <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                    <p>{`Lesson ${index + 1}`}</p>
                    <p>{courseModule && courseModule.title && courseModule.title}</p>
                  </div>
                  <Grid item xs={8} sm={8} md={8} lg={8} aria-hidden="ture">
                    <div style={{ display: "flex", marginLeft: "0.8rem" }}>
                      <div className={classes.index}>{index + 1}.</div>
                      <div className={classes.title}>
                        {courseModule.title.length < 25 ? courseModule.title : courseModule.title.substring(0, 25) + "..."}
                      </div>
                    </div>
                    {descPreview !== "" && <div className={classes.brief}>{descPreview}</div>}
                    {showViewMore && (
                      <Typography
                        aria-owns={anchorOpen ? "mouse-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={(e) => {
                          handlePopoverOpen(e, cleanInfo);
                        }}
                        onMouseLeave={handlePopoverClose}
                        className={classes.briefLink}
                      >
                        View more...
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button size="small" onClick={()=>{onLessonCardClick(courseModule.lesson_id)}} className={classes.iconContainer} aria-label="start lesson">{displayStatusIcon(courseModule.lesson_status)}</Button>
                  </Grid>
                </Grid>
              </Paper>
            </ListItem>
          );
        })
      );
    }
  };

  return (
    <div className={classes.cardRoot}>
      {renderCard()}
      {props.isReaderAvailable && props.renderReadContent && (
        <ListItem className={classes.listItem}>
          <Paper
            component="button"
            elevation={3}
            className={classes.rmCard}
            onClick={() => {
              props.renderReadContent();
            }}
          >
            <Grid container>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div style={style.iconFrame}>
                  <LibraryBooksIcon className={classes.ricon} />
                </div>
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <div className={classes.readingMaterial}>Module - Take Aways ({props.isReaderAvailable.count})</div>
              </Grid>
            </Grid>
          </Paper>
        </ListItem>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Oops..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We understand your interest. Please buy the course to view modules in it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Buy Now
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={anchorOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popoverContent} dangerouslySetInnerHTML={createMarkup(popoverContent)}></div>
      </Popover>
    </div>
  );
}

export default withRouter(CardLayout);

const useStyles = makeStyles({
  cardRoot: {
    backgroundColor: style.backgroundColor._screen,
  },
  listItem: {
    padding: "1px 0 1px 0",
  },
  ricon: {
    color: style.fontColour._blue,
    fontSize: style.iconSize._medium,
    padding: "1.3rem 0.5rem 0 0",
  },
  icon: {
    color: style.iconColor,
    fontSize: "1.5rem",
    paddingTop: "1.8rem",
  },
  popoverContent: {
    fontStyle: "Italic",
    color: "#938d8d",
  },
  root: {
    maxWidth: 275,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "1rem",
    width: "20%",
  },
  rmCard: {
    cursor: "pointer",
    width: "96%",
    backgroundColor: style.backgroundColor._white,
    marginBottom: "0.3rem",
  },
  card: {
    cursor: "pointer",
    width: "97%",
    backgroundColor: style.backgroundColor._white,
    marginBottom: "0.5rem",
    border: "none",
    //overflow:'scroll'
    "&:hover": {
      cursor: "pointer",
      borderLeft: "2px solid #2e8eec",
      borderRadius: "2px",
    },
  },
  index: {
    color: style.fontColour._blue,
    padding: "0.7rem 0.2rem 0 0.2rem",
    fontSize: style.fontSize._labels,
  },
  readingMaterial: {
    padding: "1.5rem 0.5rem 1.5rem 0",
    fontSize: style.fontSize._labels,
    color: style.fontColour._blue,
    textAlign: "left",
  },
  title: {
    padding: "0.7rem 0.5rem 0.5rem 0",
    fontSize: style.fontSize._labels,
    color: style.fontColour._blue,
  },
  briefLink: {
    fontSize: "0.7rem",
    color: style.fontColour._blue,
    margin: "0.5rem 0 0.2rem 2rem",
  },
  brief: {
    fontSize: "0.8rem",
    color: style.fontColour._grey,
    fontStyle: "italic",
    display: "flex",
    marginLeft: "2rem",
  },
  duration: {
    fontSize: style.fontSize._smallDesc,
    fontStyle: "italic",
    color: style.fontColour._grey,
    textAlign: "initial",
    marginLeft: "1rem",
  },
  link: {
    textDecoration: "none",
  },
  iconContainer: {
    textTransform: 'none'
  }
});
