import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//Local Imports
import { style } from "../../Styles/theme";
import {
  getQuestions,
  submitAssessment,
} from "../../Store/Assessments/actionCreator";
import ListingLoader from '../Shared/SkeletonLoaders/listingLoader'

export default function AssessmentTest(props) {
  const classes = useStyles();
  const [quesList, setQuesList] = useState([]);
  const [qNo, setQNo] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [textAnswer, setTextAnswer] = useState();
  const [answers, setAnswers] = useState();
  const [correctOption, setCorrectOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [lenExceeded, setLenExceeded] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [fetchQuesLoader, setFetchQuesLoader] = useState(true)

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const onChangeCorrectOption = (event) => {
    const selectedVal = Number(event.target.value);
    let newCorrectOption = [];
    if (correctOption.indexOf(selectedVal) > -1) {
      newCorrectOption = correctOption.filter(function (value, index, arr) {
        return value !== selectedVal;
      });
    } else {
      newCorrectOption = [...correctOption];
      newCorrectOption.push(selectedVal);
    }
    setCorrectOption(newCorrectOption);
  };

  const isSelectedChecked = (key) => {
    return correctOption.indexOf(key) > -1;
  };

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleTextAnswer = (event) => {
    setTextAnswer(event.target.value);
    if (event.target.value.length > 999) {
      setLenExceeded(true);
    } else {
      setLenExceeded(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = () => {
    setFetchQuesLoader(true)
    let payload = {
      assessmentId: props.testId,
    };
    getQuestions(payload, successCallBack, failureCallBack);
  };

  const successCallBack = (res) => {
    setQuesList(res.questions);
    setFetchQuesLoader(false)
  };

  const failureCallBack = (error) => {
    setFetchQuesLoader(false)
  };

  const handlePrevious = (quesNo, type) => {
    fetchAnswer(quesNo);
    setQNo(qNo - 1);
  };

  const fetchAnswer = (questionNo) => {
    let data = {};
    if (quesList.length > 0) {
      data = quesList.find((obj) => obj.id == questionNo);
    }
    let ansObj = {};
    if (answers && answers.length > 0) {
      ansObj = answers.find((ansr) => ansr.question == questionNo);
    }
    if (Object.keys(data).length > 0 && data.type === "objective") {
      let ans =
        ansObj && Object.keys(ansObj).length > 0
          ? ansObj.answer.toString()
          : "";
      setRadioValue(ans);
    } else if (Object.keys(data).length > 0 && data.type === "checkbox") {
      let ans = ansObj && Object.keys(ansObj).length > 0 ? ansObj.answer : [];
      let selectedCorrectAnswers = ans;
      let formatedCorrectAnswers = [];
      for (let i in selectedCorrectAnswers) {
        formatedCorrectAnswers.push(Number(selectedCorrectAnswers[i]));
      }
      setCorrectOption(formatedCorrectAnswers);
    } else if (Object.keys(data).length > 0 && data.type === "text") {
      let ans = ansObj && Object.keys(ansObj).length > 0 ? ansObj.answer : "";
      setTextAnswer(ans);
    }
  };

  const saveAnswers = (quesNo, type) => {
    setShowAlertMsg(false);
    let quesAnsweredFlag = false;
    let ans = {};
    let ansArr = answers !== undefined ? [...answers] : [];
    let index;
    if (ansArr.length > 0) {
      index = ansArr.findIndex((obj) => obj.question == quesNo);
    }
    if (index !== -1) {
      ansArr.splice(index, 1);
    }
    if (type === "objective") {
      if (radioValue !== "") {
        ans = { question: quesNo, answer: parseInt(radioValue) };
        ansArr.push(ans);
        quesAnsweredFlag = true;
      }
    } else if (type === "checkbox") {
      if (correctOption.length > 0) {
        ans = { question: quesNo, answer: correctOption };
        ansArr.push(ans);
        quesAnsweredFlag = true;
      }
    } else if (type === "text") {
      if (textAnswer !== "") {
        ans = { question: quesNo, answer: textAnswer };
        ansArr.push(ans);
        quesAnsweredFlag = true;
      }
    }
    if (quesAnsweredFlag === true) {
      setAnswers(ansArr);
      if (qNo !== quesList.length - 1) {
        fetchAnswer(quesList[qNo + 1].id);
        setQNo(qNo + 1);
      } else {
        setDisableSubmitBtn(false);
      }
    } else {
      setShowAlertMsg(true);
    }
  };

  const postResults = () => {
    setInProgress(true);
    let payload = {
      assessment_id: props.testId,
      answers: answers,
    };
    submitAssessment(payload, submitSuccessCallBack, submitFailureCallBack);
  };

  const submitSuccessCallBack = (res) => {
    setInProgress(false);
    props.closeModal();
  };

  const submitFailureCallBack = (error) => {
    setInProgress(false);
  };

  const renderAnswerContainer = (type, options) => {
    if (type === "objective") {
      return (
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="answers radio"
            value={radioValue}
            onChange={handleChange}
          >
            {options.map((item) => {
              return (
                <div>
                  <FormControlLabel
                    className={classes.radioOptions}
                    value={item.id}
                    control={<Radio />}
                    label={
                      //item.option
                      <div dangerouslySetInnerHTML={{ __html: item && item.option && item.option }} />
                    }
                  />
                </div>
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    } else if (type === "checkbox") {
      {
        return (
          <FormControl component="fieldset">
            <Grid container>
              {options.map((item, index) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControlLabel
                      value={index}
                      control={
                        <Checkbox
                          id={`check_${index}`}
                          color="secondary"
                          checked={isSelectedChecked(index)}
                        />
                      }
                      label={
                        //item.option
                        <div dangerouslySetInnerHTML={{ __html: item && item.option && item.option }} />
                      }
                      labelPlacement="end"
                      className={classes.checkOptions}
                      onChange={onChangeCorrectOption}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormControl>
        );
      }
    } else if (type === "text") {
      return (
        <TextField
          error={lenExceeded}
          id="outlined-multiline-static"
          multiline
          rows="5"
          variant="outlined"
          value={textAnswer}
          helperText="Max:1000 chars"
          onChange={handleTextAnswer}
          className={classes.textArea}
          inputProps={{
            maxLength: 1000,
          }}
        />
      );
    }
  };

  const listLoader = () => {
    let children = []
    for (let i = 0; i < 3; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  return (
    <React.Fragment>
      {fetchQuesLoader ? (
        listLoader()
      ) : (
        <React.Fragment>
          {quesList.length > 0 && (
            <React.Fragment>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className={classes.questionNo}>
                    Question: {qNo + 1} of {quesList.length}
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                {quesList[qNo].scenario && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.label}>Scenario</div>
                    <div className={classes.scenario}>{quesList[qNo].scenario}</div>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className={classes.label}>Question</div>
                  <div className={classes.question}>{quesList[qNo].question} </div>
                </Grid>
              </Grid>
              <Grid container style={{ marginBottom: '100px' }}>
                {renderAnswerContainer(quesList[qNo].type, quesList[qNo].options)}
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Button
                className={classes.button}
                variant="contained"
                disabled={qNo == 0}
                onClick={() => {
                  handlePrevious(quesList[qNo - 1].id, quesList[qNo - 1].type);
                }}
                color="primary"
              >
                Previous Question
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {showAlertMsg && (
                <div style={{ color: "red", paddingTop: "1rem" }}>
                  Please answer current question. You can modify later.
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div style={{ display: "flex" }}>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => {
                    saveAnswers(quesList[qNo].id, quesList[qNo].type);
                  }}
                  color="primary"
                >
                  {qNo == quesList.length - 1 ? "Save" : "Save and Next"}
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  disabled={disableSubmitBtn}
                  color="primary"
                  onClick={() => {
                    handleDialogOpen();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once Submitted you would not be able retake assessment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={30} className={classes.buttonProgress} />
          ) : null}
          <Button
            onClick={handleDialogClose}
            disabled={inProgress}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              postResults();
            }}
            disabled={inProgress}
            color="primary"
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    margin: "1rem",
    backgroundColor: style.fontColour._blue,
  },
  dialogTitle: {
    color: style.fontColour._blue,
  },
  title: {
    color: style.fontColour._darkBlue,
    fontSize: style.fontSize._heading,
    textAlign: "center",
    fontFamily: style.fontFamily._appFont,
  },
  container: {
    paddingTop: "4rem",
  },
  paper: {
    height: "100%",
  },
  appBar: {
    top: "auto",
    bottom: 0,
    flexGrow: 1,
    backgroundColor: "#f6f6f6",
  },
  questionNo: {
    padding: "3rem 0 0 2rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
  },
  label: {
    padding: "1rem 0 0 2rem",
    color: style.fontColour._blue,
    fontSize: "1.5rem",
  },
  scenario: {
    padding: "0.5rem 0 0 4rem",
    color: style.fontColour._blue,
    fontSize: "1rem",
  },
  question: {
    padding: "0.5rem 0 0 4rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
  },
  radioOptions: {
    padding: "0.5rem 0 0 4rem",
    color: style.fontColour._blue,
    fontSize: "1rem",
  },
  checkOptions: {
    padding: "0.5rem 0 0 4rem",
    color: style.fontColour._blue,
    fontSize: "1rem",
  },
  textArea: {
    padding: "0.5rem 0 0 4rem",
    color: style.fontColour._darkBlue,
    fontSize: "1rem",
    width: "100%",
  },
  buttonProgress: {
    marginTop: "-5x",
  },
}));
