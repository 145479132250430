import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '300px'
  },
  imagWrapper: {
    marginTop: '20px',
  },
  txtWrapper: {
    width: '100%',
    marginTop: '6px',
  },
  searchWrapper: {
    backgroundColor: '#f5f5f5',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 6,
    width: '100%'
  },
})
)
export default function RecommendListingLoader(props) {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '-92px'}}>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.imagWrapper} >
              <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
            </div>
            <div className={classes.txtWrapper}>
              <Skeleton count={3} width="50%" color="#ccc" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
