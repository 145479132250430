import React from 'react';
import Skeleton from 'react-skeleton-loader';
import Grid from '@material-ui/core/Grid';

export default function CardListingLoader(props) {
  return(
    <div style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12}>
              <Skeleton count={2} height="20px" width="100%" color="#ccc" />
            </Grid>
        </Grid>
    </div>
  )
}