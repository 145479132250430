import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "typeface-roboto";
import axios from "axios";
//Local Imports
import Layout from "./Layout";
import { getDomainInfo } from "./Helpers/basic";

axios.interceptors.request.use(
  (config) => {
    const domainDet = getDomainInfo();
    let token = "";
    if (domainDet.domainTokenLabel !== undefined) {
      var cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (domainDet.domainTokenLabel === cookiePair[0].trim()) {
          token = decodeURIComponent(cookiePair[1]);
        }
      }
    }
    // token = "1bdd15a3dacdc50215cacc13e6e8941ac78b49c9"; // prabhu
    //token = "d85ddb87a1567c1159f426858e62967261c056b1" //laukik
    // token = "8e74fb1fea1337af37ebfe61ff543e1397bd5a0a"
    // token = "da2dea3e3bc6441b5d9e93f4635522e6a624d710"
    // token = "ac711b11b86095d327800d7cc481eac9519bde54" // Kalyan - RMBL Student
    // token = "bea06683fb33f4cd2eb3b11daea4c10ff964db9c" // mentor1
    // token = "f42db1c2456f2fdf1b7f0a4c2d2f453f22ec60cd" // mentor2
    //token = '7062c17dbe547805a1b3ab7f3225db3385882b97' //ansrsource laukik
    if (token !== "") {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {

//   if(error.response)
//   {
//   const status = error.response ? error.response.status : null;
//   // if(error.response.params.module_id && error.response.params.course_id)
//   // {
//   // }
//   if (401 === status) {
//     let isLoggedIn = getLoggedInStatus();
//     if (isLoggedIn == true) {
//     } else {
//     }
//   } else {
//     return Promise.reject(error);
//   }
// }
// else {

// }
// });

function App() {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Layout history={history} />
    </Router>
  );
}

export default App;
