import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Route } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Skeleton from 'react-skeleton-loader';
import Chip from '@material-ui/core/Chip';
//local imports
import Header from '../Header';
import { style } from "../../Styles/theme";
import { showSnackBar } from "../../Store/AlertMessages/actionCreator";
import { getUserDetails, getDomainInfo } from '../../Helpers/basic';
import { updateProfile, fetchProfile } from '../../Store/Auth/actionCreator';
import { fetchOrgGrpsList, fetchStudentMentors } from '../../Store/Discussions/actionCreator';
import ViewDetailsPopup from './viewDetailsPopup';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    backgroundColor: style.backgroundColor._lightWhite,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'left'
  },
  button: {
    marginTop: '17px',
    backgroundColor: style.fontColour._blue,
    textTransform: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  iconWrapper: {
    color: style.fontColour._blue
  },
  headWrapper: {
    color: style.fontColour._blue,
    fontWeight: 500
  },
  chipWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    height: '24px',
    color: style.fontColour._blue,
    border: "1px solid #2e8eec",
    marginLeft: '3px'
  },
}))

function UserProfileView(props) {
  const classes = useStyles();
  const morethan700px = useMediaQuery("(max-width:700px)");
  const userDetails = getUserDetails();
  const domainInfo = getDomainInfo();
  const userName = userDetails && userDetails.firstName ? `${userDetails.firstName} ${userDetails.lastName}` : userDetails.first_name ? `${userDetails.first_name} ${userDetails.last_name}` : null;
  const userType = userDetails && userDetails.roles && userDetails.roles.length ? userDetails.roles[0] : ''
  const [inProgress, setInProgress] = useState(false);
  const [defaultZoomApiKey, setDefaultZoomKey] = useState('')
  const [zoomApiKey, setZoomApiKey] = useState(defaultZoomApiKey);
  const [defaultZoomApiSecretKey, setDefaultZoomApiSecretKey] = useState('')
  const [zoomApiSecretKey, setZoomApiSecretKey] = useState(defaultZoomApiSecretKey)
  const [defaultZoomEmailId, setDefaultZoomEmailId] = useState('');
  const [zoomEmailId, setZoomEmailId] = useState(defaultZoomEmailId);
  const [showKeySecret, setShowSecretKey] = useState(false)
  const [grpsLoader, setGrpsLoader] = useState(true)
  const [orgGrpsList, setOrgGrpsList] = useState([]);
  const [openGrpDetailsPopup, setGrpDetailsPopup] = useState(false);
  const [selectedGrpData, setSelectedGrpData] = useState([]);
  const [mentorsLoader, setMentorsLoader] = useState(true);
  const [studentMentorsList, setStudentMentorsList] = useState([]);

  const [validation, setValidation] = useState({
    emailId: false,
    apiKey: false,
    apiSecretKey: false
  })

  const fetchProfileSuccess = res => {
    if (res && res.user && Object.keys(res.user) && Object.keys(res.user).length) {
      setDefaultZoomKey(res.user.zoom_api_key && res.user.zoom_api_key)
      setDefaultZoomApiSecretKey(res.user.zoom_api_secret_key && res.user.zoom_api_secret_key)
      setDefaultZoomEmailId(res.user.zoom_email_id && res.user.zoom_email_id)
      setZoomApiKey(res.user.zoom_api_key && res.user.zoom_api_key)
      setZoomApiSecretKey(res.user.zoom_api_secret_key && res.user.zoom_api_secret_key)
      setZoomEmailId(res.user.zoom_email_id && res.user.zoom_email_id)
    }
  }
  const fetchProfileFailure = err => { }

  //FOR FETCHING THE USER GROPS DETAILS CALLBACKS
  const successOrgGrpList = res => {
    setGrpsLoader(false)
    setOrgGrpsList(res && res.data)
  }
  const failureOrgGrpList = err => {
    setGrpsLoader(false)
    setOrgGrpsList([])
  }

  //FOR FETCHING STUDENT MENTORS LIST
  const successStudentMentors = res => {
    let studentMentr = res && res.data && res.data.length ? res.data : []
    let mentorsArrSet = []
    studentMentr.filter(function (mentor) {
      return mentorsArrSet.push(`${mentor.mentor_name} (${mentor.email})`)
    })
    setStudentMentorsList(mentorsArrSet)
    setMentorsLoader(false)
  }
  const failureStudentMentors = err => {
    setStudentMentorsList([])
    setMentorsLoader(false)
    props.showSnackBar({
      state: false,
      message: 'Something went wrong! While fetching student mentors!',
      type: 'error'
    })
  }

  useEffect(() => {
    if (domainInfo && domainInfo.name === 'rmbl') {
      fetchProfile({}, fetchProfileSuccess, fetchProfileFailure)
      fetchOrgGrpsList({}, successOrgGrpList, failureOrgGrpList)
      if (userType && userType === 'Student') {
        const payload = { student_id: userDetails.id }
        fetchStudentMentors(payload, successStudentMentors, failureStudentMentors)
      }
    }
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  const handleZoomKeysChange = (event, type) => {
    if (type === 'zoomemail') {
      setZoomEmailId(event.target.value)
    } else if (type === 'apiKey') {
      setZoomApiKey(event.target.value)
    } else {
      setZoomApiSecretKey(event.target.value)
    }
    setValidation({
      emailId: false,
      apiKey: false,
      apiSecretKey: false
    })
  }

  //ZOOM SEND KEYS CALLBACKS
  const successZoomSendKeys = res => {
    setInProgress(false)
    setShowSecretKey(false)
    props.showSnackBar({
      state: true,
      type: 'success',
      message: (defaultZoomApiKey || defaultZoomApiSecretKey) ? 'Zoom keys updated successfully' : 'Zoom keys added successfully'
    })
    fetchProfile({}, fetchProfileSuccess, fetchProfileFailure)
  }
  const failureZoomSendKeys = err => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      type: 'error',
      message: (defaultZoomApiKey || defaultZoomApiSecretKey) ? 'Something went wrong! While updating zoom keys!' : 'Something went wrong! While adding zoom keys!'
    })
  }

  //ON SUBMIT ZOOM KEYS
  const onSubmitKeys = () => {
    if (!zoomEmailId) {
      setValidation({
        emailId: true,
        apiKey: false,
        apiSecretKey: false
      })
    } else if (!zoomApiKey) {
      setValidation({
        emailId: false,
        apiKey: true,
        apiSecretKey: false
      })
    } else if (!zoomApiSecretKey) {
      setValidation({
        emailId: false,
        apiKey: false,
        apiSecretKey: true
      })
    } else {
      setValidation({
        emailId: false,
        apiKey: false,
        apiSecretKey: false
      })
      setInProgress(true)
      const payload = {
        zoom_email_id: zoomEmailId,
        zoom_api_key: zoomApiKey,
        zoom_api_secret_key: zoomApiSecretKey
      }
      updateProfile(payload, successZoomSendKeys, failureZoomSendKeys)
    }
  }

  const handleClickShowPassword = () => {
    setShowSecretKey(!showKeySecret)
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const SimpleBreadcrumbs = () => {
    return (
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon style={style.brdCrmbSeparator} fontSize="small" />} aria-label="Breadcrumb">
          <RouterLink style={style.brdCrmbPrimary} to="/">
            Home
          </RouterLink>
          <div style={style.brdCrmbSecondary}>Profile</div>
        </Breadcrumbs>
      </Route>
    );
  }

  const getLoaderView = count => {
    let children = []
    for (let i = 0; i < count; i++) {
      children.push(
        <Skeleton widthRandomness={0} key={i} count={1} width="15%" height='20px' color='#e0e0e0' borderRadius="25px" />
      )
    }
    return (
      children
    )
  }

  //FOR SHOWING THE GRP DETAILS IN POPUP
  const onViewGrpDetails = (type, data) => {
    setGrpDetailsPopup(true)
    let grpMembers = data && Object.keys(data) && Object.keys(data).length && data.group_members
    let grpMemNames = []
    grpMembers.filter(function (grp) {
      return grpMemNames.push(`${grp.name} (${grp.email}) - ${grp.role}`)
    })
    setSelectedGrpData(grpMemNames)
  }
  const onCloseMentees = () => {
    setGrpDetailsPopup(false)
    setSelectedGrpData([])
  }

  return (
    <div>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>View Profile Details</h1>
      </div>
      <Grid container>
        <Header history={props} />
      </Grid>
      <Grid container style={{ marginTop: morethan700px ? '118px' : "6rem", paddingLeft: '10px' }}>
        {SimpleBreadcrumbs()}
      </Grid>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography component="h6" variant="h5" align="center">
            <b style={{ color: style.fontColour._blue }}>User Profile</b>
          </Typography>
          <Typography variant="caption" display="block" className={classes.infoBlock}>
            <span className={classes.headWrapper}>Name: </span>
            <span>{userName}</span>
          </Typography>
          <Typography variant="caption" display="block" className={classes.infoBlock}>
            <span className={classes.headWrapper}>User Id: </span>
            <span>{userDetails.id}</span>
          </Typography>
          <Typography variant="caption" display="block" className={classes.infoBlock}>
            <span className={classes.headWrapper}>Email: </span>
            <span>{userDetails.username}</span>
          </Typography>
          <Typography variant="caption" display="block" className={classes.infoBlock}>
            <span className={classes.headWrapper}>Role: </span>
            <span>{userType}</span>
          </Typography>
          {domainInfo && domainInfo.name === 'rmbl' ? (
            <React.Fragment>
              { userType === 'Student' ? (
                <React.Fragment>
                  <Typography variant="caption" display="block" className={classes.infoBlock}>
                    <span className={classes.headWrapper}>My Group(s): </span>
                    {grpsLoader ? (
                      getLoaderView(2)
                    ) : (
                      <React.Fragment>
                        {orgGrpsList && orgGrpsList.length ? (
                          orgGrpsList.map((grp, index) => {
                            return (
                              <span key={index}>
                                <Chip
                                  variant="outlined"
                                  label={`${grp.group_title} - (${grp.members_count})`}
                                  className={classes.chipWrapper}
                                  onClick={() => { onViewGrpDetails('showgrpdetails', grp) }}
                                />
                              </span>
                            )
                          })
                        ) : (<span style={{ marginLeft: '3px', fontSize: '14px' }}>No groups found!</span>)}
                      </React.Fragment>
                    )}
                  </Typography>
                  <Typography variant="caption" display="block" className={classes.infoBlock}>
                    <span className={classes.headWrapper}>My Mentor(s): </span>
                    {mentorsLoader ? (
                      getLoaderView(2)
                    ) : (
                      <React.Fragment>
                        {studentMentorsList && studentMentorsList.length ? (
                          studentMentorsList.map((mentor, index) => {
                            return (
                              <span key={index}>
                                <Chip
                                  variant="outlined"
                                  label={mentor}
                                  className={classes.chipWrapper}
                                />
                              </span>
                            )
                          })
                        ) : (<span style={{ marginLeft: '3px', fontSize: '14px' }}>No mentors found!</span>)}
                      </React.Fragment>
                    )}
                  </Typography>
                </React.Fragment>
              ) : null}
              <Grid container spacing={1} style={{ marginTop: '10px' }}>
                <Grid item xs={12} sm={6} md={12} lg={6} xl={12}>
                  <TextField
                    className={classes.nameFields}
                    id="zoomemail"
                    name="Zoom Email"
                    label="Zoom Email ID"
                    helperText="The email which you use for creating zoom event"
                    required
                    error={validation.emailId}
                    fullWidth
                    onChange={(event) => { handleZoomKeysChange(event, 'zoomemail') }}
                    value={zoomEmailId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6} xl={12}>
                  <TextField
                    className={classes.nameFields}
                    id="API KEY"
                    name="Api Key"
                    label="Zoom API Key"
                    autoComplete="new-password"
                    required
                    error={validation.apiKey}
                    fullWidth
                    onChange={(event) => { handleZoomKeysChange(event, 'apiKey') }}
                    value={zoomApiKey}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '15px' }}>
                  <TextField
                    className={classes.nameFields}
                    id="API SECRET KEY"
                    name="Api SECRET Key"
                    autoComplete="new-password"
                    label="Zoom API Secret Key"
                    type={showKeySecret ? 'text' : 'password'}
                    required
                    error={validation.apiSecretKey}
                    fullWidth
                    onChange={(event) => { handleZoomKeysChange(event, 'secretKey') }}
                    value={zoomApiSecretKey}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle secret visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showKeySecret ? <Visibility className={classes.iconWrapper} /> : <VisibilityOff className={classes.iconWrapper} />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                onClick={onSubmitKeys}
                className={classes.button}
                disabled={inProgress}
              >
                {inProgress ? (
                  <CircularProgress size={25} className={classes.buttonProgress} />
                ) : (null)}
                {(defaultZoomApiKey || defaultZoomApiSecretKey) ? 'Update Zoom Keys' : 'Add Zoom Keys'}
              </Button>
            </React.Fragment>
          ) : null}
          {openGrpDetailsPopup ? (
            <ViewDetailsPopup
              open={openGrpDetailsPopup}
              close={onCloseMentees}
              data={selectedGrpData}
              type="showgrpdetails"
            />
          ) : null}
        </Paper>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(UserProfileView)