import axios from "axios";
import { apiConfig } from "../../Configs/apiConfig";

export const listEventsCalendar = (successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.getEventList}`;
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
      //successCallBack(calendarEventList);
    });
};

export const createMeetingEvent = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.createEvent}`;
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};

export const modifyMeetingEvent = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.modifyEvent}`;
  axios.patch(url, formData)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};

export const deleteMeetingEvent = (meetingId, successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.deleteEvent}${meetingId}/`;
  axios.delete(url)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};


const calendarEventList = {
  "data": [
    {
      "id": 13,
      "author": 8,
      "course": 3,
      "meeting_type_display_name": "Scheduled Meeting",
      "start_time": "2021-03-05T04:01:00Z",
      "end_time": "2021-03-05T07:31:00Z",
      "duration": 30,
      "topic": "Python Basics",
      "meeting_type": 2,
      "agenda": "Testing the events",
      "meeting_id": "92884817602",
      "status": "waiting",
      "meeting_timezone": "UTC",
      "start_url": "https://zoom.us/s/92884817602?zak=eyJ6bV9za20iOiJ6bV9vMm0iLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjbGllbnQiLCJ1aWQiOiJtVnVVaFZqX1FKV3JYRk83T0lXUzRBIiwiaXNzIjoid2ViIiwic3R5IjoxMDAsIndjZCI6ImF3MSIsImNsdCI6MCwic3RrIjoiSG1YaGtsdklnRzEwUXRzLVpZd3lXM1I0elpQc05LeUdSVnRBUlZ5UWlhay5CZ1VnYlRZclRYcDZRazlZTmpaSVl6RmtkM1JXTURKUmF6WjNiek5KVERNemIwWkFOalZsTnpFNE5EWXdNak5pWlRBME56STBaR0V5TjJGbU1UZGtNMlV6WVRaa01XWTFOamRrTlRFNE5HRTRZV0l4T0ROaU9HVTRaV0ZpTldZMlpHUXlNQUFnWmpSRGJITlJOaTl5Y1RNMVRGcFpjazFpYTNGQ2IxSkVSRVU0WlVremJWVUFBMkYzTVEiLCJleHAiOjE1OTI4NDUyNzMsImlhdCI6MTU5MjgzODA3MywiYWlkIjoiRGs4dk5uZkxUU0NDaWUxa0I4RG9PQSIsImNpZCI6IiJ9.g1lu1SxvAtKQhtArhs2joDMOeevBHGcKZIATlhlSf3U",
      "join_url": "https://zoom.us/j/92884817602?pwd=Qjdwamp1MGdBa0VTdU9SUzA1OWdGQT09",
      "password": "I97WN4RDW1",
      "encrypted_password": "Qjdwamp1MGdBa0VTdU9SUzA1OWdGQT09",
      "host_video": null,
      "participant_video": null,
      "join_before_host": null,
      "mute_upon_entry": null,
      "watermark": true,
      "approval_type": 2,
      "auto_recording": "cloud",
      "waiting_room": null,
      "registrants_email_notification": null,
      "meeting_authentication": null,
      "recurrence_type": null,
      "recurrence_repeat_interval": null,
      "weekly_days": null,
      "is_host": true,
      "assigned_to": [
        {
          "id": 6,
          "group_title": "Group-6",
          "group_members": [
            { "id": 10, "name": "Denis", "email": "140793@dummy.com", "role": "Student" },
            { "id": 11, "name": "Jessy", "email": "140794@dummy.com", "role": "Student" }]
        }
      ],
      "host_details": {
        "name": 'Test user1',
        "email": 'Test@dummy.com'
      },
    },
    {
      "id": 14,
      "author": 8,
      "course": 3,
      "meeting_type_display_name": "Scheduled Meeting",
      "start_time": "2021-03-05T08:01:00Z",
      "end_time": "2021-03-05T10:31:00Z",
      "duration": 50,
      "topic": "Test meeting-2",
      "meeting_type": 2,
      "agenda": "Testing the events-2",
      "meeting_id": "9288481760212",
      "status": "waiting",
      "meeting_timezone": "UTC",
      "start_url": "https://zoom.us/s/92884817602?zak=eyJ6bV9za20iOiJ6bV9vMm0iLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjbGllbnQiLCJ1aWQiOiJtVnVVaFZqX1FKV3JYRk83T0lXUzRBIiwiaXNzIjoid2ViIiwic3R5IjoxMDAsIndjZCI6ImF3MSIsImNsdCI6MCwic3RrIjoiSG1YaGtsdklnRzEwUXRzLVpZd3lXM1I0elpQc05LeUdSVnRBUlZ5UWlhay5CZ1VnYlRZclRYcDZRazlZTmpaSVl6RmtkM1JXTURKUmF6WjNiek5KVERNemIwWkFOalZsTnpFNE5EWXdNak5pWlRBME56STBaR0V5TjJGbU1UZGtNMlV6WVRaa01XWTFOamRrTlRFNE5HRTRZV0l4T0ROaU9HVTRaV0ZpTldZMlpHUXlNQUFnWmpSRGJITlJOaTl5Y1RNMVRGcFpjazFpYTNGQ2IxSkVSRVU0WlVremJWVUFBMkYzTVEiLCJleHAiOjE1OTI4NDUyNzMsImlhdCI6MTU5MjgzODA3MywiYWlkIjoiRGs4dk5uZkxUU0NDaWUxa0I4RG9PQSIsImNpZCI6IiJ9.g1lu1SxvAtKQhtArhs2joDMOeevBHGcKZIATlhlSf3U",
      "join_url": "https://zoom.us/j/92884817602?pwd=Qjdwamp1MGdBa0VTdU9SUzA1OWdGQT09",
      "password": "I97WN4RDW1",
      "encrypted_password": "Qjdwamp1MGdBa0VTdU9SUzA1OWdGQT09",
      "host_video": null,
      "participant_video": null,
      "join_before_host": null,
      "mute_upon_entry": null,
      "watermark": true,
      "approval_type": 2,
      "auto_recording": "local",
      "waiting_room": null,
      "registrants_email_notification": null,
      "meeting_authentication": null,
      "recurrence_type": null,
      "recurrence_repeat_interval": null,
      "weekly_days": null,
      "is_host": true,
      "assigned_to": [
        {
          "id": 6,
          "group_title": "Group-6",
          "group_members": [
            { "id": 10, "name": "Denis", "email": "140793@dummy.com", "role": "Student" },
            { "id": 11, "name": "Jessy", "email": "140794@dummy.com", "role": "Student" }]
        }
      ],
      "host_details": {
        "name": 'Test user2',
        "email": 'Test@dummy.com'
      },
    },
    {
      "id": 15,
      "author": 8,
      "course": 3,
      "meeting_type_display_name": "Scheduled Meeting",
      "start_time": "2021-03-05T11:01:00Z",
      "end_time": "2021-03-05T13:31:00Z",
      "duration": 50,
      "topic": "Test meeting-3",
      "meeting_type": 2,
      "agenda": "Testing the events-3",
      "meeting_id": "9288481760213",
      "status": "waiting",
      "meeting_timezone": "UTC",
      "start_url": "https://zoom.us/s/9288481760213?zak=eyJ6bV9za20iOiJ6bV9vMm0iLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjbGllbnQiLCJ1aWQiOiJtVnVVaFZqX1FKV3JYRk83T0lXUzRBIiwiaXNzIjoid2ViIiwic3R5IjoxMDAsIndjZCI6ImF3MSIsImNsdCI6MCwic3RrIjoiSG1YaGtsdklnRzEwUXRzLVpZd3lXM1I0elpQc05LeUdSVnRBUlZ5UWlhay5CZ1VnYlRZclRYcDZRazlZTmpaSVl6RmtkM1JXTURKUmF6WjNiek5KVERNemIwWkFOalZsTnpFNE5EWXdNak5pWlRBME56STBaR0V5TjJGbU1UZGtNMlV6WVRaa01XWTFOamRrTlRFNE5HRTRZV0l4T0ROaU9HVTRaV0ZpTldZMlpHUXlNQUFnWmpSRGJITlJOaTl5Y1RNMVRGcFpjazFpYTNGQ2IxSkVSRVU0WlVremJWVUFBMkYzTVEiLCJleHAiOjE1OTI4NDUyNzMsImlhdCI6MTU5MjgzODA3MywiYWlkIjoiRGs4dk5uZkxUU0NDaWUxa0I4RG9PQSIsImNpZCI6IiJ9.g1lu1SxvAtKQhtArhs2joDMOeevBHGcKZIATlhlSf3U",
      "join_url": "https://zoom.us/j/9288481760213?pwd=Qjdwamp1MGdBa0VTdU9SUzA1OWdGQT09",
      "password": "I97WN4RDW1",
      "encrypted_password": "Qjdwamp1MGdBa0VTdU9SUzA1OWdGQT09",
      "host_video": null,
      "participant_video": null,
      "join_before_host": null,
      "mute_upon_entry": null,
      "watermark": true,
      "approval_type": 2,
      "auto_recording": "none",
      "waiting_room": null,
      "registrants_email_notification": null,
      "meeting_authentication": null,
      "recurrence_type": 2,
      "recurrence_repeat_interval": null,
      "weekly_days": "1,2,3,4",
      "is_host": false,
      "assigned_to": [
        {
          "id": 6,
          "group_title": "Group-6",
          "group_members": [
            { "id": 10, "name": "Denis", "email": "140793@dummy.com", "role": "Student" },
            { "id": 11, "name": "Jessy", "email": "140794@dummy.com", "role": "Student" }]
        }
      ],
      "host_details": {
        "name": 'Test user3',
        "email": 'Test@dummy.com'
      },
    },
    {
      "agenda": "Recurrence meeting test",
      "alternative_hosts": null,
      "approval_type": null,
      "audio": "both",
      "authentication_domains": null,
      "authentication_name": null,
      "authentication_option": null,
      "author": 1,
      "auto_recording": "cloud",
      "close_registration": true,
      "cn_meeting": false,
      "contact_email": "laukik.sarode@continualengine.com",
      "contact_name": "Laukik Sarode",
      "course": null,
      "created_at": "2021-03-05T06:33:41.198707Z",
      "created_date": "2021-03-05T06:33:41.198924Z",
      "duration": 30,
      "encrypted_password": "WWo2SHpHdkd2K0x6emE2WlErWUU0QT09",
      "end_time": "2021-03-05T19:00:00Z",
      "end_times": 1,
      "enforce_login": false,
      "enforce_login_domains": null,
      "event_host": 2,
      "h323_password": "4611905760",
      "host_id": "CldOHuxxSdqNLfauVahGyA",
      "host_video": false,
      "id": 26,
      "in_meeting": false,
      "is_active": true,
      "join_before_host": false,
      "join_url": "https://us02web.zoom.us/j/83576861370?pwd=WWo2SHpHdkd2K0x6emE2WlErWUU0QT09",
      "meeting_authentication": false,
      "meeting_id": "83576861370",
      "meeting_timezone": "UTC",
      "meeting_type": 8,
      "meeting_type_display_name": "Recurring Meeting with fixed time",
      "meeting_uuid": "AL5L1D15TGW93gI36K/Epw==",
      "modified_date": "2021-03-05T06:33:41.198939Z",
      "mute_upon_entry": false,
      "participant_video": false,
      "password": "IXEPKUNXBD",
      "pstn_password": "4611905760",
      "recurrence_repeat_interval": 1,
      "recurrence_type": 2,
      "registrants_confirmation_email": true,
      "registrants_email_notification": false,
      "registration_type": 1,
      "start_time": "2021-03-05T18:30:00Z",
      "start_url": "https://us02web.zoom.us/s/83576861370?zak=eyJ6bV9za20iOiJ6bV9vMm0iLCJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJjbGllbnQiLCJ1aWQiOiJDbGRPSHV4eFNkcU5MZmF1VmFoR3lBIiwiaXNzIjoid2ViIiwic3R5IjoxLCJ3Y2QiOiJ1czAyIiwiY2x0IjowLCJzdGsiOiIyb01GLUZ5dVA1UDlmaVIwczJvTzBkdXZxS0RXTmdveWVyWDhERmdvMGZzLkFHLjBCbm9XYW0wU0IzOXVqbnJWaFo5V2NQSGhLVGlHRDZBV0V2dTRKWWRfbkVIbEgwbjVlOGxMMTNQbVVFQ2tjcThaNEYwS19DRW9Va1UxUGkyLjlXeG9xS2JEMHhjeFJBdUdtX1dNOUEuXzdtVW5TMndudlFBUllTUiIsImV4cCI6MTYxNDkzMzIyMCwiaWF0IjoxNjE0OTI2MDIwLCJhaWQiOiJHZm5wb0pDQ1FPQ09MbmxwN2RCRnRRIiwiY2lkIjoiIn0.xl8PCTriGEs8HSK0f09E2Pwl80xhQ3SGQKaOIgYKoig",
      "status": "waiting",
      "topic": "RecurrenceTypeMeeting",
      "use_pmi": false,
      "waiting_room": false,
      "watermark": false,
      "weekly_days": "1,2,3,4,5,6",
      "is_host": true,
      "assigned_to": [
        {
          "id": 6,
          "group_title": "Group-6",
          "group_members": [
            { "id": 10, "name": "Denis", "email": "140793@dummy.com", "role": "Student" },
            { "id": 11, "name": "Jessy", "email": "140794@dummy.com", "role": "Student" }]
        }
      ],
      "host_details": {
        "name": 'Test user3',
        "email": 'Test@dummy.com'
      },
    }
  ],
  "message": "Retrieved 7 events",
};
